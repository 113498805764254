import i18next from "../../../../../utils/i18n/i18next";

export const FETCH_CART_ITEMS_PENDING = "FETCH_CART_ITEMS_PENDING";
export const FETCH_CART_ITEMS_SUCCESS = "FETCH_CART_ITEMS_SUCCESS";
export const FETCH_CART_ITEMS_ERROR = "FETCH_CART_ITEMS_ERROR";

export const ADD_ITEM_TO_CART_PENDING = "ADD_ITEM_TO_CART_PENDING";
export const ADD_ITEM_TO_CART_SUCCESS = "ADD_ITEM_TO_CART_SUCCESS";
export const ADD_ITEM_TO_CART_ERROR = "ADD_ITEM_TO_CART_ERROR";

export const DELETE_ITEM_FROM_CART_PENDING = "DELETE_ITEM_FROM_CART_PENDING";
export const DELETE_ITEM_FROM_CART_SUCCESS = "DELETE_ITEM_FROM_CART_SUCCESS";
export const DELETE_ITEM_FROM_CART_ERROR = "DELETE_ITEM_FROM_CART_ERROR";

export const UPDATE_ITEM_FROM_CART_PENDING = "UPDATE_ITEM_FROM_CART_PENDING";
export const UPDATE_ITEM_FROM_CART_SUCCESS = "UPDATE_ITEM_FROM_CART_SUCCESS";
export const UPDATE_ITEM_FROM_CART_ERROR = "UPDATE_ITEM_FROM_CART_ERROR";

export const SET_CART_ITEMS = "SET_CART_ITEMS";
export const SET_APPLIED_COUPON_CODE = "SET_DISCOUNT_TYPE";
export const SET_ORDER_DISCOUNTS = "SET_ORDER_DISCOUNTS";
export const SET_CART_SUBTOTAL = "SET_CART_SUBTOTAL";
export const SET_DELIVERY_DATA = "SET_DELIVERY_DATA";
export const SET_DISCOUNT_INFO = "SET_DISCOUNT_INFO";
export const SET_SELECTED_DELIVERY_TIME_TYPE = "SET_SELECTED_DELIVERY_TIME_TYPE";
export const SET_SELECTED_DATE_TIME = "SET_SELECTED_DATE_TIME";
export const SET_SELECTED_DELIVERY_METHOD = "SET_SELECTED_DELIVERY_METHOD";
export const SET_OPEN_TIMES = "SET_OPEN_TIMES";
export const SET_SELECTED_DELIVERY_DATA = "SET_SELECTED_DELIVERY_DATA";
export const SET_DELIVERY_LOCATION = "SET_DELIVERY_LOCATION";
export const SET_FILTERED_OPTION_LIST_BY_AVAILABILITY = "SET_FILTERED_OPTION_LIST_BY_AVAILABILITY";

export const SET_SUB_TOTAL = "SET_SUB_TOTAL";
export const SET_TOTAL_DISCOUNT = "SET_TOTAL_DISCOUNT";
export const SET_PAYMENT_AMOUNT = "SET_PAYMENT_AMOUNT";
export const SET_ORDER_NOTES = "SET_ORDER_NOTES";
export const SET_DISCOUNT_ATTACHED_CART_ITEMS = "SET_DISCOUNT_ATTACHED_CART_ITEMS";

export const SET_LOCATION_ID = "SET_LOCATION_ID";
export const SET_APPLIED_DISCOUNTS = "SET_APPLIED_DISCOUNTS";

// FETCH ITEMS
function fetchCartItemsPending() {
  return {
    type: FETCH_CART_ITEMS_PENDING,
  };
}

function fetchCartItemsSuccess(items) {
  return {
    type: FETCH_CART_ITEMS_SUCCESS,
    payload: items,
  };
}

function fetchCartItemsError(error) {
  return {
    type: FETCH_CART_ITEMS_ERROR,
    error,
  };
}

// ADD ITEM to CART
function addItemToCartPending() {
  return {
    type: ADD_ITEM_TO_CART_PENDING,
  };
}

export function addItemToCartSuccess(item) {
  return {
    type: ADD_ITEM_TO_CART_SUCCESS,
    payload: item,
  };
}

function addItemToCartError(error) {
  return {
    type: ADD_ITEM_TO_CART_ERROR,
    error,
  };
}

// DELETE ITEMS FROM CART
function deleteItemFromCartPending() {
  return {
    type: DELETE_ITEM_FROM_CART_PENDING,
  };
}

function deleteItemFromCartSuccess(idx) {
  return {
    type: DELETE_ITEM_FROM_CART_SUCCESS,
    idx,
  };
}

function deleteItemFromCartError(error) {
  return {
    type: DELETE_ITEM_FROM_CART_ERROR,
    error,
  };
}

// update cart item
function updateItemFromCartPending() {
  return {
    type: UPDATE_ITEM_FROM_CART_PENDING,
  };
}

function updateItemFromCartSuccess(idx, changes) {
  return {
    type: UPDATE_ITEM_FROM_CART_SUCCESS,
    idx,
    changes,
  };
}

function updateItemFromCartError(error) {
  return {
    type: UPDATE_ITEM_FROM_CART_ERROR,
    error,
  };
}

export function setCartItems(items) {
  return {
    type: SET_CART_ITEMS,
    payload: items,
  };
}

export function setAppliedDiscounts(appliedDiscounts) {
  return {
    type: SET_APPLIED_DISCOUNTS,
    payload: appliedDiscounts,
  };
}

export function setDeliveryData(deliveryData) {
  return {
    type: SET_DELIVERY_DATA,
    payload: deliveryData,
  };
}

export function setDiscountInfo(discountInfo) {
  return {
    type: SET_DISCOUNT_INFO,
    payload: discountInfo,
  };
}

export const setSelectedDeliveryMethod = (payload) => ({
  type: SET_SELECTED_DELIVERY_METHOD,
  payload,
});

export function setSelectedDeliveryTimeType(timeType) {
  return {
    type: SET_SELECTED_DELIVERY_TIME_TYPE,
    payload: timeType,
  };
}

export function setSelectedDateTime(selectedDateTime) {
  return {
    type: SET_SELECTED_DATE_TIME,
    payload: selectedDateTime,
  };
}

export const setOpenTimes = (payload) => ({
  type: SET_OPEN_TIMES,
  payload,
});

export const setSelectedDeliveryData = (payload) => ({
  type: SET_SELECTED_DELIVERY_DATA,
  payload,
});

export const setDeliveryLocation = (payload) => ({
  type: SET_DELIVERY_LOCATION,
  payload,
});

export const setFilteredDeliveryOptionListByAvailability = (payload) => ({
  type: SET_FILTERED_OPTION_LIST_BY_AVAILABILITY,
  payload,
});

export const setCartSubtotal = (payload) => ({
  type: SET_CART_SUBTOTAL,
  payload,
});

export const setOrderDiscounts = (payload) => ({
  type: SET_ORDER_DISCOUNTS,
  payload,
});

export const setAppliedCouponCode = (payload) => ({
  type: SET_APPLIED_COUPON_CODE,
  payload,
});

export const setSubTotal = (payload) => ({
  type: SET_SUB_TOTAL,
  payload,
});

export const setTotalDiscount = (payload) => ({
  type: SET_TOTAL_DISCOUNT,
  payload,
});

export const setTotalPriceItems = (payload) => ({
  type: SET_PAYMENT_AMOUNT,
  payload,
});

export const setDiscountAttachedCartItems = (payload) => ({
  type: SET_DISCOUNT_ATTACHED_CART_ITEMS,
  payload,
});

export const setOrderNotes = (payload) => ({
  type: SET_ORDER_NOTES,
  payload,
});

export function setLocationId(locationId) {
  return {
    type: SET_LOCATION_ID,
    payload: locationId,
  };
}

export const fetchCartItemsAsync = () => async (dispatch) => {
  dispatch(fetchCartItemsPending());
  try {
    return dispatch(fetchCartItemsSuccess([]));
  } catch (error) {
    return dispatch(fetchCartItemsError(i18next.t("errors.networkFailed")));
  }
};

export const addItemToCartAsync = (item) => async (dispatch) => {
  dispatch(addItemToCartPending());
  try {
    return dispatch(addItemToCartSuccess(item));
  } catch (error) {
    return dispatch(addItemToCartError(i18next.t("errors.networkFailed")));
  }
};

export const deleteItemFromCartAsync = (idx) => async (dispatch) => {
  dispatch(deleteItemFromCartPending());
  try {
    return dispatch(deleteItemFromCartSuccess(idx));
  } catch (error) {
    return dispatch(deleteItemFromCartError(i18next.t("errors.networkFailed")));
  }
};

export const updateItemFromCartAsync = (idx, changes) => async (dispatch) => {
  dispatch(updateItemFromCartPending());
  try {
    return dispatch(updateItemFromCartSuccess(idx, changes));
  } catch (error) {
    return dispatch(updateItemFromCartError(i18next.t("errors.networkFailed")));
  }
};
