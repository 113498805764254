import React, { useEffect, useState } from "react";

import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import _ from "lodash";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { getIsItemActive } from "../../../../../../utils/availability";
import { formatPrice, getPriceByPcp } from "../../../../../../utils/utils";
import ErrorBoundary from "../../../../../common/error/ErrorBoundary";

const FullWidthFormControlLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    width: 100%;
    flex: 1;
    @media (max-width: 600px) {
      margin-right: 0;
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    padding: theme.spacing(1),
    flex: 1,
    justifyContent: "space-between",

  },
  label: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: theme.palette.text.itemTextColor,
  },
  price: {
    // marginLeft: theme.spacing(2),
  },
  subItem: {
    width: "48%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  stageTitle: {
    color: theme.palette.text.titleTextColor,
  },
  checkbox: {
    color: theme.palette.primary.main,
  },
}));

const SubProductStage = ({
  stage,
  subProdData,
  subItems,
  setSubItems,
  errors,
  expandedStageGroups,
  catMenuOptions,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const classes = useStyles();
  const error = errors.find((e) => e.stage === stage);
  // eslint-disable-next-line camelcase
  const { mealDealPriceChangePoint, mealDealMax } = subProdData;
  const { activeCatMenu } = useSelector((state) => state.catMenu);

  useEffect(() => {
    if (_.isEmpty(subProdData.stageGroup)) {
      setIsOpen(true);
    }
  }, [subProdData]);

  useEffect(() => {
    if (!isOpen) {
      const subItemNew = Object.assign([], subItems);
      subItemNew[stage].subs = [];
      setSubItems(subItemNew);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleSelectSubItem = async (sub, e) => {
    sub["stageId"] = subProdData.itemId
    const subPriced = { ...sub };
    subPriced.price = getPriceByPcp(
      subItems[stage].subs,
      mealDealPriceChangePoint,
      sub.extraCost,
      sub.surcharge,
      sub.itemId,
    );
    const { checked } = e.target;
    const subItemNew = Object.assign([], subItems);
    if (checked) {
      // check whether stage can have only one selection
      if (mealDealMax === 1) {
        subItemNew[stage].subs = [subPriced];
      } else {
        // add new sub item to main prod array
        subItemNew[stage].subs.push(subPriced);
      }
    } else {
      subItemNew[stage].subs = subItemNew[stage].subs.filter(
        (s) => s.itemId !== subPriced.itemId,
      );
    }
    setSubItems(subItemNew);
  };

  useEffect(() => {
    if (!_.isEmpty(subProdData.stageGroup)) {
      if (expandedStageGroups.includes(subProdData.stageGroup)) {
        // const subItemNew = Object.assign([], subItems);
        // subItemNew[stage].subs = [];
        // setSubItems(subItemNew);
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandedStageGroups]);

  if (!subItems.length) return null;

  const ItemLabel = ({ title, extraCost, surcharge, itemId }) => (
    <Box component="div" className={classes.label}>
      <Typography variant="caption">{title}</Typography>
      <Typography className={classes.price} variant="subtitle2">
        {formatPrice(
          getPriceByPcp(
            subItems[stage].subs,
            mealDealPriceChangePoint,
            extraCost,
            surcharge,
            itemId,
          ),
        )}
      </Typography>
    </Box>
  );

  return (
    <>
      {isOpen && (
        <>
          <FormControl component="fieldset" className={classes.root}>
            <Typography variant="h6" className={classes.stageTitle}>
              {subProdData.title}
            </Typography>
            {error && <Alert severity="error">{error.error}</Alert>}
            <RadioGroup
              row
              style={{ justifyContent: "space-between" }}
              aria-label="chooseYourItem"
              name="chooseYourItem"
              defaultValue={subProdData.itemId}
            >
              {subProdData.subProducts.map((sub) => (
                <ErrorBoundary key={`stage${stage}subItem-${sub.itemId}`}>
                  <FullWidthFormControlLabel
                    className={classes.subItem}
                    id={`stage${stage}subItem-${sub.itemId}`}
                    key={`stage${stage}subItem-${sub.itemId}`}
                    value={sub.itemId}
                    checked={
                      !!subItems[stage]?.subs?.find(
                        (_sub) => _sub.itemId === sub.itemId,
                      )
                    }
                    control={<Checkbox color="primary" />}
                    label={(
                      <ItemLabel
                        title={sub.title}
                        extraCost={sub.extraCost}
                        surcharge={sub.surcharge}
                        itemId={sub.itemId}
                      />
                    )}
                    disabled={!getIsItemActive(
                      catMenuOptions,
                      sub.menuId,
                      sub.catId,
                      activeCatMenu.catMenuId,
                    )}
                    labelPlacement="end"
                    onChange={(e) => handleSelectSubItem(sub, e)}
                  />
                </ErrorBoundary>
              ))}
            </RadioGroup>
          </FormControl>
          <Divider className={classes.divider} variant="middle" />
        </>
      )}
    </>
  );
};

export default SubProductStage;
