/* eslint-disable max-len */
import React, { useState } from "react";

import { makeStyles, Typography, useTheme } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";

import { logError, logInfo } from "../../../../services/logging";
import { fetchCouponInfo } from "../../../../services/paymentService";
import {
  calDiscountedPriceCoupon,
  getDiscountListApplicableForAllCartItems,
} from "../../../../utils/discouts";
import AlertMessage from "../../../common/AlertMessage";
import ButtonContained from "../../../common/buttons/ButtonContained";
import TextFieldDefault from "../../../common/textFields/TextFieldDefault";
import {
  setAppliedCouponCode,
  setAppliedDiscounts,
  setDiscountAttachedCartItems,
  setOrderDiscounts,
  setSubTotal,
  setTotalDiscount,
  setTotalPriceItems,
} from "../../storeHome/MyOrder/redux/actions";
import CouponCard from "./CouponCard";
import CouponDiscountTotal from "./CouponDiscountTotal";

const useStyles = makeStyles((theme) => ({
  CouponSectionWrapper: {
    border: `1px solid ${theme.palette.text.accentTextColor}`,
    padding: 8,
    borderRadius: theme.shape.borderRadius,
    marginTop: "32px",
    margin: 8,
    maxWidth: 440,
  },
}));

const CouponSection = ({handleContinue}) => {
  const [coupon, setCoupon] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const {
    selectedDeliveryMethod,
    selectedDateTime,
    cartItems,
    appliedCouponCode,
  } = useSelector((state) => state.cart);
  const { discountInfo: discountList } = useSelector((state) => state.store);
  const dispatch = useDispatch();

  const classes = useStyles();
  const { params } = useRouteMatch();

  const handleChangeCoupon = (e) => {
    setCoupon(e.target.value.trim());
  };

  const handleSubmitCoupon = async () => {
    logInfo({
      message: "requesting COUPON check",
      coupon,
      selectedDeliveryMethod,
      selectedDateTime,
      cartItems,
    });
    try {
      const res = await fetchCouponInfo(params.locationId, coupon);
      logInfo({
        message: "coupon request response received.",
        couponRequestRes: res?.data,
      });
      const appliedDiscount = res.data.data;
      const {
        error,
        reason,
        totalPrice: tPriceItems,
        totalDiscount: tDisc,
        subTotal,
        discountAttachedCartItems,
        orderDiscounts,
        appliedDiscounts,
      } = calDiscountedPriceCoupon(
        cartItems,
        appliedDiscount,
        selectedDateTime,
        selectedDeliveryMethod,
      );
      logInfo({
        message: "calculated discounted price with coupon applied",
        totalPrice: tPriceItems,
        totalDiscount: tDisc,
        subTotal,
        discountAttachedCartItems,
        appliedDiscounts,
        error,
        reason,
      });
      // add coupon discount to applied discounts
      // const appliedDiscounts = !_.isEmpty(orderDiscounts)
      //   ? orderDiscounts.map((disc) => ({
      //     data: disc,
      //     discountAmount: tDisc.toFixed(2),
      //   }))
      //   : [];

      if (!error) {
        logInfo({
          message: "coupon applied successfully. dispatch values to redux",
        });
        dispatch(setSubTotal(parseFloat(subTotal.toFixed(2))));
        dispatch(setTotalDiscount(parseFloat(tDisc.toFixed(2))));
        dispatch(setTotalPriceItems(parseFloat(tPriceItems.toFixed(2))));
        dispatch(setDiscountAttachedCartItems(discountAttachedCartItems || []));
        dispatch(setOrderDiscounts(orderDiscounts || []));
        dispatch(setAppliedDiscounts(appliedDiscounts || []));

        setAlertSeverity("success");
        setAlertMessage(`The coupon ${coupon} is applied.`);
        dispatch(setAppliedCouponCode(coupon));
        setCoupon("");
        logInfo({ message: "coupon applied successfully" });
      } else {
        logInfo({ message: "WARNING | coupon application failed. ", reason });
        setAlertSeverity("error");
        return setAlertMessage(reason);
      }
    } catch (error) {
      setAlertSeverity("error");
      if (error.response && error.response.status === 400) {
        logError({
          message: "CRITICAL | invalid coupon applied",
          coupon,
          error,
        });
        return setAlertMessage(
          "The applied coupon is invalid! Please check the code and retry",
        );
      }
      logError({
        message: "CRITICAL | Error coupon check. unexpected exception",
        coupon,
        error,
      });
      setAlertMessage("Unexpected error! Please check your connection");
    }
  };

  const handleRemoveCoupon = () => {
    const discountsApplicableForAllCartItems =
      getDiscountListApplicableForAllCartItems(
        cartItems,
        discountList,
        selectedDateTime,
        selectedDeliveryMethod,
      );
    logInfo({ message: "coupon removed", removedCoupon: appliedCouponCode });
    dispatch(setOrderDiscounts(discountsApplicableForAllCartItems));
    setAlertSeverity("info");
    setAlertMessage(`The coupon ${coupon} is removed.`);
    dispatch(setAppliedCouponCode(""));
  };

  const theme = useTheme()

  return (
    <>
      <div className={classes.CouponSectionWrapper}>
        <AlertMessage
          message={alertMessage}
          setMessage={() => setAlertMessage("")}
          severity={alertSeverity}
        />
        <Typography
          variant="subtitle1"
          align="center"
          style={{ margin: 8, marginBottom: 16, fontWeight: "bold" }}
        >
          Have a discount code? Add it below.
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TextFieldDefault
            style={{ flex: 4, margin: 0 }}
            id="couponInput"
            label="Discount Code"
            variant="filled"
            onChange={handleChangeCoupon}
            value={coupon}
          />
          <span style={{ margin: 4 }} />
          <ButtonContained
            flex="1"
            color="secondary"
            height="54px"
            onClick={handleSubmitCoupon}
            disabled={!coupon}
          >
            <Typography style={{color: theme.palette.secondary.contrastText}}>
            Add
            </Typography>
          </ButtonContained>
        </div>
        <div> 
          {appliedCouponCode && (
            <>
              <div style={{ margin: 8 }} />
              <CouponCard
                couponCode={appliedCouponCode}
                handleRemoveCoupon={handleRemoveCoupon}
              />
            </>
          )}
        </div>
        <CouponDiscountTotal handleContinue={handleContinue} />

      </div>
      {/* <Hidden smUp> */}
        
      {/* </Hidden> */}
    </>
  );
};

CouponSection.propTypes = {};
CouponSection.defaultProps = {};

export default CouponSection;
