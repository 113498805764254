import React from "react";

import { Grid, Hidden, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";

import CartItemList from "../../components/@pages/storeHome/MyOrder/CartItemList";
import Header from "../../components/@pages/storeHome/MyOrder/Header";
import SubtotalBar from "../../components/common/SubtotalBar";

const useStyles = makeStyles((theme) => ({
  OrderInfoSectionWrapper: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.primary.main,
  },
}));

const OrderInfoSection = () => {
  const { cartItems } = useSelector((state) => state.cart);
  const classes = useStyles();
  return (
    <Hidden xsDown>
      <Grid
        item
        sm={4}
        xs={12}
        className={classes.OrderInfoSectionWrapper}
      >
        <Header />
        <CartItemList cartItems={cartItems} />
        <SubtotalBar />
      </Grid>
    </Hidden>
  );
};

OrderInfoSection.propTypes = {

};
OrderInfoSection.defaultProps = {

};

export default OrderInfoSection;
