import { useMediaQuery } from "@material-ui/core";

const useViewport = () => {
  const sm = useMediaQuery("(max-width:600px)");
  const md = useMediaQuery("(max-width:960px)");
  const lg = useMediaQuery("(max-width:1280px)");

  const isMobileView = sm;
  const isTabView = !sm && md;
  const isDesktopView = !md && lg;

  return {
    isMobileView,
    isTabView,
    isDesktopView,
  };
};

export default useViewport;
