import React, { useEffect, useState } from "react";

import { CircularProgress, useMediaQuery, useTheme } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import qs from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useRouteMatch } from "react-router-dom";

import { DELIVERY_TYPE_PICKUP } from "../../utils/constants";
import { isEmbeddedWindow } from "../../utils/utils";
import {
  setSelectedDeliveryMethod,
  setSelectedDeliveryTimeType,
} from "../@pages/storeHome/MyOrder/redux/actions";
import OpenFullScreenAlert from "../@pages/storeHome/OpenFullScreenAlert";
import SelectedOptionsDialog from "../@pages/storeHome/SelectedOptionsDialog";
import CookieBlockModal from "../common/dialogs/CookieBlockModal";

const useStyles = makeStyles((theme) => ({
  Layout: {
    padding: theme.spacing(0),
    // backgroundColor: theme.palette.primary.main,
    // height: "100vh",
    // maxWidth: 1200,
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(0),
      backgroundColor: theme.palette.background.menuBackgroundColor,
      height: "100vh",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  fullScreenAlert: {
    position: "absolute",
  },
}));

const Layout = ({ children }) => {
  const [cookieBlockAlertOpen, setCookieBlockAlertOpen] = useState(false);
  const [showFullscreenAlert, setShowFullscreenAlert] = useState(false);

  const classes = useStyles();
  const { storeInfo } = useSelector((state) => state.store);
  const { deliveryData } = useSelector((state) => state.cart);
  const { catMenuListAll } = useSelector((state) => state.catMenu);
  const { embedType } = useSelector((state) => state.global);
  const { theme: storeTheme } = useSelector((state) => state.initData);
  const location = useLocation();
  const theme = useTheme();
  const {
    params: { locationId },
  } = useRouteMatch();

  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const { isDineIn } = qs.parse(location.search, { parseBooleans: true });
  const dispatch = useDispatch();

  useEffect(() => {
    // set business display name as tab title
    if (!_.isEmpty(storeInfo)) {
      document.title = storeInfo.businessDisplayName;
      document
        .querySelector("meta[name='description']")
        .setAttribute(
          "content",
          `${storeInfo.businessDisplayName} - Online Ordering`,
        );

      if (!locationId) return;

      // Google analytics initialization
      if (storeInfo?.googleAnalyticsId) {
        const gtagScript = document.createElement("script");
        gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${storeInfo.googleAnalyticsId}`;
        gtagScript.async = true;
        document.head.appendChild(gtagScript);

        const gaScript = document.createElement("script");
        gaScript.text = `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${storeInfo.googleAnalyticsId}');
        `;
        document.head.appendChild(gaScript);
      }

      // Facebook pixel tracking integration
      if (storeInfo?.pixelAnalyticsId) {
        const pixelScript = document.createElement("script");
        pixelScript.text = `
          !(function (f, b, e, v, n, t, s) {
            if (f.fbq) return;
            n = f.fbq = function () {
              n.callMethod
                ? n.callMethod.apply(n, arguments)
                : n.queue.push(arguments);
            };
            if (!f._fbq) f._fbq = n;
            n.push = n;
            n.loaded = !0;
            n.version = "2.0";
            n.queue = [];
            t = b.createElement(e);
            t.async = !0;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s);
          })(
            window,
            document,
            "script",
            "https://connect.facebook.net/en_US/fbevents.js",
          );
          fbq("init", ${storeInfo.pixelAnalyticsId});
          fbq("track", "PageView");
          `;
        document.head.appendChild(pixelScript);

        const pixelNoscript = document.createElement("noscript");
        const pixelImg = document.createElement("img");
        pixelImg.height = "1";
        pixelImg.width = "1";
        pixelImg.style = "display: none;";
        pixelImg.src = `https://www.facebook.com/tr?id=${storeInfo.pixelAnalyticsId}&ev=PageView&noscript=1`;
        pixelNoscript.appendChild(pixelImg);
        document.head.appendChild(pixelNoscript);
      }

      if (storeInfo?.tikTokAnalyticsId) {
        const ticktockScript = document.createElement("script");
        ticktockScript.text = `
        !function (w, d, t) {
          w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];
          ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};
          for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);
          ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;
            n++)ttq.setAndDefer(e,ttq.methods[n]);
            return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");
          o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;
          var a=document.getElementsByTagName("script")[0];
          a.parentNode.insertBefore(o,a)};
          ttq.load('${storeInfo?.tikTokAnalyticsId}');
          ttq.page();
        }(window, document, 'ttq');
      `;
        document.head.appendChild(ticktockScript);
      }
    }
  }, [storeInfo, locationId]);

  const isShopPage = window.location.pathname.includes("/shop");

  useEffect(() => {
    if (matches && isEmbeddedWindow()) {
      return setShowFullscreenAlert(true);
    }
    setShowFullscreenAlert(false);
  }, [matches]);

  useEffect(() => {
    if (window.location.href.includes("?isDineIn")) {
      if (isDineIn) {
        dispatch(setSelectedDeliveryMethod("DINEIN"));
        dispatch(setSelectedDeliveryTimeType("ASAP"));
      }
    } else if (window.location.pathname.includes("/shop")) {
      if (deliveryData.find((d) => d.type === DELIVERY_TYPE_PICKUP)) {
        dispatch(setSelectedDeliveryMethod("PICKUP"));
      } else {
        dispatch(setSelectedDeliveryMethod("DELIVER"));
      }
    }
  }, [deliveryData, dispatch, isDineIn]);

  useEffect(() => {
    const request = indexedDB.open("prestoedb");
    request.onerror = () => {
      setCookieBlockAlertOpen(true);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (
    !cookieBlockAlertOpen &&
    (_.isEmpty(catMenuListAll) || _.isEmpty(storeTheme)) &&
    !window.location.pathname.includes("order-status")
  ) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "400px",
        }}
      >
        <CircularProgress disableShrink />
      </div>
    );
  }

  const handleOpenFullScreen = () => {
    window.parent.location.href = `https://app.eatpresto.co.uk/location/${locationId}/shop`;
  };

  const handleCloseCookieDialog = (e, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setCookieBlockAlertOpen(false);
  };

  return (
    <>
      {embedType === "WP" && (
        <OpenFullScreenAlert
          open={showFullscreenAlert}
          handleOpenFullScreen={handleOpenFullScreen}
        />
      )}
      <CookieBlockModal
        open={cookieBlockAlertOpen}
        handleClose={handleCloseCookieDialog}
      />
      <SelectedOptionsDialog
        isDineIn={isDineIn}
        cookieBlockAlertOpen={cookieBlockAlertOpen}
        showFullscreenAlert={showFullscreenAlert}
      />
      <Container maxWidth={isShopPage ? "xl" : "lg"} className={classes.Layout}>
        {children}
      </Container>
    </>
  );
};

export default Layout;
