import Cookies from "js-cookie";

export function setCookie(name, value, config) {
  Cookies.set(name, value, config);
}

export function getCookie(name) {
  return Cookies.get(name);
}

export function removeCookie(name, config) {
  Cookies.remove(name, config);
}

export function clearCookies() {
  const cookies = Cookies.get();
  Object.keys(cookies).forEach((key) => {
    removeCookie(key);
  });
}
