
export const SET_SELECTED_ITEM_LIST = "SET_SELECTED_ITEM_LIST";
export const SET_CURRENT_POS = "SET_CURRENT_POS";
export const SET_TITLE_POS_LIST = "SET_TITLE_POS_LIST";


export function setSelectedItemList(items) {
  return {
    type: SET_SELECTED_ITEM_LIST,
    payload: items,
  };
}

export function setCurrentPos(pos) {
  return {
    type: SET_CURRENT_POS,
    payload: pos,
  };
}

export function setTitlePosList(posList) {
  return {
    type: SET_TITLE_POS_LIST,
    payload: posList,
  };
}
