import React, { useEffect } from "react";

import { makeStyles, Tooltip, Typography } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import Color from "color";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";

import {
  CARRIER_BAG_CHARGE_MESSAGE,
  SERVICE_FEE_MESSAGE,
} from "../../../utils/constants";
import { calDiscountedPriceAutomatic } from "../../../utils/discouts";
import { formatPrice } from "../../../utils/utils";
import {
  setAppliedDiscounts,
  setDiscountAttachedCartItems,
  setOrderDiscounts,
  setSubTotal,
  setTotalDiscount,
  setTotalPriceItems,
} from "../../@pages/storeHome/MyOrder/redux/actions";
import { setExtraCharges } from "../../@pages/storeHome/redux/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    padding: theme.spacing(0.5, 2, 0.5, 2),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  rowWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  totalWrapper: {
    marginTop: 2,
    paddingTop: 4,
    display: "flex",
    justifyContent: "space-between",
    borderTop: `1px solid ${Color(theme.palette.text.primaryTextColor).alpha(
      0.5,
    )}`,
    borderBottom: `1px solid ${Color(theme.palette.text.primaryTextColor).alpha(
      0.5,
    )}`,
  },
  lineRow: {
    borderTop: `1px solid ${theme.palette.background.boarderColor}`,
    marginTop: 1,
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const SubtotalBar = () => {
  const {
    selectedDeliveryMethod,
    cartItems,
    selectedDateTime,
    subtotal,
    selectedDeliveryData,
    totalPriceItems,
    totalDiscount,
    isCouponApplied,
  } = useSelector((state) => state.cart);

  const {
    discountInfo: discountList,
    serviceCharge: sCharge,
    carrierBagCharge,
    extraCharges,
  } = useSelector((state) => state.store);
  const dispatch = useDispatch();

  const classes = useStyles();

  useEffect(() => {
    let _applicableCarrierBagCharge = 0;
    if (
      parseFloat(carrierBagCharge)
      && (selectedDeliveryMethod === "PICKUP"
        || selectedDeliveryMethod === "DELIVER")
    ) {
      _applicableCarrierBagCharge = parseFloat(carrierBagCharge);
    }

    if (!isCouponApplied) {
      const {
        totalPrice: tPriceItems,
        totalDiscount: tDisc,
        subTotal,
        discountAttachedCartItems,
        orderDiscounts,
        appliedDiscounts,
      } = calDiscountedPriceAutomatic(
        cartItems,
        discountList,
        selectedDateTime,
        selectedDeliveryMethod,
      );
      dispatch(setDiscountAttachedCartItems([]));
      dispatch(setSubTotal(parseFloat(subTotal.toFixed(2))));
      dispatch(setTotalDiscount(parseFloat(tDisc.toFixed(2))));
      dispatch(setTotalPriceItems(parseFloat(tPriceItems.toFixed(2))));
      dispatch(setDiscountAttachedCartItems(discountAttachedCartItems || []));
      dispatch(setAppliedDiscounts(appliedDiscounts || []));
      dispatch(setOrderDiscounts(orderDiscounts || []));
      dispatch(
        setExtraCharges({ carrierBagCharge: _applicableCarrierBagCharge }),
      );
    }
  }, [
    dispatch,
    cartItems,
    discountList,
    selectedDateTime,
    selectedDeliveryMethod,
    selectedDeliveryData,
    isCouponApplied,
    carrierBagCharge,
  ]);

  const deliveryCost = selectedDeliveryData?.charge
    ? parseFloat(selectedDeliveryData?.charge)
    : 0;
  const serviceCharge = parseFloat(sCharge) ? parseFloat(sCharge) : 0;
  // eslint-disable-next-line max-len
  const _carrierBagCharge = !_.isEmpty(extraCharges) && parseFloat(extraCharges?.carrierBagCharge)
    ? extraCharges.carrierBagCharge
    : 0;

  const totalPaymentAmount = subtotal
    ? totalPriceItems + deliveryCost + serviceCharge + _carrierBagCharge
    : 0;

  return (
    <div className={classes.root}>
      <div className={classes.rowWrapper}>
        <Typography variant="body2" component="span">
          Subtotal
        </Typography>
        <Typography variant="body2" component="span">
          {_.isNumber(subtotal) && formatPrice(subtotal)}
        </Typography>
      </div>
      {!!selectedDeliveryData?.charge && selectedDeliveryMethod === "DELIVER" && (
        <div className={classes.rowWrapper}>
          <Typography variant="body2" component="span">
            Delivery Charge
          </Typography>
          <Typography variant="body2" component="span">
            {!!selectedDeliveryData?.charge
              && formatPrice(selectedDeliveryData?.charge)}
          </Typography>
        </div>
      )}
      {!!selectedDeliveryData?.charge
        && selectedDeliveryMethod === "PICKUP"
        && parseFloat(selectedDeliveryData?.charge) > 0 && (
          <div className={classes.rowWrapper}>
            <Typography variant="body2" component="span">
              Pickup Charge
            </Typography>
            <Typography variant="body2" component="span">
              {!!selectedDeliveryData?.charge
                && formatPrice(selectedDeliveryData?.charge)}
            </Typography>
          </div>
      )}
      {!!parseFloat(totalDiscount) && (
        <div className={classes.rowWrapper}>
          <Typography variant="body2" component="span">
            Discount
          </Typography>
          <Typography variant="body2" component="span">
            {formatPrice(totalDiscount, true)}
          </Typography>
        </div>
      )}
      {!!parseFloat(_carrierBagCharge) && !!parseFloat(subtotal) && (
        <div className={classes.rowWrapper}>
          <Typography
            variant="body2"
            component="span"
            className={classes.center}
          >
            Carrier bags
            <span style={{ margin: 2 }} />
            <Tooltip
              enterTouchDelay={1}
              title={CARRIER_BAG_CHARGE_MESSAGE}
              aria-label="carrier bag charge"
            >
              <HelpIcon style={{ fontSize: 16 }} />
            </Tooltip>
          </Typography>
          <Typography variant="body2" component="span">
            {formatPrice(_carrierBagCharge)}
          </Typography>
        </div>
      )}
      {!!parseFloat(serviceCharge) && !!parseFloat(subtotal) && (
        <div className={classes.rowWrapper}>
          <Typography
            variant="body2"
            component="span"
            className={classes.center}
          >
            Service charge
            <span style={{ margin: 2 }} />
            <Tooltip
              enterTouchDelay={1}
              title={SERVICE_FEE_MESSAGE}
              aria-label="service fee message"
            >
              <HelpIcon style={{ fontSize: 16 }} />
            </Tooltip>
          </Typography>
          <Typography variant="body2" component="span">
            {formatPrice(serviceCharge)}
          </Typography>
        </div>
      )}
      <div className={classes.totalWrapper}>
        <Typography
          variant="subtitle1"
          component="span"
          style={{ fontWeight: "bold" }}
        >
          Total
        </Typography>
        <Typography
          variant="subtitle1"
          component="span"
          style={{ fontWeight: "bold" }}
        >
          {formatPrice(totalPaymentAmount)}
        </Typography>
      </div>
    </div>
  );
};

SubtotalBar.propTypes = {};
SubtotalBar.defaultProps = {};

export default SubtotalBar;
