import React, { useEffect, useState } from "react";

import {
  Card, Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import Skeleton from "@material-ui/lab/Skeleton";

import noImage from "../../../../../assets/images/noimage.jpg";
import RenderAvailabilityBadges from "../../../../common/badges/RenderAvailabilityBadges";

const IMAGE_SIZE = 600;

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "inherit",
    [theme.breakpoints.up("md")]: {
      // borderRight: "1px solid lightgray",
    },
  },
  title: {
    color: theme.palette.text.titleTextColor,
  },
  desc: {
    color: theme.palette.text.itemDescColor,
    display: "flex",
    justifyContent: "space-between",
  },
  skeleton: {
    position: "absolute",
    top: 0,
    left: 0,
    maxWidth: IMAGE_SIZE,
    maxHeight: IMAGE_SIZE,
    width: "100%",
    height: "100%",
  },
  skeletonWrapper: {
    height: 0,
    overflow: "hidden",
    paddingTop: "90%",
    position: "relative",
  },
  itemImage: {
    maxWidth: IMAGE_SIZE,
    maxHeight: IMAGE_SIZE,
    borderRadius: `${theme.shape.borderRadius} ${theme.shape.borderRadius} 0 0`,
    width: "100%",
    height: ({ isImageLoaded }) => (isImageLoaded ? "100%" : "0px"),
  },
  CardContent: {
    padding: theme.spacing(2),
  },
}));

const MenuItemDetails = ({ image, title, desc, itemOptions, isOutOfStock }) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const classes = useStyles({ isImageLoaded });

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  useEffect(() => {
    const convertThumbCloudinary = () => {
      if (image.includes("res.cloudinary.com")) {
        const [firstPart, secondPart] = image.split("/upload/");
        return setImageUrl(`${firstPart}/upload/t_presto_cover/${secondPart}`);
      }
      return setImageUrl(image);
    };
    convertThumbCloudinary();
  }, [image]);

  return (
    <Card className={classes.root} elevation={0}>
      {imageUrl && (
        <>
          {!isImageLoaded && (
            <Grid className={classes.skeletonWrapper}>
              <Skeleton variant="rect" className={classes.skeleton} />
            </Grid>
          )}

          <Fade in={isImageLoaded}>
            <img
              className={classes.itemImage}
              id="menuItemDisplayImage"
              onError={() => {
                setIsImageLoaded(true);
                document.getElementById("menuItemDisplayImage").src = noImage;
              }}
              onLoad={handleImageLoad}
              alt="menu item"
              src={imageUrl}
              title="menu item"
            />
          </Fade>
        </>
      )}
      <div className={classes.CardContent}>
        <Typography
          className={classes.title}
          gutterBottom
          variant="h6"
          component="div"
        >
          {title}
        </Typography>
        {desc && (
          <Typography
            className={classes.desc}
            variant="subtitle2"
            component="div"
          >
            {desc}
          </Typography>
        )}
        <RenderAvailabilityBadges
          isOutOfStock={isOutOfStock}
          availability={itemOptions.availability}
          deliveryTypes={itemOptions.deliveryTypes}
        />
      </div>
    </Card>
  );
};

export default MenuItemDetails;
