import React from "react";

import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 0,
    flex: 1,
    margin: "auto 0",
    elevation: 0,
    width: "100%",
    height: theme.shape.stickyButtonHeight,
  },
}));

const BottomStickyBtn = ({ label, setOpen }) => {
  const classes = useStyles();

  const handleClick = () => {
    setOpen(true);
  };

  return (
    <Button
      variant="contained"
      className={classes.root}
      color="secondary"
      size="small"
      onClick={handleClick}
    >
      {label}
    </Button>
  );
};

export default BottomStickyBtn;
