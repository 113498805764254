export const SET_CATEGORY_LIST = "SET_CATEGORY_LIST";
export const SET_CATEGORY_SELECTED = "SET_CATEGORY_SELECTED";

export const setCategoryList = (categoryList) => (dispatch) => {
  dispatch({
    type: SET_CATEGORY_LIST,
    payload: categoryList,
  });
};

export const setCategorySelected = (category) => (dispatch) => {
  dispatch({
    type: SET_CATEGORY_SELECTED,
    payload: category,
  });
};
