import React from "react";

import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(() => ({
  subListing: {},
}));

const CartItemBuildYourItem = ({ cartItemData }) => {
  const classes = useStyle();
  const { subItems } = cartItemData;

  return (
    <Box className={classes.subListing}>
      {subItems.map((stg, sti) => (
        <Box component="div" key={String(sti)}>
          {/* <Typography component="div">{stg.title}</Typography> */}
          {stg.subs.map((s, i) => (
            <Typography key={String(i) + String(sti)} variant="body2" component="div">
              {s.title}
            </Typography>
          ))}
        </Box>
      ))}
    </Box>
  );
};

export default CartItemBuildYourItem;
