import * as React from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";

import googleIcon from "../../../../../assets/images/googleSignIn/google.svg";
import SocialSignInButton from "../../../../common/buttons/SocialSignInButton";
import { useDispatch, useSelector } from "react-redux";
import { GoogleSignInUser } from "../../../../../utils/GoogleSignIn";

const GoogleSignIn = ({
  setAuthError,
  isNeedDisabled,
  setIsEmptyLastName,
  setIssOpenFirstNameLastNameModal,
  setGoogleFirstName,
  setToken,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { params } = useRouteMatch();
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { isActiveLoyalty } = useSelector((state) => state.loyalty);

  const handleClick = async () => {
    GoogleSignInUser(
      isNeedDisabled,
      setIsLoading,
      setAuthError,
      history,
      params,
      location,
      dispatch,
      setIsEmptyLastName,
      setIssOpenFirstNameLastNameModal,
      setGoogleFirstName,
      setToken,
      isActiveLoyalty,
    );
  };

  return (
    <SocialSignInButton
      handleClick={handleClick}
      logoSrc={googleIcon}
      labelText="Sign in with Google"
      alt="google sign in"
      isLoading={isLoading}
      disabled={isNeedDisabled}
    />
  );
};

export default GoogleSignIn;
