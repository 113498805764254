/* eslint-disable import/prefer-default-export */
/* eslint-disable implicit-arrow-linebreak */
import axiosClient from "./client";

/**
 *
 * @param {string} locationId
 */
export function fetchCouponInfo(locationId, couponCode) {
  return axiosClient({
    url: `${process.env.REACT_APP_API_ENDPOINT_EATPRESTO}/public/location/${locationId}/discount/${couponCode}`,
    method: "GET",
  });
}

export function fetchPaymentErrorInfo(locationId, chargeID) {
  return axiosClient({
    url: `${process.env.REACT_APP_API_ENDPOINT_EATPRESTO}/public-auth/location/${locationId}/order/outcome/${chargeID}`,
    method: "GET",
  });
}
