import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 1000,
    display: "flex",
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100%",
    color: theme.palette.text.titleTextColor,
    fontWeight: 700,
  },
}));

const StickyBtnWrapper = ({ children }) => {
  const classes = useStyles();
  const matches = useMediaQuery("(max-height:500px)");

  return <div id="sticky-nav" className={classes.root} style={matches ? { display: "none" } : {}}>{children}</div>;
};

export default StickyBtnWrapper;
