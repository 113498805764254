import React, { useEffect, useState } from "react";

import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";
import Color from "color";
import { useSelector } from "react-redux";

import getSecondaryTheme from "../theme/secondary";

const WHITE_PURE = "#FFFFFF";
const BLACK_PURE = "#000000";

const SecondaryThemeProvider = ({ children }) => {
  const { theme: shopTheme } = useSelector((state) => state.initData);
  const [innerTheme, setInnerTheme] = useState(createTheme({}));

  const actionTextColor = shopTheme.actionTextColor || BLACK_PURE;

  const titleTextColor = shopTheme.titleTextColor || BLACK_PURE;
  const subtitleTextColor = shopTheme.subtitleTextColor || BLACK_PURE;
  const itemTextColor = shopTheme.itemTextColor || BLACK_PURE;
  const itemDescColor = shopTheme.itemDescColor || BLACK_PURE;
  const menuTextColor = shopTheme.menuTextColor || BLACK_PURE;
  const accentTextColor = shopTheme.accentTextColor || BLACK_PURE;
  const primaryTextColor = shopTheme.primaryTextColor || BLACK_PURE;

  const menuBackgroundColor = shopTheme.menuBackgroundColor || WHITE_PURE;
  const categoryBackgroundColor = shopTheme.categoryBackgroundColor || WHITE_PURE;
  const accentColor = shopTheme.accentColor || WHITE_PURE;

  const primaryColor = shopTheme.primaryColor || BLACK_PURE;

  const secondaryColor = shopTheme.secondaryColor && accentColor === shopTheme.secondaryColor
    ? shopTheme.primaryColor
    : shopTheme.secondaryColor || WHITE_PURE;

  const secondaryTextColor = shopTheme.secondaryColor && accentColor === shopTheme.secondaryColor
    ? shopTheme.primaryTextColor
    : shopTheme.secondaryTextColor || BLACK_PURE;

  const boarderColor = shopTheme.boarderColor || BLACK_PURE;

  const defaultTextColor = accentTextColor;

  const isDark = Color(accentColor).isDark();

  const borderRadius = 8;

  useEffect(() => {
    const theme = getSecondaryTheme(
      defaultTextColor,
      primaryTextColor,
      actionTextColor,
      titleTextColor,
      subtitleTextColor,
      itemTextColor,
      itemDescColor,
      menuTextColor,
      accentTextColor,
      secondaryTextColor,

      primaryColor,
      secondaryColor,

      menuBackgroundColor,
      categoryBackgroundColor,
      accentColor,

      boarderColor,

      isDark,

      borderRadius,
    );
    const innertheme = responsiveFontSizes(theme);
    setInnerTheme(innertheme);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopTheme]);

  return <ThemeProvider theme={innerTheme}>{children}</ThemeProvider>;
};

export default SecondaryThemeProvider;
