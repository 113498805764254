import React from "react";

import { Badge, IconButton, Typography } from "@material-ui/core";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

import BottomStickyBtn from "../../../common/BottomStickyBtn";
import MobileCartDialog from "../../../common/MobileCartDialog";

const CartDialog = ({ cartCount, isIcon }) => {
  const [bucketOpen, setBucketOpen] = React.useState(false);

  const Label = () => (
    <div style={{display: "flex", alignItems: "center"}}>
      <Typography variant="button">ORDER</Typography>
      <ShoppingCartIcon />
    </div>
  );

  return (
    <>
      <MobileCartDialog setBucketOpen={setBucketOpen} bucketOpen={bucketOpen} />
      {isIcon ? (
        <IconButton
          style={{ color: "inherit", marginRight: 12 }}
          onClick={() => setBucketOpen(true)}
        >
          <Badge
            style={{ marginLeft: 8 }}
            badgeContent={cartCount}
            color="primary"
          >
            <ShoppingCartIcon />
          </Badge>
        </IconButton>
      ) : (
        <BottomStickyBtn
          label={<Label cartItemCount={cartCount} />}
          labelText="ORDER"
          setOpen={setBucketOpen}
        />
      )}
    </>
  );
};

export default CartDialog;
