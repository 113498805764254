import {
  FETCH_INIT_DATA_ERROR,
  FETCH_INIT_DATA_PENDING,
  FETCH_INIT_DATA_SUCCESS,
} from "./actions";

export const InitDataInitialState = {
  theme: {},
  isFetching: false,
  error: null,
};

export default function InitDataReducer(state = InitDataInitialState, action) {
  switch (action.type) {
    case FETCH_INIT_DATA_PENDING:
      return {
        ...state,
        isFetching: true,
      };

    case FETCH_INIT_DATA_SUCCESS:
      return {
        ...state,
        theme: action.payload,
        isFetching: false,
      };

    case FETCH_INIT_DATA_ERROR:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    default:
      return state;
  }
}
