import {
  QUERY_PARAM_POPUP_CAT,
  QUERY_PARAM_POPUP_CATMENU,
  QUERY_PARAM_POPUP_MENU,
} from "../utils/constants";
import useQueryParam from "./RouteHooks/useQueryParam";

const useMenuUtils = () => {
  const { getParams } = useQueryParam();

  const getCatMenuByID = (catMenuList, catMenuID) => {
    try {
      return catMenuList.find(
        (cm) => String(cm.catMenuId) === String(catMenuID),
      );
    } catch (error) {
      return null;
    }
  };

  const getCategoryByID = (catMenu, catID) => {
    try {
      const categories = Object.values(catMenu.data);
      return categories.find(
        (cat) => String(cat.catId) === String(catID),
      );
    } catch (error) {
      return null;
    }
  };

  const getMenuItemByID = (category, menuID) => {
    try {
      const menuItems = Object.values(category.data);
      return menuItems.find(
        (item) => String(item.menuId) === String(menuID),
      );
    } catch (error) {
      return null;
    }
  };

  const getMenuItemByQueryParams = (availCatMenuList) => {
    const queryParams = getParams([
      QUERY_PARAM_POPUP_CATMENU,
      QUERY_PARAM_POPUP_CAT,
      QUERY_PARAM_POPUP_MENU,
    ]);

    const catID = queryParams[QUERY_PARAM_POPUP_CAT];
    const catMenuID = queryParams[QUERY_PARAM_POPUP_CATMENU];
    const menuID = queryParams[QUERY_PARAM_POPUP_MENU];

    const catMenu = getCatMenuByID(availCatMenuList, catMenuID);
    if (catMenu) {
      const category = getCategoryByID(catMenu, catID);
      if (category) {
        const menuItem = getMenuItemByID(category, menuID);
        if (menuItem) {
          return { catMenu, category, menuItem };
        }
        return { catMenu, category, menuItem: null };
      }
      return { catMenu, category: null, menuItem: null };
    }
    return { catMenu: null, category: null, menuItem: null };
  };

  return {
    getMenuItemByQueryParams,
    getCatMenuByID,
    getCategoryByID,
  };
};

export default useMenuUtils;
