import React from "react";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
} from "@material-ui/core";
import firebase from "firebase";
import TextFieldDefault from "../../../../common/textFields/TextFieldDefault";
import { useForm } from "react-hook-form";
import { useState } from "react";
import StickyButton from "../../../../common/buttons/StickyButton";

const useStyles = makeStyles((theme) => ({
  modal: {
    "& .MuiDialog-container": {
      [theme.breakpoints.up("md")]: {
        height: theme?.view?.isFixedList ? "initial" : "100%",
      },
    },
    "& .MuiDialog-paper": {
      margin: 4,
    },
  },
}));

const PasswordResetModal = ({
  setOpenDialog,
  openDialog,
  setIsLoading,
  setError,
  isLoading,
  setAlertMessage,
}) => {
  const [email, setEmail] = useState("");
  const { register, errors, trigger } = useForm();

  const handleCancel = () => {
    setOpenDialog(false);
  };

  const handleResetPassword = async () => {
    // Set 'isLoadingPasswordReset' state to true, indicating the start of the process
    setIsLoading(true);
    try {
      // Attempt to send a password reset email using Firebase authentication
      await firebase.auth().sendPasswordResetEmail(email);
      setIsLoading(false);
      setOpenDialog(false);
      setAlertMessage("Please check your email");
    } catch (error) {
      setIsLoading(false);
      setError(error.message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmail(value);
  };

  const classes = useStyles();

  return (
    <Dialog
      classes={{ root: classes.modal }}
      open={openDialog}
      onClose={(e, reason) => {
        if (reason === "backdropClick") {
          return;
        }
        setOpenDialog(false);
      }}
      keepMounted
      minWidth="lg"
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>Reset Password</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <TextFieldDefault
              id="email"
              name="email"
              label="Email"
              type="email"
              value={email}
              variant="filled"
              onChange={handleChange}
              fullWidth
              style={{width: "370px"}}
              inputRef={register({
                required: true,
                minLength: 2,
                maxLength: 30,
              })}
              helperText={errors.password ? "Please enter password" : ""}
              error={errors.password?.type}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <Button onClick={handleCancel} color="inherit">
          Cancel
        </Button>
        <Button onClick={handleResetPassword} color="inherit" autoFocus style={{marginRight: "12px"}}>
          {isLoading ? (
            <CircularProgress size={20} color="inherit" />
          ) : (
            "Send Reset Link"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PasswordResetModal;
