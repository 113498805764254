import React, { useEffect, useState } from "react";

import { Fade, makeStyles, useTheme } from "@material-ui/core";
import Color from "color";

import MenuItemNoImage from "./MenuItemNoImage";

const useStyles = makeStyles((theme) => ({
  ImageDefaultWrapper: {
    width: "100%",
    minHeight: "auto",
    aspectRatio: ({ maxImageHeight, maxImageWidth }) => `${maxImageWidth} / ${maxImageHeight}`,
    borderTopLeftRadius: ({ topLeft }) => topLeft,
    borderTopRightRadius: ({ topRight }) => topRight,
    borderBottomLeftRadius: ({ bottomLeft }) => bottomLeft,
    borderBottomRightRadius: ({ bottomRight }) => bottomRight,
    backgroundColor: Color(
      theme.palette.background.menuBackgroundColor,
    ).isDark()
      ? Color(theme.palette.background.menuBackgroundColor)
        .mix(Color("#fff"), 0.1)
        .hex()
      : Color(theme.palette.background.menuBackgroundColor)
        .mix(Color("#000"), 0.1)
        .hex(),
    [theme.breakpoints.down("sm")]: {
      height: "100%",
    },
  },
  img: {
    verticalAlign: "middle",
    borderTopLeftRadius: ({ topLeft }) => topLeft,
    borderTopRightRadius: ({ topRight }) => topRight,
    borderBottomLeftRadius: ({ bottomLeft }) => bottomLeft,
    borderBottomRightRadius: ({ bottomRight }) => bottomRight,
    aspectRatio: ({ maxImageHeight, maxImageWidth }) => `${maxImageWidth} / ${maxImageHeight}`,
    width: "100%",
    objectFit: "cover",
    [theme.breakpoints.down("sm")]: {
      height: "100%",
    },
  },
}));

const MenuItemImage = ({
  src,
  alt,
  curved = "right",
  maxImageHeight,
  maxImageWidth,
}) => {
  const [imageUrl, setImageUrl] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    const convertThumbCloudinary = () => {
      if (src.includes("res.cloudinary.com")) {
        const [firstPart, secondPart] = src.split("/upload/");
        return setImageUrl(`${firstPart}/upload/t_presto_thumb/${secondPart}`);
      }
      return setImageUrl(src);
    };
    convertThumbCloudinary();
  }, [src]);

  let topLeft = 0;
  let topRight = 0;
  let bottomRight = 0;
  let bottomLeft = 0;

  switch (curved) {
    case "top":
      topLeft = theme.shape.borderRadius;
      topRight = theme.shape.borderRadius;
      bottomLeft = 0;
      bottomRight = 0;
      break;

    case "right":
      topRight = theme.shape.borderRadius;
      bottomRight = theme.shape.borderRadius;
      topLeft = 0;
      bottomLeft = 0;
      break;

    case "left":
      topLeft = theme.shape.borderRadius;
      bottomRight = theme.shape.borderRadius;
      topRight = 0;
      bottomRight = 0;
      break;

    case "bottom":
      bottomLeft = theme.shape.borderRadius;
      bottomRight = theme.shape.borderRadius;
      topLeft = 0;
      topRight = 0;
      break;

    case "all":
      topLeft = theme.shape.borderRadius;
      topRight = theme.shape.borderRadius;
      bottomLeft = theme.shape.borderRadius;
      bottomRight = theme.shape.borderRadius;
      break;

    default:
      break;
  }

  const classes = useStyles({
    topLeft,
    topRight,
    bottomRight,
    bottomLeft,
    maxImageHeight,
    maxImageWidth,
    src,
  });

  const onError = () => {
    setError(true);
    setIsLoaded(true);
  };

  return (
    <div className={classes.ImageDefaultWrapper}>
      {!error && (
        <Fade in={!!imageUrl}>
          <img
            className={classes.img}
            src={imageUrl}
            style={!isLoaded ? { visibility: "hidden" } : {}}
            alt={alt}
            onError={onError}
            onLoad={() => {
              setIsLoaded(true);
              setError(false);
            }}
          />
        </Fade>
      )}
      {error && (
        <Fade in>
          <MenuItemNoImage
            maxImageHeight={maxImageHeight}
            maxImageWidth={maxImageWidth}
          />
        </Fade>
      )}
    </div>
  );
};

MenuItemImage.propTypes = {};
MenuItemImage.defaultProps = {};

export default MenuItemImage;
