/* eslint-disable react/jsx-props-no-spreading */
import React from "react";

import { Button, makeStyles } from "@material-ui/core";

export const STICKY_BUTTON_HEIGHT = 56;

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(1),
    borderRadius: 0,

    "&.Mui-disabled": {
      backgroundColor: theme.palette.secondary.dark,
      color: theme.palette.text.disabled,
    },

    "&:hover": {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
}));

const StickyButton = ({ onClick, children, flex, ...props }) => {
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      color="secondary"
      onClick={onClick}
      style={{ flex, height: STICKY_BUTTON_HEIGHT }}
      classes={{ root: classes.root }}
      {...props}
    >
      {children}
    </Button>
  );
};

export default StickyButton;
