import React, { memo } from "react";

import { Box, Typography } from "@material-ui/core";
import { darken, makeStyles, useTheme } from "@material-ui/core/styles";

import PriceUtils from "../../../../../../utils/PriceUtils";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },

  itemCardLeft: {
    flex: 4,
  },
  itemCardRight: {
    flex: 1,
    textAlign: "right",
  },
  itemCard: {
    display: "flex",
    padding: "0.7rem",
    // margin: "0.5rem auto",
    textAlign: "start",
    transition: "all 0.5s ease",
    "&:hover": {
      backgroundColor: darken(
        theme.palette.background.menuBackgroundColor,
        0.1,
      ),
      cursor: "pointer",
    },
  },
}));

const MenuItemListCard = ({ title, desc, menuItemData, id }) => {
  const classes = useStyles();
  const theme = useTheme();

  const priceDisplayText = PriceUtils.getMenuItemPrice(menuItemData);

  return (
    <Box component="div" id={id} className={classes.itemCard}>
      <Box className={classes.itemCardLeft}>
        <Typography
          variant="subtitle1"
          component="div"
          style={{ color: theme.palette.text.itemTextColor }}
        >
          {title}
        </Typography>
        {desc && (
          <Typography
            variant="caption"
            style={{ color: theme.palette.text.itemDescColor }}
          >
            {desc}
          </Typography>
        )}
      </Box>
      <span style={{ margin: 8 }} />
      <Box className={classes.itemCardRight}>
        <Typography
          variant="subtitle1"
          style={{ color: theme.palette.text.itemTextColor }}
        >
          {priceDisplayText}
        </Typography>
      </Box>
    </Box>
  );
};

function cardPropsEqual(prevProps, nextProps) {
  return prevProps.menuId === nextProps.menuId;
}

export default memo(MenuItemListCard, cardPropsEqual);
