/* eslint-disable camelcase */
import React from "react";

import _ from "lodash";
import sum from "lodash/sum";
import qs from "query-string";
import NumberFormat from "react-number-format";

import { currencySymbols } from "./const";
import {
  DELIVERY_TYPE_DELIVER,
  DELIVERY_TYPE_DINEIN,
  DELIVERY_TYPE_PICKUP,
  PAYMENT_METHOD_CARD,
  PAYMENT_METHOD_CASH,
  REGEX_PATTERN_HTML_STRING,
} from "./constants";
import moment from "./time/moment";
import TimeUtils from "./TimeUtils";

export const formatPrice = (amount, isNegative = false) => (
  <NumberFormat
    value={amount}
    displayType="text"
    thousandSeparator
    prefix={isNegative ? `- ${currencySymbols.GBP}` : currencySymbols.GBP}
    decimalScale={2}
    fixedDecimalScale
  />
);

export const priceWithCurrency = (amount) => {
  if (!parseFloat(amount)) return `${currencySymbols.GBP}0.00`;
  return `${currencySymbols.GBP}${amount}`;
};

export const priceRangeWithCurrency = (prices) => {
  try {
    if (_.isEmpty(prices)) return `${currencySymbols.GBP}0.00`;

    let priceList = _.sortBy(prices.map((price) => parseFloat(price)));
    priceList = _.sortedUniqBy(priceList.map((price) => parseFloat(price)));

    let priceDisplayText = "";
    let minPrice = 0;
    let maxPrice = 0;

    if (priceList.length > 1) {
      [minPrice] = priceList;
      maxPrice = priceList[priceList.length - 1];
      priceDisplayText = `${currencySymbols.GBP}${minPrice}\xa0-\xa0${currencySymbols.GBP}${maxPrice} `;
    } else {
      [minPrice] = priceList;
      priceDisplayText = `${currencySymbols.GBP}${minPrice}`;
    }
    return priceDisplayText;
  } catch (error) {
    return `${currencySymbols.GBP}0.00`;
  }
};

export const getItemCat = (selectedItemData) => {
  if (
    selectedItemData.itemType === "direct"
    && selectedItemData.data.length === 1
  ) {
    return "singleDirect";
  }
  if (
    selectedItemData.itemType === "direct"
    && selectedItemData.data.length > 1
  ) {
    return "multiDirect";
  }
  if (selectedItemData.itemType === "byo") return "buildYourOwn";

  return null;
};

export const getPriceByPcp = (subs, pcp, extraCost, surcharge, itemId) => {
  if (subs.length >= pcp) {
    if (subs.slice(0, pcp).find((i) => i.itemId === itemId)) {
      return parseFloat(surcharge);
    }
    return parseFloat(extraCost);
  }
  return parseFloat(surcharge);
};

export const calByoOneUnitPrice = (subItems, mainPrice) => {
  const stgPriceList = subItems.map((stg) => {
    const priceList = stg.subs.map((s) => getPriceByPcp(stg.subs, stg.pcp, s.extraCost, s.surcharge, s.itemId));
    return sum(priceList);
  });
  return sum(stgPriceList) + parseFloat(mainPrice);
};

/*
'M' is statute miles (default)
'K' is kilometers
'N' is nautical miles
*/
export const getDistance = (lat1, lon1, lat2, lon2, unit) => {
  if (lat1 === lat2 && lon1 === lon2) {
    return 0;
  }

  // const lat2 = 53.3707965;
  // const lon2 = -1.4176416;
  const radlat1 = (Math.PI * lat1) / 180;
  const radlat2 = (Math.PI * lat2) / 180;
  const theta = lon1 - lon2;
  const radtheta = (Math.PI * theta) / 180;
  let dist = Math.sin(radlat1) * Math.sin(radlat2)
    + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  if (dist > 1) {
    dist = 1;
  }
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  if (unit === "K") {
    dist *= 1.609344;
  }
  if (unit === "N") {
    dist *= 0.8684;
  }
  return dist;
};

export const filterDeliveryOptionsByDistance = (
  deliveryOptionsList,
  deliveryLocation,
  storeLocation,
) => {
  const { lat: delLat, lng: delLng } = deliveryLocation;
  const { lat: storeLat, lon: storeLng } = storeLocation;
  const distanceDeliveryNodeList = deliveryOptionsList.filter(
    (option) => option.area.type === "distance",
  );
  let filteredListByDistance = _.filter(distanceDeliveryNodeList, (o) => {
    const deliverRadius = parseFloat(o.area.data.distance);
    const dist = getDistance(
      parseFloat(delLat),
      parseFloat(delLng),
      parseFloat(storeLat),
      parseFloat(storeLng),
      "M",
    );
    return dist < deliverRadius;
  });
  const minDistance = _.min(
    filteredListByDistance.map((dis) => parseFloat(dis.area.data.distance)),
  );
  filteredListByDistance = filteredListByDistance.filter(
    (dis) => parseFloat(dis.area.data.distance) === minDistance,
  );
  return filteredListByDistance;
};

export const filterDeliveryOptionsByPostalCode = (
  deliveryOptionsList,
  googleAddress,
) => {
  try {
    const { postal_code, postal_code_prefix } = googleAddress;
    const postalCodePrefix = postal_code
      ? postal_code.split(" ")[0]
      : postal_code_prefix;

    const postalCodeDeliveryNodeList = deliveryOptionsList.filter(
      (option) => option.area.type === "postcode",
    );
    const filteredListByPostcode = postalCodeDeliveryNodeList.filter((option) => Object.keys(option.area.data).includes(postalCodePrefix));
    return filteredListByPostcode;
  } catch (error) {
    return [];
  }
};

export function calCenterOfLocations(lat1, lng1, lat2, lng2) {
  return { lat: (lat1 + lat2) / 2, lng: (lng1 + lng2) / 2 };
}

export function setLocalStore(name, value) {
  localStorage.setItem(name, JSON.stringify(value));
}

export function getLocalStore(name) {
  try {
    return JSON.parse(localStorage.getItem(name));
  } catch (error) {
    return null;
  }
}

export function convertDeliveryType(deliveryType) {
  switch (deliveryType) {
    case DELIVERY_TYPE_PICKUP:
      return "PICKUP";
    case "PICKUP":
      return DELIVERY_TYPE_PICKUP;

    case DELIVERY_TYPE_DINEIN:
      return "DINEIN";
    case "DINEIN":
      return DELIVERY_TYPE_DINEIN;

    case DELIVERY_TYPE_DELIVER:
      return "DELIVER";
    case "DELIVER":
      return DELIVERY_TYPE_DELIVER;
    default:
      break;
  }
  return deliveryType;
}

export function convertDeliveryTypeToTypeLabel(deliveryType) {
  switch (deliveryType) {
    case DELIVERY_TYPE_PICKUP:
      return "PICKUP";
    case DELIVERY_TYPE_DINEIN:
      return "DINE IN";
    case DELIVERY_TYPE_DELIVER:
      return "DELIVER";
    default:
      break;
  }
  return deliveryType;
}

export function constructItemsArray(discountAttachedCartItems, cartItems) {
  let itemList = _.cloneDeep(cartItems);
  itemList = itemList.map((item) => {
    const newItem = { ...item };
    const discountItem = discountAttachedCartItems.find(
      (i) => i.item.menuId === item.item.menuId,
    );
    newItem.discountIds = [
      ...discountItem.applicableDiscounts.map((discount) => discount.id),
    ];
    return newItem;
  });

  return itemList.map((cartItem) => {
    const { catId, menuId, itemId, price, size } = cartItem.item;
    const { title } = cartItem.itemData;

    const subs = cartItem.subItems?.map((sub) => sub.subs).flat();

    const subItems = subs?.map((item) => ({
      id: item.itemId,
      menuId: item.menuId,
      catId: item.catId,
      catMenuId: cartItem.catMenuId,
      name: item.title,
      price: item.price.toFixed(2),
      discountedPrice: "0.00",
      quantity: 1,
      stageId: item.stageId,
    }));

    return {
      id: itemId,
      menuId,
      catId,
      catMenuId: cartItem.catMenuId,
      name: cartItem.type === "multiDirect" ? `${title} ${size}` : title,
      price,
      discountedPrice: "",
      quantity: cartItem.quantity,
      discountIds: cartItem.discountIds,
      subItems: subItems || [],
    };
  });
}

// /**
//  * convert time between time zones
//  * @param {string} time current time zone time format: 2014-06-01 12:00
//  * @param {string} currentTimeZone current timezone
//  * @param {string} toTimeZone convert time zone
//  */
// export function convertTimezone(time, currentTimeZone, toTimeZone) {
//   const currentTime = momentzone.tz(time, currentTimeZone);
//   return currentTime.clone().tz(toTimeZone);
// }

export function getLocationIdAndOrderIdByUrl(url) {
  let locationId = url.split("location/")[1];
  let orderId = "";
  [locationId, orderId] = locationId.split("/order/");
  orderId = orderId.replace("/confirm", "");
  return {
    orderId,
    locationId,
  };
}

export function getLocationIdByPathname() {
  try {
    return window.location.pathname.split("location/")[1].split("/")[0];
  } catch (error) {
    return "";
  }
}

export const isFixedListView = (theme, matches) => theme?.view?.isFixedList && matches;

export const isEmbeddedWindow = () => !(window.top === window.self);

export const getCompleteOrderErrorMessage = (
  restaurantName,
  restaurantContact,
) => `Something went wrong but your order might have gone through. Please contact the ${restaurantName} on ${restaurantContact} to confirm.`;

export const getPaymentLabel = (paymentType) => {
  switch (paymentType) {
    case PAYMENT_METHOD_CARD:
      return "Card";
    case PAYMENT_METHOD_CASH:
      return "Cash";
    default:
      return "";
  }
};

export const convertDayCodeToNames = (code) => {
  switch (code) {
    case "sun":
      return "Sunday";
    case "mon":
      return "Monday";
    case "tue":
      return "Tuesday";
    case "wed":
      return "Wednesday";
    case "wen":
      return "Wednesday";
    case "thu":
      return "Thursday";
    case "fri":
      return "Friday";
    case "sat":
      return "Saturday";
    default:
      return "";
  }
};

export const isSingleDirectItem = (menuItem) => {
  const { data, itemType } = menuItem;
  if (itemType === "direct" && data.length === 1) return true;
  return false;
};

export const isMultipleDirectItem = (menuItem) => {
  const { data, itemType } = menuItem;
  if (itemType === "direct" && data.length > 1) return true;
  return false;
};

export const isByoItem = (menuItem) => {
  const { itemType } = menuItem;
  if (itemType === "byo") return true;
  return false;
};

export const postMessageToParentWindow = (data) => {
  window.parent.postMessage(
    { ...data },
    process.env.REACT_APP_RESTAURANT_DOMAIN,
  );
};

export const isMobileView = () => window.matchMedia("(max-width: 900px)").matches;

export const isMatchDeliveryMethod = (deliveryMethod, allowedDeliveryMethods) => _.isEmpty(allowedDeliveryMethods)
  || (!_.isEmpty(allowedDeliveryMethods)
    && allowedDeliveryMethods.includes(convertDeliveryType(deliveryMethod)));

export const isMatchAvailability = (time, availabilityList) => _.isEmpty(availabilityList)
  || (!_.isEmpty(availabilityList)
    && TimeUtils.isTimeInWeeklyTimes(moment(time), availabilityList));

export const isStoreHomePage = () => window.location.pathname.includes("/shop");

export const formatDate = (dateTime) => moment(dateTime).format("YYYY-MM-DD HH:mm:ss");

export const getClientQueryParams = () => {
  try {
    return qs.parse(window.location.search, {
      parseBooleans: true,
      parseNumbers: true,
    });
  } catch (error) {
    return {};
  }
};

export const getQueryParams = (url) => {
  try {
    const queryString = url.split("?")[1];
    return qs.parse(queryString, { parseBooleans: true, parseNumbers: true });
  } catch (error) {
    return {};
  }
};

export const isValidHTMLString = (htmlString) => {
  try {
    return REGEX_PATTERN_HTML_STRING.test(htmlString.trim());
  } catch (error) {
    return false;
  }
};
