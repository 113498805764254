import React from "react";

import {
  Button,
  makeStyles,
  SvgIcon,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    textTransform: "none",
    minWidth: 220,
    maxHeight: 40,
    display: "flex",
    justifyContent: "space-between",
  // Default background color
  backgroundColor: "#e0e0e0",
  // Change the background color when disabled
  "&:disabled": {
    backgroundColor: "#e0e0e0",
    color: "black"
  },
  // Optional hover effect (if you want to keep it)
  "&:hover": {
    backgroundColor: "#e0e0e0",
  },
  },
}));

const SocialSignInButton = ({
  handleClick,
  logoSrc,
  labelText,
  isLoading,
  alt = "alt",
  disabled,
}) => {
  const classes = useStyles();
  return (
    <Button
      variant="contained"
      size="large"
      onClick={handleClick}
      classes={{ root: classes.root }}
      disabled={isLoading || disabled}
    >
      {typeof logoSrc === "string" ? (
        <img
          src={logoSrc}
          alt={alt}
          style={{ width: 24, height: 24, marginRight: 8 }}
        />
      ) : (
        <SvgIcon>{logoSrc}</SvgIcon>
      )}
      {isLoading ? (
        <Typography variant="subtitle2">{labelText}</Typography>
      ) : (
        <Typography variant="subtitle2">{labelText}</Typography>
      )}
    </Button>
  );
};

export default SocialSignInButton;
// "https://developers.google.com/identity/images/g-logo.png"
