export const SET_AVAILABLE_PAYMENT_METHODS = "SET_AVAILABLE_PAYMENT_METHODS";
export const SET_SELECTED_PAYMENT_METHOD = "SET_SELECTED_PAYMENT_METHOD";

export const setAvailablePaymentMethods = (payload) => ({
  type: SET_AVAILABLE_PAYMENT_METHODS,
  payload,
});

export const setSelectedPaymentMethod = (payload) => ({
  type: SET_SELECTED_PAYMENT_METHOD,
  payload,
});
