import React, { useEffect } from "react";

import { useDispatch } from "react-redux";

import DeliveryDetails from "../../components/@pages/delivery/DeliveryDetails";
import { setAppliedCouponCode, setOrderDiscounts } from "../../components/@pages/storeHome/MyOrder/redux/actions";
import Stepper from "../../components/Stepper";
import ContentSection from "../../containers/ContentSection";
import OrderInfoSection from "../../containers/OrderInfoSection";
import PageWrapper from "../../containers/PageWrapper";
import SecondaryThemeProvider from "../../containers/SecondaryThemeProvider";

const DeliveryPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setAppliedCouponCode(""));
    dispatch(setOrderDiscounts([]));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper>
      <OrderInfoSection />
      <SecondaryThemeProvider>
        <ContentSection>
          <Stepper activeStep={0} />
          <DeliveryDetails />
        </ContentSection>
      </SecondaryThemeProvider>
    </PageWrapper>
  );
};

export default DeliveryPage;
