import React, { useEffect, useState, useRef } from "react";
import AlertMessage from "../../components/common/AlertMessage";
import SecondaryThemeProvider from "../../MyOrderPageContainers/SecondaryThemeProvider";
import ContentContainer from "../../MyOrderPageContainers/ContentContainer";
import ContentSection from "../../MyOrderPageContainers/ContentSection";
import withInitDataFetch from "../../components/Layout/withFetch";
import Layout from "../../components/Layout";
import PageWrapper from "../../MyOrderPageContainers/PageWrapper";
import OrderList from "./OrderList";
import { getAllOrder } from "../../services/orderService";
import { useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginUserError, loginUserSuccess } from "../../redux/actions/auth";
import { setLocalStore } from "../../utils/storage/localStorage";
import firebase from "../../utils/firebase";
import { logInfo } from "../../services/logging";
import LoginDialog from "../../components/@pages/storeHome/LoginDialog";
import { fetchRewardDetailsListAsync, fetchRewardDetailsListSuccess } from "../../components/@pages/storeHome/CatMenu/redux/actions";
const WithDataLayout = withInitDataFetch(Layout);

const MyOrdersPage = () => {
  const { params } = useRouteMatch();
  const [orderNodeData, setOrderNodeData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoad, setIsLoad] = useState(true);
  const [alertMessage, setAlertMessage] = useState("");
  const [orderStatus, setOrderStatus] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoadingScroll, setIsLoadingScroll] = useState(false);

  const { isActiveLoyalty } = useSelector((state) => state.loyalty);

  const dispatch = useDispatch();
  const containerRef = useRef(null);

  const handleGetAllOrderDetails = async (pageSize) => {
    try {
      const res = await getAllOrder(params.locationId, pageSize, 1);
      const { currentPage, totalPages } = res.data;
      setOrderNodeData(res.data.data);
      setIsLoading(false);
      setCurrentPage(currentPage);
      setTotalPages(totalPages);
      if (totalPages > 1) {
        setIsLoadingScroll(true);
      }
      setIsLoad(true);
    } catch (error) {
      if (error?.response?.status === 401) {
        onAuthStateChange();
        // return setOpenLoginDialog(true);
      } else {
        setIsLoading(false);
        setAlertMessage("Unexpected error! Please check your connection");
      }
    }
  };

  const subscribeConnection = (status) => {
    if (status) {
      setOrderStatus(status);
    }
  };

  const onAuthStateChange = async () => {
    // dispatch(loginUserPending(""));
    const authFirebase = firebase.auth();
    await authFirebase.onAuthStateChanged((firebaseUser) => {
      if (firebaseUser) {
        firebaseUser
          .getIdToken(true)
          .then((idToken) => {
            if (isActiveLoyalty?.isActive) {
              dispatch(fetchRewardDetailsListAsync(params.locationId));
            } else {
              dispatch(fetchRewardDetailsListSuccess({locationId: params.locationId, points: []}));
            }
            dispatch(
              loginUserSuccess(firebaseUser.displayName, firebaseUser.email),
            );
            setLocalStore("idToken", idToken);
            setLocalStore("userId", firebaseUser.uid);
            handleGetAllOrderDetails("20");
            logInfo({
              message: `Auth state changed: authentication success | user ${firebaseUser.uid}}`,
            });
          })
          .catch(() => {
            dispatch(loginUserError("Error login user"));
          });
      } else {
        dispatch(loginUserError("Error login user"));
      }
    });
  };

  useEffect(() => {
    handleGetAllOrderDetails("20");
  }, []);

  const handleScroll = async () => {
    const container = containerRef.current;

    // Calculate whether the user has reached the bottom of the container

    if (currentPage < totalPages) {
      const isAtBottom =
        container.scrollHeight - container.scrollTop <=
        container.clientHeight + 1;

      if (isAtBottom) {
        if (isLoad) {
          const pageSize = (pageNumber + 1) * 20;
          setPageNumber((prevState) => prevState + 1);
          setIsLoad(false);
          return handleGetAllOrderDetails(pageSize);
        }
      }
    } else {
      setIsLoadingScroll(false);
    }
  };
  return (
    <WithDataLayout>
      <PageWrapper>
        <LoginDialog />
        <AlertMessage
          message={alertMessage}
          setMessage={setAlertMessage}
          severity="error"
        />
        <SecondaryThemeProvider>
          <ContentSection>
            <ContentContainer
              noPadding
              isMyOrdersPage
              orderStatus={orderStatus}
            >
              <OrderList
                orderNodeData={orderNodeData}
                isMyOrdersPage
                subscribeConnection={(e) => subscribeConnection(e)}
                setOrderStatus={setOrderStatus}
                orderStatus={orderStatus}
                isLoading={isLoading}
                handleScroll={handleScroll}
                containerRef={containerRef}
                isLoadingScroll={isLoadingScroll}
              />
            </ContentContainer>
          </ContentSection>
        </SecondaryThemeProvider>
      </PageWrapper>
    </WithDataLayout>
  );
};

MyOrdersPage.propTypes = {};
MyOrdersPage.defaultProps = {};

export default MyOrdersPage;
