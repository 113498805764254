import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import _ from "lodash";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

import { DELIVERY_TYPE_DELIVER, DELIVERY_TYPE_DINEIN, DELIVERY_TYPE_PICKUP } from "../../../../../utils/constants";
import ToggleButtonDefault from "../../../../common/buttons/ToggleButtonDefault";
import {
  setSelectedDeliveryMethod,
  setSelectedDeliveryTimeType,
} from "../redux/actions";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    margin: "8px 16px",
  },
  toggle: {
    flex: 1,
  },
}));

const Delivery = ({ actions, isModal }) => {
  const classes = useStyles();
  const [showDineIn, setShowDineIn] = useState(false);
  const [showDelivery, setShowDelivery] = useState(false);
  const { selectedDeliveryMethod, deliveryData } = useSelector(
    (state) => state.cart,
  );
  const { isDineIn } = useSelector((state) => state.global);

  const handleChange = (event, selection) => {
    if (selection == null) return;
    actions.setSelectedDeliveryMethod(selection);
  };

  useEffect(() => {
    if (
      !_.isEmpty(deliveryData)
      && deliveryData.find((d) => d.type === DELIVERY_TYPE_DINEIN)
    ) {
      setShowDineIn(true);
    }

    if (
      !_.isEmpty(deliveryData)
      && deliveryData.find((d) => d.type === DELIVERY_TYPE_DELIVER)
    ) {
      setShowDelivery(true);
    }
  }, [deliveryData]);

  // useEffect(() => {
  //   if (isDineIn) {
  //     actions.setSelectedDeliveryMethod("DINEIN");
  //     actions.setSelectedDeliveryTimeType("ASAP");
  //   }
  // }, [isDineIn, actions]);

  return (
    <ToggleButtonGroup
      className={classes.root}
      value={selectedDeliveryMethod}
      exclusive
      onChange={handleChange}
    >
      {deliveryData.find((d) => d.type === DELIVERY_TYPE_PICKUP) && !isDineIn && (
        <ToggleButtonDefault
          isContrast={!!isModal}
          className={classes.toggle}
          value="PICKUP"
        >
          Pick up
        </ToggleButtonDefault>
      )}
      {showDelivery && !isDineIn && (
        <ToggleButtonDefault
          isContrast={!!isModal}
          className={classes.toggle}
          value="DELIVER"
        >
          Deliver
        </ToggleButtonDefault>
      )}
      {isDineIn && (
        <>
          {showDineIn ? (
            <ToggleButtonDefault
              selected
              isContrast={!!isModal}
              className={classes.toggle}
              value="DINEIN"
            >
              Dine in
            </ToggleButtonDefault>
          ) : <h6>No Dine-in option available</h6>}
        </>
      ) }
    </ToggleButtonGroup>
  );
};

const mapStateToProps = (state) => ({
  cart: state.cart,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    { setSelectedDeliveryMethod, setSelectedDeliveryTimeType },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Delivery);
