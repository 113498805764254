/* eslint-disable camelcase */
import React, { useEffect, useState } from "react";

import { useTheme } from "@material-ui/core";
import _ from "lodash";
import qs from "query-string";
import { useSelector } from "react-redux";

import { HOME_ICON_URL, RESTAURANT_ICON_URL } from "../../../utils/constants";

// import circle from "../../../assets/images/circle.png";

const GoogleMapDelivery = () => {
  const [mapUrl, setMapUrl] = useState("");
  const theme = useTheme();

  const { storeInfo: businessInfo } = useSelector((state) => state.store);
  const { deliveryLocation, selectedDeliveryMethod, selectedDeliveryData } = useSelector(
    (state) => state.cart,
  );
  const { lat: deliveryLat, lng: deliveryLng } = deliveryLocation;
  useEffect(() => {
    if (!businessInfo) return;
    const { lon } = businessInfo;
    const { lat } = businessInfo;

    let markers = [`icon:${RESTAURANT_ICON_URL}|${lat},${lon}`];
    if (selectedDeliveryMethod === "DELIVER" && !_.isEmpty(selectedDeliveryData)) {
      markers = [...markers, `icon:${HOME_ICON_URL}|${deliveryLat},${deliveryLng}`];
    }
    const obj = {
      // center,
      // zoom: 13,
      scale: 4,
      size: "600x350",
      maptype: "roadmap",
      markers: deliveryLat && deliveryLat ? markers : [`icon:${RESTAURANT_ICON_URL}|${lat},${lon}`],
      key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    };
    const url = `https://maps.googleapis.com/maps/api/staticmap?${qs.stringify(
      obj,
    )}`;
    setMapUrl(url);

    // getPathPoints({ latitude: lat, longitude: lon }, 10);
  }, [
    businessInfo,
    selectedDeliveryMethod,
    deliveryLocation,
    deliveryLat,
    deliveryLng,
    selectedDeliveryData,
  ]);

  return (
    <img style={{ width: "100%", borderRadius: theme.shape.borderRadius }} src={mapUrl} alt="Google map of order" />
  );
};

export default GoogleMapDelivery;
