import React, { useState } from "react";

import { makeStyles, Typography, useTheme } from "@material-ui/core";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

import { logInfo } from "../../../../../../services/logging";
import FacebookPixel from "../../../../../../utils/analytics/FacebookPixel";
import { gaEventAddToCart } from "../../../../../../utils/googleAnalytics";
import {
  formatPrice,
  postMessageToParentWindow,
} from "../../../../../../utils/utils";
import AddToCartBtn from "../../../../../common/AddToCartBtn";
import QuantitySection from "../../../../../common/QuantitySection";
import { addItemToCartAsync } from "../../../MyOrder/redux/actions";
import TicktockPixel from "../../../../../../utils/TicktockPixel";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 2, 2, 3),
    color: theme.palette.text.itemDescColor,
  },
}));

const SingleDirectItem = ({
  selectedItemData,
  actions,
  handleClose,
  catMenuId,
  isAvailable,
  embedType,
}) => {
  const [quantity, setQuantity] = useState(1);
  const { price } = selectedItemData.data[0];
  const classes = useStyles();

  const theme = useTheme();
  const { storeInfo } = useSelector((state) => state.store);
  const {
    storeInfo: { businessDisplayName },
  } = useSelector((state) => state.store);
  const { categorySelected } = useSelector((state) => state.category);
  const { activeCatMenu } = useSelector((state) => state.catMenu);

  const addItemToCart = async () => {
    const cartItem = {};
    cartItem.itemData = selectedItemData;
    cartItem.catMenuId = catMenuId;
    cartItem.applicableDiscounts = [];
    [cartItem.item] = selectedItemData.data;
    cartItem.quantity = quantity;
    cartItem.type = "singleDirect";
    cartItem.totalPrice = (quantity * parseFloat(price)).toFixed(2);
    cartItem.oneUnitPrice = parseFloat(price).toFixed(2);

    if (!theme?.view?.isMobile && theme?.view?.isFixedList) {
      if (embedType === "WIX") {
        actions.addItemToCartAsync(cartItem);
      } else {
        postMessageToParentWindow({
          addItemToCart: true,
          cartItemNode: cartItem,
        });
      }
    } else {
      actions.addItemToCartAsync(cartItem);
    }
    logInfo({ message: "Item added to cart" });
    handleClose();
    gaEventAddToCart(
      cartItem,
      quantity,
      categorySelected,
      activeCatMenu,
      businessDisplayName,
      cartItem.oneUnitPrice,
    );
    FacebookPixel.addToCart(cartItem, categorySelected);
    // if (storeInfo?.tikTokAnalyticsId) {
    //   TicktockPixel.addToCart(cartItem, categorySelected);
    // }
  };

  return (
    <div className={classes.root}>
      <Typography variant="h6" component="div">
        {formatPrice(price)}
      </Typography>
      <QuantitySection quantity={quantity} setQuantity={setQuantity} />
      <AddToCartBtn onClick={addItemToCart} disabled={!isAvailable} />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ addItemToCartAsync }, dispatch),
});

export default connect(null, mapDispatchToProps)(SingleDirectItem);
