import React from "react";

import {
  makeStyles,
  Typography,
} from "@material-ui/core";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import AppleIcon from "@material-ui/icons/Apple";
import { Icon } from "@iconify/react";

const useStyles = makeStyles(() => ({
  CreditCartPaymentWrapper: {},
}));

const CreditCartPayment = ({ selectedPaymentMethod }) => {
  const classes = useStyles();
  return (
    <div className={classes.CreditCartPaymentWrapper}>
      <Typography
        variant="subtitle1"
        style={{
          lineHeight: 1,
          display: "flex",
          alignItems: "center",
          textTransform: "none",
          fotWeight: "bold",
        }}
      >
        <CreditCardIcon />
        Card &nbsp;
        <AppleIcon />
        Pay &nbsp;
        <Icon width="22px" height="22px" icon="ri:google-fill" />
        Pay
      </Typography>
    </div>
  );
};

CreditCartPayment.propTypes = {};
CreditCartPayment.defaultProps = {};

export default CreditCartPayment;
