import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Typography,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import Color from "color";

const useStyles = makeStyles((theme) => ({
  SelectedOptionsDialogWrapper: {
    color: theme.palette.text.itemTextColor,
    backgroundColor: Color(
      theme.palette.background.menuBackgroundColor,
    ).isDark()
      ? Color(theme.palette.background.menuBackgroundColor)
          .mix(Color("#fff"), 0.1)
          .hex()
      : Color(theme.palette.background.menuBackgroundColor)
          .mix(Color("#000"), 0.1)
          .hex(),
  },
  dialogContent: {
    [theme.breakpoints.up("sm")]: {
      maxHeight: "68vh",

    },
  },
}));

export default function DialogDefault({
  open,
  setOpen,
  dialogTitle,
  children,
  handleSubmit,
  disabled,
  isLoadingButton,
}) {
  const handleClose = () => {
    setOpen(false);
  };
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <div className={classes.SelectedOptionsDialogWrapper}>
        <DialogTitle
          style={{
            color: theme.palette.common.white,
            backgroundColor: theme.palette.success.dark,
            padding: theme.spacing(1),
            paddingLeft: "20px",
          }}
          id="alert-dialog-slide-title"
        >
          {dialogTitle}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {children}
        </DialogContent>
        <DialogActions style={{ paddingRight: "28px", margin: "4px 0px" }}>
          <Button
            disabled={isLoadingButton}
            onClick={handleClose}
            variant="contained"
            color="secondary"
          >
            <Typography>Cancel</Typography>
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="secondary"
            disabled={disabled || isLoadingButton}
            style={{ width: "92px", height: "38px" }}
          >
            <Typography>
              {isLoadingButton ? <CircularProgress size={24} /> : "Confirm"}
            </Typography>
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
}
