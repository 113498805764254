/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import {
  Grid,
  InputAdornment,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import { logError, logInfo } from "../../../../../services/logging";
import { currencySymbols } from "../../../../../utils/const";
import DeliveryUtils from "../../../../../utils/DeliveryUtils";
import { formatPrice } from "../../../../../utils/utils";
import AlertMessage from "../../../../common/AlertMessage";
import ButtonContained from "../../../../common/buttons/ButtonContained";
import PageTitle from "../../../../common/PageTitle";
import TextFieldDefault from "../../../../common/textFields/TextFieldDefault";
import GoogleMapDelivery from "../../../../maps/GoogleMapDelivery";
import {
  setDeliveryLocation,
  setSelectedDeliveryData,
} from "../../../storeHome/MyOrder/redux/actions";
import DeliverList from "./DeliveryList";
import { getAllLocationInfo } from "../../../../../services/customerService";
import ButtonDefault from "../../../../common/buttons/ButtonDefault";

const GOOGLE_MAPS_URL = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places&callback=initAutocomplete`;
const COMMA_SPACE_STR = ", ";
const componentForm = {
  street_number: "short_name",
  street_address: "short_name",
  route: "long_name",
  postal_town: "long_name",
  sublocality: "short_name",
  sublocality_level_1: "long_name",
  postal_code: "short_name",
  postal_code_prefix: "short_name",
};

// Initial State for Google Address and Delivery Address
const initStateGoogleAddress = {
  street_number: "",
  street_address: "",
  route: "",
  postal_town: "",
  sublocality: "",
  sublocality_level_1: "",
  postal_code: "",
  postal_code_prefix: "",
};

const initStateDeliveryAddress = {
  doorNo: "",
  streetAddressLine1: "",
  streetAddressLine2: "",
  city: "",
  postalCode: "",
  postalCodePrefix: "",
  postalCodeLatter: "",
  lat: 0,
  lng: 0,
  isAddressSelected: false,
  extraAddressInfo: "",
};

/**
 * DeliverView Component: This component is responsible for managing the delivery address form and options.
 * It allows users to select delivery options, enter their address details, and search for addresses using
 * Google Maps autocomplete. It also displays a list of valid delivery options available for the selected address.
 * The component uses Redux for state management and various Material-UI components for the UI.
 *
 * @param {Object} addressErrors - An object containing error flags for address fields.
 * @param {function} resetErrors - A function to reset the error flags for address fields.
 * @param {function} handleContinue - A callback function to handle the Continue button action.
 */
const DeliverView = ({ addressErrors, resetErrors, handleContinue }) => {
  const {
    deliveryLocation,
    filteredDeliveryOptionListByAvail,
    totalPriceItems,
    selectedDeliveryData,
  } = useSelector((state) => state.cart);

  const [isDisabled, setIsDisabled] = useState(true);
  const [alertMessage, setAlertMessage] = useState("");
  const [isDeliveryListPage, setIsDeliveryListPage] = useState(true);
  const [isSearchGoogleMap, setIsSearchGoogleMap] = useState(true);
  const [customerInfo, setCustomerInfo] = useState({});
  const [deliveryListNode, setDeliveryListNode] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isBackButtonDisable, setIsBackButtonDisable] = useState(true);
  const [deliveryAddress, setDeliveryAddress] = useState({
    ...deliveryLocation,
  });
  const [googleAddress, setGoogleAddress] = useState({
    ...deliveryLocation,
  });

  const dispatch = useDispatch();

  const { storeInfo: businessInfo } = useSelector((state) => state.store);

  /**
   * useEffect Hook: This hook is used to update the delivery location when the user
   * navigates away from the delivery list page to the address form page. It ensures
   * that the selected address details (from Google Maps or manually entered) are
   * correctly saved as the delivery location.
   */
  useEffect(() => {
    // Check if the user is on the delivery list page (i.e., not on the address form page).
    if (!isDeliveryListPage) {
      // Dispatch the 'setDeliveryLocation' action to update the delivery location
      dispatch(
        setDeliveryLocation({
          ...googleAddress, // Add the address details retrieved from Google Maps (if any).
          ...deliveryAddress, // Add the manually entered address details (if any).
        }),
      );
    }
  }, [deliveryAddress, isDeliveryListPage]);

  /**
   * useEffect Hook: This hook is used to update the delivery address in the local
   * state when the user navigates between the delivery list page and the address form page.
   * If the user is on the address form page and has selected an address from Google Maps,
   * the selected address details will be used. Otherwise, the previously entered manual
   * address details will be used.
   */
  useEffect(() => {
    // Check if the user is on the address form page (i.e., not on the delivery list page).
    if (!isDeliveryListPage) {
      // Check if the user has selected an address from Google Maps (isSearchGoogleMap is true).
      if (isSearchGoogleMap) {
        // Set the delivery address state with the address details from Google Maps and
        // the delivery location state, if available.
        setDeliveryAddress({
          ...deliveryAddress,
          doorNo: deliveryLocation.doorNo || googleAddress.street_number,
          streetAddressLine1:
            deliveryLocation.streetAddressLine1 ||
            _.compact([googleAddress.street_address, googleAddress.route]).join(
              COMMA_SPACE_STR,
            ),
          streetAddressLine2:
            deliveryLocation.streetAddressLine2 ||
            _.compact([
              googleAddress.sublocality,
              googleAddress.sublocality_level_1,
            ]).join(COMMA_SPACE_STR),
          city: deliveryLocation.city || googleAddress.postal_town,
          postalCode: deliveryLocation.postalCode || googleAddress.postal_code,
          extraAddressInfo: deliveryLocation.extraAddressInfo || "",
          postalCodePrefix:
            googleAddress.postal_code_prefix ||
            googleAddress?.postal_code?.split(" ")[0] ||
            "",
          // eslint-disable-next-line no-nested-ternary
          postalCodeLatter: deliveryLocation?.postalCode
            ? deliveryLocation?.postalCode.split(" ")[1]
            : googleAddress?.postal_code
            ? googleAddress?.postal_code?.split(" ")[1]
            : "",
        });
      } else {
        // If the user has not selected an address from Google Maps, set the delivery
        // address state with the previously manually entered address details.
        setDeliveryAddress({
          ...deliveryAddress,
          doorNo: deliveryAddress.doorNo,
          streetAddressLine1: deliveryAddress.streetAddressLine1,
          streetAddressLine2: deliveryAddress.streetAddressLine2,
          city: deliveryAddress.city,
          postalCode: deliveryAddress.postalCode,
          extraAddressInfo: deliveryAddress.extraAddressInfo,
          postalCodePrefix: deliveryAddress.postal_code_prefix,
          // eslint-disable-next-line no-nested-ternary
          postalCodeLatter: deliveryAddress.postalCodeLatter,
        });
      }
    }
  }, [googleAddress, isDeliveryListPage]);

  /**
   * This function is called when the user selects an address from Google Maps autocomplete.
   * It retrieves the place details from the autocomplete object and updates the delivery address
   * state with the selected address information. It also logs the delivery location selection
   * information.
   */
  const fillInAddress = () => {
    // Reset any previous errors related to the address form.
    resetErrors();
    // Get the place details from the Google Maps autocomplete object.
    const place = autocomplete.getPlace();

    // If the place details do not include geometry (latitude and longitude), return early.
    if (!place.geometry) return;

    // Extract latitude and longitude from the place details.
    const {
      geometry: {
        location: { lat, lng },
      },
    } = place;

    // Set the delivery address state with the selected address information.
    setDeliveryAddress({
      ...initStateDeliveryAddress, // Clear any previously selected address data.
      isAddressSelected: true, // Mark the address as selected.
      lat: lat(), // Set the latitude.
      lng: lng(), // Set the longitude.
    });

    let address = {};

    // Loop through the address components received from Google Maps.
    for (let i = 0; i < place.address_components.length; i++) {
      const addressType = place.address_components[i].types[0];

      // Check if the address type is present in the componentForm object (defined earlier).
      // If present, extract the value and store it in the address object.
      if (componentForm[addressType]) {
        const val = place.address_components[i][componentForm[addressType]];
        address = { ...address, [addressType]: val };
      }
    }

    // Set the Google address state with the extracted address components.
    setGoogleAddress(address);
    logInfo({
      message: "Delivery location selected on google maps",
      addressInfo: place.address_components,
      location: { lat: lat(), lng: lng() },
    });
  };

  /**
   * This useEffect hook is responsible for handling the selection of delivery data based on the user's address.
   * It runs whenever there are changes in deliveryLocation, googleAddress, filteredDeliveryOptionListByAvail, or isDeliveryListPage.
   * It checks the availability of delivery options and filters them based on distance and postal code criteria.
   * It also checks the minimum order price compatibility and selects the appropriate delivery option.
   * If no suitable delivery option is found, it displays an alert message indicating the unavailability of delivery.
   */
  useEffect(() => {
    const handleSelectDeliveryData = () => {
      // Check if the delivery data is loaded and available.
      const isLoaded = DeliveryUtils.isDeliveryDataLoaded(
        deliveryLocation,
        businessInfo,
      );

      // If not loaded, return early.
      if (!isLoaded) return;

      try {
        // check if distance options are available
        const storeLocation = { ...businessInfo };
        let filteredOptions = DeliveryUtils.filterDistanceDeliveryOptions(
          filteredDeliveryOptionListByAvail,
          deliveryLocation,
          storeLocation,
        );

        // if no distance option matching, then check the postcode options
        if (_.isEmpty(filteredOptions)) {
          // Check if the postal code prefix is empty in the user's address.
          const isPrefixEmpty =
            DeliveryUtils.isPostCodePrefixEmpty(googleAddress);
          if (isPrefixEmpty) {
            // If the prefix is empty, clear the selected delivery data and disable the form.
            dispatch(setSelectedDeliveryData({}));
            setIsDisabled(true);
            logInfo({
              message:
                "No postal code or postal prefix received for searched delivery location",
              googleAddress,
            });
            return setAlertMessage(
              "Please type the full address or the postcode.",
            );
          }

          // filter postcode delivery options by given criteria
          const postcodeDeliveryNodeList =
            DeliveryUtils.filterPostCodeDeliveryOptions(
              filteredDeliveryOptionListByAvail,
              googleAddress,
              deliveryLocation,
              storeLocation,
            );

          if (_.isEmpty(postcodeDeliveryNodeList)) {
            // If no delivery options are available for the postcode, clear the selected delivery data and disable the form.
            dispatch(setSelectedDeliveryData({}));
            setIsDisabled(true);
            logInfo({
              message:
                "No delivery options received with filterDeliveryOptionsByDistance",
              filteredDeliveryOptionListByAvail,
              deliveryLocation,
              storeLocation,
            });
            return setAlertMessage(
              "Sorry! No delivery option available for your area.",
            );
          }
          filteredOptions = postcodeDeliveryNodeList;
        }

        // If filteredOptions is not empty, enable the form.
        if (!_.isEmpty(filteredOptions)) setIsDisabled(false);
        // check min order price compatibility
        const minOrderPriceRequired = _.min(
          filteredOptions.map((option) => parseFloat(option.minOrderPrice)),
        );
        if (totalPriceItems < minOrderPriceRequired) {
          // If the total price is less than the minimum required, display an alert message.
          logInfo({
            message: "Min order price match for delivery option fails",
            minOrderPriceRequired,
            filteredOptions,
          });
          return setAlertMessage(
            `Minimum order total should not  be less than ${currencySymbols.GBP}${minOrderPriceRequired}`,
          );
        }

        // Select the delivery option with the maximum minOrderPrice that is less than or equal to totalPriceItems.
        const selectedOrder = _.maxBy(
          filteredOptions.filter(
            (option) => parseFloat(option.minOrderPrice) <= totalPriceItems,
          ),
          (option) => parseFloat(option.minOrderPrice),
        );
        if (!_.isObject(selectedOrder)) {
          logError({
            message:
              "CRITICAL | ERROR: 100020 | got empty value on getting max value for delivery options",
          });
        }

        // Dispatch the selected delivery data.
        dispatch(setSelectedDeliveryData(selectedOrder));

        // If it's not a Google Maps search, proceed to handleContinue with the customerInfo.
        if (!isSearchGoogleMap) {
          handleContinue(customerInfo);
        }
      } catch (error) {
        logError({
          message:
            "CRITICAL | ERROR: 100021 | Exception on delivery option selection",
          error,
        });
        return setAlertMessage(
          "Unexpected error: CODE: 000021 Please contact support",
        );
      }
    };

    if (!isDeliveryListPage) {
      // If not in the delivery list page, execute the handleSelectDeliveryData function.
      handleSelectDeliveryData();
    }
  }, [
    deliveryLocation,
    googleAddress,
    filteredDeliveryOptionListByAvail,
    isDeliveryListPage,
  ]);

  let autocomplete;

  // const geolocate = () => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition((position) => {
  //       const geolocation = {
  //         lat: position.coords.latitude,
  //         lng: position.coords.longitude,
  //       };
  //       const circle = new window.google.maps.Circle({
  //         center: geolocation,
  //         radius: position.coords.accuracy,
  //       });
  //       try {
  //         autocomplete.setBounds(circle.getBounds());
  //       } catch (error) {
  //         console.error("setBound issue: minor");
  //       }
  //     });
  //   }
  // };

  /**
   * This useEffect hook is responsible for setting up the Google Maps Autocomplete functionality
   * and initializing the Autocomplete object when the component mounts.
   * It runs whenever there are changes in `deliveryLocation.isAddressSelected` or `isDeliveryListPage`.
   * If the `isDeliveryListPage` is true or the `deliveryLocation.isAddressSelected` is true, the effect does nothing.
   * Otherwise, it initializes the Google Maps Autocomplete and attaches a listener to handle address selection.
   * The Autocomplete is restricted to show results only in the United Kingdom (UK).
   */
  useEffect(() => {
    // If the component is not in the delivery list page.
    if (!isDeliveryListPage) {
      // Check if the delivery location's address is already selected, if yes, then return early.
      if (deliveryLocation.isAddressSelected) return;

      // Function to initialize the Google Maps Autocomplete.
      window.initAutocomplete = function () {
        autocomplete = new window.google.maps.places.Autocomplete(
          document.getElementById("autocomplete"),
          { types: ["geocode"] }, // Restrict the Autocomplete to show only geocode results.
        );

        // Set the fields to be retrieved from the Autocomplete response.
        autocomplete.setFields(["address_component", "geometry"]);
        // Attach a listener to the Autocomplete to handle address selection.
        autocomplete.addListener("place_changed", fillInAddress);
        // Set component restrictions to show results only in the United Kingdom (UK).
        autocomplete.setComponentRestrictions({
          country: ["uk"],
        });
      };

      // Create a new script element and set its source to the Google Maps API URL.
      const script = document.createElement("script");
      script.src = GOOGLE_MAPS_URL;
      script.defer = true;

      // Append the script element to the document body, which triggers the loading of the Google Maps API.
      document.body.appendChild(script);
    }
  }, [deliveryLocation.isAddressSelected, isDeliveryListPage]);

  const {
    doorNo,
    streetAddressLine1,
    streetAddressLine2,
    city,
    // postalCode,
    extraAddressInfo,
    postalCodePrefix,
    postalCodeLatter,
  } = deliveryAddress;

  /**
   * This function handles the changes in the postal code input field.
   */
  const handleInputChangePostCode = (e) => {
    const { value } = e.target;
    setDeliveryAddress((prevState) => ({
      ...prevState,
      postalCodeLatter: value.toUpperCase(),
      postalCode: `${postalCodePrefix} ${value.toUpperCase()}`,
    }));
  };

  /**
   * This function is responsible for handling changes in the input field for the postal code's latter part.
   * It takes the event object `e` as an argument, which contains the new value of the input field.
   * The function updates the state of the `deliveryAddress` object by setting the `postalCodeLatter` property
   * to the uppercase of the new value and concatenates it with the `postalCodePrefix` to form the complete postal code.
   * The updated state is achieved using the `setDeliveryAddress` function from the `useState` hook.
   *
   * @param {Object} e - The event object representing the input change event.
   */
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    // Update the state of `deliveryAddress`.
    setDeliveryAddress((prevState) => ({ ...prevState, [id]: value }));
  };

  /**
   * This function is responsible for resetting the delivery address fields and related states.
   * It sets the `isSearchGoogleMap` state to `true` to enable Google Maps search.
   * It sets the `isDisabled` state to `true` to disable the address input fields.
   * It dispatches actions to Redux to reset the selected delivery data and delivery location.
   * It updates the `deliveryAddress` state with the initial state values for delivery address.
   * It also updates the `googleAddress` state with the initial state values for Google address.
   */
  const resetAddress = () => {
    // Enable Google Maps search
    setIsSearchGoogleMap(true);
    // Disable the address input fields
    setIsDisabled(true);
    // Reset selected delivery data using Redux action
    dispatch(setSelectedDeliveryData({}));
    // Reset delivery location using Redux action
    dispatch(
      setDeliveryLocation({
        ...initStateDeliveryAddress,
        initStateGoogleAddress,
        isAddressSelected: false,
      }),
    );
    // Reset the `deliveryAddress` state with the initial state values
    setDeliveryAddress({ ...initStateDeliveryAddress });
    // Reset the `googleAddress` state with the initial state values
    setGoogleAddress({ ...initStateGoogleAddress });
  };

  /**
   * This function is responsible for handling the opening of the delivery view.
   * It takes a `data` object as a parameter containing customer details and delivery address data.
   * It sets the `customerInfo` state with the customer details to pre-fill the customer information.
   * It sets the `deliveryAddress` state with the delivery address data received from the `data` object.
   * It sets the `googleAddress` state with the relevant postal code prefix and postal town from the delivery address data.
   * It sets `isSearchGoogleMap` to `false` to prevent further searching on Google Maps.
   * It sets `isDeliveryListPage` to `false` to indicate that it is not on the delivery list page anymore.
   * @param {object} data - An object containing customer details and delivery address data.
   */
  const handleOpenDeliveryView = (data) => {
    // Prepare customer details object to pre-fill customer information
    const customerDetails = {
      isNeedAutoSaveCustomer: true,
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNumber: data.contactNo,
      email: data.email,
      marketing: data?.marketing,
    };

    // Set the customer information using `setCustomerInfo` state setter
    setCustomerInfo(customerDetails);

    // Prepare delivery address data object to set delivery address fields
    const deliveryAddressData = {
      city: data.city,
      doorNo: data.doorNo,
      extraAddressInfo: data.extraAddressInfo,
      isAddressSelected: true,
      lat: data.lat,
      lng: data.lon,
      postalCode: data.postcode,
      postalCodeLatter: data.postcode.split(" ")[1],
      postalCodePrefix: data.postcode.split(" ")[0],
      postal_code_prefix: data.postcode.split(" ")[0],
      postal_town: data.city,
      streetAddressLine1: data.addressLine1,
      streetAddressLine2: data.addressLine2,
    };

    // Set the delivery address using `setDeliveryAddress` state setter
    setDeliveryAddress(deliveryAddressData);

    // Extract the postal_code_prefix and postal_town from the deliveryAddressData for Google address
    const { postal_code_prefix, postal_town } = deliveryAddressData;

    // Set the Google address using `setGoogleAddress` state setter
    setGoogleAddress({ postal_code_prefix, postal_town });
    // Set `isSearchGoogleMap` to `false` to prevent further searching on Google Maps
    setIsSearchGoogleMap(false);
    // Set `isDeliveryListPage` to `false` to indicate that it is not on the delivery list page anymore
    setIsDeliveryListPage(false);
  };

  /**
   * This function filters the deliveryOptionList to get only valid delivery options.
   * It checks for specific conditions to determine if a delivery option is valid or not.
   * If valid options are found, it sets them in the `deliveryListNode` state.
   * If no valid options are found, it sets `isDisabled` and `isBackButtonDisable` states to `true`.
   * @param {array} deliveryOptionList - An array containing delivery option data.
   */
  const getValidDeliveryOption = (deliveryOptionList) => {
    // Filter the deliveryOptionList to get only valid delivery options
    const filterDeliveryOptionList = deliveryOptionList.filter(
      (data) =>
        !_.isEmpty(data.lat) &&
        !_.isEmpty(data.lon) &&
        !_.isEmpty(data.doorNo) &&
        !_.isEmpty(data.addressLine1) &&
        data.postcode.split(" ").length === 2 &&
        !_.isEmpty(data.postcode.split(" ")[0]) &&
        !_.isEmpty(data.postcode.split(" ")[1]),
    );
    if (_.isEmpty(filterDeliveryOptionList)) {
      // If no valid delivery options found, disable the necessary buttons
      setIsDisabled(true);
      setIsBackButtonDisable(true);
    } else {
      // If valid delivery options found, enable the back button and set the filtered options
      setIsBackButtonDisable(false);
      setDeliveryListNode(filterDeliveryOptionList);
    }
    // Set isLoading to false to indicate that the operation is complete
    setIsLoading(false);
  };

  /**
   * This function is responsible for fetching location information using the `getAllLocationInfo` API.
   * It then calls the `getValidDeliveryOption` function to filter and set valid delivery options.
   * If there is an error during the API call, it handles the error by disabling buttons and setting relevant states.
   */
  const handleGetLocationInfo = async () => {
    try {
      // Fetch all location information using the getAllLocationInfo API
      const res = await getAllLocationInfo();

      // Call the getValidDeliveryOption function to filter and set valid delivery options
      getValidDeliveryOption(res.data.data);
    } catch (error) {
      // If there is an error during the API call, handle the error
      // Disable necessary buttons and set relevant states
      setIsDisabled(true);
      setIsBackButtonDisable(true);
      setIsDeliveryListPage(false);
      setIsLoading(false);
    }
  };

  /**
   * This useEffect hook is responsible for handling initial setup related to delivery location.
   * If a delivery location is selected, it sets `isDeliveryListPage` to `false`.
   * Otherwise, it sets `isDeliveryListPage` to `true`.
   * It enables buttons and calls the `handleGetLocationInfo` function to fetch and process location information.
   */
  useEffect(() => {
    if (deliveryLocation.isAddressSelected) {
      // If a delivery location is selected, set `isDeliveryListPage` to `false`
      setIsDeliveryListPage(false);
    } else {
      // If no delivery location is selected, set `isDeliveryListPage` to `true`
      setIsDeliveryListPage(true);
    }

    // Enable buttons
    setIsDisabled(false);

    // Call the `handleGetLocationInfo` function to fetch and process location information
    handleGetLocationInfo();
  }, []);

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <>
      <AlertMessage
        message={alertMessage}
        setMessage={setAlertMessage}
        severity="error"
      />
      {isDeliveryListPage && !deliveryLocation.isAddressSelected ? (
        <DeliverList
          setIsDeliveryListPage={setIsDeliveryListPage}
          handleOpenDeliveryView={handleOpenDeliveryView}
          deliveryListNode={deliveryListNode}
          setIsDisabled={setIsDisabled}
          isLoading={isLoading}
        />
      ) : (
        <>
          <div
            style={
              matches
                ? {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }
                : {
                    display: "block",
                    marginBottom: "20px",
                  }
            }
          >
            <PageTitle title="Start by adding your address" />
            {!isBackButtonDisable && (
              <ButtonDefault
                bgColor={theme.palette.primary.main}
                textColor={theme.palette.text.primaryTextColor}
                style={
                  matches
                    ? {
                        width: "200px",
                        textTransform: "none",

                        display: "flex",
                        alignItems: "center",
                      }
                    : {
                        width: "100%",
                        textTransform: "none",

                        display: "flex",
                        alignItems: "center",
                      }
                }
                onClick={() => {
                  resetAddress();
                  setIsDeliveryListPage(true);
                }}
                fullWidth
              >
                <Typography>
                  <ArrowBackIcon
                    style={{
                      fontWeight: "bold",
                      fontSize: "20px",
                      marginRight: "8px",
                      marginTop: "7px",
                    }}
                  />
                </Typography>
                <Typography style={{ fontWeight: "bold", fontSize: "18px" }}>
                  Address Book
                </Typography>
              </ButtonDefault>
            )}
          </div>
          {!_.isEmpty(selectedDeliveryData) && (
            <MuiAlert severity="success" elevation={6} variant="filled">
              Nice! We can deliver to your area in{" "}
              {selectedDeliveryData.delayMins} minutes
              (&nbsp;Delivery&nbsp;charge&nbsp;is&nbsp;
              {formatPrice(selectedDeliveryData.charge)}
              &nbsp;)
            </MuiAlert>
          )}
          {!deliveryLocation.isAddressSelected && (
            <TextFieldDefault
              id="autocomplete"
              // onFocus={geolocate}
              label="Postcode or the first line of your address"
              type="text"
              variant="filled"
              fullWidth
              autoComplete="off"
            />
          )}
          {deliveryLocation.isAddressSelected && (
            <>
              <div style={{ margin: 5 }} />
              <ButtonContained onClick={resetAddress} fullWidth>
                Search new address
              </ButtonContained>
              <div style={{ margin: 5 }} />
            </>
          )}

          {!isDisabled && (
            <Grid container spacing={2} id="address" style={{ padding: 16 }}>
              <Grid item sm={6} xs={12}>
                <TextFieldDefault
                  id="doorNo"
                  variant="filled"
                  label="Building/House number"
                  type="text"
                  value={doorNo}
                  onChange={handleInputChange}
                  fullWidth
                  inputProps={{ maxLength: 10 }}
                  error={addressErrors.doorNo}
                  helperText={
                    addressErrors.doorNo && "Enter a valid building/housing no"
                  }
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextFieldDefault
                  id="streetAddressLine1"
                  variant="filled"
                  label="Address line 1"
                  type="text"
                  value={streetAddressLine1}
                  onChange={handleInputChange}
                  fullWidth
                  inputProps={{ maxLength: 100 }}
                  error={addressErrors.streetAddressLine1}
                  helperText={
                    addressErrors.streetAddressLine1 && "Enter address line 1"
                  }
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextFieldDefault
                  id="streetAddressLine2"
                  variant="filled"
                  label="Address line 2"
                  type="text"
                  onChange={handleInputChange}
                  value={streetAddressLine2}
                  fullWidth
                  inputProps={{ maxLength: 100 }}
                />
              </Grid>

              <br />
              <Grid item sm={6} xs={12}>
                {/* <TextFieldDefault
                  id="administrative_area_level_1"
                  variant="filled"
                  label="State"
                  type="text"
                  value={administrative_area_level_1}
                  disabled={isDisabled}
                  fullWidth
                /> */}
                <TextFieldDefault
                  id="city"
                  variant="filled"
                  label="City"
                  type="text"
                  value={city}
                  onChange={handleInputChange}
                  fullWidth
                  inputProps={{ maxLength: 30 }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                {/* {!postal_code_prefix && (
                  <TextFieldDefault
                    id="postalCode"
                    variant="filled"
                    label="Postal code"
                    type="text"
                    value={postalCode}
                    onChange={handleInputChange}
                    fullWidth
                    inputProps={{ maxlength: 3 }}
                  />
                )} */}
                <TextFieldDefault
                  id="postalCodeLatter"
                  variant="filled"
                  label="Postal code"
                  type="text"
                  value={postalCodeLatter}
                  onChange={handleInputChangePostCode}
                  fullWidth
                  inputProps={{ maxLength: 5 }}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {postalCodePrefix}
                      </InputAdornment>
                    ),
                  }}
                  error={addressErrors.postalCodeLatter}
                  helperText={
                    addressErrors.postalCodeLatter &&
                    "Please enter the complete postcode"
                  }
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <TextFieldDefault
                  multiline
                  rows={2}
                  id="extraAddressInfo"
                  variant="filled"
                  label="Delivery instructions"
                  type="text"
                  value={extraAddressInfo}
                  onChange={handleInputChange}
                  fullWidth
                  inputProps={{ maxlength: 300 }}
                />
              </Grid>
              <br />
              {/* <Grid item sm={12} xs={12}>
                <TextFieldDefault
                  id="country"
                  variant="filled"
                  label="Country"
                  type="text"
                  value={country}
                  disabled={isDisabled}
                  fullWidth
                />
              </Grid> */}
            </Grid>
          )}
          <div style={{ marginTop: 8 }}>
            <GoogleMapDelivery />
          </div>
        </>
      )}
    </>
  );
};

DeliverView.propTypes = {
  // bla: PropTypes.string,
};

DeliverView.defaultProps = {
  // bla: 'test',
};

export default DeliverView;
