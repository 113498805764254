import _ from "lodash";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";

const useQueryParam = () => {
  const { search, pathname } = useLocation();
  const { push } = useHistory();

  const addParam = (key, value) => {
    let queryParams = queryString.parse(search, {
      parseBooleans: true,
      parseNumbers: true,
    });
    queryParams = { ...queryParams, [key]: value };
    return push(`${pathname}?${queryString.stringify(queryParams)}`);
  };

  const addParams = (params) => {
    try {
      let queryParams = queryString.parse(search, {
        parseBooleans: true,
        parseNumbers: true,
      });
      queryParams = { ...queryParams, ...params };
      return push(`${pathname}?${queryString.stringify(queryParams)}`);
    } catch (error) {
      console.error("Error attach query params to url: ", error);
    }
  };

  const getParam = (key) => {
    const queryParams = queryString.parse(search, {
      parseBooleans: true,
      parseNumbers: true,
    });
    const param = queryParams[key];
    return param || null;
  };

  const getParams = (paramKeys) => {
    try {
      const queryParams = queryString.parse(search, {
        parseBooleans: true,
        parseNumbers: true,
      });
      const params = {};
      paramKeys.forEach((key) => {
        if (queryParams[key]) {
          params[key] = queryParams[key];
        }
      });
      return params;
    } catch (error) {
      console.error("Error getting query params from url: ", error);
      return {};
    }
  };

  const removeParam = (key) => {
    let queryParams = queryString.parse(search, {
      parseBooleans: true,
      parseNumbers: true,
    });
    queryParams = _.omit(queryParams, [key]);
    if (_.isEmpty(queryParams)) {
      return push(`${pathname}`);
    }
    return push(`${pathname}?${queryString.stringify(queryParams)}`);
  };

  const removeParams = (keys) => {
    try {
      let queryParams = queryString.parse(search, {
        parseBooleans: true,
        parseNumbers: true,
      });
      queryParams = _.omit(queryParams, [...keys]);
      if (_.isEmpty(queryParams)) {
        return push(`${pathname}`);
      }
      return push(`${pathname}?${queryString.stringify(queryParams)}`);
    } catch (error) {
      console.error("Error removing query params from url: ", error);
    }
  };

  const updateParams = (params) => {
    try {
      let queryParams = queryString.parse(search);
      queryParams = { ...queryParams, ...params };
      queryParams = _.omitBy(queryParams, _.isEmpty);
      return push(`${pathname}?${queryString.stringify(queryParams)}`);
    } catch (error) {
      console.error("Error attach query params to url: ", error);
    }
  };

  return {
    addParam,
    addParams,
    getParam,
    getParams,
    removeParam,
    removeParams,
    updateParams,
  };
};

export default useQueryParam;
