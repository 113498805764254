import React from "react";

import { Box, Typography } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import Color from "color";

import { formatPrice } from "../../../../utils/utils";

const useStyle = makeStyles((theme) => ({
  CartItemCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: "4px",
    minHeight: 48,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: Color(
      theme.palette.background.menuBackgroundColor,
    ).isDark()
      ? Color(theme.palette.background.menuBackgroundColor)
          .mix(Color("#fff"), 0.1)
          .hex()
      : Color(theme.palette.background.menuBackgroundColor)
          .mix(Color("#000"), 0.1)
          .hex(),
    padding: theme.spacing(1),
  },
  title: {
    display: "flex",
  },
  subItems: {
    display: "flex",
  },
}));

/**
 * This component, CartItemCard, represents a card displaying information about a cart item,
 * including the item name, quantity, price, and any sub-items it may have.
 */
const CartItemCard = ({ cartItemData, idx }) => {
  const { name, quantity, price, subItems } = cartItemData;

  /**
   * Calculates the total price of the cart item, including the price of any sub-items if present.
   */
  const calTotalPrice = () => {
    const subTotalPrice = !_.isEmpty(subItems)
      ? _.sum(subItems.map((i) => parseFloat(i.price)))
      : 0;
    return parseFloat(price) + subTotalPrice;
  };

  const classes = useStyle();

  return (
    <Box id={`cartItemCard-${idx}`} className={classes.CartItemCard}>
      <div className={classes.title}>
        <Typography
          variant="body2"
          component="span"
          style={{ flex: 1, display: "flex", alignItems: "center" }}
        >
          {quantity}
          &nbsp;
          <Icon fontSize="inherit">close</Icon>
        </Typography>
        <Typography
          variant="body2"
          component="span"
          style={{ flex: 5, fontWeight: "bold" }}
        >
          {name}
        </Typography>
        <Typography
          variant="body2"
          component="span"
          style={{ flex: 2, textAlign: "end" }}
        >
          {formatPrice(calTotalPrice() * quantity)}
        </Typography>
      </div>

      <div className={classes.subItems}>
        <span style={{ flex: 1 }} />
        <span style={{ flex: 7 }}>
          {!_.isEmpty(subItems) && (
            <>
              {subItems.map((sub, i) => (
                <Typography key={String(i)} variant="body2" component="div">
                  {sub.name}
                </Typography>
              ))}
            </>
          )}
        </span>
      </div>
    </Box>
  );
};

export default CartItemCard;
