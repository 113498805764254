import { SET_CATEGORY_LIST, SET_CATEGORY_SELECTED } from "./actions";

export const CategoryInitialState = {
  catMenuItemList: [],
  categoryList: [],
  categorySelected: {},
  isFetching: false,
  error: null,
};

export const CategoryReducer = (state = CategoryInitialState, action) => {
  let { categoryList, categorySelected } = state;

  switch (action.type) {
    case SET_CATEGORY_LIST:
      categoryList = action.payload;
      break;
    case SET_CATEGORY_SELECTED:
      categorySelected = action.payload;
      break;
    default:
      return state;
  }

  // Return the state object
  return {
    ...CategoryInitialState,
    categoryList,
    categorySelected,
  };
};
