import { isMobileView, isEmbeddedWindow } from "../utils/utils";

export default function getThemeConfigOuter(
  defaultTextColor,
  primaryTextColor,
  actionTextColor,
  titleTextColor,
  subtitleTextColor,
  itemTextColor,
  itemDescColor,
  menuTextColor,
  accentTextColor,
  secondaryTextColor,

  primaryColor,
  secondaryColor,

  menuBackgroundColor,
  categoryBackgroundColor,
  accentColor,

  boarderColor,

  isDark,

  viewLayoutType,

  borderRadius,
) {
  return {
    props: {
      // Name of the component ⚛️
      MuiIconButton: {
        // The default props to change
        padding: 0,
      },
    },
    overrides: {
      MuiButton: {
        textPrimary: {
          color: primaryTextColor,
        },
      },
      MuiCheckbox: {
        colorPrimary: {
          color: subtitleTextColor,
          "&$checked": {
            color: subtitleTextColor,
          },
        },
      },
      MuiRadio: {
        colorPrimary: {
          color: subtitleTextColor,
          "&$checked": {
            color: subtitleTextColor,
          },
        },
      },
      MuiIconButton: {
        root: {
          padding: 2,
        },
        colorPrimary: {
          color: subtitleTextColor,
          "&$checked": {
            color: subtitleTextColor,
          },
        },
      },
      // date time picker - start
      // MuiPickersClockNumber: {
      //   clockNumberSelected: {
      //     color: Color(primaryColor).negate().toString(),
      //   },
      // },
      // MuiPickerDTTabs: {
      //   tabs: {
      //     color: BLACK_PURE,
      //     backgroundColor: WHITE_PURE,
      //   },
      // },
      MuiPickersToolbar: {
        // root: {
        //   color: WHITE_PURE,
        //   backgroundColor: WHITE_PURE,
        // },
        toolbar: {
          borderRadius: 8,
          // color: Color(primaryTextColor).toString(),
        },
      },

      // MuiPickersDay: {
      //   daySelected: {
      //     backgroundColor: Color(primaryColor).isLight()
      //       ? Color(primaryColor).darken(0.2).toString()
      //       : Color(primaryColor).alpha(0.2).toString(),
      //     "&:hover": {
      //       backgroundColor: Color(primaryColor).isLight()
      //         ? Color(primaryColor).darken(0.1).toString()
      //         : Color(primaryColor).alpha(0.1).toString(),
      //     },
      //   },
      //   current: {
      //     color: primaryTextColor,
      //   },
      // },
      // date time picker -- end

      // Style sheet name ⚛️
      MuiInputLabel: {
        animated: {
          fontSize: 18,
        },
      },
      MuiDialog: {
        paperScrollPaper: {
          borderRadius,
        },
      },
      MuiInputBase: {
        input: {
          "&:-webkit-autofill": {
            transitionDelay: "9999s",
            transitionProperty: "background-color, color",
          },
        },
      },
      PrivateSwitchBase: {
        root: {
          padding: 4,
        },
      },
      MuiFormLabel: {
        root: {
          color: defaultTextColor,
          "&$focused": {
            color: defaultTextColor,
          },
        },
      },
      MuiOutlinedInput: {
        root: {
          position: "relative",
          color: defaultTextColor,
          "& $notchedOutline": {
            borderColor: defaultTextColor,
          },
          "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
            borderColor: defaultTextColor,
            // Reset on touch devices, it doesn't add specificity
            "@media (hover: none)": {
              borderColor: defaultTextColor,
            },
          },
          "&$focused $notchedOutline": {
            borderColor: defaultTextColor,
            borderWidth: 1,
          },
        },
      },
      MuiFilledInput: {
        root: {
          // color: defaultTextColor,
        },
        underline: {
          borderRadius,
          borderBottom: "none",
          "&:hover": {
            borderBottom: "none",
          },
          "&:before": {
            borderBottom: "none",
          },
          "&:after": {
            borderBottom: "none",
          },
          "&:hover:before": {
            borderBottom: "none",
          },
        },
      },
    },
    palette: {
      eatpresto: {
        red: "#EB3C33",
      },
      primary: {
        main: primaryColor,
        contrastText: primaryTextColor,
      },
      secondary: {
        main: secondaryColor,
        contrastText: secondaryTextColor,
      },
      background: {
        menuBackgroundColor,
        categoryBackgroundColor,
        boarderColor,
        accentColor,
      },
      scrollbarStyles: {
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
          width: 8,
        },
        "&::-webkit-scrollbar-track": {
          boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "rgba(0,0,0,.2)",
          borderRadius: borderRadius ? 7 : 0,
        },
      },
      text: {
        defaultTextColor,
        actionTextColor,
        titleTextColor,
        subtitleTextColor,
        itemTextColor,
        itemDescColor,
        menuTextColor,
        accentTextColor,
        primaryTextColor,
        secondaryTextColor,
      },
      type: isDark ? "dark" : "light",
      contrastThreshold: 3,
      tonalOffset: 0.2,
    },
    typography: {
      fontFamily: "'PT Sans', sans-serif;",
      fontSize: 15,
      lineHeight: 18,
      letterSpacing: 0,
    },
    view: {
      viewLayoutType,
      isFixedList: viewLayoutType === "fixed_list" && isEmbeddedWindow(),
      isMobile: isMobileView(),
    },
    shape: {
      borderRadius,
      stickyButtonHeight: 56,
    },
  };
}
