/* eslint-disable no-shadow */
import { setLocalStore } from "../utils/storage/localStorage";
import axiosClient from "./client";
const queryString = require("query-string");

export function getAllOrder(locationId, size,currentPage) {
  return axiosClient({
    method: "GET",
    url: queryString.stringifyUrl({
      url: `${process.env.REACT_APP_API_ENDPOINT_EATPRESTO}/public-auth/location/${locationId}/order/get-all`,
      query: { size: size, page: currentPage, sort: ["dueTime","DESC"] }}, {sort: false, arrayFormat: 'comma', skipNull: true, skipEmptyString: true},
    ),
  });
}

export function crateOrder(locationId, data) {
  return axiosClient({
    url: `${process.env.REACT_APP_API_ENDPOINT_EATPRESTO}/public-auth/location/${locationId}/order`,
    method: "POST",
    data,
  });
}

export function confirmOrder(locationId, orderId, data) {
  return axiosClient({
    url: `${process.env.REACT_APP_API_ENDPOINT_EATPRESTO}/public-auth/location/${locationId}/order/${orderId}/confirm`,
    method: "POST",
    data,
  });
}

export function checkOrder(locationId, orderId) {
  return axiosClient({
    url: `${process.env.REACT_APP_API_ENDPOINT_EATPRESTO}/public-auth/location/${locationId}/order/${orderId}`,
    method: "GET",
  });
}

export function checkOrderPublic(locationId, orderId) {
  return axiosClient({
    url: `${process.env.REACT_APP_API_ENDPOINT_EATPRESTO}/public/location/${locationId}/order/${orderId}`,
    method: "GET",
  });
}

export function completeOrder(locationId, orderId, failError) {
  const performFetch = () => axiosClient({
    url: `${process.env.REACT_APP_API_ENDPOINT_EATPRESTO}/public-auth/location/${locationId}/order/${orderId}/complete`,
    method: "POST",
    data: {},
  });

  return new Promise((resolve, reject) => {
    const fetchPromise = performFetch();

    fetchPromise
      .then((res) => {
        resolve(res);
      })
      .catch(() => {
        const timer1 = setTimeout(() => {
          const fetchPromise = performFetch();
          fetchPromise
            .then((res) => {
              resolve(res);
            })
            .catch(() => {
              const timer2 = setTimeout(() => {
                const fetchPromise = performFetch();
                fetchPromise
                  .then((res) => {
                    resolve(res);
                  })
                  .catch(() => {
                    const checkPromise = checkOrder(locationId, orderId);
                    checkPromise
                      .then((res) => {
                        const { status } = res.data.data;
                        if (
                          status === "new"
                          || status === "pos"
                          || status === "accepted"
                        ) {
                          resolve(res);
                        } else {
                          // eslint-disable-next-line prefer-promise-reject-errors
                          reject({
                            response: {
                              data: {
                                message: failError,
                              },
                            },
                          });
                        }
                      })
                      .catch(() => {
                        // eslint-disable-next-line prefer-promise-reject-errors
                        reject({
                          response: {
                            data: {
                              message: failError,
                            },
                          },
                        });
                      });
                  });
                clearTimeout(timer2);
              }, 1000);
            });
          clearTimeout(timer1);
        }, 1000);
      });
  });
}

export async function recreateOrderSession(locationId) {
  try {
    const res = await crateOrder(locationId, {});
    const { version, id } = res.data.data;
    setLocalStore("sessionVersion", version);
    setLocalStore("orderId", id);
    return { version, id };
  } catch (error) {
    return 0;
  }
}

export async function updateOrderSession(locationId, orderId) {
  try {
    const res = await checkOrder(locationId, orderId);
    const { status, version, id } = res.data.data;
    if (status === "started" || status === "checkout") {
      return { version, id };
    }
    const newSessionRes = recreateOrderSession(locationId);
    const { version: newVersion, id: newId } = newSessionRes.data.data;
    setLocalStore("sessionVersion", version);
    setLocalStore("orderId", id);
    return { version: newVersion, id: newId };
  } catch (error) {
    return recreateOrderSession(locationId);
  }
}
