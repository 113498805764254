import React, { useEffect } from "react";

import { CircularProgress, Grid, Typography } from "@material-ui/core";
import firebase from "../../../../../utils/firebase";
import { isFunction } from "lodash";
import { setLocalStore } from "../../../../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import {
  loginUserSuccess,
  setIsOpenLoginModal,
} from "../../../../../redux/actions/auth";
import { customerUpdateNew } from "../../../../../services/customerService";
import { fetchRewardDetailsListAsync, fetchRewardDetailsListSuccess } from "../../CatMenu/redux/actions";
import { useRouteMatch } from "react-router-dom";

const EmailVerificationLoadingModal = ({
  isOpenLoginModal,
  setIsOpenEmailVerificationLoadingModal,
  customerDetails,
}) => {
  const dispatch = useDispatch();
  const { params } = useRouteMatch();
  const { isActiveLoyalty } = useSelector((state) => state.loyalty);
  const handleCloseModal = async (user, idToken) => {
    const response = await customerUpdateNew(
      idToken,
      customerDetails.firstName,
      customerDetails.lastName,
    );
    setLocalStore("customerId", response.data.data.id);
    setLocalStore("shortId", response.data.data.shortId);
    setLocalStore("idToken", idToken);
    if (isActiveLoyalty?.isActive) {
      dispatch(fetchRewardDetailsListAsync(params.locationId));
    } else {
      dispatch(fetchRewardDetailsListSuccess({locationId: params.locationId, points: []}));
    }
    dispatch(
      loginUserSuccess(
        `${customerDetails.firstName} ${customerDetails.lastName}`,
        customerDetails.email,
      ),
    );
    await user.updateProfile({
      displayName: `${customerDetails.firstName} ${customerDetails.lastName}`,
    });
    if (window.authCallback && isFunction(window.authCallback)) {
      window.authCallback();
      window.authCallback = null;
    }
    setIsOpenEmailVerificationLoadingModal(false); // Close modal if email is verified
    dispatch(setIsOpenLoginModal(false));
  };

  const checkEmailVerification = () => {
    const user = firebase.auth().currentUser;
    if (user) {
      user.reload().then(async () => {
        if (user.emailVerified) {
          const idToken = await user.getIdToken(true);
          handleCloseModal(user, idToken);
        }
      });
    }
  };

  useEffect(() => {
    if (isOpenLoginModal) {
      // Set an interval to check the email verification status every 5 seconds
      const intervalId = setInterval(() => {
        checkEmailVerification();
      }, 3000);

      // Clean up the interval when the component is unmounted or modal closes
      return () => clearInterval(intervalId);
    }
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
        <Typography variant="h5">Verify your email address.</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "32px",
          marginBottom: "32px",
        }}
      >
        <CircularProgress size={60} style={{ color: "white" }} />
      </Grid>
    </Grid>
  );
};

EmailVerificationLoadingModal.propTypes = {};
EmailVerificationLoadingModal.defaultProps = {};

export default EmailVerificationLoadingModal;
