import React from "react";

import isEmpty from "lodash/isEmpty";

import {
  DELIVERY_TYPE_TAKEOUT,
  DELIVERY_TYPE_DELIVER,
  DELIVERY_TYPE_DINEIN,
} from "../../../utils/constants";
import DeliveryOnlyBadge from "./DeliveryOnlyBadge";
import DineInOnlyBadge from "./DineInOnlyBadge";
import OutOfStockBadge from "./OutOfStockBadge";
import PickupOnlyBadge from "./PickupOnlyBadge";
import TimeSpecificBadge from "./TimeSpecificBadge";

const RenderAvailabilityBadges = ({
  isOutOfStock,
  availability,
  deliveryTypes,
}) => (
  <>
    {isOutOfStock && <OutOfStockBadge />}
    {!isOutOfStock && (
      <div>
        {!isEmpty(availability) && (
          <TimeSpecificBadge availability={availability} />
        )}
        {deliveryTypes?.includes(DELIVERY_TYPE_TAKEOUT) && <PickupOnlyBadge />}
        {deliveryTypes?.includes(DELIVERY_TYPE_DELIVER) && (
          <DeliveryOnlyBadge />
        )}
        {deliveryTypes?.includes(DELIVERY_TYPE_DINEIN) && <DineInOnlyBadge />}
      </div>
    )}
  </>
);

export default RenderAvailabilityBadges;
