/* eslint-disable react/jsx-props-no-spreading */
import React from "react";

import { IconButton, makeStyles } from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  root: {
    flex: 1,
    padding: 2,
    boxShadow: "none",
    // color: theme.palette.secondary.dark,
    // "&.MuiButton-root": {
    //   borderRadius: 0,
    //   backgroundColor: "transparent",
    // },
    // "&.Mui-disabled": {
    //   color: theme.palette.text.disabled,
    // },
    "&:hover": {
      color: theme.palette.secondary.dark,
      backgroundColor: "transparent",
    },
  },
}));

const IconButtonDefault = ({ children, onClick, color = "secondary", ...props }) => {
  const classes = useStyle();

  return (
    <IconButton
      classes={{ root: classes.root }}
      variant="contained"
      color={color}
      onClick={onClick}
      {...props}
    >
      {children}
    </IconButton>
  );
};

export default IconButtonDefault;
