import React from "react";

import { Step, StepButton, Stepper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { connect, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  Stepper: {
    backgroundColor: theme.palette.p,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(0),
      // height: "90%",
    },
    borderBottom: `1px solid ${theme.palette.background.boarderColor}`,
    height: 24,
    padding: theme.spacing(2, 1),
    "&.MuiPaper-root": {
      backgroundColor: "transparent",
    },
    "& .MuiStepButton-root": {
      padding: theme.spacing(1, 0),
    },
    "& .MuiStepConnector-root": {
      display: "none",
    },
    "& .MuiStep-root": {
      flex: 1,
      padding: theme.spacing(0, 1),
      margin: theme.spacing(0),
    },
    "& .MuiStepIcon-root": {
      color: theme.palette.background.accentTextColor,
    },
    "& .MuiStepLabel-label": {
      color: theme.palette.background.accentTextColor,
    },
    "& .MuiStepIcon-text": {
      fill: theme.palette.background.accentColor,
    },
  },
}));

const DELIVER_TYPES = {
  DELIVER: "Delivery",
  PICKUP: "Pickup",
  DINEIN: "Dine in",
};

const CartStepper = ({ activeStep }) => {
  const classes = useStyles();
  const { selectedDeliveryMethod } = useSelector((state) => state.cart);
  const isTabView = useMediaQuery("(min-width:600px)");

  const STEPS = [
    DELIVER_TYPES[selectedDeliveryMethod],
    "Contact",
    "Payment",
    "Confirmation",
  ];

  if (!isTabView) return null;
  return (

    <Stepper
      classes={{ root: classes.Stepper }}
      nonLinear
      activeStep={activeStep}
    >
      {STEPS.map((label, index) => (
        <Step key={String(index)}>
          <StepButton completed={index < activeStep}>{label}</StepButton>
        </Step>
      ))}
    </Stepper>
  );
};

const mapStateToProps = (state) => ({
  cart: state.cart,
});

// const mapDispatchToProps = {};

export default connect(mapStateToProps, null)(CartStepper);
