import qs from "query-string";

import { SET_IS_DINEIN, SET_EMBED_TYPE } from "../actions/global";

export const GlobalInitialState = {
  isDineIn: false,
  embedType: "default",
};

function GlobalReducer(state = GlobalInitialState, action) {
  switch (action.type) {
    case SET_IS_DINEIN: {
      const { isDineIn, viewMode } = qs.parse(window.location.search, {
        parseBooleans: true,
      });
      if (viewMode === "dinein") {
        window.location.href = `${window.location.pathname}?isDineIn=true`;
        return {
          ...state,
          isDineIn: true,
        };
      }
      if (isDineIn === true) {
        return {
          ...state,
          isDineIn: true,
        };
      }
      return {
        ...state,
        isDineIn: false,
      };
    }

    case SET_EMBED_TYPE: {
      const { embedType } = qs.parse(window.location.search);
      return {
        ...state,
        embedType,
      };
    }
    default:
      return state;
  }
}

export default GlobalReducer;
