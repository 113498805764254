import React, { useEffect, useState } from "react";

import { useTheme } from "@material-ui/core";
import qs from "query-string";
import { useSelector } from "react-redux";

import { HOME_ICON_URL, RESTAURANT_ICON_URL } from "../../../utils/constants";
import { calCenterOfLocations } from "../../../utils/utils";
import _ from "lodash";

const GoogleMapStatic = ({ size, height }) => {
  const [mapUrl, setMapUrl] = useState("");
  const theme = useTheme();

  const { storeInfo: businessInfo } = useSelector((state) => state.store);
  const { deliveryLocation, selectedDeliveryMethod } = useSelector(
    (state) => state.cart,
  );
  const { lat: deliveryLat, lng: deliveryLng } = deliveryLocation;
  useEffect(() => {
    if (!businessInfo) return;
    const { lon } = businessInfo;
    const { lat } = businessInfo;

    let markers = [`icon:${RESTAURANT_ICON_URL}|${lat},${lon}`];
    let center = [`${lat},${lon}`];
    if (selectedDeliveryMethod === "DELIVER") {
      const { lat: latCenter, lng: lngCenter } = calCenterOfLocations(
        lat,
        lon,
        deliveryLat,
        deliveryLng,
      );

      markers = [
        ...markers,
        `icon:${HOME_ICON_URL}|${deliveryLat},${deliveryLng}`,
      ];
      center = `${latCenter},${lngCenter}`;
    }

    let newSize = _.isEmpty(size) ? "600x350" : size;
    const obj = {
      center,
      zoom: 12,
      scale: 4,
      size: newSize,
      maptype: "roadmap",
      markers,
      key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    };
    const url = `https://maps.googleapis.com/maps/api/staticmap?${qs.stringify(
      obj,
    )}`;
    setMapUrl(url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessInfo, size]);
  return (
    <img
      style={
        height
          ? {
              width: "100%",
              borderRadius: theme.shape.borderRadius,
              height: `${height}px`,
            }
          : { width: "100%", borderRadius: theme.shape.borderRadius }
      }
      src={mapUrl}
      alt="Google map of order"
    />
  );
};

export default GoogleMapStatic;
