import React from "react";

import DialogContentText from "@material-ui/core/DialogContentText";
import { useHistory, useLocation } from "react-router-dom";

import store from "../../../redux";
import { transferLogsServerAndClearLocalLogs } from "../../../services/logging";
import { clearLocalStoreOnOrderSuccess } from "../../../utils/storage/localStorage";
import AlertDialog from "./AlertDialog";

const OrderSuccessAlertDialog = ({
  open,
  handleClose,
  orderStatusPageLink,
  timer,
}) => {
  const location = useLocation();

  const storeCleanup = () => {
    clearLocalStoreOnOrderSuccess();
    store.dispatch({
      type: "RESET",
    });
  };

  const history = useHistory();

  const handleRedirect = async () => {
    transferLogsServerAndClearLocalLogs();
    clearTimeout(timer);
    storeCleanup();
    history.push({ pathname: orderStatusPageLink, search: location.search });
  };

  return (
    <AlertDialog
      variant="success"
      dialogTitle="Order placed successfully"
      open={open}
      handleClose={handleClose}
      handleSubmit={handleRedirect}
      submitLabel="ORDER STATUS PAGE"
    >
      <DialogContentText id="alert-dialog-description">
        You will now be redirected to order status page. If not please use the
        ORDER STATUS PAGE button to manually navigate to order status page.
      </DialogContentText>
    </AlertDialog>
  );
};

export default OrderSuccessAlertDialog;
