/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-console */
import React, { useEffect, useState } from "react";

import { Box, Typography, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import _ from "lodash";
import { useSelector } from "react-redux";

import useQueryParam from "../../../../hooks/RouteHooks/useQueryParam";
import useMenuUtils from "../../../../hooks/useMenuUtils";
import FacebookPixel from "../../../../utils/analytics/FacebookPixel";
import {
  MENU_VIEW_TYPE_GRID,
  MENU_VIEW_TYPE_IMAGE_LIST,
  MENU_VIEW_TYPE_LIST,
  QUERY_PARAM_POPUP_CAT,
  QUERY_PARAM_POPUP_CATMENU,
  QUERY_PARAM_POPUP_MENU,
} from "../../../../utils/constants";
import { gaEventViewItem } from "../../../../utils/googleAnalytics";
import { isEmbeddedWindow } from "../../../../utils/utils";
import CatMenu from "../CatMenu";
import LayoutGrid from "./layouts/LayoutGrid";
import LayoutImageList from "./layouts/LayoutImageList";
import LayoutList from "./layouts/LayoutList";
import MenuItemModal from "./MenuItemModal";

const useStyles = makeStyles((theme) => ({
  ItemListNoScrollWrapper: {
    color: theme.palette.text.titleTextColor,
    textAlign: "center",
    backgroundColor: theme.palette.background.menuBackgroundColor,
    height: "100%",
  },
  catTitle: {
    position: "default",
    top: 0,
    backgroundColor: theme.palette.background.menuBackgroundColor,
    color: theme.palette.text.subtitleTextColor,
  },
  scroll: {
    // minHeight: "80vh",
    // overflow: "auto",
    [theme.breakpoints.down("sm")]: {
      // height: "calc(100vh - 134px)",
    },
    width: "100%",
    "&::-webkit-scrollbar": {
      width: "5px",
      display: "none",
    },
  },
}));

/**
 * The ItemListNoScroll component displays the items of a selected category in a fixed list layout.
 * It also allows searching for specific items and provides a modal to view item details.
 */
const ItemListNoScroll = ({ searchName, setSearchName, setIsOpen, isOpen }) => {
  const [openMenuItem, setOpenMenuItem] = useState(false);
  const [selectedItemData, setSelectedItemData] = useState({});
  const [filteredList, setFilteredList] = useState([]);
  const [topOffset, setTopOffset] = useState(10);
  const [frameHeight, setFrameHeight] = useState(700);

  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const { getParam, addParams, updateParams } = useQueryParam();
  const { getMenuItemByQueryParams } = useMenuUtils();

  const isEmbedded = isEmbeddedWindow();

  const { categorySelected } = useSelector((state) => state.category);
  const { selectedDeliveryMethod, selectedDateTime } = useSelector(
    (state) => state.cart,
  );
  const { catMenuList, catMenuOptions, activeCatMenu, catMenuListAll } =
    useSelector((state) => state.catMenu);
  const {
    theme: { itemDisplayType },
  } = useSelector((state) => state.initData);
  const { embedType } = useSelector((state) => state.global);
  const {
    storeInfo: { businessDisplayName },
  } = useSelector((state) => state.store);

  /**
   * The 'sendPostMessage' function is responsible for sending a postMessage to the parent
   * window (if applicable) to adjust the height of the embedded iframe containing the component.
   * It calculates the desired frame height based on the provided 'height' parameter and sends it as part of the postMessage.
   */
  const sendPostMessage = (height) => {
    window.parent.postMessage(
      {
        frameHeight: height < 700 ? 700 + 102 : height + 144,
      },
      process.env.REACT_APP_RESTAURANT_DOMAIN,
    );
  };

  /**
   * The 'handleCloseItemModal' function is responsible for closing the item modal and
   * updating the query parameters accordingly. It sets the 'openMenuItem' state to false,
   * indicating that the modal should be closed,
   * and updates the query parameters related to the item modal to their initial values.
   */
  const handleCloseItemModal = () => {
    updateParams({
      [QUERY_PARAM_POPUP_CAT]: categorySelected.catId,
      [QUERY_PARAM_POPUP_CATMENU]: activeCatMenu.catMenuId,
      [QUERY_PARAM_POPUP_MENU]: null,
    });
    setOpenMenuItem(false);
  };

  /**
   * The 'setOpenMenuItemFunc' function is responsible for opening the item modal with the selected menu item's details
   * and updating relevant state and query parameters.
   *
   * @param {Object} data - An object containing the details of the selected menu item.
   * @param {boolean} isOpenByQueryParams - A boolean value indicating whether the modal is being opened based on query parameters.
   */
  const setOpenMenuItemFunc = async (data, isOpenByQueryParams) => {
    // Check if the modal is not being opened by query parameters
    if (!isOpenByQueryParams) {
      // If not, update the query parameters with the selected menu item's details
      addParams({
        [QUERY_PARAM_POPUP_MENU]: data?.menuId,
        [QUERY_PARAM_POPUP_CAT]: data?.catId,
        [QUERY_PARAM_POPUP_CATMENU]: activeCatMenu.catMenuId,
      });
    }

    // Check if the view is fixed list and the application is embedded within another platform
    if (theme.view.isFixedList && isEmbedded) {
      // If so, handle specific cases based on the 'embedType'
      if (embedType === "WIX") {
        // If the 'embedType' is 'WIX', wait for the 'setSelectedItemData' function to complete before sending a post message to the parent window with the details of the menu item and the modal type 'typeWix: true'
        await setSelectedItemData(data);
        window.parent.postMessage({ openModal: true, typeWix: true }, "*");
      } else {
        // If the 'embedType' is not 'WIX', send a post message to the parent window with the following data:
        // - 'openModal: true': Indicates that the modal should be opened.
        // - 'menuItemData': The details of the selected menu item ('data').
        // - 'selectedDeliveryMethod': The selected delivery method from the Redux state.
        // - 'selectedDateTime': The selected date and time from the Redux state.
        // - 'catMenuId': The ID of the currently selected category menu ('categorySelected.catMenuId').
        window.parent.postMessage(
          {
            openModal: true,
            menuItemData: data,
            selectedDeliveryMethod,
            selectedDateTime,
            catMenuId: categorySelected.catMenuId,
          },
          process.env.REACT_APP_RESTAURANT_DOMAIN,
        );
      }
    } else {
      // If the view is not fixed list, set the 'selectedItemData' state to the details of the selected menu item
      // and set the 'openMenuItem' state to true, indicating that the item modal should be opened.
      setSelectedItemData(data);
      setOpenMenuItem(true);
    }

    // Call tracking functions to track the view event of the selected menu item for analytics purposes.
    gaEventViewItem(data, businessDisplayName, categorySelected, activeCatMenu);
    FacebookPixel.viewContent(data, categorySelected);
  };

  /**
   * The 'useEffect' hook is used to handle opening the item modal
   * if the query parameters contain the details of a selected menu item.
   * This effect will only run when the 'catMenuListAll' dependency changes.
   */
  useEffect(() => {
    // Check if the embedType is 'WP' or 'WIX'
    if (embedType === "WP" || embedType === "WIX") return;

    // handle open item modal if query param contained on url
    // Check if the 'catMenuListAll' is empty (no available category menu data)
    if (_.isEmpty(catMenuListAll)) return;

    // Retrieve the menuItem details from the query parameters based on 'catMenuListAll'
    const { menuItem } = getMenuItemByQueryParams(catMenuListAll);

    // Check if 'menuItem' is not null (i.e., query parameters contain the details of a selected menu item)
    if (menuItem) {
      // If 'menuItem' is not null, call the 'setOpenMenuItemFunc' function to
      // open the item modal with the details of the selected menu item.
      // The second argument 'true' indicates that the modal is being opened by query parameters.
      setOpenMenuItemFunc(menuItem, true);
    }
  }, [catMenuListAll]);

  /**
   * The 'useEffect' hook is used to observe changes in the height of the 'storeMainContainer' element
   * and send the height to the parent window if the view is fixed and the app is embedded.
   * This effect will only run when any of the dependencies ('theme', 'matches', 'isEmbedded', 'embedType') change.
   */
  useEffect(() => {
    // Check if the view is fixed and the app is embedded
    if (theme.view.isFixedList && isEmbedded) {
      // If the above condition is true, create a new ResizeObserver to
      // observe changes in the height of the 'storeMainContainer' element.
      // The ResizeObserver will call the callback function with 'entries' when the height changes.
      const ro = new ResizeObserver((entries) => {
        // Iterate through each 'entry' in 'entries'
        for (const entry of entries) {
          // Call the 'sendPostMessage' function to send the height to the parent window.
          sendPostMessage(entry.target.offsetHeight);
          // Set the 'frameHeight' state to the observed height.
          setFrameHeight(entry.target.offsetHeight);
        }
      });

      // Start observing the 'storeMainContainer' element by passing it to the ResizeObserver.
      ro.observe(document.getElementById("storeMainContainer"));

      // Clean up the observer when the component unmounts or when the dependencies change.
      // The 'ro.disconnect()' method is called to stop observing the element.
      return () => {
        ro.disconnect();
      };
    }
  }, [theme, matches, isEmbedded, embedType]);

  /**
   * The 'useEffect' hook is used to handle the WIX functionality, which involves opening a modal
   * when a message is received from the parent WIX window.
   * The effect listens for 'message' events from the parent window and responds accordingly.
   * This effect will run when the 'embedType' or 'frameHeight' dependencies change.
   */
  useEffect(() => {
    // Define the function that will handle opening the modal in response to the 'message' event from the parent window.
    const handleOpenModalWix = async (event) => {
      // Get the 'scrollY' data from the received event.
      const { scrollY } = event?.data;

      // Check if 'scrollY' is defined (i.e., if the message contains the 'scrollY' property).
      if (scrollY !== undefined) {
        // Calculate the top offset for the modal based on the 'scrollY' and 'frameHeight' values.
        if (frameHeight > scrollY + 400) {
          setTopOffset(scrollY);
        } else {
          setTopOffset(frameHeight - 500);
        }

        // Open the menu item modal.
        setOpenMenuItem(true);
      }
    };

    // Check if the app is embedded in WIX.
    if (embedType === "WIX") {
      // If the app is embedded in WIX, add an event listener to listen for 'message' events from
      // the parent window and call 'handleOpenModalWix' when a message is received.
      window.addEventListener("message", handleOpenModalWix, false);
    }

    // Clean up the event listener when the component unmounts or when the 'embedType' or 'frameHeight' dependencies change.
    // The 'window.removeEventListener' method is called to remove the event listener to prevent memory leaks.
    return () => {
      window.removeEventListener("message", handleOpenModalWix);
    };
  }, [embedType, frameHeight]);

  const catMenuId = getParam(QUERY_PARAM_POPUP_CATMENU);

  /**
   * The 'handleChangeSearch' function is a callback function that is triggered when the user types in the search input field.
   * It updates the 'searchName' state with the current input value and calls 'handleSearchDetails' function to perform the search.
   *
   * @param {object} e - The event object representing the user input event.
   */
  const handleChangeSearch = (e) => {
    // Extract the value of the input from the event object.
    const { value } = e.target;

    // Update the 'searchName' state with the current input value.
    setSearchName(value);
    // Call the 'handleSearchDetails' function to perform the search based on the input value.
    handleSearchDetails(value);
  };

  /**
   * The 'handleSearchDetails' function is responsible for filtering the menu items based on the provided search value.
   * It filters the 'catMenuList' based on the 'activeCatMenu.catMenuId' and then filters the menu items by title.
   * The filtered menu items are then stored in the 'filteredList' state.
   *
   * @param {string} value - The search value entered by the user.
   */
  const handleSearchDetails = (value) => {
    // Check if the search value is not empty and 'catMenuList' is available.
    if (!_.isEmpty(value) && catMenuList) {
      // Filter the 'catMenuList' to get the menu items for the active category menu.
      const menuItemsForActiveCategory = Object.values(catMenuList).filter(
        (item) => item.catMenuId === activeCatMenu.catMenuId,
      );

      // Check if the filtered list is not empty.
      if (!_.isEmpty(menuItemsForActiveCategory)) {
        // Get the data (menu items) from the first item of the filtered list.
        const activeCategoryMenuItems = Object.values(
          menuItemsForActiveCategory[0].data,
        );

        // Filter the menu items based on their title, ignoring case sensitivity.
        const filteredLists = activeCategoryMenuItems
          .map((menuItem) => ({
            ...menuItem,
            data: Object.fromEntries(
              Object.entries(menuItem.data).filter(([key, data]) =>
                data.title.toLowerCase().includes(value.toLowerCase()),
              ),
            ),
          }))
          .filter((menuItem) => Object.keys(menuItem.data).length > 0);

        // Update the 'filteredList' state with the filtered menu items.
        setFilteredList(filteredLists);
      }
    }
  };

  return (
    <div className={classes.ItemListNoScrollWrapper}>
      {embedType !== "WP" && (
        <MenuItemModal
          open={openMenuItem}
          handleClose={handleCloseItemModal}
          selectedItemData={selectedItemData}
          catMenuId={catMenuId || activeCatMenu.catMenuId}
          selectedDeliveryMethod={selectedDeliveryMethod}
          selectedDateTime={selectedDateTime}
          catMenuOptions={catMenuOptions}
          topOffset={topOffset}
        />
      )}
      <CatMenu
        isFixedList
        setSearchName={setSearchName}
        searchName={searchName}
        handleChangeSearch={handleChangeSearch}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
      />
      <Box id="items-container" className={classes.scroll}>
        {!_.isEmpty(categorySelected) &&
          (searchName ? (
            !_.isEmpty(filteredList) &&
            filteredList.map(
              (data1) =>
                data1.isActive && (
                  <Box
                    key={`itemSection-title-${data1.catId}`}
                    style={{ marginTop: "1rem" }}
                  >
                    <Typography
                      id={`itemSection-title-catId_${data1.catId}`}
                      className={classes.catTitle}
                      variant="h5"
                    >
                      {data1.title}
                    </Typography>

                    {!_.isEmpty(data1) && (
                      <>
                        {itemDisplayType === MENU_VIEW_TYPE_LIST && (
                          <LayoutList
                            category={data1}
                            setOpenMenuItemFunc={setOpenMenuItemFunc}
                          />
                        )}
                        {itemDisplayType === MENU_VIEW_TYPE_GRID && (
                          <LayoutGrid
                            category={data1}
                            setOpenMenuItemFunc={setOpenMenuItemFunc}
                          />
                        )}
                        {itemDisplayType === MENU_VIEW_TYPE_IMAGE_LIST && (
                          <LayoutImageList
                            category={data1}
                            setOpenMenuItemFunc={setOpenMenuItemFunc}
                          />
                        )}
                      </>
                    )}
                  </Box>
                ),
            )
          ) : (
            <Box
              key={`itemSection-title-${categorySelected.catId}`}
              style={{ marginTop: "1rem" }}
            >
              <Typography
                id={`itemSection-title-catId_${categorySelected.catId}`}
                className={classes.catTitle}
                variant="h5"
              >
                {categorySelected.title}
              </Typography>

              {itemDisplayType === MENU_VIEW_TYPE_LIST && (
                <LayoutList
                  category={categorySelected}
                  setOpenMenuItemFunc={setOpenMenuItemFunc}
                />
              )}
              {itemDisplayType === MENU_VIEW_TYPE_GRID && (
                <LayoutGrid
                  category={categorySelected}
                  setOpenMenuItemFunc={setOpenMenuItemFunc}
                />
              )}
              {itemDisplayType === MENU_VIEW_TYPE_IMAGE_LIST && (
                <LayoutImageList
                  category={categorySelected}
                  setOpenMenuItemFunc={setOpenMenuItemFunc}
                />
              )}
            </Box>
          ))}
      </Box>
    </div>
  );
};

ItemListNoScroll.propTypes = {};
ItemListNoScroll.defaultProps = {};

export default ItemListNoScroll;
