import React from "react";

import { makeStyles } from "@material-ui/core";

import MenuItemCard from "./MenuItemListCard";

const useStyles = makeStyles(() => ({
  LayoutListWrapper: {},
}));

const LayoutList = ({ category, setOpenMenuItemFunc }) => {
  const { data: items } = category;
  const classes = useStyles();
  return (
    <div className={classes.LayoutListWrapper}>
      {Object.keys(items).map((m, i) => (
        <div
          onClick={() => setOpenMenuItemFunc(items[m])}
          onKeyDown={() => setOpenMenuItemFunc(items[m])}
          key={String(i)}
          role="button"
          tabIndex="-1"
        >
          <MenuItemCard
            key={`item-${category.catId}-${items[m].menuId}`}
            id={`item-${category.catId}-${items[m].menuId}`}
            title={items[m].title}
            desc={items[m].desc}
            data={items[m].data}
            menuItemData={items[m]}
          />
        </div>
      ))}
    </div>
  );
};

LayoutList.propTypes = {};
LayoutList.defaultProps = {};

export default LayoutList;
