import React, { useEffect, useState } from "react";

import {
  Box,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  TextField,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import isEmpty from "lodash/isEmpty";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";

import useQueryParam from "../../../../hooks/RouteHooks/useQueryParam";
import useMenuUtils from "../../../../hooks/useMenuUtils";
import { logInfo } from "../../../../services/logging";
import { getAvailableCatMenuList } from "../../../../utils/availability";
import {
  QUERY_PARAM_POPUP_CAT,
  QUERY_PARAM_POPUP_CATMENU,
} from "../../../../utils/constants";
import CartDialog from "../CartDialog";
import { setCategoryList, setCategorySelected } from "../Menu/redux/actions";
import MenuSideBar from "../MenuSideBar";
import CatMenuTabs from "./CatMenuTabs";
import {
  fetchCatMenuListAsync,
  fetchCatMenuListSuccess,
  setActiveCatMenu,
} from "./redux/actions";

const useStyles = makeStyles((theme) => ({
  CatMenu: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "center",
    },
  },
  arrowRoot: {
    color: theme.palette.text.defaultTextColor,
  },
  title: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    color: theme.palette.text.titleTextColor,
  },
  arrowIcon: {
    color: theme.palette.text.titleTextColor,
  },
  textField: {
    overflowWrap: "break-word",
    wordWrap: "break-word",
    borderRadius: "10px",
    border: `1px solid ${theme.palette.text.itemTextColor}`,
    color: theme.palette.text.itemTextColor,
    [`& fieldset`]: {
      borderRadius: "10px",
      color: theme.palette.text.itemTextColor,
      border: `1px solid ${theme.palette.text.itemTextColor}`,
      cursor: "pointer",
    },
    "& .MuiInputBase-input": {
      color: theme.palette.text.itemTextColor,
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      color: theme.palette.text.itemTextColor,
      border: `1px solid ${theme.palette.text.itemTextColor}`,
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      color: theme.palette.text.itemTextColor,
      border: `1px solid ${theme.palette.text.itemTextColor}`,
    },
    // color: theme.palette.primary.contrastText,
    // "& .MuiInputBase-root": {
    //   color: theme.palette.primary.contrastText,
    //   borderRadius: theme.shape.borderRadius,
    // },
    // "& .MuiFormLabel-root": {
    //   color: theme.palette.primary.contrastText,
    // },
  },
}));

const CatMenu = ({
  actions,
  isFixedList,
  currentCat,
  setCurrentCat,
  cartCount,
  setSearchName,
  searchName,
  handleChangeSearch,
  setIsOpen,
  isOpen,
}) => {
  const classes = useStyles();
  const [activeIdx, setActiveIdx] = useState(0);
  const [openNoDataModal, setOpenNoDataModal] = useState(false);
  const { addParams, getParams } = useQueryParam();
  const {
    activeCatMenu: activeCM,
    catMenuOptions,
    catMenuList,
    catMenuListAll,
  } = useSelector((state) => state.catMenu);

  const { categorySelected } = useSelector((state) => state.category);
  const { selectedDeliveryMethod, selectedDateTime } = useSelector(
    (state) => state.cart,
  );
  const dispatch = useDispatch();
  const { getMenuItemByQueryParams } = useMenuUtils();

  useEffect(() => {
    setOpenNoDataModal(false);
    // on initial load we need to filter cat menus according to
    // 1. catMenuListAll
    // 2. selectedDeliveryMethod
    // 3. selectedDateTime: user selected delivery time for order (scheduled or order now)

    if (!isEmpty(catMenuListAll)) {
      // filter catMenu list by time, delivery options and catMenu options
      const availCatMenuList = getAvailableCatMenuList(
        catMenuListAll,
        catMenuOptions,
        selectedDeliveryMethod,
        selectedDateTime,
      );

      if (!isEmpty(availCatMenuList)) {
        try {
          // set filtered CatMenu list
          dispatch(fetchCatMenuListSuccess(availCatMenuList));

          // set first item on the list as the default
          const defaultCatList = Object.values(availCatMenuList[0].data);
          const defaultSelectedCategory = defaultCatList[0];

          const defaultActiveCatMenu = {
            title: availCatMenuList[0].title,
            catMenuId: availCatMenuList[0].catMenuId,
          };
          const defaultActiveCatMenuIdx = 0;

          // --- handle select category and catMenu if query params available

          const { catMenu, category } =
            getMenuItemByQueryParams(availCatMenuList);
          if (catMenu) {
            try {
              const activeCatMenuInfo = {
                catMenuId: catMenu.catMenuId,
                title: catMenu.title,
              };
              const activeCatMenuIdx = availCatMenuList.findIndex(
                (cm) => String(cm.catMenuId) === String(catMenu.catMenuId),
              );
              const activeCatMenu = availCatMenuList[activeCatMenuIdx];
              const activeCatMenuCategories = Object.values(activeCatMenu.data);

              dispatch(setActiveCatMenu(activeCatMenuInfo));
              setActiveIdx(activeCatMenuIdx);
              dispatch(setCategoryList(activeCatMenuCategories));

              if (category) {
                return dispatch(setCategorySelected(category));
              }
              const activeCatMenuDefaultCategory = activeCatMenuCategories[0];
              return dispatch(
                setCategorySelected(activeCatMenuDefaultCategory),
              );
            } catch (error) {
              // handle error
            }
          }

          // --- end handle select category and catMenu ---

          dispatch(setActiveCatMenu(defaultActiveCatMenu));
          setActiveIdx(defaultActiveCatMenuIdx);
          dispatch(setCategoryList(defaultCatList));
          dispatch(setCategorySelected(defaultSelectedCategory));
        } catch (error) {
          dispatch(fetchCatMenuListSuccess(catMenuListAll));
        }
      } else {
        setOpenNoDataModal(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedDeliveryMethod,
    selectedDateTime,
    catMenuListAll,
    catMenuOptions,
    dispatch,
  ]);

  useEffect(() => {
    try {
      const params = getParams([
        QUERY_PARAM_POPUP_CAT,
        QUERY_PARAM_POPUP_CATMENU,
      ]);
      const isSameParams =
        params[QUERY_PARAM_POPUP_CAT] === categorySelected.catId &&
        params[QUERY_PARAM_POPUP_CATMENU] === activeCM.catMenuId;
      if (!isSameParams) {
        addParams({
          [QUERY_PARAM_POPUP_CAT]: categorySelected.catId,
          [QUERY_PARAM_POPUP_CATMENU]: activeCM.catMenuId,
        });
      }
    } catch (error) {
      // handle error
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categorySelected, activeCM]);

  const handleChange = (val, catMenuIdx) => {
    setSearchName("");
    const cats = Object.values(catMenuList[catMenuIdx].data);
    actions.setCategoryList(cats);
    setActiveIdx(catMenuIdx);
    dispatch(
      setActiveCatMenu({
        title: catMenuList[catMenuIdx].title,
        catMenuId: catMenuList[catMenuIdx].catMenuId,
      }),
    );
    if (isFixedList) {
      const initCat = !_.isEmpty(cats) ? cats[0] : {};
      dispatch(setCategorySelected(initCat));
    }
    logInfo({
      message: "Category menu changed.",
      catMenuTitle: catMenuList[catMenuIdx].title,
    });
  };

  const handleClearButton = () => {
    if (searchName || isOpen) {
      setSearchName("");
      setIsOpen(false);
    }
  };
  const handleOpenSearch = () => {
    setIsOpen(true);
  };

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <Box className={classes.CatMenu}>
      <Dialog open={openNoDataModal} maxWidth="lg" onClose={null}>
        <DialogTitle>No menu available</DialogTitle>
      </Dialog>
      <Hidden mdUp>
        <MenuSideBar
          currentCat={currentCat}
          setCurrentCat={setCurrentCat}
          isIcon
          setSearchName={setSearchName}
          setIsOpen={setIsOpen}
        />
      </Hidden>
      <Hidden mdUp>
        {matches ? (
          isOpen ? (
            <Box
              display="grid"
              gridTemplateColumns="repeat(26, 1fr)"
              style={{ marginRight: "6px" }}
            >
              <Box
                gridColumn={"span 24"}
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "24px",
                  paddingRight: "20px",
                  minHeight: "56px",
                }}
              >
                <TextField
                  classes={{ root: classes.textField }}
                  id="outlined-inline-static"
                  placeholder="Search for a dish"
                  variant="outlined"
                  onChange={handleChangeSearch}
                  value={searchName}
                  fullWidth
                  size="small"
                  InputProps={{
                    maxLength: 200,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                          style={{ color: theme.palette.text.itemTextColor }}
                          onClick={handleClearButton}
                        >
                          {isOpen ? <ClearIcon /> : <SearchIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box
                gridColumn="span 2"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <div>
                  <CartDialog cartCount={cartCount} isIcon />
                </div>
              </Box>
            </Box>
          ) : (
            <Box
              display="grid"
              gridTemplateColumns="repeat(26, 1fr)"
              style={{ marginRight: "6px" }}
            >
              <Box gridColumn={"span 23"}>
                <Box display="grid" gridTemplateColumns="repeat(23, 1fr)">
                  <Box gridColumn={"span 21"}>
                    <CatMenuTabs
                      activeIdx={activeIdx}
                      handleChange={handleChange}
                      searchName={searchName}
                      handleChangeSearch={handleChangeSearch}
                      setSearchName={setSearchName}
                      setIsOpen={setIsOpen}
                      isOpen={isOpen}
                    />{" "}
                  </Box>
                  <Box
                    gridColumn="span 2"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <SearchIcon
                      onClick={handleOpenSearch}
                      style={{
                        color: theme.palette.text.itemTextColor,
                        cursor: "pointer",
                      }}
                    />
                  </Box>
                </Box>
              </Box>

              <Box
                gridColumn="span 3"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <div>
                  <CartDialog cartCount={cartCount} isIcon />
                </div>
              </Box>
            </Box>
          )
        ) : (
          <Grid container>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <Hidden xsDown>
                <div
                  style={
                    isOpen
                      ? {
                          width: "calc(100vw - 309px)",
                          display: "flex",
                          justifyContent: "start",
                          marginRight: "4px",
                        }
                      : {
                          width: "calc(100vw - 125px)",
                          display: "flex",
                          justifyContent: "start",
                          marginRight: "4px",
                        }
                  }
                >
                  <CatMenuTabs
                    activeIdx={activeIdx}
                    handleChange={handleChange}
                    searchName={searchName}
                    handleChangeSearch={handleChangeSearch}
                    setSearchName={setSearchName}
                    setIsOpen={setIsOpen}
                    isOpen={isOpen}
                  />
                </div>
                {!isOpen ? (
                  <SearchIcon
                    style={{ cursor: "pointer" }}
                    onClick={handleOpenSearch}
                  />
                ) : (
                  <div style={{ marginLeft: "8px" }}>
                    <TextField
                      classes={{ root: classes.textField }}
                      id="outlined-inline-static"
                      placeholder="Search for a dish"
                      variant="outlined"
                      onChange={handleChangeSearch}
                      value={searchName}
                      style={{ width: "200px" }}
                      size="small"
                      w
                      InputProps={{
                        maxLength: 200,
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              edge="end"
                              style={{
                                color: theme.palette.text.itemTextColor,
                              }}
                              onClick={handleClearButton}
                            >
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />{" "}
                  </div>
                )}

                <div style={{ width: "60px" }}>
                  <CartDialog cartCount={cartCount} isIcon />
                </div>
              </Hidden>
            </Grid>
          </Grid>
        )}

        {/* <Grid container style={{display: "flex", alignItems: "center"}}>
        <Grid item xs={11} sm={11} md={0}>
         <CatMenuTabs
            activeIdx={activeIdx}
            handleChange={handleChange}
            searchName={searchName}
            handleChangeSearch={handleChangeSearch}
            setSearchName={setSearchName}
            setIsOpen={setIsOpen}
            isOpen={isOpen}
          />
        

        </Grid>
        <Grid item xs={2} sm={1} style={{display: "flex", justifyContent: "end"}}>
        <CartDialog cartCount={cartCount} isIcon />
        </Grid>
      </Grid> */}
      </Hidden>

      <Hidden smDown>
        <Grid container style={{ placeItems: "center" }}>
          <Grid item xs={12}>
            <CatMenuTabs
              activeIdx={activeIdx}
              handleChange={handleChange}
              searchName={searchName}
              handleChangeSearch={handleChangeSearch}
              setSearchName={setSearchName}
              setIsOpen={setIsOpen}
              isOpen={isOpen}
            />
          </Grid>
        </Grid>
      </Hidden>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  category: state.category,
  item: state.item,
  catMenu: state.catMenu,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    { fetchCatMenuListAsync, setCategorySelected, setCategoryList },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(CatMenu);
