import React, { useEffect, useState } from "react";

import { Grid, Hidden, Paper, Typography, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import _ from "lodash";
import { connect, useDispatch, useSelector } from "react-redux";

import CartDialog from "../../components/@pages/storeHome/CartDialog";
import ItemListNoScroll from "../../components/@pages/storeHome/ItemList/ItemListNoScroll";
import ItemListScroll from "../../components/@pages/storeHome/ItemList/ItemListScroll";
import Menu from "../../components/@pages/storeHome/Menu";
import MenuSideBar from "../../components/@pages/storeHome/MenuSideBar";
import MyOrder from "../../components/@pages/storeHome/MyOrder";
import {
  setAppliedCouponCode,
  setFilteredDeliveryOptionListByAvailability,
  setOrderDiscounts,
  setSelectedDeliveryData,
} from "../../components/@pages/storeHome/MyOrder/redux/actions";
import { setIsOpenNow } from "../../components/@pages/storeHome/redux/actions";
import StickyBtnWrapper from "../../components/common/StickyBtnWrapper";
import { setEmbedType } from "../../redux/actions/global";
import { logInfo } from "../../services/logging";
import AvailabilityUtils from "../../utils/AvailabilityUtils";
import { showStoreHomeNotification } from "../../utils/order";
import moment from "../../utils/time/moment";
import TimeUtils from "../../utils/TimeUtils";
import LoginDialog from "../../components/@pages/storeHome/LoginDialog";

const useStyles = makeStyles((theme) => ({
  menuItemsSection: {
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(0),
      borderRight: `1px solid ${theme.palette.background.boarderColor}`,
      borderLeft: `1px solid ${theme.palette.background.boarderColor}`,
    },
  },

  sections: {
    // margin: theme.spacing(2),
    // height: "100vh",
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(0),
      // height: "90%",
    },
  },
  myOrderSection: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    // maxHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  catSection: {
    maxHeight: "80vh",
  },
  topNotification: {
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.error.contrastText,
    height: 72,
    padding: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    // borderBottom: "1px solid black",
  },
  categorySection: {
    color: theme.palette.text.titleTextColor,
    backgroundColor: theme.palette.background.categoryBackgroundColor,
  },
}));

const StoreHomePage = () => {
  const [isFixedListLayout, setIsFixedListLayout] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [searchName, setSearchName] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const matchesMediumUp = useMediaQuery(theme.breakpoints.up("md"));
  const { embedType } = useSelector((state) => state.global);

  const { isOrderingActive, isPreOrderActive } = useSelector(
    (state) => state.store,
  );
  const { cartItems } = useSelector((state) => state.cart);
  const { openTimes, isOpenNow } = useSelector((state) => state.store);

  useEffect(() => {
    if (_.isEmpty(openTimes)) return;
    const isShopOpen = TimeUtils.isTimeInWeeklyTimes(
      moment(),
      openTimes.weekly,
    );
    logInfo({ message: `is Shop Open now: ${isShopOpen}}` });

    dispatch(setIsOpenNow(isShopOpen));
    if (showStoreHomeNotification(isOpenNow, isOrderingActive)) {
      const message = AvailabilityUtils.getNotificationMessage(
        isOrderingActive,
        isPreOrderActive,
        isOpenNow,
        openTimes.weekly,
      );
      setNotificationMessage(message);
    }
  }, [openTimes, dispatch, isPreOrderActive, isOrderingActive, isOpenNow]);

  useEffect(() => {
    dispatch(setSelectedDeliveryData({}));
    dispatch(setFilteredDeliveryOptionListByAvailability([]));
    dispatch(setAppliedCouponCode(""));
    dispatch(setOrderDiscounts([]));
    dispatch(setEmbedType());
  }, [dispatch]);

  useEffect(() => {
    const isFixedList =
      theme?.view?.viewLayoutType === "fixed_list" && matchesMediumUp;
    setIsFixedListLayout(isFixedList);
  }, [theme, matchesMediumUp]);

  const [currentCat, setCurrentCat] = useState({
    pos: 0,
    cat: { catId: "" },
  });

  const cartCount = _.sum(cartItems.map((c) => c.quantity));

  return (
    <>
      <LoginDialog />
      {showStoreHomeNotification(isOpenNow, isOrderingActive) && (
        <Paper square elevation={5} className={classes.topNotification}>
          <Typography variant="body1">{notificationMessage}</Typography>
        </Paper>
      )}

      <Grid
        container
        style={{ height: embedType !== "WP" ? "100vh" : "unset" }}
      >
        <Grid
          item
          container
          lg={10}
          md={9}
          className={classes.sections}
          id="storeMainContainer"
        >
          <Hidden smDown>
            <Grid
              item
              xs={12}
              md={4}
              lg={3}
              className={classes.categorySection}
            >
              <Menu
                currentCat={currentCat}
                setCurrentCat={setCurrentCat}
                className={classes.catSection}
                isFixedList={isFixedListLayout}
                setSearchName={setSearchName}
                setIsOpen={setIsOpen}
              />
            </Grid>
          </Hidden>
          <Grid item xs={12} md={8} lg={9} className={classes.menuItemsSection}>
            {isFixedListLayout ? (
              <ItemListNoScroll
                searchName={searchName}
                setSearchName={setSearchName}
                setIsOpen={setIsOpen}
                isOpen={isOpen}
              />
            ) : (
              <ItemListScroll
                setCurrentCat={setCurrentCat}
                currentCat={currentCat}
                cartCount={cartCount}
                searchName={searchName}
                setSearchName={setSearchName}
                setIsOpen={setIsOpen}
                isOpen={isOpen}
              />
            )}
          </Grid>
        </Grid>
        <Grid
          item
          container
          lg={2}
          md={3}
          className={classes.sections}
          id="storeMainContainer"
        >
          <Hidden smDown>
            <Grid
              className={classes.myOrderSection}
              lg={12}
              item
              xs={12}
              md={12}
            >
              <MyOrder />
            </Grid>
          </Hidden>
        </Grid>
      </Grid>
      <Hidden mdUp>
        <StickyBtnWrapper>
          <MenuSideBar
            currentCat={currentCat}
            setCurrentCat={setCurrentCat}
            setSearchName={setSearchName}
            setIsOpen={setIsOpen}
          />
          <CartDialog cartCount={cartCount} />
        </StickyBtnWrapper>
      </Hidden>
    </>
  );
};

const mapStateToProps = (state) => ({
  item: state.item,
  cart: state.cart,
});

export default connect(mapStateToProps, null)(StoreHomePage);
