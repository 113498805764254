import React, { useEffect } from "react";

import {
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useDispatch } from "react-redux";

import ContactDetailsForm from "../../components/@pages/contact/ContactDetailsForm";
import { setAppliedCouponCode, setOrderDiscounts } from "../../components/@pages/storeHome/MyOrder/redux/actions";
import Stepper from "../../components/Stepper";
import ContentSection from "../../containers/ContentSection";
import OrderInfoSection from "../../containers/OrderInfoSection";
import PageWrapper from "../../containers/PageWrapper";
import SecondaryThemeProvider from "../../containers/SecondaryThemeProvider";
import { isEmbeddedWindow, isFixedListView } from "../../utils/utils";

const ContactPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setAppliedCouponCode(""));
    dispatch(setOrderDiscounts([]));
  }, [dispatch]);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const isFixedList = isFixedListView(theme, matches);
  const isEmbedded = isEmbeddedWindow();

  useEffect(() => {
    if (isFixedList && isEmbedded) {
      window.parent.postMessage(
        {
          frameHeight: 900,
        },
        process.env.REACT_APP_RESTAURANT_DOMAIN,
      );
    }
  }, [isFixedList, isEmbedded]);

  return (
    <PageWrapper>
      <OrderInfoSection />
      <SecondaryThemeProvider>
        <ContentSection>
          <Stepper activeStep={1} />
          <ContactDetailsForm />
        </ContentSection>
      </SecondaryThemeProvider>
    </PageWrapper>
  );
};
ContactPage.propTypes = {};
ContactPage.defaultProps = {};

export default ContactPage;
