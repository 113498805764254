import React from "react";

import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  PageTitleWrapper: {
    fontSize: "44px",
    margin: theme.spacing(1, 0, 2, 0),
    marginBottom: "6px",
    [theme.breakpoints.down("xs")]: {
      margin: "0px",
      fontSize: "36px",
      lineHeight: "42px",
      marginBottom: "16px"
    },
  },
}));

const PageTitle = ({ title }) => {
  const classes = useStyles();
  return (
    <Typography
      gutterBottom
      component="div"
      className={classes.PageTitleWrapper}
    >
      {title}
    </Typography>
  );
};

PageTitle.propTypes = {};
PageTitle.defaultProps = {};

export default PageTitle;
