import * as React from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";

import fbIcon from "../../../../../assets/icons/fb.png";
import SocialSignInButton from "../../../../common/buttons/SocialSignInButton";
import { useDispatch, useSelector } from "react-redux";
import { FacebookSignInUser } from "../../../../../utils/FacebookSignIn";

const FacebookSignIn = ({
  setAuthError,
  isNeedDisabled,
  setIsEmptyLastName,
  setIssOpenFirstNameLastNameModal,
  setFacebookFirstName,
  setToken,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { params } = useRouteMatch();
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { isActiveLoyalty } = useSelector((state) => state.loyalty);

  const handleClick = async () => {
    FacebookSignInUser(
      isNeedDisabled,
      setIsLoading,
      setAuthError,
      history,
      params,
      location,
      dispatch,
      setIsEmptyLastName,
      setIssOpenFirstNameLastNameModal,
      setFacebookFirstName,
      setToken,
      isActiveLoyalty,
    );
  };

  return (
    <SocialSignInButton
      handleClick={handleClick}
      logoSrc={fbIcon}
      labelText="Sign in with Facebook"
      alt="Facebook sign in"
      isLoading={isLoading}
      disabled={isNeedDisabled}
    />
  );
};

export default FacebookSignIn;
