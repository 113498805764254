/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-return-assign */
/**
 * Use the CSS tab above to style your Element's container.
 */
import React, { useImperativeHandle, useRef } from "react";

import { useTheme } from "@material-ui/core";
import { CardElement } from "@stripe/react-stripe-js";

import TextFieldDefault from "../../../../../../common/textFields/TextFieldDefault";
import "./CardSectionStyles.css";

const StripeInput = (
  { component: Component, inputRef, setPaymentInfo, ...props },
) => {
  const elementRef = useRef();
  useImperativeHandle(inputRef, () => ({
    focus: () => elementRef.current.focus,
  }));
  return (
    <Component
      onReady={(element) => (elementRef.current = element)}
      {...props}
      onChange={(e) => {
        setPaymentInfo((prevState) => ({ ...prevState,
          postalCode: e.value.postalCode }));
      }}
    />
  );
};

function CardSection({ setPaymentInfo }) {
  const theme = useTheme();

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: theme.palette.text.accentTextColor,
        fontFamily: "\"Helvetica Neue\", Helvetica, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: theme.palette.text.accentTextColor,
        },
        ":-webkit-autofill": {
          color: theme.palette.text.accentTextColor,
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  return (
    <TextFieldDefault
      variant="filled"
      id="card"
      required
      name="card"
      fullWidth
      InputProps={{
        inputComponent: StripeInput,
        inputProps: { component: CardElement, options: CARD_ELEMENT_OPTIONS, setPaymentInfo },
      }}
    />
  );
}

export default CardSection;
