import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import CartItemCard from "../../@pages/orderStatus/CartItemCard";

const useStyles = makeStyles((theme) => ({
  cartItemsSection: {
    overflowX: "hidden",
    backgroundColor: theme.palette.primary.main,
    flex: 1,
    overflowY: "auto",
    [theme.breakpoints.down("xs")]: {
      height: "calc(100% - 172px)",
    },
    [theme.breakpoints.up("md")]: {
      maxHeight: 342,
    },
    ...theme.palette.scrollbarStyles,
    "&::-webkit-scrollbar": {
      width: 0,
    },
  },
}));

const OrderCartItems = ({ cartItems }) => {
  const classes = useStyles();
  return (
    <div className={classes.cartItemsSection}>
      {cartItems.map((item, i) => (
        <CartItemCard
          cartItemData={item}
          key={`cartItemCard${item.menuId}`}
          idx={i}
        />
      ))}
    </div>
  );
};

export default OrderCartItems;
