import React, { useState, useEffect } from "react";

import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";

import ContentContainer from "../../../../containers/ContentContainer";
import {
  logInfo,
  transferLogsServerAndClearLocalLogs,
} from "../../../../services/logging";
import {
  EMAIL_REGEX_PATTERN_SIMPLE,
  UK_PHONE_NUMBER_REGEX_PATTERN,
} from "../../../../utils/constants";
import BottomNavButtons from "../../../common/BottomNavButtons";
import StickyBtnWrapper from "../../../common/StickyBtnWrapper";
import TextFieldDefault from "../../../common/textFields/TextFieldDefault";
import { addCompletedPage } from "../../../routes/redux/actions";
import MobileStepper from "../../../Stepper/MobileStepper";
import { setCustomerDetails } from "../redux/actions";
import { useCallback } from "react";
import { Checkbox, FormControlLabel, useTheme } from "@material-ui/core";

/**
 * This component displays a form for collecting contact details from the user,
 * including first name, last name, email address, and phone number. It also handles form validation and navigation.
 */
const ContactDetailsForm = () => {
  const contact = useSelector((state) => state.contact);
  const auth = useSelector((state) => state.auth);
  const theme = useTheme();

  const [customer, setCustomer] = useState({
    ...contact.customer,
  });

  const history = useHistory();
  const { params } = useRouteMatch();
  const dispatch = useDispatch();
  const location = useLocation();
  const { register, errors, trigger } = useForm();

  /**
   * Function to trim white spaces from input values.
   */
  const trimValues = useCallback(async () => {
    setCustomer({
      firstName: customer.firstName.trim(),
      lastName: customer.lastName.trim(),
      email: customer.email.trim(),
      phoneNumber: customer.phoneNumber.trim(),
      marketing: customer?.marketing,
    });
  }, [customer]);

  /**
   * Function to handle the "Continue" button click, which submits the form and navigates to the next step.
   */
  const handleContinue = async () => {
    // Trim white spaces from the form values.
    trimValues();

    // Trigger form validation using the useForm trigger function.
    // This will validate all the registered fields and return a boolean indicating if the form is valid.
    const isValid = await trigger();

    // If the form is not valid, return early and do not proceed.
    if (!isValid) return;

    // Dispatch an action to update the Redux store with the customer details.
    dispatch(setCustomerDetails(customer));
    // Dispatch an action to add the "/payments" page to the list of completed pages in the Redux store.
    dispatch(addCompletedPage("/payments"));

    // Log an information message indicating that the contact details have been added.
    logInfo({ message: "Contact details added", customer });

    // Transfer the logs to the server and clear the local logs (if any).
    transferLogsServerAndClearLocalLogs();

    // Navigate to the next step (payments) using React Router's history.push method.
    history.push({
      pathname: `/location/${params.locationId}/payments`,
      search: location.search,
    });
  };

  /**
   * useEffect to initialize the form with contact details and auto-save if needed.
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // Destructure the contact and auth objects to get relevant data.
    const { firstName, lastName, email, phoneNumber, marketing } =
      contact.customer;
    const { displayName, email: authEmail } = auth;

    // Initialize variables to store initial form values.
    let initialFirstName = "";
    let initialLastName = "";
    let initialEmail = "";
    let initialMarketing = "";
    // Check if the displayName is available in the auth object.
    if (displayName) {
      // Split the displayName into an array by space.
      const displaySplit = displayName?.split(" ");

      // Set the initialFirstName to either the existing firstName or the first element of the displaySplit array.
      initialFirstName = firstName || (displaySplit[0] ? displaySplit[0] : "");

      // Set the initialLastName to either the existing lastName or the rest of the elements in the displaySplit array joined by space.
      initialLastName =
        lastName || (displaySplit[1] ? displaySplit.slice(1).join(" ") : "");

      // Set the initialEmail to either the existing email or the email from the auth object.
      initialEmail = email || authEmail;
      initialMarketing = marketing;
    } else {
      initialMarketing = marketing;
    }

    // Create an object with the initial form values.
    const customerDetails = {
      firstName: initialFirstName,
      lastName: initialLastName,
      email: initialEmail,
      marketing: initialMarketing,
      phoneNumber,
    };

    // Update the state with the initial form values using setCustomer.
    setCustomer(customerDetails);

    // Dispatch an action to update the Redux store with the initial form values.
    dispatch(setCustomerDetails(customerDetails));

    // If the customer select delivery location using previous delivery list (customer.isNeedAutoSaveCustomer is true),
    // proceed to the next step using the handleContinue function.
    if (customer.isNeedAutoSaveCustomer) {
      handleContinue();
    }
  }, []);

  /**
   * Function to navigate to the previous step.
   */
  const handleBackward = () => {
    history.push({
      pathname: `/location/${params.locationId}/delivery`,
      search: location.search,
    });
  };

  /**
   * Function to handle changes in form fields and update state accordingly.
   */
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "marketing") {
      if (value === "true") {
        setCustomer({ ...customer, [name]: false });
      } else {
        setCustomer({ ...customer, [name]: true });
      }
    } else {
      setCustomer({ ...customer, [name]: value });
    }
  };
  return (
    <>
      {/* MobileStepper for navigation */}
      <MobileStepper
        activeStep={2}
        handleNext={handleContinue}
        handleBack={handleBackward}
      />
      <ContentContainer>
        {/* Form header */}
        <Typography
          gutterBottom
          variant="h3"
          component="div"
          style={{ margin: 16 }}
        >
          Your contact details
        </Typography>
        <div>
          {/* First Name field */}
          <TextFieldDefault
            id="firstName"
            name="firstName"
            label="First name"
            value={customer.firstName}
            variant="filled"
            fullWidth
            onChange={handleChange}
            inputRef={register({
              required: true,
              minLength: 2,
              maxLength: 30,
            })}
            helperText={errors.firstName ? "Please enter first name" : ""}
            error={errors.firstName?.type}
          />
          {/* Last Name field */}
          <TextFieldDefault
            id="lastName"
            name="lastName"
            value={customer.lastName}
            onChange={handleChange}
            label="Last name"
            variant="filled"
            fullWidth
            inputRef={register({
              required: true,
              minLength: 2,
              maxLength: 30,
            })}
            helperText={errors.lastName ? "Please enter last name" : ""}
            error={errors.lastName?.type}
          />
          {/* Email field */}
          <TextFieldDefault
            id="email"
            name="email"
            value={customer.email}
            onChange={handleChange}
            label="Email address"
            variant="filled"
            fullWidth
            inputRef={register({
              required: true,
              minLength: 3,
              maxLength: 50,
              pattern: EMAIL_REGEX_PATTERN_SIMPLE,
            })}
            helperText={errors.email ? "Please enter a valid email" : ""}
            error={errors.email?.type}
          />
          {/* Phone Number field */}
          <TextFieldDefault
            id="phoneNumber"
            value={customer.phoneNumber}
            onChange={handleChange}
            name="phoneNumber"
            label="Phone number"
            variant="filled"
            fullWidth
            inputRef={register({
              required: true,
              minLength: 3,
              maxLength: 30,
              pattern: UK_PHONE_NUMBER_REGEX_PATTERN,
            })}
            helperText={
              errors.phoneNumber ? "Please enter a valid phone number" : ""
            }
            error={errors.phoneNumber?.type}
          />
          <FormControlLabel
            control={
              <Checkbox
                style={{ color: theme.palette.text.itemTextColor }}
                value={customer.marketing}
                checked={customer.marketing}
                name="marketing"
                size="small"
                onChange={handleChange}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            style={{ paddingLeft: "5px" }}
            labelPlacement="end"
            label={
              <Typography variant="caption">
                Save my details to keep up to date on discounts and offers
              </Typography>
            }
          />
        </div>
      </ContentContainer>

      {/* Bottom navigation buttons for larger screens */}
      <Hidden xsDown>
        <BottomNavButtons
          handleBackward={handleBackward}
          handleContinue={handleContinue}
          continueButtonLabel="Continue"
        />
      </Hidden>

      {/* Sticky bottom navigation buttons for smaller screens */}
      <Hidden smUp>
        <StickyBtnWrapper>
          <BottomNavButtons
            handleBackward={handleBackward}
            handleContinue={handleContinue}
            continueButtonLabel="Continue"
          />
        </StickyBtnWrapper>
      </Hidden>
    </>
  );
};

ContactDetailsForm.propTypes = {};
ContactDetailsForm.defaultProps = {};

export default ContactDetailsForm;
