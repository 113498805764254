import React from "react";

import { Divider, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  Divider: {
    backgroundColor: theme.palette.background.boarderColor,
  },
}));

const DividerDefault = () => {
  const classes = useStyles();
  return <Divider className={classes.Divider} />;
};

export default DividerDefault;
