import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core";
import qs from "query-string";
import { useSelector } from "react-redux";

import { RESTAURANT_ICON_URL } from "../../../../../utils/constants";
import PageTitle from "../../../../common/PageTitle";
import PickupDetails from "./PickupDetails/PickupDetails";

const useStyles = makeStyles((theme) => ({
  PickupViewWrapper: {
    borderRadius: theme.shape.borderRadius,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
  },
  locationMap: {
    backgroundColor: "transparent",
    flex: 1,
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      marginTop: 16,
    },
  },
  googleMap: {
    width: "100%",
    height: "100%",
    borderRadius: theme.shape.borderRadius,
  },
}));

const PickupView = () => {
  const classes = useStyles();
  const [mapParams, setMapParams] = useState("");
  const { selectedDateTime } = useSelector((state) => state.cart);
  const { storeInfo: businessInfo } = useSelector((state) => state.store);

  useEffect(() => {
    if (!businessInfo) return;
    const { lon } = businessInfo;
    const { lat } = businessInfo;
    const obj = {
      center: `${lat},${lon}`,
      zoom: 16,
      scale: 2,
      size: "600x400",
      maptype: "roadmap",
      markers: `icon:${RESTAURANT_ICON_URL}|${lat},${lon}`,
      key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    };
    setMapParams(qs.stringify(obj));
  }, [businessInfo]);

  const url = `https://maps.googleapis.com/maps/api/staticmap?${mapParams}`;
  return (
    <div className={classes.PickupViewWrapper}>
      <PageTitle title="You're placing an order for Pickup" />

      {businessInfo && (
        <div className={classes.locationMap}>
          <PickupDetails
            pickupAddress={businessInfo.addressFormatted}
            pickupDateTime={selectedDateTime}
          />
          <img
            className={classes.googleMap}
            src={url}
            alt="Google map of pickup location"
          />
        </div>
      )}
      {/* {true && <CircularProgress color="secondary" />} */}
      {/* {errorFetching && (
        <Typography variant="subtitle1">
          Sorry! can not load the map.
        </Typography>
      )} */}
    </div>
  );
};

export default PickupView;
