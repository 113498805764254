// google analytics

import gtag from "./gtag";

export function googleAnalyticsItemObject(
  data,
  businessDisplayName,
  categorySelected,
  activeCatMenu,
  quantity,
) {
  return {
    id: data.itemId,
    name: data.title,
    price: data.price,
    brand: businessDisplayName,
    category: categorySelected.title,
    // coupon: "",
    quantity,
    list_name: activeCatMenu.title,
    // list_position: 1,
    variant: data.size,
  };
}

export function gaEventViewItem(
  data,
  businessDisplayName,
  categorySelected,
  activeCatMenu,
) {
  try {
    gtag("event", "view_item", {
      items: [
        {
          ...googleAnalyticsItemObject(
            data,
            businessDisplayName,
            categorySelected,
            activeCatMenu,
            1,
          ),
        },
      ],
    });
  } catch {
    // pass
  }
}

export function gaEventLogin(method) {
  gtag("event", "login", { method });
}

export function gaEventPageView(pagePath, pageTitle) {
  try {
    gtag("event", "page_view", {
      page_location: window.location.href,
      page_path: pagePath,
      page_title: pageTitle,
    });
  } catch {
    // pass
  }
}

export function gaEventAddToCart(
  cartItem,
  quantity,
  categorySelected,
  activeCatMenu,
  businessDisplayName,
  unitPrice,
) {
  try {
    gtag("event", "add_to_cart", {
      currency: "GBP",
      items: [
        {
          id: cartItem.item.itemId,
          name: cartItem.itemData.title,
          price: parseFloat(unitPrice),
          brand: businessDisplayName,
          category: categorySelected.title,
          coupon: "",
          quantity,
          list_name: activeCatMenu.title,
          variant: cartItem.item.size,
        },
      ],
      value: unitPrice * quantity,
    });
  } catch {
    // pass
  }
}

export function createFunctionWithTimeout(callback, opt_timeout) {
  let called = false;
  function fn() {
    if (!called) {
      called = true;
      callback();
    }
  }
  setTimeout(fn, opt_timeout || 1000);
  return fn;
}
