import React from "react";

import { IconButton } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import ClearIcon from "@material-ui/icons/Clear";

import ButtonContained from "../../../../common/buttons/ButtonContained";

const useStyles = makeStyles((theme) => ({
  textField: {
    display: "flex",
    color: theme.palette.primary.contrastText,
    "& .MuiInputBase-root": {
      color: theme.palette.primary.contrastText,
      borderRadius: theme.shape.borderRadius,
    },
    "& .MuiFormLabel-root": {
      color: theme.palette.primary.contrastText,
    },
  },
  root: {
    margin: theme.spacing(1, 2),
  },
  addNoteBtn: {
    display: "flex",
  },
  label: {
    display: "flex",
    justifyContent: "space-between",
  },

  closeBtn: {
    position: "absolute",
    top: 8,
    right: 8,
  },
}));

const SpecialNotes = ({ notes, setNotes, showNote, setShowNote }) => {
  const classes = useStyles();

  const ClearButton = () => (
    <IconButton className={classes.closeBtn} onClick={() => setShowNote(false)}>
      <ClearIcon />
    </IconButton>
  );

  const handleChange = (e) => {
    const { value } = e.target;
    setNotes(value);
  };

  return (
    <div className={classes.root}>
      {!showNote && (
        <ButtonContained
          fullWidth
          height="30px"
          onClick={() => setShowNote(true)}
        >
          Add special note
        </ButtonContained>
      )}
      <Collapse in={showNote}>
        <TextField
          classes={{ root: classes.textField }}
          id="outlined-multiline-static"
          label="Special requests"
          multiline
          rows={4}
          onChange={handleChange}
          value={notes}
          InputProps={{
            maxLength: 200,
            endAdornment: <ClearButton />,
          }}
          variant="filled"
        />
      </Collapse>
    </div>
  );
};

export default SpecialNotes;
