import React from "react";

import InfoIcon from "@material-ui/icons/Info";

import IconLabel from "../labels/IconLabel";

const OutOfStockBadge = () => (
  <IconLabel
    error
    text="Out of stock"
    icon={<InfoIcon />}
    tooltipContent="This item is currently not available"
  />
);

export default OutOfStockBadge;
