/* eslint-disable camelcase */
import _ from "lodash";

import { currencySymbols } from "./const";
import { isByoItem, isMultipleDirectItem, isSingleDirectItem } from "./utils";

const PriceUtils = {
  /**
   * get price text as a single price or
   * price range
   */
  getMenuItemPrice: (menuItem) => {
    const { data } = menuItem;
    if (isSingleDirectItem(menuItem)) {
      return PriceUtils.priceWithCurrency(data[0].price);
    }

    if (isMultipleDirectItem(menuItem)) {
      const priceList = data.map((item) => item.price);
      return PriceUtils.priceRangeWithCurrency(priceList);
    }

    if (isByoItem(menuItem)) {
      if (parseFloat(data[0].price) === 0) {
        let priceList = [];
        try {
          priceList = data[0].subProducts[0].subProducts.map(
            (sub) => sub.surcharge,
          );
        } catch (error) {
          priceList = [];
        }
        return PriceUtils.priceRangeWithCurrency(priceList);
      }
      return PriceUtils.priceWithCurrency(data[0].price);
    }
  },

  /**
   * get price text with currency
   */
  priceWithCurrency: (amount) => {
    if (!parseFloat(amount)) return `${currencySymbols.GBP}0.00`;
    return `${currencySymbols.GBP}${amount}`;
  },

  /**
   * get price range text with currency for items with multiple prices
   * and price ranges
   */
  priceRangeWithCurrency: (prices) => {
    try {
      if (_.isEmpty(prices)) return `${currencySymbols.GBP}0.00`;
      let priceList = _.sortBy(prices.map((price) => parseFloat(price)));
      priceList = _.sortedUniqBy(priceList);
      let priceDisplayText = "";
      let minPrice = 0;
      let maxPrice = 0;

      if (priceList.length > 1) {
        [minPrice] = priceList;
        maxPrice = priceList[priceList.length - 1];
        priceDisplayText = `${currencySymbols.GBP}${minPrice}\xa0-\xa0${currencySymbols.GBP}${maxPrice} `;
      } else {
        [minPrice] = priceList;
        priceDisplayText = `${currencySymbols.GBP}${minPrice}`;
      }
      return priceDisplayText;
    } catch (error) {
      return `${currencySymbols.GBP}0.00`;
    }
  },
};

export default PriceUtils;
