import React from "react";

import { makeStyles } from "@material-ui/core";

import MenuItemCard from "./MenuItemImageCard";

const useStyles = makeStyles(() => ({
  LayoutImageListWrapper: {},
}));

const LayoutImageList = ({ category, setOpenMenuItemFunc }) => {
  const classes = useStyles();
  const { data: items } = category;

  return (
    <div className={classes.LayoutImageListWrapper}>
      {Object.keys(items).map((m, i) => (
        <div
          onClick={() => setOpenMenuItemFunc(items[m])}
          key={String(i)}
          onKeyDown={() => setOpenMenuItemFunc(items[m])}
          role="menuitem"
          tabIndex="-1"
        >
          <MenuItemCard
            key={`item-${category.catId}-${items[m].menuId}`}
            id={`item-${category.catId}-${items[m].menuId}`}
            title={items[m].title}
            desc={items[m].desc}
            data={items[m].data}
            menuItemData={items[m]}
          />
        </div>
      ))}
    </div>
  );
};

LayoutImageList.propTypes = {};
LayoutImageList.defaultProps = {};

export default LayoutImageList;
