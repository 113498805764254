export const SET_IS_DINEIN = "SET_IS_DINEIN ";
export const SET_EMBED_TYPE = "SET_EMBED_TYPE";

export const setIsDineIn = () => ({
  type: SET_IS_DINEIN,
});

export const setEmbedType = () => ({
  type: SET_EMBED_TYPE,
});
