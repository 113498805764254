import React, { useEffect, useState } from "react";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { logInfo } from "../../../../../../../services/logging";
import CheckoutForm from "../CheckoutForm";

const PaymentSection = ({
  paymentData,
  setOpenCardPaymentSection,
  openCardPaymentSection,
  setShowSuccessModal,
  setTimer,
  timer,
}) => {
  const [stripePromiseObj, setStripePromiseObj] = useState(null);

  useEffect(() => {
    if (!paymentData.publicKey) return;

    const loadStripeDefaultAsync = async () => {
      const stripeProm = await loadStripe(paymentData.publicKey, {
        apiVersion: "2020-08-27",
        stripeAccount: paymentData.entityId,
      });
      setStripePromiseObj(stripeProm);
    };

    const loadStripeExpressAsync = async () => {
      const stripeProm = await loadStripe(paymentData.publicKey);
      setStripePromiseObj(stripeProm);
    };

    if (paymentData.paymentGatewayProvider === "stripe") {
      loadStripeDefaultAsync();
      logInfo({ message: "payment gateway provider: STRIPE" });
    } else if (paymentData.paymentGatewayProvider === "express") {
      logInfo({ message: "payment gateway provider: EXPRESS" });
      loadStripeExpressAsync();
    }
  }, [paymentData]);

  // if (!stripePromiseObj) return null;
  return (
    <>
      <Elements stripe={stripePromiseObj}>
        <CheckoutForm
          paymentData={paymentData}
          setOpenCardPaymentSection={setOpenCardPaymentSection}
          openCardPaymentSection={openCardPaymentSection}
          setShowSuccessModal={setShowSuccessModal}
          setTimer={setTimer}
          timer={timer}
        />
      </Elements>
    </>
  );
};

PaymentSection.propTypes = {};
PaymentSection.defaultProps = {};

export default PaymentSection;
