import axios from "axios";

const baseUrl = process.env.REACT_APP_API_ENDPOINT;

const options = {
  baseUrl,
};

const axiosClient = axios.create(options);

export default axiosClient;
