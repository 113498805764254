import axios from "axios";
import qs from "query-string";

import axiosClient from ".";
import { getLocalStore } from "../../utils/storage/localStorage";
import { getClientQueryParams, getQueryParams, getLocationIdAndOrderIdByUrl } from "../../utils/utils";
import { recreateOrderSession, updateOrderSession } from "../orderService";

axiosClient.interceptors.request.use(
  (config) => {
    const configModified = config;

    // add query catMenu and Category query params to request

    const clientQueryParams = getClientQueryParams();
    const requestQueryParams = getQueryParams(configModified.url);
    const queryStringUrl = qs.stringifyUrl({ url: configModified.url, query: { ...clientQueryParams, ...requestQueryParams } });
    configModified.url = queryStringUrl;
    // --- end add query params ---

    if (config.url.includes("/public/") || config.url.includes("logz.io")) {
      return configModified;
    }
    configModified.headers = {
      ...config.headers,
      Authorization: `Bearer ${getLocalStore("idToken")}`,
      // add over riding headers here
    };
    return configModified;
  },
  (error) => Promise.reject(error),
);

axiosClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response) {
      if (
        error.response.status === 400
          && originalRequest.url.includes("/confirm")
          && originalRequest.url.includes("/order/")
          && error.response?.data?.message === "Invalid Order Id"
      ) {
        const { orderId: oldOrderId, locationId } = getLocationIdAndOrderIdByUrl(
          error.response.config.url,
        );
        const { id: newOrderId } = await updateOrderSession(locationId, oldOrderId);
        const url = originalRequest.url.replace(oldOrderId, newOrderId);
        const updatedRequest = { ...originalRequest, url };
        return axios(updatedRequest);
      }
      if (
        error.response.status === 403
          && originalRequest.url.includes("/confirm")
          && originalRequest.url.includes("/order/")
      ) {
        // when user changes the login middle of process
        const {
          locationId,
          orderId: oldOrderId,
        } = getLocationIdAndOrderIdByUrl(error.response.config.url);
        const { version, id: newOrderId } = await recreateOrderSession(
          locationId,
        );
        const updateData = {
          ...JSON.parse(originalRequest.data),
          version,
          id: newOrderId,
        };
        const url = originalRequest.url.replace(oldOrderId, newOrderId);
        const updatedRequest = { ...originalRequest, data: updateData, url };
        return axios(updatedRequest);
      }
      if (error.response.status === 401) {
        console.error("UNAUTHORIZED");
        return Promise.reject(error);
      }
      if (
        error.response.status === 409
          && originalRequest.url.includes("/confirm")
          && originalRequest.url.includes("/order/")
      ) {
        const { orderId, locationId } = getLocationIdAndOrderIdByUrl(
          error.response.config.url,
        );
        const { version } = await updateOrderSession(locationId, orderId);
        const updateData = { ...JSON.parse(originalRequest.data), version };
        const updatedRequest = { ...originalRequest, data: updateData };
        return axios(updatedRequest);
      }
    } else {
      // redirect to login
    }
    // eslint-disable-next-line consistent-return
    return Promise.reject(error);
  },
);
