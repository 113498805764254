import React from "react";

import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";

import moment from "../../../utils/time/moment";
import GoogleMapStatic from "../../maps/GoogleMapStatic";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import PageTitle from "../../common/PageTitle";

const useStyles = makeStyles((theme) => ({
  rootBoxStyle: {
    backgroundColor: theme.palette.text.accentTextColor,
    color: theme.palette.background.accentColor,
    borderRadius: "10px",
    padding: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  deliveryDetailsBoxStyle: {
    backgroundColor: theme.palette.text.accentTextColor,
    color: theme.palette.background.accentColor,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: "10px",
    padding: "0px 8px 8px 8px",
    minHeight: "252px",
  },
  container: {
    wordWrap: "break-word",
  },
}));

/**
 * Component to display the delivery address.
 */
const DeliveryAddress = ({ deliveryLocation }) => {
  // Destructuring delivery location details.
  const { doorNo, streetAddressLine1, streetAddressLine2, city, postalCode } =
    deliveryLocation;

  return (
    <div>
      <Typography style={{ display: "flex" }}>
        {doorNo}
        {", "}
        {streetAddressLine1}
        {", "}
      </Typography>
      <Typography style={{ display: "flex" }}>
        {streetAddressLine2}
        {", "}
        {city}
      </Typography>
      <Typography style={{ display: "flex" }}>{postalCode}</Typography>
    </div>
  );
};

/**
 * Component to display the store address.
 */
const StoreAddress = ({ storeInfo }) => {
  // Destructuring store information details.
  const {
    businessDisplayName,
    buildingNoOrName,
    addressLine1,
    addressLine2,
    city,
    postcode,
  } = storeInfo;

  return (
    <div>
      <Typography style={{ display: "flex", fontWeight: "bold" }}>
        {businessDisplayName}
      </Typography>
      <Typography style={{ display: "flex" }}>
        {buildingNoOrName}
        {", "}
        {addressLine1}
      </Typography>
      <Typography style={{ display: "flex" }}>
        {addressLine2}
        {addressLine2 ? ", " : ""}
        {city}
      </Typography>
      <Typography style={{ display: "flex" }}>{postcode}</Typography>
    </div>
  );
};

/**
 * This component renders the order details, including delivery/pickup information,
 * customer contact details, and a static map showing the delivery location.
 */
const OrderDetails = () => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const {
    customer: { firstName, lastName, email, phoneNumber },
  } = useSelector((state) => state.contact);
  const { storeInfo } = useSelector((state) => state.store);
  const { businessDisplayName } = storeInfo;
  const {
    selectedDeliveryMethod,
    deliveryLocation,
    selectedDeliveryTimeType,
    selectedDateTime,
  } = useSelector((state) => {
    return state.cart;
  });

  const { extraAddressInfo } = deliveryLocation;
  const gridRef = useRef(null);
  let deliveryText;
  let icon = "restaurant_menu";

  /**
   * Function to format date and time.
   */
  const formatDateTime = (dateTime) => {
    return {
      time: moment(dateTime).format("hh:mmA"),
      date: moment(dateTime).format("Do MMM YYYY"),
    };
  };

  /**
   * Determine the delivery text based on the selected delivery method.
   */
  switch (selectedDeliveryMethod) {
    case "PICKUP":
      // Set the deliveryText to "Pickup" if the selected delivery method is "PICKUP".
      deliveryText = "Pickup";
      break;
    case "DELIVER":
      // Set the deliveryText to "Delivery" if the selected delivery method is "DELIVER".
      deliveryText = "Delivery";
      break;
    default:
      // If none of the cases match, set the deliveryText to "Dine In".
      deliveryText = "Dine In";
  }

  /**
   * Determine the icon based on the selected delivery method.
   */
  switch (selectedDeliveryMethod) {
    case "DELIVER":
      // Set the icon to "delivery_dining" if the selected delivery method is "DELIVER".
      icon = "delivery_dining";
      break;
    case "PICKUP":
      // Set the icon to "shopping_bag" if the selected delivery method is "PICKUP".
      icon = "shopping_bag";
      break;
    default:
      // If none of the cases match, do nothing (icon remains unchanged)
      break;
  }

  /**
   * Function to update the height and width of the component based on the ref's client width.
   */
  const updateHeight = (ref) => {
    // Check if the ref is available and not null.
    if (ref.current) {
      // Get the current client width of the ref (grid item).
      const width = ref.current.clientWidth;

      // Set the height to a fixed value of 258 (could be customized as needed).
      setHeight(258);

      // Update the state with the calculated width.
      setWidth(width);
    }
  };

  /**
   * useEffect hook to update height on mount.
   */
  useEffect(() => {
    updateHeight(gridRef);
  }, []);

  const classes = useStyles();
  return (
    <>
      <Grid container spacing={2}>
        {/* Page title */}
        <Grid item xs={12}>
          <PageTitle title={businessDisplayName} />
        </Grid>

        {/* Delivery time and type */}
        <Grid item xs={12}>
          <Box className={classes.rootBoxStyle}>
            <Typography style={{ fontSize: "18px" }}>
              {selectedDeliveryTimeType === "ASAP"
                ? "Order For Now"
                : "Scheduled"}
            </Typography>

            <div>
              <Typography
                style={{
                  fontWeight: "bold",
                  textAlign: "right",
                  fontSize: "18px",
                }}
              >
                {selectedDeliveryTimeType === "ASAP"
                  ? "ASAP"
                  : formatDateTime(selectedDateTime).time}
              </Typography>
              {selectedDeliveryTimeType !== "ASAP" && (
                <Typography style={{ fontSize: "18px" }}>
                  {formatDateTime(selectedDateTime).date}
                </Typography>
              )}
            </div>
          </Box>
        </Grid>

        {/* Delivery details */}
        <Grid item xs={12} sm={6}>
          <Box className={classes.deliveryDetailsBoxStyle} ref={gridRef}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" style={{ fontWeight: "bold" }}>
                {deliveryText}
              </Typography>
              <Typography>
                {
                  <span
                    class="material-icons-outlined"
                    style={{ fontSize: "40px", paddingTop: "8px" }}
                  >
                    {icon}
                  </span>
                }
              </Typography>
            </div>
            {selectedDeliveryMethod === "DELIVER" && (
              <DeliveryAddress deliveryLocation={deliveryLocation} />
            )}
            {selectedDeliveryMethod !== "DELIVER" && (
              <StoreAddress storeInfo={storeInfo} />
            )}

            <div>
              <Typography style={{ fontWeight: "bold" }}>
                {firstName} {lastName}
              </Typography>
              <Typography>{phoneNumber}</Typography>
              <Typography className={classes.container}>{email}</Typography>
            </div>
          </Box>
        </Grid>

        {/* Google Map */}
        {height > 0 && (
          <Grid item xs={12} sm={6}>
            <GoogleMapStatic size={`${width}x${height}`} height={height} />
          </Grid>
        )}
        {extraAddressInfo && (
          <Grid item xs={12}>
            <Box className={classes.rootBoxStyle}>
              <Typography> {extraAddressInfo} </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};

OrderDetails.propTypes = {};
OrderDetails.defaultProps = {};

export default OrderDetails;
