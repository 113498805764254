import React from "react";

import { Grid, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import {
  PAYMENT_METHOD_CARD,
  PAYMENT_METHOD_CASH,
} from "../../../../utils/constants";
import { setSelectedPaymentMethod } from "../redux/actions";
import CashPayment from "./CashPayment";
import CreditCardPayment from "./CreditCardPayment";
import ButtonDefault from "../../../common/buttons/ButtonDefault";
import { Icon } from "@iconify/react";

const useStyles = makeStyles((theme) => ({
  PaymentMethodsWrapper: {
    maxWidth: "460px",
  },
}));

const PaymentMethods = () => {
  const {
    selectedDeliveryData: { paymentTypes },
  } = useSelector((state) => state.cart);
  const { selectedPaymentMethod } = useSelector((state) => state.payments);
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleChangeButton = (paymentMethod) => {
    dispatch(setSelectedPaymentMethod(paymentMethod));
  };

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const largeScreenSize = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <div
      style={{ marginRight: "4px", marginTop: "24px", marginLeft: "8px" }}
      className={classes.PaymentMethodsWrapper}
    >
      <Grid container>
        <Grid item xs={12}>
          {paymentTypes?.includes(PAYMENT_METHOD_CARD) && (
            <ButtonDefault
              textColor={
                selectedPaymentMethod === "card"
                  ? theme.palette.background.accentColor
                  : theme.palette.text.accentTextColor
              }
              bgColor={
                selectedPaymentMethod === "card"
                  ? theme.palette.text.accentTextColor
                  : theme.palette.background.accentColor
              }
              border={
                selectedPaymentMethod === "card"
                  ? theme.palette.background.accentColor
                  : theme.palette.text.accentTextColor
              }
              style={
                paymentTypes.length === 1
                  ? { width: "100%", height: "56px" }
                  : largeScreenSize
                  ? { marginRight: "4px", height: "56px", width: "258px" }
                  : { marginRight: "4px", height: "56px", width: "244px" }
              }
              onClick={() => handleChangeButton("card")}
            >
              <Grid container>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingLeft: "8px",
                    paddingRight: "8px",
                  }}
                >
                  {selectedPaymentMethod === "card" ? (
                    <Icon
                      color={theme.palette.primary.main}
                      width="28px"
                      height="28px"
                      icon="ic:sharp-radio-button-checked"
                    />
                  ) : (
                    <Icon
                      color={theme.palette.primary.main}
                      width="26px"
                      height="26px"
                      icon="eva:radio-button-off-outline"
                    />
                  )}
                  <CreditCardPayment
                    selectedPaymentMethod={selectedPaymentMethod}
                  />
                </Grid>
              </Grid>
            </ButtonDefault>
          )}

          {paymentTypes?.includes(PAYMENT_METHOD_CASH) && (
            <ButtonDefault
            textColor={
              selectedPaymentMethod === "cash"
                ? theme.palette.background.accentColor
                : theme.palette.text.accentTextColor
            }
            bgColor={
              selectedPaymentMethod === "cash"
                ? theme.palette.text.accentTextColor
                : theme.palette.background.accentColor
            }
            border={
              selectedPaymentMethod === "cash"
                ? theme.palette.background.accentColor
                : theme.palette.text.accentTextColor
            }
              onClick={() => handleChangeButton("cash")}
              style={
                paymentTypes.length === 1
                  ? { width: "100%", height: "56px" }
                  : matches
                  ? { width: "143px", height: "56px" }
                  : { width: "108px", height: "56px" }
              }
            >
              <Grid container>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingLeft: "8px",
                    paddingRight: "8px",
                  }}
                >
                  {selectedPaymentMethod === "cash" ? (
                    <Icon
                      color={theme.palette.primary.main}
                      width="28px"
                      height="28px"
                      icon="ic:sharp-radio-button-checked"
                      style={
                        matches ? { marginTop: "3px" } : { marginTop: "0px" }
                      }
                    />
                  ) : (
                    <Icon
                      color={theme.palette.primary.main}
                      width="26px"
                      height="26px"
                      icon="eva:radio-button-off-outline"
                      style={
                        matches ? { marginTop: "3px" } : { marginTop: "0px" }
                      }
                    />
                  )}
                  <CashPayment selectedPaymentMethod={selectedPaymentMethod} />
                </Grid>
              </Grid>
            </ButtonDefault>
          )}
          {/* {paymentTypes?.includes(PAYMENT_METHOD_CASH) && (
            <ButtonDefault
              textColor={
                selectedPaymentMethod === "cash"
                  ? theme.palette.background.accentColor
                  : theme.palette.primary.contrastText
              }
              bgColor={
                selectedPaymentMethod === "cash"
                  ? theme.palette.primary.contrastText
                  : theme.palette.background.accentColor
              }
              border={
                selectedPaymentMethod === "cash"
                  ? theme.palette.background.accentColor
                  : theme.palette.primary.contrastText
              }
              onClick={() => handleChangeButton("cash")}
              style={{ width: "110px", height: "56px" }}
            >
              {selectedPaymentMethod === "cash" ? (
                <Icon
                  color={theme.palette.primary.main}
                  width="28px "
                  height="28px "
                  icon="ic:sharp-radio-button-checked"
                />
              ) : (
                <Icon
                  color={theme.palette.primary.main}
                  width="28px "
                  height="28px "
                  icon="eva:radio-button-off-outline"
                />
              )}
              <CashPayment selectedPaymentMethod={selectedPaymentMethod} />
            </ButtonDefault>
          )} */}
        </Grid>
      </Grid>
      {/* <FormControl className={classes.PaymentMethodsWrapper}>
        <RadioGroup
          aria-label="paymentMethod"
          name="paymentMethod"
          value={selectedPaymentMethod}
          // onChange={handleChange}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {paymentTypes?.includes(PAYMENT_METHOD_CARD) && (
            <ButtonDefault
              textColor={
                selectedPaymentMethod === "card"
                  ? theme.palette.background.accentColor
                  : theme.palette.primary.contrastText
              }
              bgColor={
                selectedPaymentMethod === "card"
                  ? theme.palette.primary.contrastText
                  : theme.palette.background.accentColor
              }
              border={
                selectedPaymentMethod === "card"
                  ? theme.palette.background.accentColor
                  : theme.palette.primary.contrastText
              }
              onClick={() => handleChangeButton("card")}
            >
              <CreditCardPayment
                selectedPaymentMethod={selectedPaymentMethod}
              />
            </ButtonDefault>
          )}

          {paymentTypes?.includes(PAYMENT_METHOD_CASH) && (
            <ButtonDefault
              textColor={
                selectedPaymentMethod === "cash"
                  ? theme.palette.background.accentColor
                  : theme.palette.primary.contrastText
              }
              bgColor={
                selectedPaymentMethod === "cash"
                  ? theme.palette.primary.contrastText
                  : theme.palette.background.accentColor
              }
              border={
                selectedPaymentMethod === "cash"
                ? theme.palette.background.accentColor
                : theme.palette.primary.contrastText
              }
              onClick={() => handleChangeButton("cash")}
            >
              <CashPayment selectedPaymentMethod={selectedPaymentMethod} />
            </ButtonDefault>
          )}
        </RadioGroup>
      </FormControl> */}
    </div>
  );
};

PaymentMethods.propTypes = {};
PaymentMethods.defaultProps = {};

export default PaymentMethods;
