import { setLocalStore } from "../../utils/storage/localStorage";
import {
  LOGIN_USER_PENDING,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  SET_IS_OPEN_LOGIN_MODAL,
} from "../actions/auth";

export const AuthInitialState = {
  displayName: "",
  email: "",
  isAuthenticated: false,
  isFetching: false,
  error: null,
  isOpenLoginModal: false,
};

const AuthReducer = (state = AuthInitialState, action) => {
  switch (action.type) {
    case LOGIN_USER_PENDING:
      return {
        ...state,
        isAuthenticated: false,
        isFetching: true,
        error: null,
      };
    case LOGIN_USER_SUCCESS:
      setLocalStore("username", action.displayName);
      return {
        ...state,
        displayName: action.displayName,
        email: action.email,
        isAuthenticated: true,
        isFetching: false,
        error: null,
      };
    case LOGIN_USER_ERROR:
      return {
        ...state,
        displayName: "",
        email: "",
        isAuthenticated: false,
        isFetching: false,
        error: action.error,
      };
    case SET_IS_OPEN_LOGIN_MODAL:
      return {
        ...state,
        isOpenLoginModal: action.payload,
      };
    default:
      return state;
  }
};

export default AuthReducer;
