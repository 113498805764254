import { SET_AVAILABLE_PAYMENT_METHODS, SET_SELECTED_PAYMENT_METHOD } from "./actions";

export const PaymentsInitialState = {
  availablePaymentMethods: [],
  selectedPaymentMethod: "",
  cardNumber: "",
  error: null,
};

const PaymentsReducer = (state = PaymentsInitialState, action) => {
  switch (action.type) {
    case SET_AVAILABLE_PAYMENT_METHODS:
      return {
        ...state,
        availablePaymentMethods: action.payload,
      };
    case SET_SELECTED_PAYMENT_METHOD:
      return {
        ...state,
        selectedPaymentMethod: action.payload,
      };
    default:
      return state;
  }
};

export default PaymentsReducer;
