import React from "react";

import { makeStyles } from "@material-ui/core";
import RestaurantMenuIcon from "@material-ui/icons/RestaurantMenu";
import Color from "color";

const useStyles = makeStyles((theme) => ({
  MenuItemNoImageWrapper: {
    width: "100%",
    height: "auto",
    aspectRatio: ({ maxImageHeight, maxImageWidth }) => `${maxImageWidth} / ${maxImageHeight}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: Color(theme.palette.background.menuBackgroundColor).isDark()
      ? Color(theme.palette.background.menuBackgroundColor)
        .mix(Color("#fff"), 0.05)
        .hex()
      : Color(theme.palette.background.menuBackgroundColor)
        .mix(Color("#000"), 0.05)
        .hex(),
  },
}));

const MenuItemNoImage = ({ maxImageHeight, maxImageWidth }) => {
  const classes = useStyles({ maxImageHeight, maxImageWidth });

  return (
    <div className={classes.MenuItemNoImageWrapper}>
      <RestaurantMenuIcon fontSize="large" />
    </div>
  );
};

MenuItemNoImage.propTypes = {

};
MenuItemNoImage.defaultProps = {

};

export default MenuItemNoImage;
