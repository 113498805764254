/* eslint-disable react/jsx-props-no-spreading */
import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import ToggleButton from "@material-ui/lab/ToggleButton";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    color: theme.palette.primary.contrastText,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // color: theme.palette.primary.contrastText,
    // "&.MuiToggleButton-root": {
    //   backgroundColor: theme.palette.secondary.main,
    // },
    "&.Mui-disabled": {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.text.disabled,
    },
    // "&.Mui-selected": {
    //   backgroundColor: theme.palette.secondary.light,
    // },
    // "&:hover": {
    //   backgroundColor: Color(theme.palette.secondary.main).darken(0.05).hex(),
    // },
    // "&.Mui-selected:hover": {
    //   backgroundColor: theme.palette.secondary.dark,
    // },
  },
  contrastRoot: {
    flex: 1,
    // color: Color(theme.palette.text.accentTextColor).darken(0.05).hex(),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: `1px solid ${theme.palette.secondary.main}`,
    // "&.MuiToggleButton-root": {
    //   backgroundColor: theme.palette.background.accentColor,
    // },

    // "&.Mui-disabled": {
    //   backgroundColor: Color(theme.palette.background.accentColor).darken(0.05).hex(),
    //   color: Color(theme.palette.text.accentTextColor).darken(0.05).hex(),
    // },
    "&.Mui-selected": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },
    // "&:hover": {
    //   backgroundColor: Color(theme.palette.background.accentColor).lighten(0.05).hex(),
    // },
    "&.Mui-selected:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));

const ToggleButtonDefault = ({ children, isContrast, ...props }) => {
  const classes = useStyles();

  return (
    <ToggleButton
      color="secondary"
      classes={{ root: isContrast ? classes.contrastRoot : classes.root }}
      {...props}
    >
      {children}
    </ToggleButton>
  );
};

export default ToggleButtonDefault;
