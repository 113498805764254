import React from "react";

import { Typography } from "@material-ui/core";

const ItemCount = ({ count = 0 }) => (
  <Typography variant="subtitle1" component="div">
    <strong>{count}</strong>
    &nbsp;
    items
  </Typography>
);

export default ItemCount;
