import React from "react";

import { Grid, makeStyles } from "@material-ui/core";

import MenuItemCard from "./MenuItemCardGrid";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
  },
  layoutGridWrapper: {
    padding: theme.spacing(1),
  },
}));

const LayoutGrid = ({ category, setOpenMenuItemFunc }) => {
  const { data: items } = category;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={1} className={classes.layoutGridWrapper}>
        {Object.keys(items).map((m, i) => (
          <MenuItemCard
            key={String(i)}
            id={`item-${category.catId}-${items[m].menuId}`}
            title={items[m].title}
            desc={items[m].desc}
            data={items[m].data}
            menuItemData={items[m]}
            setOpenMenuItemFunc={setOpenMenuItemFunc}
          />
        ))}
      </Grid>
    </div>
  );
};

LayoutGrid.propTypes = {};
LayoutGrid.defaultProps = {};

export default LayoutGrid;
