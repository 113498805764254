// import moment from "moment";
import moment from "moment-timezone";

import { TIMEZONE_EUROPE_LONDON } from "../constants";

moment.tz.setDefault(TIMEZONE_EUROPE_LONDON);

moment.updateLocale("en", {
  relativeTime: {
    future: "",
    past: "",
    s: "a few seconds",
    ss: "%d seconds",
    m: "a minute",
    mm: "%d minutes",
    h: "an hour",
    hh: "%d hours",
    d: "tomorrow",
    dd: "%d days",
    w: "a week",
    ww: "%d weeks",
    M: "a month",
    MM: "%d months",
    y: "a year",
    yy: "%d years",
  },
  calendar: {
    lastDay: "[Yesterday at] LT",
    sameDay: "[today at] LT",
    nextDay: "[tomorrow at] LT",
    lastWeek: "[last] dddd [at] LT",
    nextWeek: "[on] dddd [at] LT",
    sameElse: "L",
  },
});

export default moment;
