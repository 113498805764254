import isEmpty from "lodash/isEmpty";
import isFinite from "lodash/isFinite";

import { TIME_FORMAT_SERVER } from "../constants";
import moment from "../time/moment";
import { constructItemsArray, convertDeliveryType } from "../utils";

export const getDeliveryTimeFormatted = (
  selectedDateTime,
  selectedDeliveryTimeType,
  selectedDeliveryData,
) => {
  let deliveryTime = moment(selectedDateTime);
  if (selectedDeliveryTimeType === "ASAP") {
    deliveryTime = moment().add(selectedDeliveryData.delayMins, "minutes");
  } else if (selectedDeliveryTimeType === "SPECTIME") {
    const minDeliveryTime = moment().add(
      selectedDeliveryData.delayMins,
      "minutes",
    );
    if (deliveryTime.isSameOrBefore(minDeliveryTime)) {
      deliveryTime = minDeliveryTime;
    }
  }

  // return convertTimezone(
  //   deliveryTime.format(TIME_FORMAT_SERVER),
  //   TIMEZONE_ASIA_COLOMBO,
  //   TIMEZONE_EUROPE_LONDON,
  // ).format(TIME_FORMAT_SERVER);

  return deliveryTime.format(TIME_FORMAT_SERVER);
};

export const getOrderData = (
  sessionVersion,
  cartItems,
  discountAttachedCartItems,
  orderDiscounts,
  selectedDeliveryData,
  totalPriceItems,
  selectedDateTime,
  selectedDeliveryTimeType,
  selectedDeliveryMethod,
  firstName,
  lastName,
  phoneNumber,
  email,
  marketing,
  deliveryLocation,
  selectedPaymentMethod,
  totalDiscount,
  orderNotes,
  serviceCharge,
  extraCharges,
  appliedDiscounts,
  restaurantInfo,
) => {
  try {
    const items = constructItemsArray(discountAttachedCartItems, cartItems);
    const discountIds = orderDiscounts.map((disc) => disc.id);

    // cal total amount
    const deliveryCharge = parseFloat(selectedDeliveryData.charge);
    if (!isFinite(deliveryCharge)) throw new Error("Delivery cost is invalid");

    // eslint-disable-next-line max-len
    const _carrierBagCharge =
      !isEmpty(extraCharges) && parseFloat(extraCharges?.carrierBagCharge)
        ? extraCharges.carrierBagCharge
        : 0;

    // eslint-disable-next-line max-len
    const paymentAmount =
      parseFloat(totalPriceItems) +
      deliveryCharge +
      parseFloat(serviceCharge) +
      parseFloat(_carrierBagCharge);
    if (!isFinite(paymentAmount)) throw new Error("Payment amount is invalid");

    const dueTime = getDeliveryTimeFormatted(
      selectedDateTime,
      selectedDeliveryTimeType,
      selectedDeliveryData,
    );

    const appliedOrderDiscounts = appliedDiscounts.map((discount) => ({
      id: discount.data.id,
      title: discount.data.title,
      amount: parseFloat(discount.discountAmount).toFixed(2),
    }));

    return {
      deliveryType: convertDeliveryType(selectedDeliveryMethod),
      dueTime,
      dueMin: selectedDeliveryData?.delayMins,
      deliveryIsAsap: selectedDeliveryTimeType === "ASAP",
      customer: {
        name: "",
        firstName,
        lastName,
        phoneNumber,
        email,
        marketing,
      },
      deliveryAddress: {
        formattedAddress: "",
        doorNo: deliveryLocation.doorNo,
        addressLine1: deliveryLocation?.streetAddressLine1,
        addressLine2: deliveryLocation?.streetAddressLine2,
        city: deliveryLocation?.city,
        postcode: deliveryLocation?.postalCode,
        county: "",
        extraAddressInfo: deliveryLocation.extraAddressInfo,
        pickupLat: restaurantInfo.lat,
        pickupLon: restaurantInfo.lon,
        deliveryLat:
          selectedDeliveryMethod === "DELIVER" ? deliveryLocation.lat : "0.0",
        deliveryLon:
          selectedDeliveryMethod === "DELIVER" ? deliveryLocation.lng : "0.0",
      },
      paymentAmount: parseFloat(paymentAmount).toFixed(2),
      paymentType: selectedPaymentMethod,
      paymentId: null,
      note: orderNotes,
      serviceCharge,
      deliveryCost: parseFloat(selectedDeliveryData?.charge).toFixed(2),
      discountTotal: totalDiscount.toFixed(2),
      version: JSON.parse(sessionVersion),
      items,
      discountIds,
      extraCharges,
      discountInfo: appliedOrderDiscounts,
    };
  } catch (error) {
    console.error("error", error);
    return {};
  }
};

export const showStoreHomeNotification = (isOpenNow, isOrderingActive) => {
  if (!isOrderingActive) return true;
  if (!isOpenNow) return true;
  return false;
};

export function getOrderState(orderState) {
  switch (orderState) {
    case "new":
      return 0;
    case "pos":
      return 0;
    case "accepted":
      return 1;
    case "ready":
      return 2;
    default:
      return 0;
  }
}
