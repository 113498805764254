import React from "react";

import { Button, Dialog, makeStyles, Typography } from "@material-ui/core";

const DISPLAY_TEXT_1 = "Hey, You're on Mobile";
const DISPLAY_TEXT_2 = "Our online ordering is best viewed in fullscreen";
const BUTTON_TEXT = "Open full screen";

const useStyles = makeStyles((theme) => ({
  OpenFullScreenAlertWrapper: {
    "& .MuiDialog-container": {
      height: "unset",
    },
    "& .MuiDialog-paperFullWidth": {
      padding: theme.spacing(3),
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.menuBackgroundColor,
      color: theme.palette.text.menuTextColor,
    },
  },
  button: {
    marginTop: theme.spacing(2),
    borderRadius: theme.spacing(2),
    height: 52,
    fontSize: 16,
  },
}));

const OpenFullScreenAlert = ({ open, handleOpenFullScreen }) => {
  const classes = useStyles();
  return (
    <Dialog
      classes={{ root: classes.OpenFullScreenAlertWrapper }}
      open={open}
      maxWidth="xs"
      fullWidth
      onClose={null}
      aria-labelledby="options-dialog"
      aria-describedby="options-dialog-content"
    >
      <Typography
        variant="h5"
        gutterBottom
        align="center"
        style={{ fontWeight: "bold" }}
      >
        {DISPLAY_TEXT_1}
      </Typography>
      <Typography variant="h6" gutterBottom align="center">
        {DISPLAY_TEXT_2}
      </Typography>
      <Button
        color="secondary"
        className={classes.button}
        onClick={handleOpenFullScreen}
        variant="contained"
        fullWidth
      >
        {BUTTON_TEXT}
      </Button>
    </Dialog>
  );
};

OpenFullScreenAlert.propTypes = {};
OpenFullScreenAlert.defaultProps = {};

export default OpenFullScreenAlert;
