import React from "react";

import { makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  root: {
    "&.MuiSnackbar-root": {
      [theme.breakpoints.up("md")]: {
        top: theme?.view?.isFixedList ? "300px" : "none",
      },
    },
  },
}));

const AlertMessage = ({
  message,
  setMessage,
  severity,
  autoHideDuration = 6000,
  anchorOrigin,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Snackbar
      open={!!message}
      autoHideDuration={autoHideDuration}
      onClose={() => setMessage("")}
      anchorOrigin={
        matches && theme?.view?.isFixedList
          ? {
            vertical: anchorOrigin || "top",
            horizontal: "center",
          }
          : { vertical: "bottom", horizontal: "center" }
      }
      classes={{ root: classes.root }}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        onClose={() => setMessage("")}
        severity={severity}
      >
        {message}
      </MuiAlert>
    </Snackbar>
  );
};

export default AlertMessage;
