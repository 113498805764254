/* eslint-disable no-nested-ternary */
import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import OtherOrderDetails from "../../@pages/orderStatus/OtherOrderDetails";
import PriceBreakdown from "../../@pages/orderStatus/PriceBeakdown";
import Header from "../../@pages/storeHome/MyOrder/Header";
import OrderCartItems from "./OrderCartItems";

const useStyles = makeStyles((theme) => ({
  deliverySection: {
    // borderTop: `1px solid ${theme.palette.background.boarderColor}`,
    backgroundColor: theme.palette.primary.main,
  },
}));

const OrderSummary = ({ orderData }) => {
  const classes = useStyles();

  const {
    deliveryCost,
    deliveryIsAsap,
    deliveryType,
    discountTotal,
    dueTime,
    items: cartItems,
    paymentAmount,
    serviceCharge,
    note,
    paymentType,
    extraCharges,
  } = orderData;

  return (
    <>
      <Header isOrderStatusPage cartItemsOrdered={cartItems} />
      <OrderCartItems cartItems={cartItems} />
      <div id="deliverySection" className={classes.deliverySection}>
        <PriceBreakdown
          deliveryCharge={deliveryCost}
          totalDiscount={discountTotal}
          totalPaymentAmount={paymentAmount}
          serviceCharge={serviceCharge}
          deliveryType={deliveryType}
          extraCharges={extraCharges}
        />
        <OtherOrderDetails
          deliveryType={deliveryType}
          paymentType={paymentType}
          deliveryIsAsap={deliveryIsAsap}
          dueTime={dueTime}
          note={note}
          isShowTime
        />
      </div>
    </>
  );
};

export default OrderSummary;
