import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

import CartItemCard from "../CartItemCard";

const useStyles = makeStyles((theme) => ({
  cartItemsSection: {
    minHeight: 254,
    overflowX: "hidden",
    backgroundColor: theme.palette.primary.main,
    flex: 1,
    overflowY: "auto",
    [theme.breakpoints.down("xs")]: {
      height: "calc(100% - 172px)",
    },
    [theme.breakpoints.up("sm")]: {
      maxHeight: 342,
      ...theme.palette.scrollbarStyles,
      "&::-webkit-scrollbar": {
        width: 0,
      },
    },

  },
}));

const CartItemList = () => {
  const { cartItems } = useSelector((state) => state.cart);
  const classes = useStyles();

  return (
    <div className={classes.cartItemsSection}>
      {cartItems.map((item, i) => (
        <CartItemCard
          cartItemData={item}
          key={`cartItemCard-${String(i)}`}
          idx={i}
        />
      ))}
    </div>
  );
};

export default CartItemList;
