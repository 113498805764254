import React from "react";

import { Divider, Icon, makeStyles, Typography } from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import PhoneIcon from "@material-ui/icons/Phone";
import RoomIcon from "@material-ui/icons/Room";
import StoreIcon from "@material-ui/icons/Store";

import {
  DELIVERY_TYPE_DELIVER,
  DELIVERY_TYPE_PICKUP,
} from "../../utils/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0 16px",
    [theme.breakpoints.up("sm")]: {
      margin: "0 48px",
    },
  },
  flexLeftCenter: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
}));

const formatAddress = (address, deliveryType) => {
  if (deliveryType === DELIVERY_TYPE_PICKUP) return "PICKUP";
  if (deliveryType === DELIVERY_TYPE_DELIVER) {
    const {
      addressLine1,
      addressLine2,
      city,
      postcode,
      doorNo,
      extraAddressInfo,
    } = address;
    return (
      <div>
        {doorNo && (
          <span>
            {" "}
            {doorNo}
            ,&nbsp;{" "}
          </span>
        )}
        {addressLine1}
        ,&nbsp;
        {addressLine2}
        <br />
        {city}
        ,&nbsp;
        {postcode}
        <br />
        {extraAddressInfo && <b>{extraAddressInfo}</b>}
      </div>
    );
  }
  return "";
};

const RestaurantContactInfo = ({
  deliveryAddress,
  customer,
  deliveryType,
  businessDisplayName,
  contactNo,
  addressFormatted,
}) => {
  const classes = useStyles();
  const { firstName, lastName, phoneNumber } = customer;
  return (
    <div className={classes.root}>
      <Typography
        gutterBottom
        variant="subtitle1"
        component="div"
        className={classes.flexLeftCenter}
      >
        <Icon>
          <AccountCircleIcon />
        </Icon>
        <span style={{ margin: 4 }} />
        {firstName} {lastName}
      </Typography>
      <Typography
        gutterBottom
        variant="subtitle1"
        component="div"
        className={classes.flexLeftCenter}
      >
        <Icon>
          <PhoneIcon />
        </Icon>
        <span style={{ margin: 4 }} />
        {phoneNumber}
      </Typography>
      {deliveryType === DELIVERY_TYPE_DELIVER && (
        <Typography
          gutterBottom
          variant="subtitle1"
          component="div"
          className={classes.flexLeftCenter}
        >
          <Icon>
            <RoomIcon />
          </Icon>
          <span style={{ margin: 4 }} />
          {formatAddress(deliveryAddress, deliveryType)}
        </Typography>
      )}

      <Divider
        style={{
          margin: "16px 40px",
          height: 1,
          // backgroundColor: theme.palette.primary.main,
        }}
        variant="middle"
      />

      <Typography
        gutterBottom
        variant="subtitle1"
        component="div"
        className={classes.flexLeftCenter}
      >
        <Icon>
          <StoreIcon />
        </Icon>
        <span style={{ margin: 4 }} />
        {businessDisplayName}
      </Typography>
      <Typography
        gutterBottom
        variant="h5"
        component="div"
        className={classes.flexLeftCenter}
      >
        {contactNo}
      </Typography>
      <Typography
        gutterBottom
        variant="subtitle2"
        component="div"
        className={classes.flexLeftCenter}
      >
        {addressFormatted}
      </Typography>
    </div>
  );
};

RestaurantContactInfo.propTypes = {};
RestaurantContactInfo.defaultProps = {};

export default RestaurantContactInfo;
