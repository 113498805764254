/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";

import _ from "lodash";
import { useSelector } from "react-redux";

import {
  isMatchAvailability,
  isMatchDeliveryMethod,
} from "../../../../../utils/utils";
import PromoBanner from "./PromoBanner";

const PromoBanners = () => {
  const [filteredBanners, setFilteredBanners] = useState([]);

  const { theme: storeTheme } = useSelector((state) => state.initData);
  const { selectedDateTime, selectedDeliveryMethod } = useSelector(
    (state) => state.cart,
  );

  useEffect(() => {
    const getBanners = (banners) => {
      const bannersByDeliveryMethod = banners.filter(
        (banner) =>
          banner &&
          isMatchDeliveryMethod(selectedDeliveryMethod, banner?.deliveryTypes),
      );
      return bannersByDeliveryMethod.filter(
        (banner) =>
          banner && isMatchAvailability(selectedDateTime, banner?.availability),
      );
    };

    const _banners = getBanners(storeTheme.banners);
    setFilteredBanners(_banners);
  }, [selectedDateTime, selectedDeliveryMethod, storeTheme.banners]);

  return (
    <>
      {!_.isEmpty(filteredBanners) &&
        filteredBanners.map(
          ({
            title,
            subtitle,
            isActive,
            backgroundColor,
            textColor,
            availability,
            deliveryTypes,
          }) => (
            <PromoBanner
              key={title}
              title={title}
              subtitle={subtitle}
              isActive={isActive}
              backgroundColor={backgroundColor}
              textColor={textColor}
              availability={availability}
              deliveryTypes={deliveryTypes}
            />
          ),
        )}
    </>
  );
};

PromoBanners.propTypes = {};
PromoBanners.defaultProps = {};

export default PromoBanners;
