import React from "react";

import RoomIcon from "@material-ui/icons/Room";

import IconLabel from "../labels/IconLabel";

const PickupOnlyBadge = () => (
  <IconLabel
    text="Pickup"
    icon={<RoomIcon />}
    tooltipContent="This item is available for pickup"
  />
);

export default PickupOnlyBadge;
