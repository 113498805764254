/* eslint-disable no-nested-ternary */
import React from "react";

import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

import {
  ASAP_TIME_LABEL,
  SPECIFIC_TIME_LABEL,
} from "../../../../utils/constants";
import {
  convertDeliveryTypeToTypeLabel,
  getPaymentLabel } from "../../../../utils/utils";

const useStyles = makeStyles((theme) => ({
  OtherOrderDetailsWrapper: {
    padding: theme.spacing(0, 2, 2, 2),
    color: theme.palette.primary.contrastText,
  },
}));

const OtherOrderDetails = ({
  deliveryType,
  paymentType,
  deliveryIsAsap,
  dueTime,
  note,
  isShowTime,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.OtherOrderDetailsWrapper}>
      <Typography component="div" variant="subtitle2">
        Order type:&nbsp;
        <Typography
          component="span"
          variant="subtitle2"
          style={{ fontWeight: "bold" }}
        >
          {convertDeliveryTypeToTypeLabel(deliveryType)}
        </Typography>
      </Typography>
      <Typography component="div" variant="subtitle2">
        Payment type:&nbsp;
        <Typography
          component="span"
          variant="subtitle2"
          style={{ fontWeight: "bold" }}
        >
          {getPaymentLabel(paymentType)}
        </Typography>
      </Typography>
      {isShowTime && (
        <Typography component="div" variant="subtitle2">
          Order time:&nbsp;
          <Typography
            component="span"
            variant="subtitle2"
            style={{ fontWeight: "bold" }}
          >
            {deliveryIsAsap
              ? ASAP_TIME_LABEL
              : `${SPECIFIC_TIME_LABEL}(${moment(dueTime).format(
                "hh:mma dddd DD MMM",
              )})`}
          </Typography>
        </Typography>
      )}
      {note && (
        <Typography component="div" variant="subtitle2">
          Order notes:&nbsp;
          <Typography
            component="span"
            variant="subtitle2"
            style={{ fontWeight: "bold" }}
          >
            {note}
          </Typography>
        </Typography>
      )}
    </div>
  );
};

OtherOrderDetails.propTypes = {};
OtherOrderDetails.defaultProps = {};

export default OtherOrderDetails;
