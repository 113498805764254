import React from "react";

import { Grid, makeStyles, Typography } from "@material-ui/core";

import imageCooking from "../../../../assets/images/cooking.png";
import imageReady from "../../../../assets/images/ready.png";
import imageWaiting from "../../../../assets/images/waiting.png";
import { DELIVERY_TYPE_DINEIN } from "../../../../utils/constants";
import { getOrderState } from "../../../../utils/order";
import moment from "../../../../utils/time/moment";
import { convertDeliveryTypeToTypeLabel } from "../../../../utils/utils";

const STATUS_IMAGE_SIZE = 160;

const useStyles = makeStyles((theme) => ({
  PageHeadingWrapper: {
    marginTop: "20px",
    textAlign: "center",
    color: theme.palette.common.white,
  },
  orderNumber: {
    textAlign: "left",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  statusOrderDetails: {
    padding: theme.spacing(1, 2),
    display: "flex",
    justifyContent: "flex-start",
    border: `1px solid ${theme.palette.background.border}`,
    flexDirection: "column",
    alignItems: "center",
  },
  flexCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  statusImage: {
    width: STATUS_IMAGE_SIZE,
    height: STATUS_IMAGE_SIZE,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  statusImageGrid: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

function getOrderStatusImage(orderStatus) {
  switch (getOrderState(orderStatus)) {
    case 0:
      return (
        <img
          src={imageWaiting}
          style={{ width: " 100%" }}
          alt="Your order is being prepared"
        />
      );
    case 1:
      return (
        <img
          src={imageCooking}
          style={{ width: " 100%" }}
          alt="Your order is being prepared"
        />
      );
    case 2:
      return (
        <img
          src={imageReady}
          style={{ width: " 100%" }}
          alt="Your order is being prepared"
        />
      );
    default:
      return "";
  }
}

const PageHeading = ({
  orderNumber,
  orderTime,
  deliveryType,
  orderStatus,
  deliveryAddress,
  statusColor,
}) => {
  const classes = useStyles({ statusColor });
  const { extraAddressInfo } = deliveryAddress;
  return (
    <>
      <div className={classes.PageHeadingWrapper}>
        <div className={classes.statusOrderDetails}>
          <Grid container>
            <Grid item xs={6} sm={6} className={classes.flexCenter}>
              <Typography
                gutterBottom
                variant="subtitle2"
                component="div"
                style={{ fontWeight: "bold" }}
              >
                <span style={{ width: 4 }} />
                Order #
                {orderNumber}
              </Typography>
              <Typography
                variant="h6"
                component="div"
                style={{ fontWeight: "bold" }}
              >
                {convertDeliveryTypeToTypeLabel(deliveryType)}
              </Typography>
              {deliveryType === DELIVERY_TYPE_DINEIN ? (
                <Typography
                  variant="h3"
                  component="div"
                  style={{ fontWeight: "bold" }}
                >
                  {`Table ${extraAddressInfo}`}
                </Typography>
              ) : (
                <Typography variant="h3" component="div">
                  {moment(orderTime).format("hh:mma")}
                </Typography>
              )}
              <Typography variant="h6" component="div">
                {moment(orderTime).format("dddd")}
                &nbsp;
                {moment(orderTime).format("DD")}
                &nbsp;
                {moment(orderTime).format("MMM")}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} className={classes.statusImageGrid}>
              <div className={classes.statusImage}>
                {getOrderStatusImage(orderStatus)}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

PageHeading.propTypes = {};
PageHeading.defaultProps = {};

export default PageHeading;
