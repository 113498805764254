import React, { useEffect, useState } from "react";

import {
  Box,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import { makeStyles } from "@material-ui/core/styles";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import DeleteIcon from "@material-ui/icons/Delete";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { connect } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { bindActionCreators } from "redux";

import { logInfo } from "../../../../../services/logging";
import { formatPrice } from "../../../../../utils/utils";
import RenderAvailabilityBadges from "../../../../common/badges/RenderAvailabilityBadges";
import IconButtonDefault from "../../../../common/buttons/IconButtonDefault";
import CartItemBuildYourItem from "../CartItemBuildYourItem";
import CartItemMultiDirect from "../CartItemMultiDirect";
import {
  deleteItemFromCartAsync,
  setCartItems,
  updateItemFromCartAsync,
} from "../redux/actions";

const useStyle = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0.5, 1),
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    borderRadius: theme.shape.borderRadius,
  },
  cartItem: {
    // padding: 8,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // minHeight: 50,
  },
  cartItemCard: {
    minHeight: 52,
    padding: theme.shape.borderRadius,
    [theme.breakpoints.down("md")]: {
      alignItems: "flex-start",
    },
    // margin: theme.spacing(1, 0, 1, 1),
    [theme.breakpoints.down("sm")]: {
      // marginRight: 8,
    },
  },
  cardOuter: {
    flex: 1,
    [theme.breakpoints.down("md")]: {
      marginTop: 4,
    },
  },
  error: {
    padding: 4,
    marginTop: 4,
    minHeight: 22,
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.common.white,
    borderRadius: `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`,
  },
  alignCenter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  removeButton: {
    padding: 2,
    marginLeft: 4,
    borderRadius: theme.shape.borderRadius,
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

const CartItemCard = ({ cartItemData, idx, actions }) => {
  const [show, setShow] = useState(false);
  const [isItemEditable, setIsItemEditable] = useState(false);

  const theme = useTheme();
  const classes = useStyle();
  const { path } = useRouteMatch();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (path.includes("shop")) {
      setIsItemEditable(true);
    }
  }, [path]);

  const { itemData, quantity, totalPrice, type, oneUnitPrice } = cartItemData;

  const handleRemove = () => {
    if (quantity > 1) {
      actions.updateItemFromCartAsync(idx, {
        quantity: quantity - 1,
        totalPrice: parseFloat(oneUnitPrice) * (quantity - 1),
      });
    } else actions.deleteItemFromCartAsync(idx);
    logInfo({ message: "Removed item from cart", cartItemData });
  };

  const handleDeleteItem = () => {
    actions.deleteItemFromCartAsync(idx);
    logInfo({ message: "Deleted item from cart", cartItemData });
  };

  const handleInc = () => {
    if (quantity < 99) {
      actions.updateItemFromCartAsync(idx, {
        quantity: quantity + 1,
        totalPrice: parseFloat(oneUnitPrice) * (quantity + 1),
      });
    }
  };

  const showQtyChange = show || matches;

  return (
    <Box
      id={`cartItemCard-${idx}`}
      className={classes.root}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      <div className={clsx(classes.alignCenter, classes.cartItemCard)}>
        <div className={classes.cardOuter}>
          <div className={classes.cartItem}>
            <Typography
              variant="body2"
              component="div"
              style={{ flex: 1, display: "flex", alignItems: "center" }}
            >
              <span>
                {quantity}
                &nbsp;
              </span>
              <Icon fontSize="inherit">close</Icon>
            </Typography>
            <Typography
              variant="body2"
              component="div"
              style={{ flex: 5, fontWeight: "bold" }}
            >
              {itemData.title}
            </Typography>
            <Typography
              variant="body2"
              component="div"
              style={{ flex: 2, textAlign: "end" }}
            >
              {formatPrice(totalPrice)}
            </Typography>
          </div>

          <div style={{ display: "flex" }}>
            <span style={{ flex: 1, textAlign: "right" }} />
            <div style={{ flex: 7 }}>
              {type === "multiDirect" && (
                <CartItemMultiDirect cartItemData={cartItemData} />
              )}
              {type === "buildYourItem" && (
                <CartItemBuildYourItem cartItemData={cartItemData} />
              )}
            </div>
          </div>
        </div>
        {isItemEditable && !cartItemData?.isNotAvailable && (
          <div>
            <AnimatePresence>
              {showQtyChange && (
                <motion.div
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: 5,
                  }}
                  initial={{ opacity: 0, width: 0 }}
                  animate={{ opacity: 1, width: 20 }}
                  exit={{ opacity: 0, width: 0 }}
                >
                  <IconButtonDefault onClick={handleInc}>
                    <ControlPointIcon fontSize="small" />
                  </IconButtonDefault>
                  <IconButtonDefault onClick={handleRemove}>
                    {quantity > 1 ? (
                      <RemoveCircleOutlineIcon fontSize="small" />
                    ) : (
                      <HighlightOffIcon fontSize="small" />
                    )}
                  </IconButtonDefault>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        )}
      </div>
      {cartItemData?.isNotAvailable && (
        <div className={clsx(classes.alignCenter, classes.error)}>
          <RenderAvailabilityBadges
            isOutOfStock={!cartItemData?.isNotAvailable}
            availability={cartItemData?.option?.availability}
            deliveryTypes={cartItemData?.option?.deliveryTypes}
          />
          <div className={classes.alignCenter} style={{ fontSize: 13 }}>
            <IconButton className={classes.removeButton} onClick={handleDeleteItem}>
              <Typography component="span" variant="overline">
                Unavailable
              </Typography>
              <DeleteIcon style={{ color: "#fff" }} fontSize="small" />
            </IconButton>
          </div>
        </div>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  cart: state.cart,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    { setCartItems, updateItemFromCartAsync, deleteItemFromCartAsync },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(CartItemCard);
