/* eslint-disable react/jsx-props-no-spreading */
import React from "react";

import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    backgroundColor: ({ bgColor }) => bgColor,
    color: ({ textColor }) => textColor,
    borderRadius: theme.shape.borderRadius,
    boxShadow: "none",
    border: ({ border }) =>
      `2px solid ${border}`,
    "&.MuiButton-root": {
      // borderRadius: 4,
    },
    "&.Mui-disabled": {
      backgroundColor: theme.palette.secondary.dark,
      color: theme.palette.text.disabled,
    },
    "&:hover": {
      backgroundColor: ({ bgColor }) => bgColor,
    },
  },
}));

const ButtonDefault = ({ onClick, children, bgColor, textColor, border, style, ...props }) => {
  const classes = useStyles({ bgColor, textColor, border });
  return (
    <Button
      variant={"contained"}
      classes={{ root: classes.root }}
      onClick={onClick}
      style={style}
      {...props}
    >
      {children}
    </Button>
  );
};

export default ButtonDefault;
