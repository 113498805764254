import React, { memo } from "react";

import { Grid, makeStyles, Typography, useTheme } from "@material-ui/core";
import Color from "color";
import { motion } from "framer-motion";
import Truncate from "react-truncate";

import useViewport from "../../../../../../hooks/useViewport";
import PriceUtils from "../../../../../../utils/PriceUtils";
import MenuItemImage from "../../../../../common/images/MenuItemImage";

const useStyles = makeStyles((theme) => {
  const bg = Color(theme.palette.background.menuBackgroundColor).isDark()
    ? Color(theme.palette.background.menuBackgroundColor)
      .mix(Color("#fff"), 0.1)
      .hex()
    : Color(theme.palette.background.menuBackgroundColor)
      .mix(Color("#000"), 0.1)
      .hex();

  return {

    MenuItemCardGridWrapper: {
      cursor: "pointer",
      position: "relative",
      paddingBottom: 64,
      backgroundColor: bg,
      borderRadius: theme.shape.borderRadius,
      margin: theme.spacing(1),
      maxWidth: 388,
    },
    imageWrapper: {
      // borderRadius: theme.shape.borderRadius,
    },
    itemDetailsWrapper: {
      position: "absolute",
      bottom: 8,
      width: "calc(100% - 16px)",
      backgroundColor: bg,
      textAlign: "left",
      padding: theme.spacing(1),
      overflow: "hidden",
      borderRadius: `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`,
    },
    image: {
      width: "100%",
      height: "100%",
      borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
    },
    itemTitle: {
      overflow: "hidden",
      color: theme.palette.text.itemTextColor,
      whiteSpace: "nowrap",
    },
    price: {
      color: theme.palette.text.itemTextColor,
    },
  };
});

const MenuItemCardGrid = ({
  menuItemData,
  id,
  title,
  desc,
  setOpenMenuItemFunc,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const priceDisplayText = PriceUtils.getMenuItemPrice(menuItemData);

  const { isDesktopView } = useViewport();

  const { data } = menuItemData;
  const [firstItem] = data;

  const handleClick = () => {
    setOpenMenuItemFunc(menuItemData);
  };

  return (
    <Grid item md={4} lg={3} sm={6} xs={6}>
      <motion.div
        whileHover="hover"
        animate="rest"
        initial="rest"
        component="div"
        id={id}
        className={classes.MenuItemCardGridWrapper}
        elevation={2}
        onClick={handleClick}
      >
        <div className={classes.imageWrapper}>
          <MenuItemImage
            src={firstItem.imageUrl}
            alt="menu item"
            curved="top"
            maxImageWidth={290}
            maxImageHeight={222}
          />
        </div>

        <motion.div
          className={classes.itemDetailsWrapper}
          variants={{
            rest: { height: 48 },
            hover: { height: 120 },
          }}
        >
          <Typography
            variant="subtitle1"
            component="div"
            align="left"
            className={classes.itemTitle}
          >
            <Truncate lines={isDesktopView ? 2 : 1}>
              {title}
            </Truncate>
          </Typography>

          <Typography
            variant="subtitle1"
            component="div"
            align="left"
            className={classes.price}
          >
            {priceDisplayText}
          </Typography>
          {desc && (
            <Typography
              variant="caption"
              align="left"
              style={{ color: theme.palette.text.itemDescColor }}
            >
              {desc}
            </Typography>
          )}
        </motion.div>
      </motion.div>
    </Grid>
  );
};

MenuItemCardGrid.propTypes = {};
MenuItemCardGrid.defaultProps = {};

function cardPropsEqual(prevProps, nextProps) {
  return prevProps.id === nextProps.id;
}

export default memo(MenuItemCardGrid, cardPropsEqual);
