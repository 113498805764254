import React from "react";

import { Typography, IconButton, Icon } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

const QuantitySection = ({ quantity, setQuantity }) => {
  const theme = useTheme();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "4px 16px",
        bottom: 48,
        position: "absolute",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        left: 0,
        right: 0,
      }}
    >
      <Typography variant="h6" component="p">
        Quantity
      </Typography>
      <div
        style={{
          margin: "8px 0",
          display: "flex",
          alignItems: "center",
        }}
      >
        <IconButton
          color="secondary"
          style={{ padding: "0 8px 0 0" }}
          aria-label="decrement"
          onClick={() => {
            if (quantity > 1) setQuantity(quantity - 1);
          }}
        >
          <Icon>remove_circle</Icon>
        </IconButton>
        <Typography variant="h6" color="textPrimary" component="p">
          {quantity}
        </Typography>
        <IconButton
          color="secondary"
          style={{ padding: "0 0 0 8px" }}
          aria-label="increment"
          onClick={() => {
            if (quantity < 99) setQuantity(quantity + 1);
          }}
        >
          <Icon>add_circle </Icon>
        </IconButton>
      </div>
    </div>
  );
};

export default QuantitySection;
