import _ from "lodash";

const FacebookPixel = {
  // content_ids, content_category, content_name, content_type, contents, currency, value
  viewContent: (data, categorySelected) => {
    try {
      const { title: catName } = categorySelected;
      const { menuId, title: itemName, data: itemNode } = data;

      window.fbq("track", "ViewContent", {
        content_ids: [menuId],
        content_category: catName,
        content_name: itemName,
        content_type: "product",
        currency: "GBP",
        value: parseFloat(itemNode.price),
      });
    } catch {
      console.error("Error pixel analytics");
    }
  },

  addToCart: (cartItem, categorySelected) => {
    try {
      const { title: catName } = categorySelected;
      const {
        itemData: { menuId, title },
        quantity,
        totalPrice,
      } = cartItem;

      window.fbq("track", "AddToCart", {
        content_ids: [menuId],
        content_category: catName,
        content_name: title,
        content_type: "product",
        quantity,
        currency: "GBP",
        value: parseFloat(totalPrice),
      });
    } catch {
      console.error("Error pixel analytics");
    }
  },

  initiateCheckout: (cartItems, totalPriceItems) => {
    try {
      const ids = cartItems.map((cartItem) => cartItem.itemData.menuId);
      const cartItemCount = _.sum(cartItems.map((i) => i.quantity));

      window.fbq("track", "InitiateCheckout", {
        content_ids: ids,
        contents: [],
        currency: "GBP",
        num_items: cartItemCount,
        value: parseFloat(totalPriceItems),
      });
    } catch {
      console.error("Error pixel analytics");
    }
  },

  addPaymentInfo: (cartItems, paymentAmount, paymentMethod) => {
    try {
      const ids = cartItems.map((cartItem) => cartItem.itemData.menuId);
      window.fbq("track", "AddPaymentInfo", {
      // content_category: "payments",
        content_ids: ids,
        contents: [],
        currency: "GBP",
        value: parseFloat(paymentAmount),
        paymentMethod,
      });
    } catch {
      console.error("Error pixel analytics");
    }
  },

  purchase: (cartItems, paymentAmount) => {
    try {
      const ids = cartItems.map((cartItem) => cartItem.itemData.menuId);
      const cartItemCount = _.sum(cartItems.map((i) => i.quantity));

      window.fbq("track", "Purchase", {
        content_ids: ids,
        // content_name,
        // content_type,
        contents: [],
        currency: "GBP",
        num_items: cartItemCount,
        value: parseFloat(paymentAmount),
      });
    } catch (error) {
      console.error("Error pixel analytics");
    }
  },
};

export default FacebookPixel;
