import React, { useEffect, useState } from "react";

import {
  Divider,
  Hidden,
  Typography,
  useTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import _, { isEmpty } from "lodash";
import { connect, useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";

import { removeLocalStore } from "../../../../../utils/storage/localStorage";
import ButtonContained from "../../../../common/buttons/ButtonContained";
import UserAccount from "../../UserAccount";
import ItemCount from "../ItemCount";
import { setCartItems } from "../redux/actions";
import LoginDialog from "../../LoginDialog";

const useStyles = makeStyles((theme) => ({
  Header: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.down("sm")]: {
      borderRadius: 0,
    },
  },
  boxTop: {
    // borderBottom: `1px solid ${theme.palette.background.boarderColor}`,
    display: "flex",
    height: 40,
    padding: theme.spacing(1),
    margin: theme.spacing(0, 1),
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(0),
      // height: "90%",
    },
  },
}));

const Header = ({
  cart: { cartItems },
  isOrderStatusPage,
  cartItemsOrdered,
  isCartEditable,
}) => {
  const [showAccountTab, setShowAccountTab] = useState(false);
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const theme = useTheme();

  useEffect(() => {
    if (path.includes("shop")) {
      setShowAccountTab(false);
    } else {
      setShowAccountTab(true);
    }
  }, [path]);

  const classes = useStyles();
  const [itemCount, setItemCount] = useState(0);

  const handleClearCart = () => {
    removeLocalStore("orderId");
    dispatch(setCartItems([]));
  };

  useEffect(() => {
    if (isOrderStatusPage && !_.isEmpty(cartItemsOrdered)) {
      const cartItemCount = _.sum(cartItemsOrdered.map((i) => i.quantity));
      return setItemCount(cartItemCount);
    }
    if (isEmpty(cartItems)) return setItemCount(0);
    const cartItemCount = _.sum(cartItems.map((i) => i.quantity));
    return setItemCount(cartItemCount);
  }, [cartItems, cartItemsOrdered, isOrderStatusPage]);

  return (
    <div className={classes.Header}>
      <Hidden smDown>
        {showAccountTab && (
          <>
            <LoginDialog />
            <UserAccount />
            <Divider
              style={{ backgroundColor: theme.palette.background.boarderColor }}
            />
          </>
        )}
      </Hidden>
      <div className={classes.boxTop}>
        <ItemCount count={itemCount} />
        {itemCount && isCartEditable ? (
          <ButtonContained height="32px" onClick={handleClearCart}>
            Clear order
          </ButtonContained>
        ) : (
          <Typography variant="subtitle1">My Order</Typography>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  cart: state.cart,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
