import React from "react";

import DirectionsBikeIcon from "@material-ui/icons/DirectionsBike";

import IconLabel from "../labels/IconLabel";

const DeliveryOnlyBadge = () => (
  <IconLabel
    text="Delivery"
    icon={<DirectionsBikeIcon />}
    tooltipContent="This item is available for delivery"
  />
);

export default DeliveryOnlyBadge;
