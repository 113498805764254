import { SET_CUSTOMER_DETAILS } from "./actions";

export const ContactInitialState = {
  customer: {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    marketing: null,
  },
};

export default (state = ContactInitialState, action) => {
  switch (action.type) {
    case SET_CUSTOMER_DETAILS:
      return {
        ...state,
        customer: action.payload,
      };

    default:
      return state;
  }
};
