import moment from "../../../../../utils/time/moment";
import { formatDate } from "../../../../../utils/utils";
import {
  ADD_ITEM_TO_CART_ERROR, ADD_ITEM_TO_CART_PENDING,
  ADD_ITEM_TO_CART_SUCCESS,

  DELETE_ITEM_FROM_CART_ERROR, DELETE_ITEM_FROM_CART_PENDING,
  DELETE_ITEM_FROM_CART_SUCCESS, FETCH_CART_ITEMS_ERROR, FETCH_CART_ITEMS_PENDING,
  FETCH_CART_ITEMS_SUCCESS,

  SET_APPLIED_COUPON_CODE, SET_CART_ITEMS,

  SET_CART_SUBTOTAL, SET_DELIVERY_DATA,

  SET_DELIVERY_LOCATION,

  SET_DISCOUNT_ATTACHED_CART_ITEMS, SET_DISCOUNT_INFO, SET_FILTERED_OPTION_LIST_BY_AVAILABILITY,

  SET_LOCATION_ID, SET_OPEN_TIMES,

  SET_ORDER_DISCOUNTS,

  SET_ORDER_NOTES, SET_PAYMENT_AMOUNT, SET_SELECTED_DATE_TIME,

  SET_SELECTED_DELIVERY_DATA, SET_SELECTED_DELIVERY_METHOD, SET_SELECTED_DELIVERY_TIME_TYPE,

  SET_SUB_TOTAL,
  SET_APPLIED_DISCOUNTS,
  SET_TOTAL_DISCOUNT, UPDATE_ITEM_FROM_CART_ERROR,

  UPDATE_ITEM_FROM_CART_PENDING, UPDATE_ITEM_FROM_CART_SUCCESS,
} from "./actions";

const preSelectedTime = moment();
preSelectedTime.add(1, "hours");

export const CartInitialState = {
  locationId: "",
  cartItems: [],
  discountAttachedCartItems: [],
  orderDiscounts: [],
  isCouponApplied: false,
  appliedCouponCode: "",
  appliedDiscounts: [],
  subtotal: 0,
  orderNotes: "",
  totalDiscount: 0,
  totalPriceItems: 0,
  deliveryData: [],
  openTimes: {},
  discountInfo: [],
  selectedDeliveryMethod: "", // pickup, delivery or dinein
  selectedDeliveryTimeType: "ASAP", // ASAP or SPECTIME (specific time)
  selectedDateTime: formatDate(preSelectedTime), // selected_time if SPECTIME or delay_time if ASAP
  selectedDeliveryData: {},
  filteredDeliveryOptionListByAvail: [],
  deliveryLocation: {
    street_number: "",
    street_address: "",
    route: "",
    postal_town: "",
    sublocality: "",
    sublocality_level_1: "",
    postal_code: "",

    doorNo: "",
    streetAddressLine1: "",
    streetAddressLine2: "",
    city: "",
    postalCode: "",

    lat: 0,
    lng: 0,

    isAddressSelected: false,
    extraAddressInfo: "",
  },
  isFetching: false,
  isAdding: false,
  isDeleting: false,
  isUpdating: false,
  error: null,
};

export function CartReducer(state = CartInitialState, action) {
  switch (action.type) {
    //   fetch cart items
    case FETCH_CART_ITEMS_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_CART_ITEMS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        cartItems: action.payload,
      };
    case FETCH_CART_ITEMS_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };

    //   add item to cart
    case ADD_ITEM_TO_CART_PENDING:
      return {
        ...state,
        isAdding: true,
      };
    case ADD_ITEM_TO_CART_SUCCESS:
      return {
        ...state,
        isAdding: false,
        cartItems: [...state.cartItems, action.payload],
      };
    case ADD_ITEM_TO_CART_ERROR:
      return {
        ...state,
        isAdding: false,
        error: action.payload,
      };

    //   delete item from cart
    case DELETE_ITEM_FROM_CART_PENDING:
      return {
        ...state,
        isDeleting: true,
      };
    case DELETE_ITEM_FROM_CART_SUCCESS: {
      const cartItems = [...state.cartItems];
      cartItems.splice(action.idx, 1);
      return {
        ...state,
        isDeleting: false,
        cartItems,
      };
    }
    case DELETE_ITEM_FROM_CART_ERROR:
      return {
        ...state,
        isDeleting: false,
        error: action.payload,
      };

    // update cart item
    case UPDATE_ITEM_FROM_CART_PENDING:
      return {
        ...state,
        isUpdating: true,
      };
    case UPDATE_ITEM_FROM_CART_SUCCESS: {
      const { idx, changes } = action;
      const cartItemsUpdated = [...state.cartItems];
      cartItemsUpdated.splice(idx, 1, { ...state.cartItems[idx], ...changes });

      return {
        ...state,
        isUpdating: false,
        cartItems: cartItemsUpdated,
      };
    }
    case UPDATE_ITEM_FROM_CART_ERROR:
      return {
        ...state,
        isUpdating: false,
        error: action.payload,
      };

    case SET_CART_ITEMS:
      return {
        ...state,
        cartItems: action.payload,
      };

    // delivery info
    case SET_DELIVERY_DATA:
      return {
        ...state,
        deliveryData: action.payload,
      };
    case SET_DISCOUNT_INFO:
      return {
        ...state,
        discountInfo: action.payload,
      };
    case SET_SELECTED_DELIVERY_TIME_TYPE:
      return {
        ...state,
        selectedDeliveryTimeType: action.payload,
      };
    case SET_SELECTED_DATE_TIME:
      return {
        ...state,
        selectedDateTime: action.payload,
      };
    case SET_SELECTED_DELIVERY_METHOD:
      if (action.payload === "DINEIN" || action.payload === "PICKUP") {
        return {
          ...state,
          selectedDeliveryData: {},
          selectedDeliveryMethod: action.payload,
        };
      }
      return {
        ...state,
        selectedDeliveryMethod: action.payload,
      };
    case SET_OPEN_TIMES:
      return {
        ...state,
        openTimes: action.payload,
      };
    case SET_SELECTED_DELIVERY_DATA:
      return {
        ...state,
        selectedDeliveryData: action.payload,
      };
    case SET_DELIVERY_LOCATION:
      return {
        ...state,
        deliveryLocation: action.payload,
      };
    case SET_CART_SUBTOTAL:
      return {
        ...state,
        subtotal: action.payload,
      };
    case SET_ORDER_DISCOUNTS:
      return {
        ...state,
        orderDiscounts: action.payload,
      };
    case SET_APPLIED_COUPON_CODE:
      if (!action.payload) {
        return {
          ...state,
          isCouponApplied: false,
          appliedCouponCode: "",
        };
      }
      return {
        ...state,
        isCouponApplied: true,
        appliedCouponCode: action.payload,
      };
    case SET_SUB_TOTAL:
      return {
        ...state,
        subtotal: action.payload,
      };
    case SET_TOTAL_DISCOUNT:
      return {
        ...state,
        totalDiscount: action.payload,
      };
    case SET_PAYMENT_AMOUNT:
      return {
        ...state,
        totalPriceItems: action.payload,

      };
    case SET_DISCOUNT_ATTACHED_CART_ITEMS:
      return {
        ...state,
        discountAttachedCartItems: action.payload,
      };
    case SET_ORDER_NOTES:
      return {
        ...state,
        orderNotes: action.payload,
      };
    case SET_FILTERED_OPTION_LIST_BY_AVAILABILITY:
      return {
        ...state,
        filteredDeliveryOptionListByAvail: action.payload,
      };
    case SET_LOCATION_ID:
      return {
        ...state,
        locationId: action.payload,
      };
    case SET_APPLIED_DISCOUNTS:
      return {
        ...state,
        appliedDiscounts: action.payload,
      };
    default:
      return state;
  }
}
