import React from "react";

import {
  Hidden,
  makeStyles,
  Typography,
} from "@material-ui/core";

import { Icon } from "@iconify/react";

const useStyles = makeStyles(() => ({
  CashPaymentWrapper: {},
}));

const CashPayment = () => {
  const classes = useStyles();
  return (
    <div className={classes.CashPaymentWrapper}>
      <Hidden xsDown>
        <Typography
          variant="subtitle1"
          style={{
            display: "flex",
            alignItems: "center",
            textTransform: "none",
            fotWeight: "bold",
          }}
        >
          <Icon width="34px" height="34px" icon="mdi:cash" /> Cash
        </Typography>
      </Hidden>
      <Hidden smUp>
        <Typography
          variant="subtitle1"
          style={{
            display: "flex",
            alignItems: "center",
            textTransform: "none",
            fotWeight: "bold",
          }}
        >
          Cash
        </Typography>
      </Hidden>
    </div>
  );
};

CashPayment.propTypes = {};
CashPayment.defaultProps = {};

export default CashPayment;
