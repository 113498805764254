/* eslint-disable max-len */
import React, { useEffect, useState, useImperativeHandle } from "react";

import DateFnsUtils from "@date-io/date-fns";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import "date-fns";
import format from "date-fns/format";
import _, { isEmpty } from "lodash";
import min from "lodash/minBy";
import { isIOS } from "react-device-detect";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

import { logInfo } from "../../../../../services/logging";
import {
  ASAP_TIME_LABEL,
  DELIVERY_TYPE_DELIVER,
  DELIVERY_TYPE_DINEIN,
  DELIVERY_TYPE_PICKUP,
  SPECIFIC_TIME_LABEL,
} from "../../../../../utils/constants";
import moment from "../../../../../utils/time/moment";
import TimeUtils from "../../../../../utils/TimeUtils";
import { formatDate } from "../../../../../utils/utils";
import ToggleButtonDefault from "../../../../common/buttons/ToggleButtonDefault";
import {
  setSelectedDateTime,
  setSelectedDeliveryTimeType,
} from "../redux/actions";
import PrestoTimePicker from "./PrestoTimePicker";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.defaultTextColor,
    padding: theme.spacing(1, 2, 0, 2),
  },
  formControl: {
    marginBottom: 8,
  },
  toggleGroup: {
    display: "flex",
    marginBottom: 8,
  },
  delayMins: {
    marginLeft: 4,
    fontSize: 8,
  },
  pickerDialog: {
    "& .MuiDialog-container": {
      [theme.breakpoints.up("md")]: {
        height: theme?.view?.isFixedList ? "initial" : "100%",
      },
    },
  },
  dialogActions: {
    color: theme.palette.text.primary,
  },
  timeButton: {
    textAlign: "left",
    "& .MuiButton-label": {
      display: "inline",
    },
  },
  modal: {
    "& .MuiDialog-container": {
      [theme.breakpoints.up("md")]: {
        height: theme?.view?.isFixedList ? "initial" : "100%",
      },
    },
    "& .MuiDialog-paper": {
      margin: 4,
    },
  },
}));

const MAX_FUTURE_DATE_LIMIT = 7;

const DeliveryDateTimePicker = React.forwardRef(({ actions, cart, isModal }, ref) => {
  const classes = useStyles();
  const {
    selectedDeliveryTimeType,
    selectedDateTime,
    selectedDeliveryMethod,
    deliveryData,
  } = cart;
  const [showPicker, setShowPicker] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [delayMins, setDelayMins] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [minimumDelayMin, setMinimumDelayMin] = useState(60);

  const [prevSelectedDateTime, setPrevSelectedDateTime] = useState(null);

  const { isPreOrderActive, openTimes } = useSelector((state) => state.store);

  const getOpenSelectedDayOpenTime = (selectedDay) => {
    if (_.isEmpty(selectedDay)) return;
    const day = moment(selectedDay).format("ddd");
    const dayNodes = openTimes?.weekly?.filter(
      (node) => node.day === day.toLowerCase(),
    );
    if (!_.isEmpty(dayNodes)) {
      const earliestNode = _.minBy(dayNodes, (node) => node.minuteOfWeek);
      // eslint-disable-next-line consistent-return
      return earliestNode.openTime;
    }
    // eslint-disable-next-line consistent-return
    return "00:00";
  };

  useEffect(() => {
    setErrorMessage("");
  }, [selectedDateTime]);

  function isValidDate(d) {
    return d instanceof Date && !Number.isNaN(d);
  }

  const handleTimeChange = (time) => {
    if (!isValidDate(time)) return;
    const selectedDate = moment(selectedDateTime).format("YYYY-MM-DD");
    const dateFormatted = format(time, "yyyy-MM-dd HH:mm:ss");
    const selectedTime = moment(dateFormatted).format("HH:mm:ss");
    const timeGap = moment(dateFormatted).diff(moment(), "minutes");

    if (timeGap < minimumDelayMin) {
      logInfo({
        message: `Selected time gap to current time is less than min delay time | minimumDelayMin: ${minimumDelayMin}} | current time: ${selectedTime} | selectedDeliveryMethod: ${selectedDeliveryMethod}`,
      });
      // eslint-disable-next-line consistent-return
      return setErrorMessage(
        `The selected time should not be less than ${minimumDelayMin} minutes from the current time.`,
      );
    }
    actions.setSelectedDateTime(`${selectedDate} ${selectedTime}`);
  };

  useEffect(() => {
    if (isEmpty(deliveryData)) return;
    if (selectedDeliveryMethod === "DINEIN") {
      setDelayMins(
        deliveryData.find((d) => d.type === DELIVERY_TYPE_DINEIN)?.delayMins,
      );
    } else if (selectedDeliveryMethod === "PICKUP") {
      setDelayMins(
        deliveryData.find((d) => d.type === DELIVERY_TYPE_PICKUP)?.delayMins,
      );
      const _minimumDelayMin = min(
        deliveryData
          ?.filter((node) => node.type === DELIVERY_TYPE_PICKUP)
          ?.map((node) => node.delayMins),
      );
      setMinimumDelayMin(_minimumDelayMin);
    } else if (selectedDeliveryMethod === "DELIVER") {
      const _minimumDelayMin = min(
        deliveryData
          ?.filter((node) => node.type === DELIVERY_TYPE_DELIVER)
          ?.map((node) => node.delayMins),
      );
      setMinimumDelayMin(_minimumDelayMin);
    }
  }, [deliveryData, selectedDeliveryMethod]);

  useEffect(() => {
    if (selectedDeliveryTimeType === "ASAP") {
      setShowPicker(false);
      const time = moment();
      // time.add(minimumDelayMin, "minutes");
      actions.setSelectedDateTime(formatDate(time));
    } else if (selectedDeliveryTimeType === "SPECTIME") {
      setShowPicker(true);
    }
  }, [selectedDeliveryTimeType, delayMins, minimumDelayMin, actions]);

  useEffect(() => {
    if (_.isEmpty(openTimes)) return;
    const latestOpenTime = getOpenSelectedDayOpenTime(selectedDateTime);
    handleTimeChange(latestOpenTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openTimes]);

  const handleChange = (event, selection) => {
    if (selection == null) return;
    if(selection === 'SPECTIME') {
      handleClickOpen();
    }
    actions.setSelectedDeliveryTimeType(selection);
  };

  const handleClickOpen = () => {
    setPrevSelectedDateTime(selectedDateTime);
    setErrorMessage("");
    setOpenDialog(true);
  };

  const handleCancel = () => {
    actions.setSelectedDateTime(prevSelectedDateTime);
    setOpenDialog(false);
  };

  const handleDateChange = (date) => {
    if (!isValidDate(date)) return;
    const dateFormatted = format(date, "yyyy-MM-dd HH:mm:ss");
    setErrorMessage("");

    actions.setSelectedDateTime(formatDate(moment(dateFormatted)));
  };

  const validateTime = () => {
    const time = moment(selectedDateTime);
    const latestOpenTime = getOpenSelectedDayOpenTime(selectedDateTime);

    if (!time.isAfter(moment())) {
      handleTimeChange(latestOpenTime);
      return setErrorMessage("Please select future time");
    }
    const isAvailable = TimeUtils.isTimeInWeeklyTimes(time, openTimes.weekly);

    if (!isAvailable) {
      handleTimeChange(latestOpenTime);
      return setErrorMessage("Please select a time between our opening hours");
    }
    return setOpenDialog(false);
  };

  useImperativeHandle(ref, () => ({
    validateTimeSelected() {
      if (selectedDeliveryTimeType === "ASAP") {
        return true;
      }
      const time = moment(selectedDateTime);

      if (!time.isAfter(moment())) {
        handleClickOpen();
        setErrorMessage("Please select future time");
        return false;
      } else {
        return true;
      }
    },
  }));

  // const CustomDatePicker = styled(DatePicker)`
  //   .MuiInputBase-input {
  //     color: ${theme.palette.primary.contrastText};
  //   }
  //   .MuiInputLabel-root {
  //     color: ${theme.palette.primary.contrastText};
  //   }
  // `;

  return (
    <div className={classes.root}>
      {selectedDeliveryMethod !== "DINEIN" && (
        <ToggleButtonGroup
          value={selectedDeliveryTimeType}
          exclusive
          onChange={handleChange}
          className={classes.toggleGroup}
        >
          <ToggleButtonDefault
            value="ASAP"
            isContrast={!!isModal}
            style={{ padding: "11px 2px" }}
          >
            <Typography
              variant="caption"
              component="span"
              style={{ fontSize: 11 }}
            >
              {ASAP_TIME_LABEL}
            </Typography>
            {/* {!!delayMins && selectedDeliveryMethod !== "DELIVER" && (
              <Typography
                className={classes.delayMins}
                variant="body2"
                component="span"
              >
                {`(in ${delayMins} mins)`}
              </Typography>
            )} */}
          </ToggleButtonDefault>
          (
          {isPreOrderActive && (
            <ToggleButtonDefault
              value="SPECTIME"
              isContrast={!!isModal}
              style={{ padding: "11px 2px" }}
            >
              <Typography
                variant="caption"
                component="span"
                style={{ fontSize: 11 }}
              >
                {SPECIFIC_TIME_LABEL}
              </Typography>
            </ToggleButtonDefault>
          )}
        </ToggleButtonGroup>
      )}
      {showPicker && (
        <>
          <Button
            classes={{ root: classes.timeButton }}
            variant="outlined"
            fullWidth
            onClick={handleClickOpen}
          >
            <Typography variant="caption" component="div">
              Select time
            </Typography>
            <Typography variant="subtitle1" component="div">
              {moment(selectedDateTime).format("dddd DD, hh:mma")}
            </Typography>
          </Button>
          <Dialog
            classes={{ root: classes.modal }}
            open={openDialog}
            onClose={(e, reason) => {
              if (reason === "backdropClick") {
                return;
              }
              setOpenDialog(false);
            }}
            keepMounted
            maxWidth="sm"
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <Collapse in={!!errorMessage} style={{ minHeight: "unset" }}>
              <Alert severity="error" style={{ margin: 8, maxWidth: 320 }}>
                {errorMessage}
              </Alert>
            </Collapse>
            <DialogContent style={{ padding: 16 }}>
              {!isIOS && (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    inputVariant="filled"
                    maxDate={moment().add(MAX_FUTURE_DATE_LIMIT, "days")}
                    minDate={moment()}
                    fullWidth
                    variant="static"
                    minDateMessage="Date should be future data. Please check you device time if this error persists"
                    autoOk
                    ampm={false}
                    disablePast
                    value={new Date(selectedDateTime)}
                    onChange={handleDateChange}
                    format="yyyy-MM-dd HH:mm:ss"
                    label="Pick your date"
                    DialogProps={{ className: classes.pickerDialog }}
                  />
                </MuiPickersUtilsProvider>
              )}
            </DialogContent>
            <PrestoTimePicker
              selectedDateTime={selectedDateTime}
              handleTimeChange={handleTimeChange}
              minimumDelayMin={minimumDelayMin}
            />
            <DialogActions className={classes.dialogActions}>
              <Button onClick={handleCancel} color="inherit">
                Cancel
              </Button>
              <Button onClick={validateTime} color="inherit" autoFocus>
                Continue
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </div>
  );
});

DeliveryDateTimePicker.defaultProps = {
  actions: {},
  cart: {},
};

const mapStateToProps = (state) => ({
  cart: state.cart,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    { setSelectedDateTime, setSelectedDeliveryTimeType },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(DeliveryDateTimePicker);
