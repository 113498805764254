/* eslint-disable react/no-danger */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";

import { IconButton, Typography, useMediaQuery } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";

import { isValidHTMLString } from "../../../../../../utils/utils";

const useStyles = makeStyles(() => ({
  PromoBannerWrapper: {
    "& .MuiSnackbarContent-root": {
      backgroundColor: ({ backgroundColor }) => backgroundColor,
      color: ({ textColor }) => textColor,
    },
  },
}));

const PromoBanner = ({
  title,
  subtitle,
  isActive,
  backgroundColor,
  textColor,
}) => {
  const [open, setOpen] = useState(true);

  const classes = useStyles({ backgroundColor, textColor });
  const theme = useTheme();
  const matchesMediumUp = useMediaQuery(theme.breakpoints.up("md"));

  const createMarkupTitle = () => ({ __html: title });
  const createMarkupSub = () => ({ __html: subtitle });

  return (
    <Snackbar
      classes={{ root: classes.PromoBannerWrapper }}
      key="1"
      anchorOrigin={{
        vertical: "top",
        horizontal: matchesMediumUp ? "right" : "center",
      }}
      open={isActive && open}
      autoHideDuration={600000}
      // onClose={() => setOpen(false)}
      message={(
        <>
          {isValidHTMLString(title) ? (
            <div dangerouslySetInnerHTML={createMarkupTitle()} />
          ) : (
            <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
              {title}
            </Typography>
          )}
          {isValidHTMLString(subtitle) ? (
            <div dangerouslySetInnerHTML={createMarkupSub()} />
          ) : (
            <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
              {subtitle}
            </Typography>
          )}
        </>
      )}
      action={(
        <IconButton
          aria-label="close"
          color="inherit"
          className={classes.close}
          onClick={() => setOpen(false)}
        >
          <CloseIcon />
        </IconButton>
      )}
    />
  );
};

PromoBanner.propTypes = {};
PromoBanner.defaultProps = {};

export default PromoBanner;
