import React from "react";

import RestaurantIcon from "@material-ui/icons/Restaurant";

import IconLabel from "../labels/IconLabel";

const DineInOnlyBadge = () => (
  <IconLabel
    text="Dine-in"
    icon={<RestaurantIcon />}
    tooltipContent="This item is available for dine-in"
  />
);

export default DineInOnlyBadge;
