import React, { useEffect, useState } from "react";

import { Hidden, useMediaQuery, useTheme } from "@material-ui/core";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";

import ContentContainer from "../../../../containers/ContentContainer";
import {
  logInfo,
  transferLogsServerAndClearLocalLogs,
} from "../../../../services/logging";
import { isEmbeddedWindow, isFixedListView } from "../../../../utils/utils";
import AlertMessage from "../../../common/AlertMessage";
import BottomNavButtons from "../../../common/BottomNavButtons";
import StickyBtnWrapper from "../../../common/StickyBtnWrapper";
import { addCompletedPage } from "../../../routes/redux/actions";
import MobileStepper from "../../../Stepper/MobileStepper";
import DeliverView from "./DeliverView";
import DineInView from "./DineInView";
import PickupView from "./PickupView";
import { setCustomerDetails } from "../../contact/redux/actions";
import { EMAIL_REGEX_PATTERN_SIMPLE, UK_PHONE_NUMBER_REGEX_PATTERN } from "../../../../utils/constants";

const addressErrorsInitState = {
  doorNo: false,
  streetAddressLine1: false,
  postalCodeLatter: false,
};
const DeliverySection = () => {
  const [alertMessage, setAlertMessage] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const { params } = useRouteMatch();
  const location = useLocation();
  const [addressErrors, setAddressErrors] = useState({
    ...addressErrorsInitState,
  });

  const {
    selectedDeliveryMethod,
    deliveryLocation: {
      doorNo,
      streetAddressLine1,
      // streetAddressLine2,
      // city,
      // postalCode,
      postalCodeLatter,
      extraAddressInfo,
    },
    selectedDeliveryData,
  } = useSelector((state) => state.cart);

  const handleContinue = () => {
    setAddressErrors(addressErrorsInitState);

    if (selectedDeliveryMethod === "DELIVER") {
      const _addressErrors = { ...addressErrors };
      if (_.isEmpty(doorNo)) {
        _addressErrors.doorNo = true;
      }
      if (_.isEmpty(streetAddressLine1)) {
        _addressErrors.streetAddressLine1 = true;
      }
      if (_.isEmpty(postalCodeLatter)) {
        _addressErrors.postalCodeLatter = true;
      }
      setAddressErrors(_addressErrors);
      if (
        _.isEmpty(doorNo)
        || _.isEmpty(streetAddressLine1)
        || _.isEmpty(postalCodeLatter)
      ) {
        return setAlertMessage("Please fill all the delivery details.");
      }
    }

    if (selectedDeliveryMethod === "DINEIN") {
      if (_.isEmpty(extraAddressInfo)) {
        return setAlertMessage(
          "Please select a table number or enter your preference",
        );
      }
    }

    if (_.isEmpty(selectedDeliveryData)) {
      logInfo({
        message:
          "WARNING | Ordering blocked due to no delivery option available",
      });
      return setAlertMessage("Sorry! No delivery options available.");
    }
    dispatch(addCompletedPage("/contact"));
    transferLogsServerAndClearLocalLogs();
    return history.push({
      pathname: `/location/${params.locationId}/contact`,
      search: location.search,
    });
  };

  const handleContinues = (customerInfo) => {
    setAddressErrors(addressErrorsInitState);

    if (selectedDeliveryMethod === "DELIVER") {
      const _addressErrors = { ...addressErrors };
      if (_.isEmpty(doorNo)) {
        _addressErrors.doorNo = true;
      }
      if (_.isEmpty(streetAddressLine1)) {
        _addressErrors.streetAddressLine1 = true;
      }
      if (_.isEmpty(postalCodeLatter)) {
        _addressErrors.postalCodeLatter = true;
      }
      setAddressErrors(_addressErrors);
      if (
        _.isEmpty(doorNo)
        || _.isEmpty(streetAddressLine1)
        || _.isEmpty(postalCodeLatter)
      ) {
        return setAlertMessage("Please fill all the delivery details.");
      }
    }

    if (selectedDeliveryMethod === "DINEIN") {
      if (_.isEmpty(extraAddressInfo)) {
        return setAlertMessage(
          "Please select a table number or enter your preference",
        );
      }
    }

    if (_.isEmpty(selectedDeliveryData)) {
      logInfo({
        message:
          "WARNING | Ordering blocked due to no delivery option available",
      });
      return setAlertMessage("Sorry! No delivery options available.");
    }

    const {firstName, lastName, phoneNumber, email}  = customerInfo
    dispatch(setCustomerDetails(customerInfo));
    if (!_.isEmpty(firstName) && !_.isEmpty(lastName) && !_.isEmpty(phoneNumber) && !_.isEmpty(email)) {
      if(UK_PHONE_NUMBER_REGEX_PATTERN.test(phoneNumber) && EMAIL_REGEX_PATTERN_SIMPLE.test(email)) {
        dispatch(addCompletedPage("/payments"));
        logInfo({ message: "Contact details added", customerInfo });
        transferLogsServerAndClearLocalLogs();
        history.push({
          pathname: `/location/${params.locationId}/payments`,
          search: location.search,
        });
      } else {
        dispatch(addCompletedPage("/contact"));
        transferLogsServerAndClearLocalLogs();
        return history.push({
          pathname: `/location/${params.locationId}/contact`,
          search: location.search,
        });
      }

    } else {
      dispatch(addCompletedPage("/contact"));
      transferLogsServerAndClearLocalLogs();
      return history.push({
        pathname: `/location/${params.locationId}/contact`,
        search: location.search,
      });
    }

  };
  const handleBackward = () => {
    history.push({
      pathname: `/location/${params.locationId}/shop`,
      search: location.search,
    });
  };

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const isFixedList = isFixedListView(theme, matches);
  const isEmbedded = isEmbeddedWindow();

  useEffect(() => {
    if (isFixedList && isEmbedded) {
      window.parent.postMessage(
        {
          frameHeight: 1200,
        },
        process.env.REACT_APP_RESTAURANT_DOMAIN,
      );
    }
  }, [isFixedList, isEmbedded]);

  return (
    <div>
      <AlertMessage
        message={alertMessage}
        setMessage={setAlertMessage}
        severity="error"
        anchorOrigin="bottom"
      />
      <MobileStepper activeStep={1} handleNext={handleContinue} handleBack={handleBackward} />
      <ContentContainer>
        {selectedDeliveryMethod === "PICKUP" && <PickupView />}
        {selectedDeliveryMethod === "DINEIN" && <DineInView />}
        {selectedDeliveryMethod === "DELIVER" && (
          <DeliverView
            addressErrors={addressErrors}
            resetErrors={() => setAddressErrors(addressErrorsInitState)}
            handleContinue={handleContinues}
          />
        )}
      </ContentContainer>

      <Hidden xsDown>
        <BottomNavButtons
          handleBackward={handleBackward}
          handleContinue={handleContinue}
          continueButtonLabel="Continue"
        />
      </Hidden>

      <Hidden smUp>
        <StickyBtnWrapper>
          <BottomNavButtons
            handleBackward={handleBackward}
            handleContinue={handleContinue}
            continueButtonLabel="Continue"
          />
        </StickyBtnWrapper>
      </Hidden>
    </div>
  );
};

export default DeliverySection;
