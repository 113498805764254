/* eslint-disable no-shadow */
import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core";
import _ from "lodash";
import { useSelector } from "react-redux";

import MenuItemModal from "../../components/@pages/storeHome/ItemList/MenuItemModal";

const useStyles = makeStyles(() => ({
  ItemPopupModalWrapper: {
    // backgroundColor: "red",
  },
}));

const ItemPopupModal = () => {
  const [open, setOpen] = useState(false);
  const [selectedItemData, setSelectedItemData] = useState({});
  const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState("");
  const [selectedDateTime, setSelectedDateTime] = useState("");
  const [catMenuId, setCatMenuId] = useState("");
  const classes = useStyles();
  const { catMenuOptions } = useSelector((state) => state.catMenu);

  useEffect(() => {
    const handleMessage = (event) => {
      const isAllowed = process.env.REACT_APP_RESTAURANT_DOMAIN === "*"
        || event.origin === process.env.REACT_APP_RESTAURANT_DOMAIN;

      if (isAllowed) {
        const {
          selectedDateTime,
          selectedDeliveryMethod,
          menuItemData,
          catMenuId,
          openModal,
        } = event?.data;
        if (openModal === true) {
          setSelectedItemData(menuItemData);
          setSelectedDeliveryMethod(selectedDeliveryMethod);
          setSelectedDateTime(selectedDateTime);
          setCatMenuId(catMenuId);
          setOpen(true);
        }
      }
    };

    if (window.location.pathname.includes("itempopupmodal")) {
      window.addEventListener("message", handleMessage, false);
    }

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    const clearItemState = () => {
      setSelectedItemData({});
    };
    if (!open) {
      clearItemState();
    }
  }, [open]);

  useEffect(() => {
    const clearStates = () => {
      setSelectedDeliveryMethod("");
      setSelectedDateTime("");
      setCatMenuId("");
      window.parent.postMessage(
        { openModal: false },
        process.env.REACT_APP_RESTAURANT_DOMAIN,
      );
    };
    if (_.isEmpty(selectedItemData)) {
      clearStates();
    }
  }, [selectedItemData]);

  const handleClose = async () => {
    await setOpen(false);
  };

  return (
    <div className={classes.ItemPopupModalWrapper}>
      <MenuItemModal
        open={open}
        handleClose={handleClose}
        selectedItemData={selectedItemData}
        catMenuId={catMenuId}
        selectedDeliveryMethod={selectedDeliveryMethod}
        selectedDateTime={selectedDateTime}
        catMenuOptions={catMenuOptions}
      />
    </div>
  );
};

ItemPopupModal.propTypes = {};
ItemPopupModal.defaultProps = {};

export default ItemPopupModal;
