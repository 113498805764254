/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";

import { CircularProgress, Hidden, Icon, makeStyles } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import PropTypes from "prop-types";

import StickyButton from "../buttons/StickyButton";
import MobileCartDialog from "../MobileCartDialog";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    width: "100%",
  },
}));

const BackIcon = () => (
  <Icon>
    <ArrowBackIosIcon />
  </Icon>
);

const BottomNavButtons = ({
  handleContinue,
  handleBackward,
  continueButtonLabel,
  continueButtonProps,
  continueButtonIsLoading,
  orderData,
}) => {
  const classes = useStyles();
  const [bucketOpen, setBucketOpen] = useState(false);

  return (
    <div className={classes.root}>
      <MobileCartDialog
        setBucketOpen={setBucketOpen}
        bucketOpen={bucketOpen}
        orderData={orderData}
      />
      {handleBackward && (
        <StickyButton flex={1} onClick={handleBackward}>
          <BackIcon />
        </StickyButton>
      )}

      <Hidden smUp>
        <StickyButton flex={1} onClick={() => setBucketOpen(true)}>
          <ShoppingCartIcon />
        </StickyButton>
      </Hidden>
      {handleContinue && (
        <StickyButton
          flex={2}
          onClick={handleContinue}
          disabled={continueButtonIsLoading}
          {...continueButtonProps}
        >
          {continueButtonIsLoading ? (
            <CircularProgress disableShrink />
          ) : (
            continueButtonLabel
          )}
        </StickyButton>
      )}
    </div>
  );
};

BottomNavButtons.propTypes = {
  handleBackward: PropTypes.func,
  handleContinue: PropTypes.func,
};

BottomNavButtons.defaultProps = {
  handleBackward: () => {},
  handleContinue: () => {},
};

export default BottomNavButtons;
