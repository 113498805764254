import React, { useEffect } from "react";
import {
  Button,
  Card,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core";
import Color from "color";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import {
  setAppliedDiscounts,
  setDiscountAttachedCartItems,
  setOrderDiscounts,
  setSubTotal,
  setTotalDiscount,
  setTotalPriceItems,
} from "../../storeHome/MyOrder/redux/actions";
import { setExtraCharges } from "../../storeHome/redux/actions";
import MobileCartDialog from "../../../common/MobileCartDialog";
import { calDiscountedPriceAutomatic } from "../../../../utils/discouts";
import { formatPrice } from "../../../../utils/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.text.accentTextColor,
    color: theme.palette.background.accentColor,
    margin: 8,
    cursor: "pointer",
    maxWidth: 440,
    display: "flex",
    justifyContent: "center",
  },
  rowWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  totalWrapper: {
    paddingTop: 4,
    display: "flex",
    justifyContent: "space-between",
    borderTop: `1px solid ${Color(theme.palette.text.primaryTextColor).alpha(
      0.5,
    )}`,
    borderBottom: `1px solid ${Color(theme.palette.text.primaryTextColor).alpha(
      0.5,
    )}`,
  },
}));

/**
 * The CouponDiscountTotal component displays the total amount to be paid for the order and
 * the applied discount (if any). It also provides a button to proceed with the order and open a mobile cart dialog.
 */
const CouponDiscountTotal = ({ orderData, handleContinue }) => {
  const [bucketOpen, setBucketOpen] = useState(false);

  // Selecting necessary data from the redux store
  const {
    selectedDeliveryMethod,
    cartItems,
    selectedDateTime,
    subtotal,
    selectedDeliveryData,
    totalPriceItems,
    totalDiscount,
    isCouponApplied,
  } = useSelector((state) => state.cart);

  // Destructuring properties from the 'store' slice of the Redux state
  const {
    discountInfo: discountList,
    serviceCharge: sCharge,
    carrierBagCharge,
    extraCharges,
  } = useSelector((state) => state.store);

  const dispatch = useDispatch();

  /**
   * This useEffect hook is responsible for calculating and
   * updating various values based on certain conditions and dependencies.
   */
  useEffect(() => {
    let _applicableCarrierBagCharge = 0;

    // Checking if the carrier bag charge is a valid number and the selected delivery method is either "PICKUP" or "DELIVER"
    if (
      parseFloat(carrierBagCharge) &&
      (selectedDeliveryMethod === "PICKUP" ||
        selectedDeliveryMethod === "DELIVER")
    ) {
      _applicableCarrierBagCharge = parseFloat(carrierBagCharge);
    }

    // Checking if the coupon is not applied
    if (!isCouponApplied) {
      // Calling the 'calDiscountedPriceAutomatic' function to calculate various prices and discounts
      const {
        totalPrice: tPriceItems,
        totalDiscount: tDisc,
        subTotal,
        discountAttachedCartItems,
        orderDiscounts,
        appliedDiscounts,
      } = calDiscountedPriceAutomatic(
        cartItems,
        discountList,
        selectedDateTime,
        selectedDeliveryMethod,
      );

      // Dispatching Redux actions to update the state with the calculated values
      dispatch(setDiscountAttachedCartItems([]));
      dispatch(setSubTotal(parseFloat(subTotal.toFixed(2))));
      dispatch(setTotalDiscount(parseFloat(tDisc.toFixed(2))));
      dispatch(setTotalPriceItems(parseFloat(tPriceItems.toFixed(2))));
      dispatch(setDiscountAttachedCartItems(discountAttachedCartItems || []));
      dispatch(setAppliedDiscounts(appliedDiscounts || []));
      dispatch(setOrderDiscounts(orderDiscounts || []));
      dispatch(
        setExtraCharges({ carrierBagCharge: _applicableCarrierBagCharge }),
      );
    }
  }, [
    dispatch,
    cartItems,
    discountList,
    selectedDateTime,
    selectedDeliveryMethod,
    selectedDeliveryData,
    isCouponApplied,
    carrierBagCharge,
  ]);

  // Variable to store the delivery cost.
  // If charge exists, parse it as a float; otherwise, set it to 0.
  const deliveryCost = selectedDeliveryData?.charge
    ? parseFloat(selectedDeliveryData?.charge)
    : 0;

  // Variable to store the service charge. If sCharge is truthy, parse it as a float; otherwise, set it to 0.
  const serviceCharge = parseFloat(sCharge) ? parseFloat(sCharge) : 0;

  // Variable to store the carrier bag charge.
  // If extraCharges is not empty and carrierBagCharge is truthy, assign carrierBagCharge; otherwise, set it to 0.
  const _carrierBagCharge =
    !_.isEmpty(extraCharges) && parseFloat(extraCharges?.carrierBagCharge)
      ? extraCharges.carrierBagCharge
      : 0;

  // Variable to store the total payment amount. If subtotal exists,
  // calculate the sum of totalPriceItems, deliveryCost, serviceCharge, and _carrierBagCharge; otherwise, set it to 0.
  const totalPaymentAmount = subtotal
    ? totalPriceItems + deliveryCost + serviceCharge + _carrierBagCharge
    : 0;

  const classes = useStyles();
  const theme = useTheme();
  return (
    <div style={{ marginTop: "20px" }}>
      <MobileCartDialog
        setBucketOpen={setBucketOpen}
        bucketOpen={bucketOpen}
        orderData={orderData}
      />
      <Card className={classes.root} elevation={0} onClick={handleContinue}>
        <Button onClick={handleContinue}>
          <div>
            <Typography
              variant="caption"
              align="center"
              style={{
                display: "flex",
                textTransform: "none",
                justifyContent: "center",
                fontSize: "13px",
                color: theme.palette.background.accentColor,
              }}
            >
              Total to Pay
            </Typography>
            <Typography
              variant="h6"
              align="center"
              style={{
                fontWeight: "bold",
                lineHeight: "20px",
                color: theme.palette.background.accentColor,
              }}
            >
              {formatPrice(totalPaymentAmount)}
            </Typography>
            {totalDiscount ? (
              <div style={{ marginTop: "12px" }}></div>
            ) : (
              <div style={{ marginTop: "6px" }}></div>
            )}
            {totalDiscount ? (
              <Typography
                variant="caption"
                align="center"
                style={{
                  marginTop: "40px",
                  color: theme.palette.background.accentColor,
                }}
              >
                including {formatPrice(totalDiscount, true)} discount
              </Typography>
            ) : null}
          </div>
        </Button>
        {/* {!!parseFloat("12") && (
          <div className={classes.rowWrapper}>
            <Typography variant="body2" component="span">
              Discount
            </Typography>
            <Typography variant="body2" component="span">
              {formatPrice(totalDiscount, true)}
            </Typography>
          </div>
        )}
        <div className={classes.totalWrapper}>
          <Typography
            variant="subtitle1"
            component="span"
            style={{ fontWeight: "bold" }}
          >
            Total
          </Typography>
          <Typography
            variant="subtitle1"
            component="span"
            style={{ fontWeight: "bold" }}
          >
            {formatPrice(totalPaymentAmount)}
          </Typography>
        </div>
        <div style={{display: "flex", justifyContent: "end", marginTop: "12px"}}>
        <IconButtonDefault  style={{display: "flex", justifyContent: "end"}} onClick={() => setBucketOpen(true)}>
          <ShoppingCartIcon />
        </IconButtonDefault>
        </div> */}
      </Card>
    </div>
  );
};

CouponDiscountTotal.propTypes = {};
CouponDiscountTotal.defaultProps = {};

export default CouponDiscountTotal;
