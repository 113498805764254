import { getLocationTheme } from "../../services/locationService";

export const FETCH_INIT_DATA_PENDING = "FETCH_INIT_DATA_PENDING";
export const FETCH_INIT_DATA_SUCCESS = "FETCH_INIT_DATA_SUCCESS";
export const FETCH_INIT_DATA_ERROR = "FETCH_INIT_DATA_ERROR";

// location theme
function fetchLocationInitDataPending() {
  return {
    type: FETCH_INIT_DATA_PENDING,
  };
}

function fetchLocationInitDataSuccess(initData) {
  return {
    type: FETCH_INIT_DATA_SUCCESS,
    payload: initData,
  };
}

function fetchLocationInitDataError(error) {
  return {
    type: FETCH_INIT_DATA_ERROR,
    error,
  };
}

export const asyncLocationInitData = (locationId) => async (dispatch) => {
  dispatch(fetchLocationInitDataPending());

  try {
    const res = await getLocationTheme(locationId);
    dispatch(fetchLocationInitDataSuccess(res.data.data));
  } catch (error) {
    dispatch(fetchLocationInitDataError("Error initial delivery info"));
  }
};
