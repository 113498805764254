import React from "react";

import { Box, Typography } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";

import { formatPrice } from "../../../../utils/utils";

const useStyle = makeStyles((theme) => ({
  CartItemCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    minHeight: 48,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(1),
    margin: theme.spacing(0.5, 1),
  },
  title: {
    display: "flex",
  },
  subItems: {
    display: "flex",
  },
}));

const CartItemCard = ({ cartItemData, idx }) => {
  const classes = useStyle();
  const { name, quantity, price, subItems } = cartItemData;

  const calTotalPrice = () => {
    const subTotalPrice = !_.isEmpty(subItems)
      ? _.sum(subItems.map((i) => parseFloat(i.price)))
      : 0;
    return parseFloat(price) + subTotalPrice;
  };

  return (
    <Box id={`cartItemCard-${idx}`} className={classes.CartItemCard}>
      <div className={classes.title}>
        <Typography
          variant="body2"
          component="span"
          style={{ flex: 1, display: "flex", alignItems: "center" }}
        >
          {quantity}
          &nbsp;
          <Icon fontSize="inherit">close</Icon>
        </Typography>
        <Typography
          variant="body2"
          component="span"
          style={{ flex: 5, fontWeight: "bold" }}
        >
          {name}
        </Typography>
        <Typography
          variant="body2"
          component="span"
          style={{ flex: 2, textAlign: "end" }}
        >
          {formatPrice(calTotalPrice() * quantity)}
        </Typography>
      </div>

      <div className={classes.subItems}>
        <span style={{ flex: 1 }} />
        <span style={{ flex: 7 }}>
          {!_.isEmpty(subItems) && (
            <>
              {subItems.map((sub, i) => (
                <Typography key={String(i)} variant="body2" component="div">
                  {sub.name}
                </Typography>
              ))}
            </>
          )}
        </span>
      </div>
    </Box>
  );
};

export default CartItemCard;
