import React from "react";

import { Box, Typography } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Color from "color";
import isEmpty from "lodash/isEmpty";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

import DividerDefault from "../../../common/dividers/DividerDefault";
import UserAccount from "../UserAccount";
import { setCategoryList, setCategorySelected } from "./redux/actions";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    color: theme.palette.text.titleTextColor,
    backgroundColor: theme.palette.background.categoryBackgroundColor,
  },
  catList: {
    backgroundColor: theme.palette.background.categoryBackgroundColor,
  },
  listItem: {
    "&.MuiListItem-root": {
      color: theme.palette.text.menuTextColor,
    },
    "&.Mui-selected": {
      backgroundColor: Color(theme.palette.background.categoryBackgroundColor)
        .darken(0.2)
        .hex(),
      // color: theme.palette.primary.main,
    },
    "&:hover": {
      // backgroundColor: Color(theme.palette.background.categoryBackgroundColor)
      //   .darken(0.2)
      //   .hex(),
    },
  },
}));

function MenuSection({
  currentCat,
  setCurrentCat,
  item,
  setSideBarOpen,
  isFixedList,
  setSearchName,
  setIsOpen
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { categoryList, categorySelected } = useSelector(
    (state) => state.category,
  );

  const handleClickCat = (cat) => {
    setSearchName("");
    setIsOpen(false)
    const { catId } = cat;
    if (isFixedList) {
      const selectedCat = categoryList.find((c) => c.catId === catId);
      return dispatch(setCategorySelected(selectedCat));
    }
    const timer = setTimeout(() => {
      const container = document.querySelector("#items-container");
    const newCat = item.titlesPosList.find((c) => c.cat.catId === catId);
    if (newCat.pos < currentCat.pos) {
      container.scrollTo({
        top: newCat.pos - item.titlesPosList[0].pos,
        behavior: "smooth",
      });
    } else {
      container.scrollTo({
        top: newCat.pos,
        behavior: "smooth",
      });
    }
    setCurrentCat(newCat);
    if (setSideBarOpen) {
      setSideBarOpen(false);
    }
    }, 100);
    return () => {
      clearTimeout(timer); // Clean up the timer if the component unmounts before it expires
    };
  };

  const isSelected = (catId) => {
    if (isFixedList) return categorySelected?.catId === catId;
    return currentCat.cat.catId === catId;
  };

  return (
    <Box className={classes.container}>
      <UserAccount />
      <DividerDefault />
      <Box id="categoryListContainer">
        <List
          component="nav"
          aria-label="category list container"
          className={classes.catList}
        >
          {!isEmpty(categoryList) &&
            categoryList.map((cat) => (
              <ListItem
                key={`catSection-${cat.catId}`}
                classes={{ root: classes.listItem }}
                button
                id={`catSection-catId_${cat.catId}`}
                selected={isSelected(cat.catId)}
                onClick={() => handleClickCat(cat)}
              >
                <ListItemText>
                  <Typography
                    variant="subtitle1"
                    classes={{ root: classes.listItem }}
                  >
                    {cat.title}
                  </Typography>
                </ListItemText>
              </ListItem>
            ))}
        </List>
      </Box>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  category: state.category,
  item: state.item,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    { setCategorySelected, setCategoryList },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuSection);
