import React, { useState } from "react";

import { makeStyles, useTheme } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
  chip: {
    "& .MuiChip-icon": {
      color: theme.palette.background.menuBackgroundColor,
    },
  },
}));

const IconLabel = ({ text, icon, tooltipContent, error }) => {
  const [open, setOpen] = useState(false);

  const classes = useStyles();

  const theme = useTheme();

  return (
    <Tooltip title={tooltipContent} open={open}>
      <Chip
        classes={{ root: classes.chip }}
        size="small"
        style={
          error
            ? {
              backgroundColor: theme.palette.error.dark,
              color: theme.palette.error.contrastText,
              margin: "0px 4px 4px 0px",
              paddingRight: 4,
            }
            : {
              margin: "0px 4px 4px 0px",
              paddingRight: 4,
              backgroundColor: theme.palette.text.subtitleTextColor,
              color: theme.palette.background.menuBackgroundColor,
            }
        }
        label={text}
        icon={icon}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      />
    </Tooltip>
  );
};

export default IconLabel;
