import React from "react";

import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";
import Color from "color";
import { isEmpty } from "lodash";

import {
  DELIVERY_TYPE_DELIVER,
  DELIVERY_TYPE_PICKUP,
  SERVICE_FEE_MESSAGE,
  CARRIER_BAG_CHARGE_MESSAGE,
} from "../../../../utils/constants";
import { formatPrice } from "../../../../utils/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    padding: 16,
    backgroundColor: "inherit",
    color: "inherit",
  },
  rowWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  totalWrapper: {
    paddingTop: 4,
    display: "flex",
    justifyContent: "space-between",
    borderTop: `1px solid ${Color(theme.palette.text.primaryTextColor).alpha(0.5)}`,
    borderBottom: `1px solid ${Color(theme.palette.text.primaryTextColor).alpha(0.5)}`,
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const PriceBreakdown = ({
  deliveryCharge,
  totalDiscount,
  totalPaymentAmount,
  deliveryType,
  serviceCharge,
  extraCharges,
}) => {
  const classes = useStyles();

  const _carrierBagCharge = !isEmpty(extraCharges) && parseFloat(extraCharges?.carrierBagCharge)
    ? extraCharges.carrierBagCharge
    : 0;

  const subtotal = parseFloat(totalPaymentAmount)
    - parseFloat(deliveryCharge)
    - parseFloat(serviceCharge)
    - _carrierBagCharge
    + parseFloat(totalDiscount);

  return (
    <div className={classes.root}>
      <div className={classes.rowWrapper}>
        <Typography variant="body2" component="span">
          Subtotal
        </Typography>
        <Typography variant="body2" component="span">
          {formatPrice(subtotal)}
        </Typography>
      </div>
      {deliveryType === DELIVERY_TYPE_DELIVER && deliveryCharge && (
        <div className={classes.rowWrapper}>
          <Typography variant="body2" component="span">
            Delivery Charge
          </Typography>
          <Typography variant="body2" component="span">
            {formatPrice(deliveryCharge)}
          </Typography>
        </div>
      )}
      {deliveryType === DELIVERY_TYPE_PICKUP && !!parseFloat(deliveryCharge) && (
        <div className={classes.rowWrapper}>
          <Typography variant="body2" component="span">
            Pickup Charge
          </Typography>
          <Typography variant="body2" component="span">
            {formatPrice(deliveryCharge)}
          </Typography>
        </div>
      )}
      {!!parseFloat(totalDiscount) && (
        <div className={classes.rowWrapper}>
          <Typography variant="body2" component="span">
            Discount
          </Typography>
          <Typography variant="body2" component="span">
            {formatPrice(totalDiscount, true)}
          </Typography>
        </div>
      )}
      {!!parseFloat(extraCharges?.carrierBagCharge) && (
        <div className={classes.rowWrapper}>
          <Typography
            variant="body2"
            component="span"
            className={classes.center}
          >
            Carrier bags
            <span style={{ margin: 2 }} />
            <Tooltip
              title={CARRIER_BAG_CHARGE_MESSAGE}
              aria-label="carrier bag charge"
            >
              <HelpIcon style={{ fontSize: 16 }} />
            </Tooltip>
          </Typography>
          <Typography variant="body2" component="span">
            {formatPrice(extraCharges?.carrierBagCharge)}
          </Typography>
        </div>
      )}
      {!!parseFloat(serviceCharge) && (
        <div className={classes.rowWrapper}>
          <Typography
            variant="body2"
            component="span"
            className={classes.center}
          >
            Service charge
            <span style={{ margin: 2 }} />
            <Tooltip
              title={SERVICE_FEE_MESSAGE}
              aria-label="service fee message"
            >
              <HelpIcon style={{ fontSize: 16 }} />
            </Tooltip>
          </Typography>
          <Typography variant="body2" component="span">
            {formatPrice(serviceCharge)}
          </Typography>
        </div>
      )}
      <div className={classes.totalWrapper}>
        <Typography
          variant="subtitle1"
          component="span"
          style={{ fontWeight: "bold" }}
        >
          Total
        </Typography>
        <Typography
          variant="subtitle1"
          component="span"
          style={{ fontWeight: "bold" }}
        >
          {formatPrice(totalPaymentAmount)}
        </Typography>
      </div>
    </div>
  );
};

export default PriceBreakdown;
