/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";

import { Button, Collapse, Icon, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import _ from "lodash";
import { deviceDetect } from "react-device-detect";
import { connect, useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { bindActionCreators } from "redux";

import useRouteNavigation from "../../../../hooks/RouteHooks/useRouteNavigation";
import { setIsOpenLoginModal } from "../../../../redux/actions/auth";
import {
  logError,
  logInfo,
  transferLogsServerAndClearLocalLogs,
} from "../../../../services/logging";
import { checkOrder, crateOrder } from "../../../../services/orderService";
import FacebookPixel from "../../../../utils/analytics/FacebookPixel";
import { getAvailabilityOfItem } from "../../../../utils/availability";
import { currencySymbols } from "../../../../utils/const";
import { ASAP_TIME_LABEL } from "../../../../utils/constants";
import DeliveryUtils from "../../../../utils/DeliveryUtils";
import {
  getLocalStore,
  setLocalStore,
} from "../../../../utils/storage/localStorage";
import moment from "../../../../utils/time/moment";
import TimeUtils from "../../../../utils/TimeUtils";
import AlertMessage from "../../../common/AlertMessage";
import ButtonContained from "../../../common/buttons/ButtonContained";
import SubtotalBar from "../../../common/SubtotalBar";
import { addCompletedPage } from "../../../routes/redux/actions";
import CartItemList from "./CartItemList";
import Delivery from "./Delivery";
import DeliveryDateTimePicker from "./DeliveryDateTimePicker";
import Header from "./Header";
import {
  fetchCartItemsAsync,
  setCartItems,
  setFilteredDeliveryOptionListByAvailability,
  setOrderNotes,
  setSelectedDeliveryData,
} from "./redux/actions";
import SpecialNotes from "./SpecialNotes";
import { setCustomerDetails } from "../../contact/redux/actions";

const useStyle = makeStyles((theme) => ({
  orderSection: {
    padding: theme.spacing(1, 2, 2, 2),
  },
  deliverySection: {
    // borderTop: `1px solid ${theme.palette.background.boarderColor}`,
    backgroundColor: theme.palette.primary.main,

    [theme.breakpoints.down("xs")]: {
      // backgroundColor: theme.palette.primary.main,
      // position: "absolute",
      // bottom: 0,
      // right: 0,
      // left: 0,
    },
  },
  expandBtn: {
    padding: "0 16px",
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  otherDetails: {
    padding: theme.spacing(1, 2),
    color: theme.palette.primary.contrastText,
  },
}));

const MyOrder = ({ actions, cart }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [deliveryType, setDeliveryType] = useState("");
  const [openSettings, setOpenSettings] = useState(true);
  const [isCartEditable, setIsCartEditable] = useState(false);
  const [notes, setNotes] = useState("");
  const [showNote, setShowNote] = useState(false);

  const classes = useStyle();
  const { params, path } = useRouteMatch();
  const { navigateRoute, setFlowNextRoute } = useRouteNavigation();
  const { isAuthenticated } = useSelector((state) => state.auth);

  useEffect(() => {
    if (path.includes("shop")) {
      setIsCartEditable(true);
    }
  }, [path]);

  const dispatch = useDispatch();
  const { isOrderingActive, isPreOrderActive } = useSelector(
    (state) => state.store,
  );

  
  const { email, displayName } = useSelector((state) => state.auth);
  const { catMenuOptions } = useSelector((state) => state.catMenu);
  const {
    selectedDateTime,
    selectedDeliveryMethod,
    deliveryData,
    openTimes,
    cartItems,
    orderNotes,
    totalPriceItems,
    selectedDeliveryTimeType,
  } = cart;

  useEffect(() => {
    setNotes(orderNotes);
    if (orderNotes) {
      setShowNote(true);
    }
  }, [orderNotes]);

  const createNewSession = async (locationId) => {
    let deviceInfo = {};
    try {
      deviceInfo = deviceDetect();
    } catch (error) {
      logError({ message: "Error detect device info" });
    }
    const userInfo = {
      email: email || "NA",
      displayName: displayName || "Guest",
    };
    try {
      const res = await crateOrder(locationId, {
        userInfo,
        device: deviceInfo,
      });
      logInfo({
        message: "Order created successfully",
        // userInfo,
        // orderInfo: res?.data?.data,
      });


      const customerDetails = {
        firstName: "",
        lastName: "",
        email: "",
        marketing:
          res.data.data?.customer?.marketing.toString() === "true" ||
          res.data.data?.customer?.marketing.toString() === "false"
            ? res.data.data?.customer?.marketing
            : true,
        phoneNumber: "",
      };

      dispatch(setCustomerDetails(customerDetails));
      setLocalStore("orderId", res.data.data.id);
      setLocalStore("displayRef", res.data.data.displayRef);
      setLocalStore("deviceInfo", deviceInfo);
      setLocalStore("sessionVersion", res.data.data.version);
    } catch (error) {
      logError({
        message: "CRITICAL: Error creating order",
        error,
        errorRes: error?.response,
      });
      setErrorMessage(
        "Error creating order. Please refresh and try again. If the issue still persist, kindly contact support.",
      );
    }
  };

  const createOrderSession = async () => {
    try {
      let orderId = getLocalStore("orderId");
      if (orderId) {
        try {
          orderId = JSON.parse(orderId);
          const res = await checkOrder(params.locationId, orderId);
          const { status } = res.data.data;
          if (status === "started" || status === "checkout") {
            logInfo({
              message:
                "Check order success. order ID status valid. Continuing with existing order ID",
              orderData: res?.data?.data,
            });
          } else {
            logInfo({
              message:
                "Check order success. order ID status does not match. Re-creating new order session",
              orderSession: res.data.data,
            });
            createNewSession(params.locationId);
          }
        } catch (error) {
          logInfo({
            message: "Check order failed | creating new order session",
            error,
          });
          createNewSession(params.locationId);
        }
      } else {
        logInfo({
          message:
            "Order ID not available in localStorage | creating new order session",
        });
        createNewSession(params.locationId);
      }
    } catch (error) {
      setErrorMessage(
        "Error checking order status. Please refresh and try again. If the issue still persist, kindly contact support.",
      );
      logError({
        message: "CRITICAL | Creating new order session failed",
        error,
        errorRes: error?.response,
      });
    }
  };

  const pickupDeliveryValidations = (filteredDeliveryOptionList) => {
    const filteredListByTime = filteredDeliveryOptionList.filter((option) => {
      if (_.isEmpty(option.availability)) {
        return true;
      }
      return TimeUtils.isTimeInWeeklyTimes(
        moment(selectedDateTime),
        option.availability,
      );
    });
    if (_.isEmpty(filteredListByTime)) {
      logInfo({
        message: "Selected time not available for delivery",
        selectedDateTime,
        filteredDeliveryOptionList,
      });
      setErrorMessage(
        "Sorry, we are not open for pickup for the selected time",
      );
      return {};
    }

    const filteredListByMinOrderPrice = filteredListByTime.filter(
      (option) => parseFloat(option.minOrderPrice) <= totalPriceItems,
    );

    if (_.isEmpty(filteredListByMinOrderPrice)) {
      const minNode = _.minBy(filteredListByTime, (option) =>
        parseFloat(option.minOrderPrice),
      );
      logInfo({
        message: "Minimum order value does not match",
        minNode,
        filteredListByMinOrderPrice,
        totalPriceItems,
      });
      setErrorMessage(
        `Minimum order value is ${currencySymbols.GBP}${minNode.minOrderPrice}`,
      );
      return {};
    }

    return _.maxBy(filteredListByMinOrderPrice, (option) =>
      parseFloat(option.minOrderPrice),
    );
  };

  const processOrder = () => {
    createOrderSession();
    setErrorMessage("");
    const { deliveryOptions: filteredDeliveryOptionList, error } =
      DeliveryUtils.getDeliveryOptions(
        selectedDateTime,
        selectedDeliveryMethod,
        deliveryData,
        openTimes,
      );
    logInfo({
      message: "Filtered delivery option list by availability",
      // filteredDeliveryOptionList,
      // selectedDateTime,
      // selectedDeliveryMethod,
      // deliveryData,
      // openTimes,
    });
    if (_.isEmpty(filteredDeliveryOptionList)) {
      // options could be empty for 2 reasons. 1. No options available for the delivery method. 2. delivery time does match
      // with available time. error codes are: NO_OPTION or NOT_AVAILABLE.
      // if NOT_AVAILABLE it should show the error message to user with closest oping time
      // ex: Sorry, delivery starts from {{4PM}} onwards.
      const { reason, message } = error;
      setErrorMessage(message);
      logInfo({
        message: "No delivery option is available",
        errorCode: reason,
      });
      return false;
    }
    if (selectedDeliveryMethod === "DELIVER") {
      actions.setFilteredDeliveryOptionListByAvailability(
        filteredDeliveryOptionList,
      );
    } else if (selectedDeliveryMethod === "PICKUP") {
      const selectedPickupOption = pickupDeliveryValidations(
        filteredDeliveryOptionList,
      );
      if (_.isEmpty(selectedPickupOption)) {
        actions.setSelectedDeliveryData({});
        return false;
      }
      actions.setSelectedDeliveryData(selectedPickupOption);
    } else if (selectedDeliveryMethod === "DINEIN") {
      actions.setSelectedDeliveryData(filteredDeliveryOptionList[0]);
    }

    dispatch(setOrderNotes(notes));
    dispatch(addCompletedPage("/delivery"));
    transferLogsServerAndClearLocalLogs();
    FacebookPixel.initiateCheckout(cartItems, totalPriceItems);
    return true;
  };

  const availabilityValidation = () => {
    const validatedCartItems = cartItems.map((cartItem) => {
      const cartItemUpdated = { ...cartItem };

      const {
        catMenuId,
        item: { catId, menuId },
      } = cartItem;
      const { isItemAvailable, option } = getAvailabilityOfItem(
        menuId,
        catId,
        catMenuId,
        catMenuOptions,
        selectedDeliveryMethod,
        selectedDateTime,
      );
      cartItemUpdated.isNotAvailable = !isItemAvailable;
      cartItemUpdated.option = option;
      return cartItemUpdated;
    });
    dispatch(setCartItems(validatedCartItems));
    return validatedCartItems;
  };

  const validateOrderTime = () => {
    const isOrderTimeInOpenTime = TimeUtils.isTimeInWeeklyTimes(
      moment(),
      openTimes.weekly,
    );
    if (!isOrderTimeInOpenTime && selectedDeliveryTimeType === "ASAP") {
      logInfo({
        message:
          "order time is not in restaurant open time and order type is ASAP",
      });
      if (selectedDeliveryMethod === "DINEIN") {
        return {
          isValid: false,
          message: "Sorry, we are currently closed.",
        };
      }
      if (isPreOrderActive) {
        return {
          isValid: false,
          message:
            "Sorry, we are currently closed. You can order for a later time by selecting the schedule option.",
        };
      }
      return {
        isValid: false,
        message: "Sorry, we are currently closed.",
      };
    }
    return {
      isValid: true,
    };
  };

  const handleOrderNow = () => {
    const { isValid: isOrderTimeValid, message: orderTimeInvalidMessage } =
      validateOrderTime();
    if (!isOrderTimeValid) {
      return setErrorMessage(orderTimeInvalidMessage);
    }
    if (!isOrderingActive) {
      logInfo({ message: "order is not active" });
      return setErrorMessage("Sorry, ordering is not available");
    }
    if (_.isEmpty(cartItems)) {
      return setErrorMessage("Please add at least one item to the basket");
    }
    if (!selectedDeliveryMethod) {
      return setErrorMessage("Please select delivery method");
    }

    const validatedCartItems = availabilityValidation();
    logInfo({ message: "Validated Cart Items", 
    // validatedCartItems
   });
    const isValid = validatedCartItems.every((item) => !item.isNotAvailable);

    if (!isValid) {
      logInfo({
        message: "Some cart items not available with selected options",
      });
      return setErrorMessage(
        "Some of the items you have selected are not available for your order type at the selected time. Please update your order.",
      );
    }

    const pathname = `/location/${params.locationId}/delivery`;

    if (isAuthenticated) {
      const isSuccess = processOrder();
      if (isSuccess) {
        return navigateRoute(pathname);
      }
      return;
    }
    setFlowNextRoute(pathname);
    window.authCallback = processOrder;
    return dispatch(setIsOpenLoginModal(true));
  };

  const renderSelections = () => (
    <div>
      <Typography component="div" variant="subtitle1">
        Order type:&nbsp;
        <Typography
          component="span"
          variant="subtitle2"
          style={{ fontWeight: "bold" }}
        >
          {selectedDeliveryMethod}
        </Typography>
      </Typography>
      <Typography component="div" variant="subtitle1">
        Order time:&nbsp;
        <Typography
          component="span"
          variant="subtitle2"
          style={{ fontWeight: "bold" }}
        >
          {selectedDeliveryTimeType === "ASAP"
            ? ASAP_TIME_LABEL
            : selectedDeliveryTimeType === "SPECTIME"
            ? `SPECIFIC TIME(${moment(selectedDateTime).format(
                "hh:mma dddd DD MMM",
              )})`
            : "DINE IN"}
        </Typography>
      </Typography>
      {orderNotes && (
        <Typography component="div" variant="subtitle1">
          Order notes:&nbsp;
          <Typography
            component="span"
            variant="subtitle2"
            style={{ fontWeight: "bold" }}
          >
            {orderNotes}
          </Typography>
        </Typography>
      )}
    </div>
  );

  return (
    <>
      <AlertMessage
        message={errorMessage}
        setMessage={setErrorMessage}
        severity="error"
      />
      <Header isCartEditable={isCartEditable} />
      <CartItemList />
      <div id="deliverySection" className={classes.deliverySection}>
        <SubtotalBar />
        {isCartEditable ? (
          <>
            <div className={classes.expandBtn}>
              <Button
                fullWidth
                variant="contained"
                onClick={() => setOpenSettings(!openSettings)}
              >
                <Icon>
                  {openSettings ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                </Icon>
              </Button>
            </div>
            <Collapse in={openSettings}>
              <Delivery
                deliveryType={deliveryType}
                setDeliveryType={setDeliveryType}
                isCartEditable={isCartEditable}
              />
              <SpecialNotes
                notes={notes}
                setNotes={setNotes}
                showNote={showNote}
                setShowNote={setShowNote}
                isCartEditable={isCartEditable}
              />
              <DeliveryDateTimePicker isCartEditable={isCartEditable} />
            </Collapse>
            <div className={classes.orderSection}>
              <ButtonContained fullWidth onClick={handleOrderNow}>
                GO TO CHECKOUT
              </ButtonContained>
            </div>
          </>
        ) : (
          <div className={classes.otherDetails}>{renderSelections()}</div>
        )}
      </div>
    </>
  );
};

MyOrder.defaultProps = {
  actions: {},
  cart: {},
};

const mapStateToProps = (state) => ({
  cart: state.cart,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      fetchCartItemsAsync,
      setSelectedDeliveryData,
      setFilteredDeliveryOptionListByAvailability,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyOrder);
