export const LOGIN_USER_PENDING = "LOGIN_USER_PENDING";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const SET_IS_OPEN_LOGIN_MODAL = "SET_IS_OPEN_LOGIN_MODAL";

export const loginUserPending = () => ({
  type: LOGIN_USER_PENDING,
});

export const loginUserSuccess = (displayName, email) => ({
  type: LOGIN_USER_SUCCESS,
  displayName,
  email,
});

export const loginUserError = (error) => ({
  type: LOGIN_USER_ERROR,
  error,
});

export const setIsOpenLoginModal = (isOpen, callback) => ({
  type: SET_IS_OPEN_LOGIN_MODAL,
  payload: isOpen,
  callback,
});

// export const asyncLoginUser = (email, password, callback) => async (
//   dispatch,
// ) => {
//   dispatch(loginUserPending(""));
//   try {
//     const auth = firebase.auth();
//     await auth.signInWithEmailAndPassword(email, password);
//     const user = auth.currentUser;
//     dispatch(loginUserSuccess(user.displayName, user.email));
//     const token = await user.getIdToken();
//     setLocalStore("idToken", token);
//     setIsOpenLoginModal(false);
//     return callback();
//   } catch (error) {
//     if (error?.message) return dispatch(loginUserError(error?.message));
//     dispatch(loginUserError("Unexpected error. Please check your connection"));
//   }
// };
