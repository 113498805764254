import React from "react";

import { Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    // minHeight: "100vh",
    [theme.breakpoints.up("md")]: {
      minHeight: theme?.view?.isFixedList ? 700 : "initial",
    },
    color: theme.palette.text.accentTextColor,
  },
}));

const PageWrapper = ({ children }) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.root}>
      {children}
    </Grid>
  );
};

export default PageWrapper;
