import { SET_CURRENT_PAGE, ADD_COMPLETED_PAGE, RESET_PAGES } from "./actions";

export const RouteInitialState = {
  completedPages: [],
  currentPage: "/shop",
};

const RouteReducer = (state = RouteInitialState, action) => {
  switch (action.type) {
    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: "",
      };
    case ADD_COMPLETED_PAGE: {
      const pageSet = new Set([...state.completedPages, action.payload]);
      return {
        ...state,
        completedPages: [...pageSet],
      };
    }
    case RESET_PAGES:
      return {
        ...state,
        completedPages: [],
        currentPage: "/shop",
      };
    default:
      return state;
  }
};

export default RouteReducer;
