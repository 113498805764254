import React from "react";

import { Button, makeStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Stepper from "@material-ui/core/MobileStepper";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

import { HEADER_HEIGHT } from "../../containers/ContentContainer";
import useViewport from "../../hooks/useViewport";

const useStyles = makeStyles(() => ({
  MobileStepperWrapper: {
    height: HEADER_HEIGHT - 16,
  },
}));

const MobileStepper = ({
  activeStep,
  handleNext,
  handleBack,
  labelNext,
  labelBack,
  isLoading,
}) => {
  const classes = useStyles();
  const { isMobileView } = useViewport();

  if (!isMobileView) return null;

  return (
    <Stepper
      className={classes.MobileStepperWrapper}
      steps={5}
      position="static"
      variant="progress"
      activeStep={activeStep}
      nextButton={(
        <Button size="small" onClick={handleNext} disabled={isLoading}>
          {isLoading ? (
            <CircularProgress color="secondary" size={30} />
          ) : (
            <>
              {labelNext || "Next"}
              <KeyboardArrowRight />
            </>
          )}
        </Button>
      )}
      backButton={(
        <Button size="small" onClick={handleBack}>
          <KeyboardArrowLeft />
          {labelBack || "Back"}
        </Button>
      )}
    />
  );
};

MobileStepper.propTypes = {};
MobileStepper.defaultProps = {};

export default MobileStepper;
