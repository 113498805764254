import React, { useEffect, useState } from "react";

import { makeStyles, Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import { DELIVERY_TYPE_DINEIN } from "../../../../../utils/constants";
import TextFieldDefault from "../../../../common/textFields/TextFieldDefault";
import { setDeliveryLocation } from "../../../storeHome/MyOrder/redux/actions";

// const TABLE_LIST = [
//   { id: "", title: "Select a table" },
//   { id: "46564654", title: "Corner table" },
//   { id: "23435345", title: "VIP-balcony" },
//   { id: "46564654", title: "VIP-pool side" },
// ];

const useStyles = makeStyles((theme) => ({
  DineInViewWrapper: {
    // -- css to populate table tiles with flex --
    // display: "flex",
    // flexWrap: "wrap",
    // padding: theme.spacing(2),
    // justifyContent: "space-around",
  },
  tableLabel: {
    backgroundColor: theme.palette.secondary.main,
    height: 36,
    bottom: 0,
    position: "absolute",
    left: 0,
    right: 0,
    color: theme.palette.secondary.contrastText,
    padding: 8,
  },
  tableCard: {
    width: "70%",
    padding: 2,
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}));

const DineInView = () => {
  const [value, setValue] = useState("");
  const [inputValue, setInputValue] = React.useState("");
  const [tables, setTables] = useState([]);
  const { deliveryData, deliveryLocation } = useSelector((state) => state.cart);

  const dispatch = useDispatch();

  useEffect(() => {
    const dineInData = deliveryData.find(
      (o) => o.type === DELIVERY_TYPE_DINEIN,
    );
    if (dineInData) {
      const tableIdList = Object.keys(dineInData.area.data);
      const tableList = tableIdList.map((id) => ({
        id,
        title: `Table ${dineInData.area.data[id]}`,
        number: dineInData.area.data[id],
      }));
      setTables(tableList);

      if (deliveryLocation.extraAddressInfo) {
        const selectedTable = tableList.find(
          (t) => t.number === deliveryLocation.extraAddressInfo,
        );
        setValue(selectedTable);
      }
    }
  }, [deliveryData, deliveryLocation]);

  const handleChange = (_value) => {
    const tableData = { ...deliveryLocation };
    tableData.extraAddressInfo = _value;
    dispatch(setDeliveryLocation(tableData));
  };

  const classes = useStyles();
  return (
    <div className={classes.DineInViewWrapper}>
      {!isEmpty(tables) && (
        <Autocomplete
          value={value}
          onChange={(event, newValue) => {
            if (!newValue) return;
            setValue(newValue);
            handleChange(newValue.number);
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          defaultValue="Select table"
          getOptionLabel={(option) => option.title}
          id="table-selection"
          options={tables.map((option) => option)}
          renderInput={(params) => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              label="Select your table"
              margin="normal"
              variant="outlined"
            />
          )}
        />
      )}
      {isEmpty(tables) && (
        <TextFieldDefault
          label="Please enter your table no"
          fullWidth
          inputProps={{ maxLength: 300 }}
          multiline
          rows={3}
          value={deliveryLocation?.extraAddressInfo}
          onChange={(e) => handleChange(e.target.value)}
        />
      )}
      {/* <div style={{ display: "flex", justifyContent: "center", height: "70%" }}>
        {selectedTable && <TableNumberCard title={selectedTable.title} />}
      </div> */}
      {!isEmpty(value) && (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: "16px",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* {deliveryLocation.extraAddressInfo && !isEmpty(tables) && (
          <Paper square elevation={4} className={classes.tableCard}>
            <img
              src={dinnerTableImg}
              style={{ width: "100%" }}
              alt="dinner table"
            />
            <div className={classes.tableLabel}>
              <Typography variant="h6">
                <strong>Reserve: </strong>
                {value.title}
              </Typography>
            </div>
          </Paper>
        )} */}
        <Typography variant="h4">
          Table
        </Typography>
        <span style={{ margin: 8 }} />
        <Typography variant="h1">
          {value.number}
        </Typography>
        <span style={{ margin: 8 }} />
        <Typography variant="h4">
          selected
        </Typography>
      </div>
      )}
      {/* {TABLE_LIST.map((num) => (
        <TableNumberCard num={num} len={TABLE_LIST.length} />
      ))} */}
    </div>
  );
};

DineInView.propTypes = {
  // bla: PropTypes.string,
};

DineInView.defaultProps = {
  // bla: 'test',
};

export default DineInView;
