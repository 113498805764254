export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const ADD_COMPLETED_PAGE = "ADD_COMPLETED_PAGE";
export const RESET_PAGES = "RESET_PAGES";

export const addCompletedPage = (payload) => ({
  type: ADD_COMPLETED_PAGE,
  payload,
});

export const setCurrentPage = (payload) => ({
  type: SET_CURRENT_PAGE,
  payload,
});

export const resetPages = () => ({
  type: RESET_PAGES,
});
