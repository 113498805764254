import React, { useState } from "react";
import {
  Avatar,
  Card,
  Fade,
  Grid,
  makeStyles,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Brightness1OutlinedIcon from "@material-ui/icons/Brightness1Outlined";
import Brightness1Icon from "@material-ui/icons/Brightness1";
import IconButtonDefault from "../../../../common/buttons/IconButtonDefault";
import firebase from "../../../../../utils/firebase";
import { useSelector } from "react-redux";
import { logError, logInfo } from "../../../../../services/logging";
import {
  getLocalStore,
  removeLocalStore,
} from "../../../../../utils/storage/localStorage";
import QRCode from "react-qr-code";
import _ from "lodash";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom";
import moment from "moment";

const colors = [
  "#4CAF50",
  "#FF5722",
  "#03A9F4",
  "#E91E63",
  "#FFEB3B",
  "#673AB7",
  "#009688",
  "#FFC107",
  "#9C27B0",
  "#CDDC39",
  "#FF9800",
  "#8BC34A",
  "#2196F3",
  "#FF5722",
  "#607D8B",
  "#00BCD4",
  "#FFEB3B",
  "#795548",
  "#3F51B5",
  "#F44336",
  "#4CAF50",
  "#FF5722",
  "#03A9F4",
  "#E91E63",
  "#FFEB3B",
  "#673AB7",
];

const useStyles = makeStyles((theme) => ({
  roots: {
    backgroundColor: theme.palette.background.menuBackgroundColor,
    "& .MuiTabs-root": {
      maxWidth: "calc(100vw - 320px)",
    },
  },
  rewardCardBody: {
    backgroundColor: theme.palette.background.paper,
  },
  tabRoot: {
    "&.MuiTab-root": {
      color: theme.palette.text.itemTextColor,
    },
    "&.Mui-selected": {
      color: theme.palette.text.titleTextColor,
      fontWeight: "bold",
    },
  },
  arrowIcon: {
    color: theme.palette.text.titleTextColor,
    width: 30,
  },
  arrow: {
    width: 22,
  },
  avatar: {
    backgroundColor: "#4CAF50", // Green background color
    color: theme.palette.common.white, // White text color
    width: theme.spacing(7), // Adjust the size if needed
    height: theme.spacing(7),
    fontSize: "24px", // Size of the letter inside
  },
}));

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

const UserDetailsModal = () => {
  const [activeIdx, setActiveIdx] = useState(0);
  const customerId = getLocalStore("customerId");
  const shortId = getLocalStore("shortId");
  const auth = useSelector((state) => state.auth);
  const { isActiveLoyalty } = useSelector((state) => state.loyalty);
  const { params } = useRouteMatch();
  const rewardDetailsList = useSelector(
    (state) => state.rewardDetails[`rewardDetailsList_${params.locationId}`],
  );

  const theme = useTheme();
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setActiveIdx(newValue);
  };

  const handleLogout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        logInfo({
          message: `User ${getLocalStore("userId")} logged out success`,
        });

        removeLocalStore("idToken");
        removeLocalStore("username");
        removeLocalStore("userId");
        // Define window.EATPRESTO if it doesn't exist
        if (!window.EATPRESTO) {
          window.EATPRESTO = {
            postMessage: (message) => {
              console.log("postMessage sent:", message);
              // Here you could also handle any required logging or fallback behavior.
            },
          };
        }

        // Send the logout message
        window.EATPRESTO.postMessage("logout_success");
      })
      .catch((error) => {
        logError({
          message: `Error logging out user ${getLocalStore("userId")}`,
          error,
        });
      });
  };

  const handleCircle = (no_of_spaces, state, stamped_spaces) => {
    const circles = [];

    for (let i = 0; i < no_of_spaces; i++) {
      circles.push(
        <Typography
          key={i}
          style={{
            display: "inline-flex",
            paddingRight: "4px",
            color:
              state === "pending"
                ? theme.palette.text.titleTextColor
                : state === "ready"
                ? "green"
                : "red",
          }}
        >
          {i < stamped_spaces ? (
            <Brightness1Icon />
          ) : (
            <Brightness1OutlinedIcon />
          )}
        </Typography>,
      );
    }

    return circles;
  };

  const maxWidthCustom = useMediaQuery("(max-width: 1280px)");
  const matchesSmallScreen = useMediaQuery("(max-width: 600px)");
  // Get the first letter of the email and find its index in the alphabet
  const firstChar = auth.email ? auth.email.charAt(0).toUpperCase() : "A";
  const charIndex = firstChar.charCodeAt(0) - 65; // A is 65 in ASCII, Z is 90

  return (
    <>
      <Tabs
        classes={{
          root: classes.roots,
        }}
        value={activeIdx}
        onChange={handleChange}
        variant="scrollable"
        selectionFollowsFocus
        scrollButtons={matchesSmallScreen ? "on" : "auto"}
        indicatorColor="primary"
        textColor="primary"
        aria-label="scrollable category menu items list"
        ScrollButtonComponent={(props) =>
          props.direction === "left" ? (
            <Fade in={!props.disabled}>
              <IconButtonDefault
                className={classes.arrow}
                aria-label="ArrowBackIosIcon"
                {...props}
              >
                <ChevronLeftIcon
                  className={classes.arrowIcon}
                  fontSize="large"
                />
              </IconButtonDefault>
            </Fade>
          ) : (
            <Fade in={!props.disabled}>
              <IconButtonDefault
                className={classes.arrow}
                aria-label="ArrowForwardIosIcon"
                {...props}
              >
                <ChevronRightIcon
                  className={classes.arrowIcon}
                  fontSize="large"
                />
              </IconButtonDefault>
            </Fade>
          )
        }
      >
        <Tab
          key={String(0)}
          classes={{ root: classes.tabRoot }}
          label={
            <Typography style={{ fontSize: "20px" }}>My Account</Typography>
          }
          {...a11yProps(0)}
        />
        {isActiveLoyalty?.isActive && (
          <Tab
            key={String(1)}
            classes={{ root: classes.tabRoot }}
            label={
              <Typography style={{ fontSize: "20px" }}>My Code</Typography>
            }
            {...a11yProps(1)}
          />
        )}
        {isActiveLoyalty?.isActive && (
          <Tab
            key={String(2)}
            classes={{ root: classes.tabRoot }}
            label={
              <Typography style={{ fontSize: "20px" }}>Rewards</Typography>
            }
            {...a11yProps(2)}
          />
        )}
      </Tabs>

      {/* Tab Content */}
      <TabPanel value={activeIdx} index={0} style={{ padding: "20px 34px" }}>
        <Typography variant="h4" align="center">
          Account
        </Typography>
        <Typography variant="body1" align="center">
          {auth.displayName}
        </Typography>
        <Typography variant="body1" align="center">
          {auth.email}
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Avatar
            className={classes.avatar}
            style={{ backgroundColor: colors[charIndex] || "#4CAF50" }} // Default to green if out of range
          >
            {firstChar}
          </Avatar>
        </div>
        <Card
          elevation={8}
          style={{
            height: "48px",
            backgroundColor: "transparent",
            display: "flex",
            alignItems: "center",
            marginTop: "100px",
            paddingLeft: "20px",
            cursor: "pointer",
          }}
          onClick={handleLogout}
        >
          <Typography>Log me out</Typography>
        </Card>
      </TabPanel>
      <TabPanel value={activeIdx} index={1} style={{ padding: "20px 34px" }}>
        <div style={{ display: "flex" }}>
          <Typography variant="h5">Your Code :</Typography>
          <Typography variant="h5" style={{ marginLeft: "8px" }}>
            {shortId}
          </Typography>
        </div>
        <Typography
          variant="h5"
          style={{
            marginTop: "20px",
          }}
        >
          Scan your QR Code
        </Typography>
        <QRCode
          size={256}
          style={{
            height: "200px",
            maxWidth: "100%",
            width: "100%",
            marginTop: "20px",
          }}
          value={customerId}
          viewBox={`0 0 256 256`}
        />
      </TabPanel>

      <TabPanel value={activeIdx} index={2} style={{ padding: "20px 34px" }}>
        {!_.isEmpty(rewardDetailsList?.rewards) ? (
          <>
            <Grid container>
              <Grid item xs={12} style={{ marginBottom: "20px" }}>
                <Typography
                  variant="h5"
                  align="center"
                >
                  Your Stamps
                </Typography>
                <Typography
                  align="center"
                  variant="h3"
                  style={{ fontWeight: "bold" }}
                >
                  {rewardDetailsList.points}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              {rewardDetailsList.rewards.map((value, index) => (
                <>
                  <Grid item xs={12} style={{ marginTop: "4px" }}>
                    <Card className={classes.rewardCardBody}>
                      <Grid container>
                        <Grid item xs={4} style={{ padding: "20px" }}>
                          {value.img ? (
                            <img
                              width="90%"
                              height="100%"
                              src={value.img}
                              alt="reward"
                            />
                          ) : (
                            <div style={{ height: "88px" }}></div>
                          )}
                        </Grid>
                        <Grid item xs={8} style={{ paddingTop: "8px" }}>
                          <Typography variant="h6">{value.title}</Typography>
                          <Typography variant="caption">
                            {value.description}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{ paddingTop: "8px", paddingLeft: "20px" }}
                        >
                          <Typography variant="body2">
                            {value.state === "pending" &&
                            value.stamped_spaces === 0
                              ? "Collect more stamps to redeem"
                              : value.state === "ready"
                              ? "Ready to Redeem"
                              : value.state === "used"
                              ? `Redeemed on ${moment(value.used_date).format(
                                  "Do MMMM YYYY",
                                )}`
                              : `${
                                  value.no_of_spaces - value.stamped_spaces
                                } more stamps to go`}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{
                            paddingTop: "8px",
                            paddingBottom: "8px",
                            paddingLeft: "20px",
                          }}
                        >
                          {handleCircle(
                            value.no_of_spaces,
                            value.state,
                            value.stamped_spaces,
                          )}
                        </Grid>
                      </Grid>
                      {/* <Grid
                        container
                        style={{ minHeight: "40px", placeItems: "center" }}
                      >
                        <Grid
                          item
                          xs={12}
                          lg={4}
                          style={{
                            display: "flex",
                            justifyContent: maxWidthCustom ? "center" : "start",
                            paddingLeft: "12px",
                            marginTop: maxWidthCustom ? "12px" : "0px",
                          }}
                        >
                          <Typography>{value.title}</Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          lg={4}
                          style={{
                            display: "flex",
                            justifyContent: maxWidthCustom ? "center" : "start",
                            marginTop:
                              maxWidthCustom && value.description
                                ? "12px"
                                : "0px",
                          }}
                        >
                          <Typography>{value.description}</Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          lg={2}
                          style={{
                            display: "flex",
                            justifyContent: maxWidthCustom ? "center" : "start",
                            marginTop: maxWidthCustom ? "12px" : "0px",
                          }}
                        >
                          <Typography>
                            {value.max_reward_value || "0.00"}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          lg={2}
                          style={{
                            display: "flex",
                            justifyContent: maxWidthCustom ? "center" : "end",
                            marginTop: maxWidthCustom ? "6px" : "0px",
                            marginBottom: maxWidthCustom ? "8px" : "0px",
                            paddingRight: maxWidthCustom ? "0px" : "20px",
                          }}
                        >
                          <Typography>{value.type}</Typography>
                        </Grid>
                      </Grid> */}
                    </Card>
                  </Grid>
                </>
              ))}
            </Grid>
          </>
        ) : (
          <Typography
            variant="h4"
            style={{
              marginTop: "80px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Rewards Not Available.
          </Typography>
        )}
      </TabPanel>
    </>
  );
};

export default UserDetailsModal;
