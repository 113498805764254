import React from "react";

import {
  Divider,
  Icon,
  makeStyles,
  Paper,
  Typography,
  useTheme,
} from "@material-ui/core";
import LocationOnIcon from "@material-ui/icons/LocationOn";

const useStyles = makeStyles((theme) => ({
  PickupDetailsWrapper: {
    backgroundColor: theme.palette.primary.main,
    position: "absolute",
    top: 0,
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.down("xs")]: {
      position: "initial",
      margin: theme.spacing(1, 0),
    },
  },
  pickupDateTime: {},
}));

const PickupDetails = ({ pickupAddress, pickupDateTime }) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Paper elevation={3} className={classes.PickupDetailsWrapper}>
      <Typography
        variant="subtitle2"
        component="div"
        style={{
          alignItems: "center",
          display: "flex",
        }}
      >
        <Icon>
          <LocationOnIcon />
        </Icon>
        Pickup from:
      </Typography>
      <Divider
        style={{
          margin: "4px 0",
          backgroundColor: theme.palette.primary.contrastText,
        }}
      />
      <Typography variant="body2" component="div">
        {pickupAddress}
      </Typography>
      {/* <Typography
        variant="subtitle2"
        component="div"
        className={classes.pickupDateTime}
      >
        Pickup time:&nbsp;
        {moment(pickupDateTime, "YYYY-MM-DD HH:mm:ss").format(
          "hh:mma dddd, MMMM D, YYYY",
        )}
      </Typography> */}
    </Paper>
  );
};

PickupDetails.propTypes = {
  // bla: PropTypes.string,
};

PickupDetails.defaultProps = {
  // bla: 'test',
};

export default PickupDetails;
