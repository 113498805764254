import React, { memo } from "react";

import { Box, Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Color from "color";

import PriceUtils from "../../../../../../utils/PriceUtils";
import MenuItemImage from "../../../../../common/images/MenuItemImage";

const useStyles = makeStyles((theme) => ({
  itemCardLeft: {
    padding: theme.spacing(1),
    flex: 5,
  },
  itemCardRight: {
    flex: 2,
    textAlign: "right",
  },
  itemCard: {
    cursor: "pointer",
    display: "flex",
    textAlign: "start",
    transition: "all 0.5s ease",
    margin: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    "&:hover": {
      backgroundColor: Color(
        theme.palette.background.menuBackgroundColor,
      ).isDark()
        ? Color(theme.palette.background.menuBackgroundColor)
          .mix(Color("#fff"), 0.2)
          .hex()
        : Color(theme.palette.background.menuBackgroundColor)
          .mix(Color("#000"), 0.2)
          .hex(),
      cursor: "pointer",
    },
    backgroundColor: Color(
      theme.palette.background.menuBackgroundColor,
    ).isDark()
      ? Color(theme.palette.background.menuBackgroundColor)
        .mix(Color("#fff"), 0.1)
        .hex()
      : Color(theme.palette.background.menuBackgroundColor)
        .mix(Color("#000"), 0.1)
        .hex(),
  },
}));

const MenuItemImageCard = ({ title, desc, menuItemData, id }) => {
  const classes = useStyles();
  const theme = useTheme();

  const priceDisplayText = PriceUtils.getMenuItemPrice(menuItemData);

  const { data } = menuItemData;
  const [firstItem] = data;

  return (
    <Box component="div" id={id} className={classes.itemCard}>
      <Box className={classes.itemCardLeft}>
        <Typography
          variant="subtitle1"
          component="div"
          style={{ color: theme.palette.text.itemTextColor }}
        >
          {title}
        </Typography>
        {desc && (
          <Typography
            variant="caption"
            style={{ color: theme.palette.text.itemDescColor }}
          >
            {desc}
          </Typography>
        )}
        <Typography
          variant="subtitle2"
          style={{ color: theme.palette.text.itemTextColor }}
        >
          {priceDisplayText}
        </Typography>
      </Box>
      <Box className={classes.itemCardRight}>
        <MenuItemImage
          src={firstItem.imageUrl}
          alt="menu item"
          curved="right"
          maxImageWidth={288}
          maxImageHeight={288}
        />
      </Box>
    </Box>
  );
};

function cardPropsEqual(prevProps, nextProps) {
  return prevProps.menuId === nextProps.menuId;
}

export default memo(MenuItemImageCard, cardPropsEqual);
