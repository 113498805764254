import React from "react";

import {
  Button,
  Dialog,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core";
import { useRouteMatch } from "react-router-dom";

import prestoIcon from "../../../assets/images/eatprestoicon.png";

const useStyles = makeStyles((theme) => ({
  CookieBlockModalWrapper: {},
  root: {
    "& .MuiDialog-paperFullWidth": {
      borderRadius: theme.shape.borderRadius,
    },
    "& .MuiDialog-container": {
      [theme.breakpoints.up("md")]: {
        height: theme?.view?.isFixedList ? "initial" : "100%",
      },
    },
  },
  textColor: {
    color:
      theme.palette.type === "dark"
        ? theme.palette.common.white
        : theme.palette.common.black,
    marginBottom: theme.spacing(2),
  },
}));

const CookieBlockModal = ({ open, handleClose }) => {
  const classes = useStyles();
  const theme = useTheme();
  const {
    params: { locationId },
  } = useRouteMatch();

  const handleClick = () => {
    window.parent.location.href = `https://app.eatpresto.co.uk/location/${locationId}/shop`;
  };

  return (
    <div className={classes.CookieBlockModalWrapper}>
      <Dialog
        open={open}
        keepMounted
        disableEscapeKeyDown
        maxWidth="sm"
        onClose={(e, reason) => handleClose(e, reason)}
        classes={{ root: classes.root }}
        aria-labelledby="cookie-block-modal"
        aria-describedby="cookie-block-modal"
      >
        <div style={{ padding: 24, textAlign: "center", fontWeight: "bold" }}>
          <Typography
            variant="h5"
            style={{
              color:
                theme.palette.type === "dark"
                  ? theme.palette.common.white
                  : theme.palette?.eatpresto?.red,
              marginBottom: theme.spacing(2),
            }}
          >
            A Message from Eat Presto Ordering Platform
          </Typography>

          <Typography variant="subtitle2" className={classes.textColor}>
            It looks like you have blocked third-party cookies or using
            incognito mode.
          </Typography>
          <Typography variant="subtitle2" className={classes.textColor}>
            Our Online Ordering application is embedded as a third-party
            application on this website and does not work without access to
            cookies to store order data.
          </Typography>
          <Typography variant="subtitle2" className={classes.textColor}>
            Please enable third-party cookies for this site or open the Online
            Ordering app directly.
          </Typography>

          <div>
            <Button
              style={{
                padding: theme.spacing(2),
                backgroundColor: theme.palette?.eatpresto?.red,
                color: theme.palette.common.white,
              }}
              variant="contained"
              onClick={handleClick}
            >
              Open Online Ordering App
            </Button>
          </div>
        </div>
        <img
          style={{ height: "100%", width: "100%" }}
          src={prestoIcon}
          alt="eatpresto icon"
        />
      </Dialog>
    </div>
  );
};

CookieBlockModal.propTypes = {};
CookieBlockModal.defaultProps = {};

export default CookieBlockModal;
