import React from "react";

import { Typography } from "@material-ui/core";

// const useStyles = makeStyles((theme) => ({
//   boxMain: {
//     display: "flex",
//     justifyContent: "space-between",
//   },
// }));

const CartItemMultiDirect = ({ cartItemData }) => {
  // const classes = useStyles();
  const { item } = cartItemData;
  return (
    <Typography variant="body2" component="div">
      {item.size}
    </Typography>
  );
};

export default CartItemMultiDirect;
