import React from "react";

import {
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    borderLeft: `1px solid ${theme.palette.background.boarderColor}`,
    backgroundColor: theme.palette.background.accentColor,
    [theme.breakpoints.down("xs")]: {
      borderLeft: "none",
    },
    display: "flex",
    flexDirection: "column",
  },
}));

const ContentSection = ({ children }) => {
  const classes = useStyles();

  return (
    <Grid classes={{ root: classes.root }} item sm={8} xs={12}>
      {children}
    </Grid>
  );
};

ContentSection.propTypes = {};
ContentSection.defaultProps = {};

export default ContentSection;
