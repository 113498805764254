import React from "react";

import { makeStyles } from "@material-ui/core";

const getStatusColor = (orderStatus) => {
  if (orderStatus === "new" || orderStatus === "pos") {
    return "#258AC2";
  }
  if (orderStatus === "accepted") {
    return "#CDA545";
  }
  if (orderStatus === "ready") {
    return "#88A548";
  }
  return "#258AC2";
};

const useStyles = makeStyles((theme) => ({
  ContentContainerWrapper: {
    padding: (props) => (props.noPadding ? 0 : 16),
    overflow: "hidden",
    width: "100%",
    maxHeight: 4000,
    minHeight: 618,
  },
  root: {
    border: "none",
    background: (props) =>
      `linear-gradient(186deg, ${
        props.orderStatus
          ? getStatusColor(props.orderStatus)
          : theme.palette.primary.main
      } 43%, ${theme.palette.background.menuBackgroundColor} 43.1%)`,
    [theme.breakpoints.down("sm")]: {
      background: (props) =>
        `linear-gradient(186deg, ${
          props.orderStatus
            ? getStatusColor(props.orderStatus)
            : theme.palette.primary.main
        } 37%, ${theme.palette.background.menuBackgroundColor} 37.2%)`,
    },
    [theme.breakpoints.down("xs")]: {
      background: (props) =>
        `linear-gradient(186deg, ${
          props.orderStatus
            ? getStatusColor(props.orderStatus)
            : theme.palette.primary.main
        } 44%, ${theme.palette.background.menuBackgroundColor} 44.2%)`,
    },
    overflowY: "auto",
    height: "100%",
    // boxSizing: "border-box",
    padding: 16,
  },
}));

const ContentContainer = ({
  children,
  noPadding,
  isOrderStatusPage,
  orderStatus,
}) => {
  const classes = useStyles({ noPadding, isOrderStatusPage, orderStatus });
  return (
    <div className={classes.ContentContainerWrapper}>
      <div className={classes.root}>{children}</div>
    </div>
  );
};

ContentContainer.propTypes = {};
ContentContainer.defaultProps = {};

export default ContentContainer;
