import React from "react";

import AccessTimeIcon from "@material-ui/icons/AccessTime";

import IconLabel from "../labels/IconLabel";
import WeeklyTimeView from "../WeeklyTimeView";

const TimeSpecificBadge = ({ availability }) => (
  <IconLabel
    text="Time specific"
    icon={<AccessTimeIcon />}
    tooltipContent={
      <WeeklyTimeView weeklyTimeList={availability} />
      }
  />
);

export default TimeSpecificBadge;
