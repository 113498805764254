import React, { useEffect, useState } from "react";

import { Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  PaymentRequestButtonElement,
  useStripe,
} from "@stripe/react-stripe-js";
import { useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";

import store from "../../../../../redux";
import { logError, logInfo } from "../../../../../services/logging";
import { completeOrder } from "../../../../../services/orderService";
import {
  clearLocalStoreOnOrderSuccess,
  getLocalStore,
} from "../../../../../utils/storage/localStorage";
import { getCompleteOrderErrorMessage } from "../../../../../utils/utils";

const useStyles = makeStyles((theme) => ({
  GooglePayWrapper: {
    textAlign: "center",
    paddingTop: 8,
    padding: theme.spacing(0, 6, 0, 6),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0),
    },
  },
}));

const GooglePay = ({
  paymentData,
  setError,
  setShowSuccessModal,
  setTimer,
  setSuccess,
}) => {
  const [paymentRequest, setPaymentRequest] = useState(null);
  const classes = useStyles();
  const stripe = useStripe();
  const { params } = useRouteMatch();
  const history = useHistory();
  const theme = useTheme();
  const orderId = getLocalStore("orderId");

  const { storeInfo } = useSelector((state) => state.store);

  const storeCleanup = () => {
    clearLocalStoreOnOrderSuccess();
    store.dispatch({
      type: "RESET",
    });
  };

  const processCompleteOrder = async () => {
    try {
      const res = await completeOrder(
        params.locationId,
        orderId,
        getCompleteOrderErrorMessage(
          storeInfo.businessDisplayName,
          storeInfo.contactNo,
        ),
      );
      logInfo({
        message: "GOOGLE_PAY | complete order request made. response received",
        completeOrderResponse: res?.data,
      });
      if (res.status === 200) {
        logInfo({
          message:
            "GOOGLE_PAY | complete order request successful. redirecting to order status page",
        });
        setShowSuccessModal(true);
        const timeout = setTimeout(() => {
          setShowSuccessModal(false);
          storeCleanup();
          history.replace(
            `/location/${params.locationId}/order-status/${orderId}`,
          );
        }, 5000);
        setTimer(timeout);
      } else {
        // setAlertMessage("Error placing the order!");
        logError(
          "GOOGLE_PAY | CRITICAL | Error complete order request. Did not receive 200 OK",
        );
        setError(
          "GOOGLE_PAY | Error occurred when completing order. Please check your connection.",
        );
      }
    } catch (err) {
      logError({
        message:
          "GOOGLE_PAY | CRITICAL | Error complete order request. EXCEPTION OCCURRED",
        error: err,
        errRes: err?.response,
      });
      setError(
        err?.response?.data?.message
          || "Unexpected error. Please check your connection",
      );
    }
  };

  useEffect(() => {
    if (!paymentData) return;
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: "GB",
        currency: "gbp",
        total: {
          label: "Payment Amount",
          amount: parseFloat(paymentData?.amount) * 100,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr);
        }
      });
    }
  }, [stripe, paymentData]);

  useEffect(() => {
    if (!paymentRequest) return;
    if (!paymentData) return;

    paymentRequest.on("paymentmethod", async (ev) => {
      // Confirm the PaymentIntent without handling potential next actions (yet).
      const {
        paymentIntent,
        error: confirmError,
      } = await stripe.confirmCardPayment(
        paymentData?.secret,
        { payment_method: ev.paymentMethod.id },
        { handleActions: false },
      );
      logInfo({
        message: "GOOGLE_PAY | confirm card payment response received",
        paymentIntent,
        confirmError,
      });
      if (confirmError) {
        logError({
          message: "GOOGLE_PAY | ERROR | confirm card payment",
          confirmError,
        });
        // Report to the browser that the payment failed, prompting it to
        // re-show the payment interface, or show an error message and close
        // the payment interface.
        ev.complete("fail");
        if (confirmError?.message) {
          return setError(confirmError?.message);
        }
        setError(
          "Sorry! We couldn't process your payment. Please try again or try different payment method",
        );
      } else {
        // Report to the browser that the confirmation was successful, prompting
        // it to close the browser payment method collection interface.
        ev.complete("success");
        // Check if the PaymentIntent requires any actions and if so let Stripe.js
        // handle the flow. If using an API version older than "2019-02-11" instead
        // instead check for: `paymentIntent.status === "requires_source_action"`.
        if (paymentIntent.status === "requires_action") {
          logInfo({
            message:
              "GOOGLE_PAY | WARNING | process on hold. requires action for payment to process",
            paymentIntentStatus: paymentIntent.status,
          });
          // Let Stripe.js handle the rest of the payment flow.
          const { error } = await stripe.confirmCardPayment(
            paymentData?.secret,
          );
          if (error) {
            logError({
              message: "GOOGLE_PAY | CRITICAL | Error processing payment",
              errorMessage: error?.message,
              error,
            });
            if (error?.message) {
              return setError(error?.message);
            }
            setError(
              "Sorry! We couldn't process your payment. Please try again or try different payment method",
            );
            // The payment failed -- ask your customer for a new payment method.
          } else {
            logInfo({ message: "GOOGLE_PAY | payment process successful" });
            // The payment has succeeded.
            setSuccess("Payment successfully processed");
            processCompleteOrder();
          }
        } else {
          // The payment has succeeded.
          logInfo({ message: "GOOGLE_PAY | payment process successful" });
          setSuccess("Payment successfully processed");
          processCompleteOrder();
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentRequest, paymentData, stripe]);

  return (
    <>
      {paymentRequest && (
        <div className={classes.GooglePayWrapper}>
          <Typography
            variant="subtitle2"
            component="div"
            style={{ padding: 8 }}
          >
            Pay using a digital wallet, Google pay or Apple Pay
          </Typography>
          <PaymentRequestButtonElement
            options={{
              paymentRequest,
              style: {
                paymentRequestButton: {
                  type: "default",
                  // One of 'default', 'book', 'buy', or 'donate'
                  // Defaults to 'default'

                  theme: theme.palette.type,
                  // One of 'dark', 'light', or 'light-outline'
                  // Defaults to 'dark'

                  height: "48px",
                  // Defaults to '40px'. The width is always '100%'.
                },
              },
            }}
          />
          <Typography
            variant="subtitle2"
            component="div"
            style={{ padding: 8 }}
          >
            Or enter card details
          </Typography>
        </div>
      )}
    </>
  );
};

GooglePay.propTypes = {};
GooglePay.defaultProps = {};

export default GooglePay;
