import React from "react";

import {
  Grid,
  Hidden,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { Skeleton } from "@material-ui/lab";
import Color from "color";

const useStyles = makeStyles((theme) => ({
  cardWrapperSelectedOrderDetailsMobile: {
    padding: "8px",
    color: theme.palette.text.itemTextColor,
  },
  CartItemCardTextMobile: {
    width: "80%",
    marginLeft: "10px",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: Color(
      theme.palette.background.menuBackgroundColor,
    ).isDark()
      ? Color(theme.palette.background.menuBackgroundColor)
          .mix(Color("#fff"), 0.1)
          .hex()
      : Color(theme.palette.background.menuBackgroundColor)
          .mix(Color("#000"), 0.1)
          .hex(),
    padding: theme.spacing(1),
  },
  CartItemCardText: {
    width: "80%",
    marginLeft: "20px",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: Color(
      theme.palette.background.menuBackgroundColor,
    ).isDark()
      ? Color(theme.palette.background.menuBackgroundColor)
          .mix(Color("#fff"), 0.1)
          .hex()
      : Color(theme.palette.background.menuBackgroundColor)
          .mix(Color("#000"), 0.1)
          .hex(),
    padding: theme.spacing(1),
  },
  CartItemCardBox: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "4px",
    minHeight: 120,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: Color(
      theme.palette.background.menuBackgroundColor,
    ).isDark()
      ? Color(theme.palette.background.menuBackgroundColor)
          .mix(Color("#fff"), 0.1)
          .hex()
      : Color(theme.palette.background.menuBackgroundColor)
          .mix(Color("#000"), 0.1)
          .hex(),
    padding: theme.spacing(1),
  },
  CartItemCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: "4px",
    minHeight: 48,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: Color(
      theme.palette.background.menuBackgroundColor,
    ).isDark()
      ? Color(theme.palette.background.menuBackgroundColor)
          .mix(Color("#fff"), 0.1)
          .hex()
      : Color(theme.palette.background.menuBackgroundColor)
          .mix(Color("#000"), 0.1)
          .hex(),
    padding: theme.spacing(1),
  },
  cardWrapperSelectedOrderDetailsDesktop: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "78vh",
    padding: "8px",
    color: theme.palette.text.itemTextColor,
    [theme.breakpoints.down("md")]: {
      height: "85vh",
    },
  },
  itemListing: {
  },
  headingStyle: {
    display: "flex",
    flexDirection: "column",
    height: "99%",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      marginBottom: "0px",
    },
  },
}));

const SkeletonLoading = () => {
  const classes = useStyles();
  return (
    <>
      <Grid container>
        <Hidden xsDown>
          <Grid item xs={12} sm={5}>
            <div className={classes.cardWrapperSelectedOrderDetailsDesktop}>
              <div className={classes.itemListing}>
                <Skeleton variant="rect" className={classes.CartItemCard} />
                <Skeleton variant="rect" className={classes.CartItemCard} />
                <Skeleton variant="rect" className={classes.CartItemCard} />
                <Skeleton variant="rect" className={classes.CartItemCard} />
              </div>

              <div
                style={{
                  width: "80%",
                  
                }}
              >
                <Skeleton variant="text" className={classes.CartItemCardText} style={{marginTop :"40px"}} />
                <Skeleton variant="text" className={classes.CartItemCardText} />
                <Skeleton variant="text" className={classes.CartItemCardText} />
                <Skeleton variant="text" className={classes.CartItemCardText} />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={7}>
            <div className={classes.headingStyle}>
              <div>
                {" "}
                <Skeleton variant="text" className={classes.CartItemCardText} />
                <Skeleton variant="text" className={classes.CartItemCardText} />
                <Skeleton variant="text" className={classes.CartItemCardText} />
                <Skeleton variant="text" className={classes.CartItemCardText} />
              </div>
              <div>
                {" "}
                <Skeleton variant="text" className={classes.CartItemCardText} />
                <Skeleton
                  variant="text"
                  className={classes.CartItemCardText}
                />
                <Skeleton variant="text" className={classes.CartItemCardText} />
                <Skeleton variant="text" className={classes.CartItemCardText} />
              </div>
            </div>
          </Grid>
        </Hidden>

        <Hidden smUp>
          <Grid item xs={12}>
            <Skeleton
              variant="react"
              style={{ margin: "10px" }}
              className={classes.CartItemCard}
            />
            <Skeleton
              variant="react"
              style={{ margin: "10px" }}
              className={classes.CartItemCardBox}
            />
            <div>
              <div className={classes.cardWrapperSelectedOrderDetailsMobile}>
                <Skeleton
                  variant="rect"
                  className={classes.CartItemCard}
                  style={{ marginTop: "30px" }}
                />
                <Skeleton variant="rect" className={classes.CartItemCard} />
                <Skeleton variant="rect" className={classes.CartItemCard} />
              </div>
              <Skeleton
                variant="text"
                className={classes.CartItemCardTextMobile}
                style={{ marginTop: "30px" }}
              />
              <Skeleton
                variant="text"
                className={classes.CartItemCardTextMobile}
              />
              <Skeleton
                variant="text"
                className={classes.CartItemCardTextMobile}
              />
            </div>
          </Grid>
        </Hidden>
      </Grid>
    </>
  );
};

SkeletonLoading.propTypes = {};
SkeletonLoading.defaultProps = {};

export default SkeletonLoading;
