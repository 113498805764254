/* eslint-disable implicit-arrow-linebreak */
import axiosClient from "./client";

/**
 *
 * @param {string} locationId
 */
export async function getLocationOrderRelatedInfo(locationId) {
  return axiosClient({
    url: `${process.env.REACT_APP_API_ENDPOINT_EATPRESTO}/public/location/${locationId}`,
    method: "GET",
  });
}

/**
 *
 * @param {string} locationId
 */
export async function getLocationTheme(locationId) {
  return axiosClient({
    url: `${process.env.REACT_APP_API_ENDPOINT_EATPRESTO}/public/location/${locationId}/theme`,
    method: "GET",
  });
}

/**
 *
 * @param {string} locationId
 */
export async function getLocationOpeningTimes(locationId) {
  return axiosClient({
    url: `${process.env.REACT_APP_API_ENDPOINT_LOCATION}/public/location/${locationId}/opening-time`,
    method: "GET",
  });
}

export async function getLocationInfo(locationId) {
  return axiosClient({
    url: `${process.env.REACT_APP_API_ENDPOINT_LOCATION}/public/location/${locationId}`,
    method: "GET",
  });
}

export async function getCombinedLocationInfo(locationId) {
  return axiosClient({
    url: `${process.env.REACT_APP_API_ENDPOINT_EATPRESTO}/public/location/${locationId}`,
    method: "GET",
  });
}

// export function validatePostalCode(postalCode) {
//   return axiosClient({
//     url: `api.postcodes.io/postcodes/${postalCode}}/validate`,
//     method: "GET",
//   });
// }
