import axios from "axios";

// eslint-disable-next-line import/prefer-default-export
export async function createCustomToken(token) {
  return axios({
    url: `${process.env.REACT_APP_CUSTOM_TOKEN}/create_custom_token`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
}
