import { isFunction } from "lodash";
import { loginUserSuccess, setIsOpenLoginModal } from "../redux/actions/auth";
import { setLocalStore } from "./utils";
import { fetchRewardDetailsListAsync, fetchRewardDetailsListSuccess } from "../components/@pages/storeHome/CatMenu/redux/actions";

export const updateLocalStorage = (
  token,
  dispatch,
  firebaseUser,
  history,
  params,
  location,
  isActiveLoyalty,
) => {
  dispatch(setIsOpenLoginModal(false));
  setLocalStore("idToken", token);
  console.log("sssssssssssssssdfsdfgdgdfg", isActiveLoyalty?.isActive)
  if (isActiveLoyalty?.isActive) {
    console.log("sssssssssssssssdfsdfgdgdfg 2", isActiveLoyalty?.isActive)
    dispatch(fetchRewardDetailsListAsync(params.locationId));
  } else {
    console.log("sssssssssssssssdfsdfgdgdfg 3", isActiveLoyalty?.isActive)
    dispatch(fetchRewardDetailsListSuccess({locationId: params.locationId, points: []}));
  }
  dispatch(
    loginUserSuccess(
      `${firebaseUser.firstName} ${firebaseUser.lastName}`,
      firebaseUser.email,
    ),
  );

  setLocalStore("userId", firebaseUser.uid);

  setTimeout(() => {
    if (window.authCallback && isFunction(window.authCallback)) {
      window.authCallback();
      window.authCallback = null;
    }

    history.push({
      pathname: `/location/${params.locationId}/shop`,
      search: location.search,
    });
  }, 1000);
};
