import React from "react";

import isEmpty from "lodash/isEmpty";

import { convertDayCodeToNames } from "../../utils/utils";

const WeeklyTimeView = ({ weeklyTimeList }) => (
  <div>
    {!isEmpty(weeklyTimeList)
        && weeklyTimeList.map((node) => (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: 8,
            }}
          >
            <span>{convertDayCodeToNames(node.day)}</span>
            {" "}
            <span>
              &nbsp;
              {node.openTime}
              {" - "}
              {node.closeTime}
            </span>
          </div>
        ))}
  </div>
);

export default WeeklyTimeView;
