/* eslint-disable react/jsx-props-no-spreading */
import React from "react";

import { connect } from "react-redux";
import { Redirect, Route, useLocation } from "react-router-dom";

const AuthenticatedRoute = ({
  component: Component,
  isAuthenticated,
  isLoading,
  completedPages,
  ...rest
}) => {
  const location = useLocation();
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated && !isLoading) {
          const page = props.match.path.split(":locationId")[1];
          if (completedPages.includes(page)) {
            return <Component {...props} />;
          }
        }

        return (
          <Redirect
            to={{
              pathname: `/location/${props.match.params.locationId}/shop`,
              state: { from: props.location },
              search: location.search,
            }}
          />
        );
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isFetching,
  isAuthenticated: state.auth.isAuthenticated,
  completedPages: state.route.completedPages,
});

export default connect(mapStateToProps)(AuthenticatedRoute);

// (isAuthenticated && !isLoading ? (
//   <Component {...props} />
// ) : (
//   <Redirect
//     to={{
//       pathname: `/location/${props.match.params.locationId}/shop`,
//       state: { from: props.location },
//     }}
//   />
// ))
