import {
  SET_SELECTED_ITEM_LIST,
  SET_CURRENT_POS,
  SET_TITLE_POS_LIST,
} from "./actions";

export const ItemInitialState = {
  itemList: [],
  currentScrollPos: {},
  titlesPosList: [],
};

export function ItemReducer(state = ItemInitialState, action) {
  switch (action.type) {
    case SET_SELECTED_ITEM_LIST:
      return {
        ...state,
        itemList: action.payload,
      };

    case SET_CURRENT_POS:
      return {
        ...state,
        currentScrollPos: action.payload,
      };
    case SET_TITLE_POS_LIST:
      return {
        ...state,
        titlesPosList: action.payload,
      };
    default:
      return state;
  }
}
