import React from "react";

import { makeStyles, Typography } from "@material-ui/core";

import ButtonContained from "../../components/common/buttons/ButtonContained";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "16px 8px",
    [theme.breakpoints.up("sm")]: {
      margin: "20px 44px 10px 48px",
    },
  },
}));

const ReOrder = ({ handleReOrder }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <ButtonContained fullWidth onClick={handleReOrder}>
        <Typography>RE-ORDER</Typography>
      </ButtonContained>
    </div>
  );
};

ReOrder.propTypes = {};
ReOrder.defaultProps = {};

export default ReOrder;
