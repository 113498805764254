import React, { useEffect, useState } from "react";

import { Dialog, makeStyles, Typography } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { useDispatch, useSelector } from "react-redux";
import { setIsOpenLoginModal } from "../../../../redux/actions/auth";
import SignInPage from "./SignInPage";
import RegistrationPage from "./RegistrationPage";
import PasswordResetModal from "../../signInPage/SignInPage/PasswordResetModal";
import UserDetailsModal from "./UserDetailsModal";
import AlertMessage from "../../../common/AlertMessage";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDialog-paperFullWidth": {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.menuBackgroundColor,
    },
    "& .MuiDialog-container": {
      [theme.breakpoints.up("md")]: {
        height: theme?.view?.isFixedList ? "initial" : "100%",
      },
    },
  },
  form: {
    padding: theme.spacing(1, 1, 0, 1),
    display: "flex",
    flexDirection: "column",
    // color: theme.palette.text.primaryTextColor,
  },
  titleSection: {
    padding: theme.spacing(2),
    textAlign: "center",
    // color: theme.palette.text.primaryTextColor,
  },
  makeMiddle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const LoginDialog = () => {
  const [authError, setAuthError] = useState("");
  const [isLoginOrRegister, setIsLoginOrRegister] = useState(true);
  const [isOpenPasswordResetNodal, setIsOpenPasswordResetNodal] =
    useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [
    isOpenEmailVerificationLoadingModal,
    setIsOpenEmailVerificationLoadingModal,
  ] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [customerDetails, setCustomerDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });

  const dispatch = useDispatch();
  const { isAuthenticated, isOpenLoginModal } = useSelector(
    (state) => state.auth,
  );

  const classes = useStyles();
  useEffect(() => {
    setAuthError("");
    setIsLoginOrRegister(true);
  }, [isOpenLoginModal]);

  const handleClose = (event, reason) => {
    if (
      (reason === "backdropClick" || reason === "escapeKeyDown") &&
      isOpenEmailVerificationLoadingModal
    ) {
      return; // Disable closing the dialog via backdrop or Escape key
    }

    dispatch(setIsOpenLoginModal(false));
  };

  const handleChangeLoginStatus = () => {
    setIsLoginOrRegister(!isLoginOrRegister);
  };

  return (
    <>
      <Dialog
        classes={{ root: classes.root }}
        open={isOpenLoginModal}
        keepMounted={false}
        maxWidth="sm"
        fullWidth
        onClose={handleClose}
        aria-labelledby="login-dialog"
        aria-describedby="login-dialog-content"
      >
        <div className={!isAuthenticated && classes.titleSection}>
          {!isAuthenticated && !isOpenEmailVerificationLoadingModal && (
            <Typography variant="h5">Come Here Often?</Typography>
          )}
          {!isAuthenticated && !isOpenEmailVerificationLoadingModal && (
            <Typography variant="subtitle1">
              Connect with us for fast checkout
            </Typography>
          )}
          {/* {isAuthenticated && !isOpenEmailVerificationLoadingModal && (
            <>
              <Typography variant="h5">Hello {displayName}</Typography>
              <Typography variant="subtitle1">
                Want to login as a different user ?
              </Typography>
              <ButtonContained height="40px" onClick={handleLogout}>
                Log me out
              </ButtonContained>
            </>
          )} */}
        </div>
        <div style={{ padding: isAuthenticated ? 0 : 0 }}>
          <div style={{ padding: "4px 0" }}>
            {authError && !isOpenEmailVerificationLoadingModal && (
              <MuiAlert severity="error" elevation={6} variant="filled">
                {authError}
              </MuiAlert>
            )}
          </div>

          {/* {isLoginOrRegister &&
         
              <SignInPage
                setIsOpenPasswordResetNodal={setIsOpenPasswordResetNodal}
                handleChangeLoginStatus={handleChangeLoginStatus}
                authError={authError}
                setAuthError={setAuthError}
                setIsOpenEmailVerificationLoadingModal={
                  setIsOpenEmailVerificationLoadingModal
                }
                setCustomerDetails={setCustomerDetails}
                isOpenEmailVerificationLoadingModal={
                  isOpenEmailVerificationLoadingModal
                }
                customerDetails={customerDetails}
              />
            } */}

          {isLoginOrRegister &&
            (isAuthenticated ? (
              <UserDetailsModal />
            ) : (
              <SignInPage
                setIsOpenPasswordResetNodal={setIsOpenPasswordResetNodal}
                handleChangeLoginStatus={handleChangeLoginStatus}
                setAuthError={setAuthError}
                setIsOpenEmailVerificationLoadingModal={
                  setIsOpenEmailVerificationLoadingModal
                }
                setCustomerDetails={setCustomerDetails}
                isOpenEmailVerificationLoadingModal={
                  isOpenEmailVerificationLoadingModal
                }
                customerDetails={customerDetails}
              />
            ))}

          {!isLoginOrRegister && (
            <RegistrationPage
              handleChangeLoginStatus={handleChangeLoginStatus}
              setAuthError={setAuthError}
              setIsOpenEmailVerificationLoadingModal={
                setIsOpenEmailVerificationLoadingModal
              }
              setCustomerDetails={setCustomerDetails}
              isOpenEmailVerificationLoadingModal={
                isOpenEmailVerificationLoadingModal
              }
              customerDetails={customerDetails}
            />
          )}

          <span style={{ margin: 8 }} />
        </div>
      </Dialog>

      <PasswordResetModal
        setOpenDialog={setIsOpenPasswordResetNodal}
        openDialog={isOpenPasswordResetNodal}
        setIsLoading={setIsLoading}
        setError={setAuthError}
        isLoading={isLoading}
        setAlertMessage={setAlertMessage}
      />

      <AlertMessage
        message={alertMessage}
        setMessage={setAlertMessage}
        severity="error"
        anchorOrigin="bottom"
      />
    </>
  );
};

LoginDialog.propTypes = {};
LoginDialog.defaultProps = {};

export default LoginDialog;
