import React from "react";

import { IconButton, SwipeableDrawer, Typography } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";

import BottomStickyBtn from "../../../common/BottomStickyBtn";
import Menu from "../Menu";

const MenuSideBar = ({ currentCat, setCurrentCat, isIcon, setSearchName, setIsOpen }) => {
  const [sideBarOpen, setSideBarOpen] = React.useState(false);

  const Label = () => (
    <>
      <Typography variant="button">Categories</Typography>
      {/* <Badge style={{ marginLeft: 8 }} badgeContent={cartCount} color="primary">
        <ShoppingCartIcon />
      </Badge> */}
    </>
  );

  return (
    <>
      <SwipeableDrawer
        anchor="left"
        open={sideBarOpen}
        onClose={() => setSideBarOpen(false)}
        onOpen={() => setSideBarOpen(true)}
      >
        <Menu
          currentCat={currentCat}
          setCurrentCat={setCurrentCat}
          setSideBarOpen={setSideBarOpen}
          setSearchName={setSearchName}
          setIsOpen={setIsOpen}
        />
      </SwipeableDrawer>

      {isIcon ? (
        <IconButton style={{ color: "inherit", marginLeft: 4 }} onClick={() => setSideBarOpen(true)}>
          <MenuIcon />
        </IconButton>
      ) : (
        <BottomStickyBtn label={<Label />} setOpen={setSideBarOpen} />
      )}
    </>
  );
};

export default MenuSideBar;
