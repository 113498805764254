import React from "react";

import {
  AppBar,
  Dialog,
  IconButton,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import _ from "lodash";

import MyOrder from "../../@pages/storeHome/MyOrder";
import UserAccount from "../../@pages/storeHome/UserAccount";
import OrderSummary from "./OrderSummary";
import LoginDialog from "../../@pages/storeHome/LoginDialog";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const MobileCartDialog = ({ setBucketOpen, bucketOpen, orderData }) => {
  const classes = useStyles();

  return (
    <Dialog fullScreen open={bucketOpen} onClose={() => setBucketOpen(false)}>
      <LoginDialog />
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setBucketOpen(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            ORDER
          </Typography>
          <UserAccount />
        </Toolbar>
      </AppBar>
      {_.isEmpty(orderData) ? (
        <MyOrder />
      ) : (
        <OrderSummary orderData={orderData} />
      )}
    </Dialog>
  );
};

export default MobileCartDialog;
