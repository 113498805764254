import React from "react";
import {
  Button,
  Grid,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import { STICKY_BUTTON_HEIGHT } from "../../../../common/buttons/StickyButton";
import { Skeleton } from "@material-ui/lab";
import _ from "lodash";
import PageTitle from "../../../../common/PageTitle";
import ButtonDefault from "../../../../common/buttons/ButtonDefault";

export const HEADER_HEIGHT = 15;
const CONTENT_SECTION_PADDING = 32;

const VIEWPORT_OFFSET =
  HEADER_HEIGHT + CONTENT_SECTION_PADDING + STICKY_BUTTON_HEIGHT + 230;

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    marginTop: "24px",
    overflowY: "auto",
    ...theme.palette.scrollbarStyles,
    "&::-webkit-scrollbar": {
      width: 8,
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.2)",
      borderRadius: 7,
    },
    [theme.breakpoints.down("sm")]: {
      height: 1200,
      minHeight: `calc(100vh - ${VIEWPORT_OFFSET}px)`,
      maxHeight: `calc(100vh - ${VIEWPORT_OFFSET}px)`,
    },
    [theme.breakpoints.down("md")]: {
      height: 2000,
      minHeight: `calc(100vh - ${VIEWPORT_OFFSET}px)`,
      maxHeight: `calc(100vh - ${VIEWPORT_OFFSET}px)`,
    },
    [theme.breakpoints.up("md")]: {
      maxHeight: `calc(100vh - ${VIEWPORT_OFFSET + 48}px)`,
      minHeight: `calc(100vh - ${VIEWPORT_OFFSET + 48}px)`,
    },
    width: "100%",
  },
  buttonStyle: {
    border: `1px solid ${theme.palette.text.accentTextColor}`,
    display: "block",
    textTransform: "none",
    borderRadius: "10px",
    marginBottom: "16px",
  },
  skeleton: {
    width: "98%",
    height: "80px",
    marginBottom: "4px",
    borderRadius: "10px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  addressStyle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primaryTextColor,
    minHeight: "72px",
    margin: "20px 4px 4px 4px",
    borderRadius: "10px",
    padding: "4px 12px 8px 12px",
  },
}));

/**
 * This component represents a delivery address selection list.
 * It displays a list of saved delivery addresses and
 * allows users to add a new address or select from existing ones.
 */
const DeliverList = ({
  setIsDeliveryListPage,
  handleOpenDeliveryView,
  deliveryListNode,
  setIsDisabled,
  isLoading,
}) => {
  /**
   * This function, handleAddressFirstRaw, takes a data object representing a delivery address and
   * constructs the first line of the address.
   */
  const handleAddressFirstRaw = (data) => {
    const { addressLine1, doorNo } = data;
    const result = [];

    // Check if doorNo exists and is not empty, then add it to the result array
    if (doorNo) {
      result.push(doorNo);
    }

    // Check if addressLine1 exists and is not empty, then add it to the result array
    if (addressLine1) {
      result.push(addressLine1);
    }

    // Join the address elements in the result array with a comma and space separator
    const output = result.join(", ");
    return output;
  };

  /**
   * This function, handleAddressSecondRaw, takes a data object representing a delivery address and
   * constructs the second line of the address.
   */
  const handleAddressSecondRaw = (data) => {
    const { addressLine2, city } = data;
    const result = [];

    // Check if addressLine2 exists and is not empty, then add it to the result array
    if (addressLine2) {
      result.push(addressLine2);
    }

    // Check if city exists and is not empty, then add it to the result array
    if (city) {
      result.push(city);
    }

    // Join the address elements in the result array with a comma and space separator
    const output = result.join(", ");
    return output;
  };

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const classes = useStyles();
  return (
    <>
      <div style={{ margin: 5 }} />
      <PageTitle title="Select your delivery address and contact information" />
      <ButtonDefault
        bgColor={theme.palette.text.accentTextColor}
        textColor={theme.palette.background.accentColor}
        style={
          matches
            ? {
                width: "280px",
                textTransform: "none",

                display: "flex",
                alignItems: "center",
              }
            : {
                width: "100%",
                textTransform: "none",

                display: "flex",
                alignItems: "center",
              }
        }
        onClick={() => {
          setIsDeliveryListPage(false);
          setIsDisabled(true);
        }}
        fullWidth
      >
        <Typography style={{ fontWeight: "bold", fontSize: "18px" }}>
          Deliver to a new address
        </Typography>
        <Typography>
          <AddIcon
            style={{
              fontWeight: "bold",
              fontSize: "20px",
              marginLeft: "8px",
              marginTop: "7px",
            }}
          />
        </Typography>
      </ButtonDefault>
      <div className={classes.cardWrapper}>
        <Grid container>
          {!isLoading ? (
            !_.isEmpty(deliveryListNode) ? (
              deliveryListNode.map((data, index) => {
                return (
                  <Grid item xs={12} md={6} xl={4} key={index}>
                    <Button
                      onClick={() => handleOpenDeliveryView(data)}
                      className={classes.buttonStyle}
                      style={matches ? { width: "96%" } : { width: "100%" }}
                    >
                      <Typography
                        alignItems="start"
                        style={{
                          paddingLeft: "4px",
                          display: "flex",
                          justifyContent: "start",
                        }}
                      >
                        {data.firstName + " " + data.lastName}
                      </Typography>
                      <Typography
                        alignItems="start"
                        style={{
                          paddingLeft: "4px",
                          display: "flex",
                          justifyContent: "start",
                        }}
                      >
                        {data.email}
                      </Typography>
                      <Typography
                        alignItems="start"
                        style={{
                          paddingLeft: "4px",
                          display: "flex",
                          justifyContent: "start",
                        }}
                      >
                        {data.contactNo}
                      </Typography>
                      <div className={classes.addressStyle}>
                        <Typography
                          style={{
                            textAlign: "left",
                          }}
                        >
                          {handleAddressFirstRaw(data)}
                        </Typography>
                        <Typography
                          style={{
                            textAlign: "left",
                          }}
                        >
                          {handleAddressSecondRaw(data)}
                        </Typography>
                        <Typography
                          style={{
                            display: "flex",
                            justifyContent: "start",
                            fontWeight: "bolder",
                            lineHeight: "24px",
                            fontSize: "18px",
                          }}
                        >
                          {data.postcode}
                        </Typography>
                      </div>
                    </Button>
                  </Grid>
                );
              })
            ) : (
              <Grid
                item
                xs={12}
                style={{
                  marginTop: "5%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography variant="h4">No saved addresses.</Typography>
              </Grid>
            )
          ) : (
            <>
              <Grid item xs={12} md={6} xl={4}>
                <Skeleton variant="rect" className={classes.skeleton} />
              </Grid>
              <Grid item xs={12} md={6} xl={4}>
                <Skeleton variant="rect" className={classes.skeleton} />
              </Grid>
              <Grid item xs={12} md={6} xl={4}>
                <Skeleton variant="rect" className={classes.skeleton} />
              </Grid>
              <Grid item xs={12} md={6} xl={4}>
                <Skeleton variant="rect" className={classes.skeleton} />
              </Grid>
              <Grid item xs={12} md={6} xl={4}>
                <Skeleton variant="rect" className={classes.skeleton} />
              </Grid>
              <Grid item xs={12} md={6} xl={4}>
                <Skeleton variant="rect" className={classes.skeleton} />
              </Grid>
            </>
          )}
        </Grid>
      </div>
    </>
  );
};

export default DeliverList;
