/* eslint-disable no-alert */
import React from "react";

import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { logInfo } from "../../../../../services/logging";
import { ASAP_TIME_LABEL, TIME_FORMAT_YYYYMMDD_HHMMSS } from "../../../../../utils/constants";
import moment from "../../../../../utils/time/moment";
import { setSelectedDateTime } from "../redux/actions";

const useStyles = makeStyles(() => ({
  HtmlPickerWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: 8,
  },
}));

const HtmlPicker = ({ minimumDelayMin }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { selectedDateTime, selectedDeliveryMethod } = useSelector((state) => state.cart);

  const handleTimeChange = (time) => {
    if (time.length !== 16) return;
    const datetime = moment(time, "YYYY-MM-DDTHH:mm").format(
      TIME_FORMAT_YYYYMMDD_HHMMSS,
    );
    // const selectedDate = moment(selectedDateTime).format("YYYY-MM-DD");
    // const selectedTime = moment(time, "HH:mm").format("HH:mm:ss");
    const timeGap = moment(datetime).diff(moment(), "minutes");
    if (timeGap <= minimumDelayMin) {
      logInfo({
        message: `Selected time gap to current time is less than min delay time | minimumDelayMin: ${minimumDelayMin}} | current time: ${datetime} | selectedDeliveryMethod: ${selectedDeliveryMethod}`,
      });
      // eslint-disable-next-line consistent-return
      return alert(
        `The selected time should not be less than ${minimumDelayMin} minutes from the current time. If required sooner, please select ${ASAP_TIME_LABEL}.`,
      );
    }

    dispatch(setSelectedDateTime(datetime));
  };

  return (
    <div className={classes.HtmlPickerWrapper}>
      <label htmlFor="timepicker">
        <strong>Select date and&nbsp;time</strong>
      </label>
      <span style={{ margin: 8 }} />
      <input
        type="datetime-local"
        id="timepicker"
        name="timepicker"
        value={moment(selectedDateTime).format("YYYY-MM-DDTHH:mm")}
        onChange={(e) => handleTimeChange(e.target.value)}
        required
      />
    </div>
  );
};

HtmlPicker.propTypes = {};
HtmlPicker.defaultProps = {};

export default HtmlPicker;
