import { useHistory, useLocation } from "react-router-dom";

import { getCookie, removeCookie, setCookie } from "../../utils/cookies";

const useRouteNavigation = () => {
  const history = useHistory();
  const { search } = useLocation();

  const navigateRoute = (pathname) => {
    history.push({
      pathname,
      search,
    });
  };

  const redirectFlowNextRoute = () => {
    const nextRoute = getCookie("nextRoute");
    if (!nextRoute) return;
    history.push(nextRoute);
    removeCookie("nextRoute");
  };

  const setFlowNextRoute = (pathname) => {
    setCookie("nextRoute", pathname + search, { expires: (1 / (24 * 60 * 60)) * 15 });
  };

  return {
    navigateRoute,
    redirectFlowNextRoute,
    setFlowNextRoute,
  };
};

export default useRouteNavigation;
