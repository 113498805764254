import _ from "lodash";

import { getLocationIdByPathname } from "../utils";

export function parseLocalStoreJSON(name) {
  try {
    return JSON.parse(localStorage.getItem(name));
  } catch {
    return localStorage.getItem(name);
  }
}

export function setLocalStore(name, value) {
  if (name === "orderId") {
    const locationId = getLocationIdByPathname();
    return localStorage.setItem(`orderId_${locationId}`, value);
  }
  if (_.isObject(value) || _.isArray(value)) {
    return localStorage.setItem(name, JSON.stringify(value));
  }
  return localStorage.setItem(name, value);
}

export function getLocalStore(name) {
  try {
    if (name === "orderId") {
      const locationId = getLocationIdByPathname();
      const orderId = localStorage.getItem(`orderId_${locationId}`);
      return orderId.replaceAll("\"", "");
    }
    return parseLocalStoreJSON(name);
  } catch {
    return null;
  }
}

export function removeLocalStore(name) {
  try {
    localStorage.removeItem(name);
  } catch {
    // handle error
  }
}

export function clearLocalStore() {
  localStorage.clear();
}

export function clearLocalStoreOnOrderSuccess() {
  const token = getLocalStore("idToken");
  const username = getLocalStore("username");
  const userId = getLocalStore("userId");
  clearLocalStore();
  setLocalStore("idToken", token);
  setLocalStore("username", username);
  setLocalStore("userId", userId);
}
