/* eslint-disable no-underscore-dangle */
/* eslint-disable import/prefer-default-export */
import _ from "lodash";
import every from "lodash/every";
import filter from "lodash/filter";
import isEmpty from "lodash/isEmpty";

import moment from "./time/moment";
import TimeUtils from "./TimeUtils";
import { convertDeliveryType } from "./utils";

export function getAvailabilityOptions(options, menuID, catID, catMenuID) {
  return {
    itemOption: options[`M${menuID}`],
    catOption: options[`C${catID}`],
    catMenuOption: options[`CM${catMenuID}`],
  };
}

export function getIsItemActive(options, menuID, catID, catMenuID) {
  const { itemOption, catOption, catMenuOption } = getAvailabilityOptions(
    options,
    menuID,
    catID,
    catMenuID,
  );
  return every(
    filter(
      [itemOption?.isActive, catOption?.isActive, catMenuOption?.isActive],
      (isActive) => isActive !== undefined,
    ),
  );
}

export function getAvailabilityOfItem(
  menuID,
  catID,
  catMenuID,
  optionList,
  selectedDeliveryMethod,
  selectedDateTime,
) {
  const { itemOption, catOption, catMenuOption } = getAvailabilityOptions(
    optionList,
    menuID,
    catID,
    catMenuID,
  );

  // set options according to order priority CM > C > M

  if (!isEmpty(itemOption) || !isEmpty(catOption) || !isEmpty(catMenuOption)) {
    let isItemActive = false;
    let isDeliveryTypeAvailable = false;
    let isTimeAvailable = false;
    let reasonCode = "";

    // item availability check
    isItemActive = getIsItemActive(optionList, menuID, catID, catMenuID);

    let priorityOption = catOption;
    if (isEmpty(priorityOption)) priorityOption = itemOption;
    if (isEmpty(priorityOption)) priorityOption = catMenuOption;

    // delivery type check
    if (isEmpty(priorityOption.deliveryTypes)) {
      isDeliveryTypeAvailable = true;
    } else if (
      priorityOption.deliveryTypes.includes(
        convertDeliveryType(selectedDeliveryMethod),
      )
    ) {
      isDeliveryTypeAvailable = true;
    } else {
      isDeliveryTypeAvailable = false;
      reasonCode = "DELIVERY_TYPE";
    }

    // time availability check
    if (isEmpty(priorityOption.availability)) {
      isTimeAvailable = true;
    } else if (
      TimeUtils.isTimeInWeeklyTimes(
        moment(selectedDateTime),
        priorityOption.availability,
      )
    ) {
      isTimeAvailable = true;
    } else {
      isTimeAvailable = false;
      reasonCode = "AVAILABILITY";
    }

    return {
      isItemAvailable:
        isItemActive && isTimeAvailable && isDeliveryTypeAvailable,
      option: priorityOption,
      isItemActive,
      reasonCode,
    };
  }
  return {
    isItemAvailable: true,
    option: null,
    isItemActive: true,
    reasonCode: "",
  };
}

const filterCategoryListByAvailability = (
  catList,
  optionList,
  selectedDateTime,
  selectedDeliveryMethod,
) => {
  const categoryOptions = Object.values(optionList)
    .filter((option) => option.entityId.startsWith("C"))
    .filter((option) => !option.entityId.includes("M"));

  return _.pickBy(catList, (catData, catKey) => {
    const catOption = categoryOptions.find((op) => op.entityId === catKey);
    if (catOption) {
      // check category is active
      if (!catOption.isActive) return false;

      let isTimeAvailable = false;
      let isDeliveryTypeAvailable = false;

      // check category availability
      if (isEmpty(catOption.availability)) {
        isTimeAvailable = true;
      } else {
        isTimeAvailable = TimeUtils.isTimeInWeeklyTimes(
          moment(selectedDateTime),
          catOption.availability,
        );
      }
      // check category delivery type
      if (isEmpty(catOption.deliveryTypes)) {
        isDeliveryTypeAvailable = true;
      } else {
        isDeliveryTypeAvailable = catOption.deliveryTypes.includes(
          convertDeliveryType(selectedDeliveryMethod),
        );
      }

      // final comparison
      if (isDeliveryTypeAvailable && isTimeAvailable) {
        return true;
      }
      return false;
    }
    return true;
  });
};

export function getAvailableCatMenuList(
  catMenuList,
  optionList,
  selectedDeliveryMethod,
  selectedDateTime,
) {
  let availCatMenuList = [];

  availCatMenuList = [...catMenuList];

  if (isEmpty(optionList)) return availCatMenuList;

  // check time avail
  availCatMenuList = [...availCatMenuList].map((catMenu) => {
    let isTimeAvailable = false;
    let isDeliveryTypeAvailable = false;

    const catMenuNew = { ...catMenu };
    const matchingCatMenuOption = Object.values(optionList).find(
      (option) => `CM${catMenu.catMenuId}` === option.entityId,
    );

    if (matchingCatMenuOption) {
      catMenuNew.isActive = matchingCatMenuOption.isActive;

      if (isEmpty(matchingCatMenuOption.availability)) {
        isTimeAvailable = true;
      } else if (
        TimeUtils.isTimeInWeeklyTimes(
          moment(selectedDateTime),
          matchingCatMenuOption.availability,
        )
      ) {
        isTimeAvailable = true;
      } else {
        isTimeAvailable = false;
      }

      if (isEmpty(matchingCatMenuOption.deliveryTypes)) {
        isDeliveryTypeAvailable = true;
      } else if (
        matchingCatMenuOption.deliveryTypes.includes(
          convertDeliveryType(selectedDeliveryMethod),
        )
      ) {
        isDeliveryTypeAvailable = true;
      } else {
        isDeliveryTypeAvailable = false;
      }

      if (isDeliveryTypeAvailable && isTimeAvailable) {
        catMenuNew.isAvailable = true;
      } else {
        catMenuNew.isAvailable = false;
      }
    } else {
      catMenuNew.isAvailable = true;
    }
    return catMenuNew;
  });

  const activeCatMenus = availCatMenuList.filter((cm) => cm.isActive);
  const catMenuListFiltered = activeCatMenus.filter((cm) => cm.isAvailable);
  const catMenuListAfterCategoriesFilteredByAvailability = catMenuListFiltered.map(
    (catMenu) => {
      const _catMenu = { ...catMenu };
      _catMenu.data = filterCategoryListByAvailability(
        catMenu.data,
        optionList,
        selectedDateTime,
        selectedDeliveryMethod,
      );
      return _catMenu;
    },
  );
  return catMenuListAfterCategoriesFilteredByAvailability;
}
