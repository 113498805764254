import React, { useEffect } from "react";

import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import { getOrderState } from "../../../../utils/order";

const useStyles = makeStyles((theme) => ({
  OrderStepperWrapper: {
    width: "100%",
    backgroundColor: (props) => props.statusColor,
  },
  root: {
    padding: 8,
    backgroundColor: (props) => props.statusColor,
    height: 22,
    color: "white",
    maxWidth: 400,
    margin: "0 auto",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  orderStatusLabel: {
    backgroundColor: (props) => props.statusColor,
    textAlign: "center",
    color: "white",
    fontWeight: "bold",
  },
}));

function StepLabelText({ label }) {
  return (
    <Typography variant="subtitle1">
      <strong>{label}</strong>
    </Typography>
  );
}

function getSteps(deliveryIsAsap) {
  return [
    <StepLabelText
      label={
        deliveryIsAsap
          ? "Confirming order with restaurant"
          : "Order sent to the restaurant"
      }
    />,
    <StepLabelText label="Your order is being prepared" />,
    <StepLabelText label="Your order is ready" />,
  ];
}

function getOrderStatusLabel(orderStatus, deliveryIsAsap) {
  switch (getOrderState(orderStatus)) {
    case 0:
      return deliveryIsAsap
        ? "Confirming order with restaurant"
        : "Order sent to the restaurant";
    case 1:
      return "Your order is being prepared";
    case 2:
      return "Your order is ready";
    default:
      return "";
  }
}

const OrderStepper = ({ orderStatus, statusColor, deliveryIsAsap }) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps(deliveryIsAsap);

  useEffect(() => {
    setActiveStep(getOrderState(orderStatus));
  }, [orderStatus]);

  const classes = useStyles({ statusColor });

  return (
    <div className={classes.OrderStepperWrapper}>
      <Stepper
        classes={{ root: classes.root }}
        activeStep={activeStep}
        nonLinear
        alternativeLabel
      >
        {steps.map((label, index) => (
          <Step key={String(index)}>
            <StepLabel active={index === activeStep} />
            {/* <StepContent>
              <div
                style={{
                  display: "flex",
                  position: "relative",
                }}
              >
                <div style={{ width: "60%" }}>
                  {getStepContent(index)}
                </div>
              </div>
            </StepContent> */}
          </Step>
        ))}
      </Stepper>
      <Typography className={classes.orderStatusLabel} variant="subtitle1">
        {getOrderStatusLabel(orderStatus, deliveryIsAsap)}
      </Typography>
    </div>
  );
};

OrderStepper.propTypes = {};
OrderStepper.defaultProps = {};

export default OrderStepper;
