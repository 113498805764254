/* eslint-disable react/jsx-props-no-spreading */
import React from "react";

import { makeStyles, TextField } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiInputBase-root": {
      margin: theme.spacing(1, 0),
      color: "black",
      border: "1px solid #e0ca79",
    },
    "& .MuiInputLabel-root": {
      color: "black",
    },
  },
}));

const TextFieldCommon = ({ color = "secondary", inputProps, ...props }) => {
  const classes = useStyles();
  const inputStyle = { WebkitBoxShadow: "0 0 0 1000px transparent inset", WebkitTextFillColor: "black" };

  return (
    <TextField
      color={color}
      classes={{ root: classes.root }}
      variant="filled"
      {...props}
      inputProps={{ style: inputStyle, maxLength: 100, ...inputProps }}
    />
  );
};

export default TextFieldCommon;
