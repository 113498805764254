import React, { useState } from "react";

import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
  useTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import Color from "color";
import moment from "moment";

import { STICKY_BUTTON_HEIGHT } from "../../../components/common/buttons/StickyButton";
import { formatPrice } from "../../../utils/utils";
import { useRouteMatch } from "react-router-dom";
import useRouteNavigation from "../../../hooks/RouteHooks/useRouteNavigation";
import AlertMessage from "../../../components/common/AlertMessage";
import { Skeleton } from "@material-ui/lab";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

export const HEADER_HEIGHT = 15;
const CONTENT_SECTION_PADDING = 32;

const VIEWPORT_OFFSET =
  HEADER_HEIGHT + CONTENT_SECTION_PADDING + STICKY_BUTTON_HEIGHT;

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    color: theme.palette.text.itemTextColor,
    width: "100%",
    margin: "8px",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    padding: "12px",
    [theme.breakpoints.down("sm")]: {
      height: 1200,
      minHeight: (props) =>
        props.isOrderStatusPage
          ? `calc(100vh - ${STICKY_BUTTON_HEIGHT}px)`
          : `calc(100vh - ${VIEWPORT_OFFSET}px)`,
      maxHeight: (props) =>
        props.isOrderStatusPage
          ? `calc(100vh - ${STICKY_BUTTON_HEIGHT}px)`
          : `calc(100vh - ${VIEWPORT_OFFSET}px)`,
    },
    [theme.breakpoints.down("md")]: {
      height: 2000,
      minHeight: (props) =>
        props.isOrderStatusPage
          ? `calc(100vh - ${STICKY_BUTTON_HEIGHT}px)`
          : `calc(100vh - ${VIEWPORT_OFFSET}px)`,
      maxHeight: (props) =>
        props.isOrderStatusPage
          ? `calc(100vh - ${STICKY_BUTTON_HEIGHT}px)`
          : `calc(100vh - ${VIEWPORT_OFFSET}px)`,
    },
    [theme.breakpoints.up("md")]: {
      maxHeight: 516,
      minHeight: 516,
    },
    overflowY: "auto",
    backgroundColor: Color(
      theme.palette.background.menuBackgroundColor,
    ).isDark()
      ? Color(theme.palette.background.menuBackgroundColor)
          .mix(Color("#fff"), 0.1)
          .hex()
      : Color(theme.palette.background.menuBackgroundColor)
          .mix(Color("#000"), 0.1)
          .hex(),
    ...theme.palette.scrollbarStyles,
    "&::-webkit-scrollbar": {
      width: 0,
    },
  },
  skeletonStyle: {
    borderRadius: "10px",
    marginBottom: "4px",
    marginLeft: "8px",
    backgroundColor: Color(
      theme.palette.background.menuBackgroundColor,
    ).isDark()
      ? Color(theme.palette.background.menuBackgroundColor)
          .mix(Color("#fff"), 0.1)
          .hex()
      : Color(theme.palette.background.menuBackgroundColor)
          .mix(Color("#000"), 0.1)
          .hex(),
    ...theme.palette.scrollbarStyles,
    "&::-webkit-scrollbar": {
      width: 0,
    },
    [theme.breakpoints.down("sm")]: {
      height: 1200,
      minHeight: (props) =>
        props.isOrderStatusPage
          ? `calc(100vh - ${STICKY_BUTTON_HEIGHT}px)`
          : `calc(100vh - ${VIEWPORT_OFFSET}px)`,
    },
    [theme.breakpoints.down("md")]: {
      height: 2000,
      minHeight: (props) =>
        props.isOrderStatusPage
          ? `calc(100vh - ${STICKY_BUTTON_HEIGHT}px)`
          : `calc(100vh - ${VIEWPORT_OFFSET}px)`,
    },
    [theme.breakpoints.up("md")]: {
      minHeight: 514,
    },
  },
  divider: {
    backgroundColor: theme.palette.background.boarderColor,
    marginTop: "8px",
    marginBottom: "8px",
    width: "100%",
  },
}));

const OrderList = ({
  orderNodeData,
  isOrderStatusPage,
  isLoading,
  handleScroll,
  containerRef,
  isLoadingScroll,
}) => {
  const [alertMessage, setAlertMessage] = useState("");

  const { params } = useRouteMatch();

  const { navigateRoute } = useRouteNavigation();
  const windowHeight = window.innerHeight;
  const handleContinue = (orderDetails) => {
    const { id } = orderDetails;
    const pathname = `/location/${params.locationId}/my-orders/${id}`;
    return navigateRoute(pathname);
  };

  const handleBackButton = () => {
    const pathname = `/location/${params.locationId}/shop`;
    return navigateRoute(pathname);
  };

  const classes = useStyles({ isOrderStatusPage, windowHeight });
  const theme = useTheme();

  const formatDate = (date) => {
    const formattedDate = moment(date).format("Do MMM YYYY h:mmA");
    const words = formattedDate.split(" ");
    words[1] = words[1].toUpperCase();
    return formattedDate;
  };

  return (
    <>
      <AlertMessage
        message={alertMessage}
        setMessage={setAlertMessage}
        severity="error"
      />
      <Grid container>
        <Grid item xs={12} sm={6} lg={4}>
          <div
            style={{
              display: "flex",
              color: theme.palette.text.itemTextColor,
              padding: "8px",
            }}
          >
            <Button
              style={{ color: theme.palette.text.primaryTextColor }}
              onClick={handleBackButton}
            >
              <ArrowBackIcon style={{ paddingRight: "4px" }} />
              <Typography>Back</Typography>
            </Button>
          </div>
          <Grid container>
            <Grid item xs={12} style={{ marginRight: "16px" }}>
              {!isLoading ? (
                !_.isEmpty(orderNodeData) ? (
                  <div
                    style={{ height: "100%" }}
                    className={classes.cardWrapper}
                    ref={containerRef}
                    onScroll={handleScroll}
                  >
                    {orderNodeData.map((orderDetails) => (
                      <>
                        <Button
                          style={{ width: "100%" }}
                          onClick={() => handleContinue(orderDetails)}
                        >
                          <Grid container>
                            <Grid
                              item
                              xs={9}
                              style={{
                                display: "flex",
                                justifyContent: "start",
                              }}
                            >
                              <div>
                                <Typography
                                  align="left"
                                  style={{
                                    color: theme.palette.text.itemTextColor,
                                  }}
                                >
                                  #{orderDetails.displayRef}
                                </Typography>
                                <Typography
                                  align="left"
                                  style={{
                                    color: theme.palette.text.itemTextColor,
                                    textTransform: "none",
                                  }}
                                >
                                  {formatDate(orderDetails.dueTime)}
                                </Typography>
                              </div>
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              style={{
                                display: "flex",
                                justifyContent: "end",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                style={{
                                  color: theme.palette.text.itemTextColor,
                                }}
                              >
                                {formatPrice(orderDetails.paymentAmount)}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Button>
                        <Divider className={classes.divider} />
                      </>
                    ))}
                    {isLoadingScroll && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "40px",
                          width: "100%",
                        }}
                      >
                        <CircularProgress size={"28px"} disableShrink />
                      </div>
                    )}
                  </div>
                ) : (
                  <Grid
                    container
                    style={{ height: "100%" }}
                    className={classes.cardWrapper}
                  >
                    <Typography variant="h6">
                      You don’t have any previous orders.
                    </Typography>
                  </Grid>
                )
              ) : (
                <>
                  <Skeleton
                    variant="rectangular"
                    width="100%"
                    height={60}
                    className={classes.skeletonStyle}
                  />
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

OrderList.propTypes = {};
OrderList.defaultProps = {};

export default OrderList;
