import React from "react";

import { IconButton, makeStyles, Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import CancelIcon from "@material-ui/icons/Cancel";

const useStyles = makeStyles((theme) => ({
  CouponCardWrapper: {
    padding: 8,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: 300,
    [theme.breakpoints.down("xs")]: {
      maxWidth: "none",
    },
  },
}));

const CouponCard = ({ couponCode, handleRemoveCoupon }) => {
  const classes = useStyles();
  return (
    <Paper elevation={1} className={classes.CouponCardWrapper} component="span">
      <div>
        <Typography variant="subtitle1" component="span">
          Coupon Code:&nbsp;
        </Typography>
        <Typography variant="subtitle1" component="span">
          {couponCode}
        </Typography>
      </div>
      <IconButton aria-label="delete" color="primary" onClick={handleRemoveCoupon}>
        <CancelIcon />
      </IconButton>
    </Paper>

  );
};

CouponCard.propTypes = {

};
CouponCard.defaultProps = {

};

export default CouponCard;
