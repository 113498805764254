/* eslint-disable no-lonely-if */
import React, { useEffect, useState } from "react";

import { FormControl, Grid, Hidden, InputLabel } from "@material-ui/core";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import _ from "lodash";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";

import ContentContainer from "../../../../../../../containers/ContentContainer";
import store from "../../../../../../../redux";
import { logError, logInfo } from "../../../../../../../services/logging";
import { completeOrder } from "../../../../../../../services/orderService";
// import {
//   EMAIL_REGEX_PATTERN,
//   UK_PHONE_NUMBER_REGEX_PATTERN,
// } from "../../../../../../../utils/constants";
import { fetchPaymentErrorInfo } from "../../../../../../../services/paymentService";
import FacebookPixel from "../../../../../../../utils/analytics/FacebookPixel";
import {
  ERROR_UNEXPECTED_CHECK_CONNECTION,
  PaymentRules,
} from "../../../../../../../utils/constants";
import {
  clearLocalStoreOnOrderSuccess,
  getLocalStore,
} from "../../../../../../../utils/storage/localStorage";
import { getCompleteOrderErrorMessage } from "../../../../../../../utils/utils";
import AlertMessage from "../../../../../../common/AlertMessage";
import BottomNavButtons from "../../../../../../common/BottomNavButtons";
import PaymentErrorAlertDialog from "../../../../../../common/dialogs/PaymentErrorAlertDialog";
import ErrorBoundary from "../../../../../../common/error/ErrorBoundary";
import StickyBtnWrapper from "../../../../../../common/StickyBtnWrapper";
// import TextFieldDefault from "../../../../../../common/textFields/TextFieldDefault";
import MobileStepper from "../../../../../../Stepper/MobileStepper";
import GooglePay from "../../../GooglePay";
import CardSection from "../CardSection";

export default function CheckoutForm({
  paymentData,
  setOpenCardPaymentSection,
  openCardPaymentSection,
  setShowSuccessModal,
  setTimer,
  timer,
}) {
  const [error, setError] = useState("");
  const [stripeError, setStripeError] = useState("");
  const [success, setSuccess] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState({
    postalCode: "",
  });
  const [isReversedAfterApproval, setIsReversedAfterApproval] = useState(false);
  // const { register, errors, trigger } = useForm();
  const { trigger } = useForm();

  const { params } = useRouteMatch();
  const history = useHistory();
  const { storeInfo } = useSelector((state) => state.store);
  const { cartItems } = useSelector((state) => state.cart);

  // const [billingInfo, setBillingInfo] = useState({
  //   cartHolderName: "",
  //   email: "",
  //   phone: "",
  //   address: {
  //     line1: "",
  //     line2: "",
  //     city: "",
  //     state: "",
  //   },
  //   city: "",
  //   state: "",
  //   zip: "",
  // });

  const stripe = useStripe();
  const elements = useElements();

  useEffect(
    () => () => {
      clearTimeout(timer);
    },
    [timer],
  );

  const storeCleanup = () => {
    clearLocalStoreOnOrderSuccess();
    store.dispatch({
      type: "RESET",
    });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const orderId = getLocalStore("orderId");
    const isValid = await trigger();
    if (!isValid) return setIsLoading(false);
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return setIsLoading(false);
    }

    const handlePaymentError = async (paymentError) => {
      setIsReversedAfterApproval(false);
      const errorMessages = { error: "", subError: "" };

      if (paymentData.paymentGatewayProvider === "express") {
        try {
          const {
            data: { data },
          } = await fetchPaymentErrorInfo(
            params.locationId,
            paymentError.charge,
          );

          if (data.networkStatus === "reversed_after_approval") {
            setIsReversedAfterApproval(true);
          }
          if (data.reason === "rule") {
            const customError = PaymentRules[data.rule];
            if (!_.isEmpty(customError)) {
              errorMessages.error = customError.error1;
              errorMessages.subError = customError.error2;
              return setStripeError(errorMessages);
            }
          }

          errorMessages.error = paymentError.message;
          return setStripeError(errorMessages);
        } catch (err) {
          if (paymentError.message) {
            errorMessages.error = paymentError.message;
            return setStripeError(errorMessages);
          }
          return setError(ERROR_UNEXPECTED_CHECK_CONNECTION);
        }
      } else {
        if (paymentError.message) {
          errorMessages.error = paymentError.message;
          return setStripeError(errorMessages);
        }
        return setError(ERROR_UNEXPECTED_CHECK_CONNECTION);
      }
    };

    const result = await stripe.confirmCardPayment(paymentData.secret, {
      payment_method: {
        card: elements.getElement(CardElement),
        // billing_details: {
        //   name: billingInfo.cartHolderName,
        //   email: billingInfo.email,
        //   phone: billingInfo.phone,
        //   address: {
        //     line1: billingInfo.address.line1,
        //     line2: billingInfo.address.line2,
        //     city: billingInfo.address.city,
        //     state: billingInfo.address.state,
        //   },
        // },
      },
    });
    logInfo({ message: "confirm card payment result", result, paymentInfo });

    if (result.error) {
      // Show error to your customer (e.g., insufficient funds)
      logError({
        message: "WARNING | Error confirm card payment request",
        error: result.error,
        paymentInfo,
      });
      handlePaymentError(result.error);
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === "succeeded") {
        logInfo({
          message:
            "payment intent status was successful. payment processed successfully. making complete order request",
        });
        const paymentAmount = getLocalStore("paymentAmount");
        FacebookPixel.purchase(cartItems, paymentAmount);
        // if (storeInfo?.tikTokAnalyticsId) {
        //   TicktockPixel.completePayment(cartItems, paymentAmount);
        // }
        // Show a success message to your customer
        setSuccess("Payment processed successfully");
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
        try {
          const res = await completeOrder(
            params.locationId,
            orderId,
            getCompleteOrderErrorMessage(
              storeInfo.businessDisplayName,
              storeInfo.contactNo,
            ),
          );
          logInfo({
            message: "complete order request made. response received",
            completeOrderRequestRes: res?.data,
          });
          if (res.status === 200) {
            logInfo({
              message:
                "order completed successfully. redirecting to order status page",
            });
            setShowSuccessModal(true);
            const timeout = setTimeout(() => {
              setShowSuccessModal(false);
              storeCleanup();
              history.replace(
                `/location/${params.locationId}/order-status/${orderId}`,
              );
            }, 5000);
            setTimer(timeout);
          } else {
            // setAlertMessage("Error placing the order!");
            logError({
              message:
                "CRITICAL | Error complete order request. Did not receive 200 OK",
            });
            setError(
              "Error occurred when completing order. Please check your connection.",
            );
          }
        } catch (err) {
          logError({
            message:
              "CRITICAL | Error complete order request. EXCEPTION OCCURRED",
            error: err,
            errorRes: err?.response,
          });
          setError(
            err?.response?.data?.message ||
              "Unexpected error. Please check your connection",
          );
        }
      }
    }
    return setIsLoading(false);
  };

  // const handleChange = (e) => {
  //   const { name: nameProp, value } = e.target;
  //   setBillingInfo((prevState) => ({ ...prevState, [nameProp]: value }));
  // };

  // const handleChangeAddress = (e) => {
  //   const { name, value } = e.target;
  //   setBillingInfo((prevState) => ({
  //     ...prevState,
  //     address: { ...prevState.address, [name]: value },
  //   }));
  // };

  return (
    <>
      <PaymentErrorAlertDialog
        error={stripeError.error}
        subError={stripeError.subError}
        open={!_.isEmpty(stripeError)}
        onClose={() => setStripeError({})}
        isReversedAfterApproval={isReversedAfterApproval}
      />
      <AlertMessage message={error} setMessage={setError} severity="error" />
      <AlertMessage
        message={success}
        setMessage={setSuccess}
        severity="success"
      />
      <MobileStepper
        activeStep={4}
        handleNext={handleSubmit}
        handleBack={() => setOpenCardPaymentSection(false)}
        labelNext="Done"
        isLoading={isLoading}
      />
      <ContentContainer>
        <ErrorBoundary>
          {paymentData && (
            <GooglePay
              paymentData={paymentData}
              setError={setError}
              setShowSuccessModal={setShowSuccessModal}
              setTimer={setTimer}
              setSuccess={setSuccess}
            />
          )}
        </ErrorBoundary>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            {/* <TextFieldDefault
              variant="filled"
              label="Cardholder name"
              id="cartHolderName"
              required
              name="cartHolderName"
              value={billingInfo.cartHolderName}
              fullWidth
              onChange={handleChange}
              inputRef={register({
                required: true,
                maxLength: 30,
              })}
              helperText={
                errors.cartHolderName
                  ? "Please enter valid card holder name"
                  : ""
              }
              error={errors.cartHolderName?.type}
            /> */}
          </Grid>
          <Grid item xs={12} sm={6}>
            {/* <TextFieldDefault
              variant="filled"
              label="Email"
              id="email"
              required
              name="email"
              value={billingInfo.email}
              fullWidth
              inputRef={register({
                required: true,
                maxLength: 50,
                // pattern: EMAIL_REGEX_PATTERN,
              })}
              helperText={errors.email ? "Please enter valid email" : ""}
              error={errors.email?.type}
              onChange={handleChange}
            /> */}
          </Grid>
          <Grid item xs={12} sm={6}>
            {/* <TextFieldDefault
              variant="filled"
              id="phone"
              label="phone"
              required
              name="phone"
              value={billingInfo.phone}
              fullWidth
              inputRef={register({
                required: true,
                maxLength: 30,
                pattern: UK_PHONE_NUMBER_REGEX_PATTERN,
              })}
              helperText={errors.phone ? "Please enter valid phone number" : ""}
              error={errors.phone?.type}
              onChange={handleChange}
            /> */}
          </Grid>
          <Grid item xs={12} sm={6}>
            {/* <TextFieldDefault
              variant="filled"
              label="Address line 1"
              id="line1"
              required
              name="line1"
              value={billingInfo.address.line1}
              fullWidth
              inputRef={register({
                required: true,
                maxLength: 50,
              })}
              helperText={
                errors.line1 ? "Please enter valid address line 1" : ""
              }
              error={errors.line1?.type}
              onChange={handleChangeAddress}
            /> */}
          </Grid>
          <Grid item xs={12} sm={6}>
            {/* <TextFieldDefault
              variant="filled"
              label="Address line 2"
              id="line2"
              name="line2"
              value={billingInfo.address.line2}
              fullWidth
              inputRef={register({
                required: false,
                maxLength: 50,
              })}
              helperText={
                errors.line2 ? "Please enter valid address line 2" : ""
              }
              error={errors.line2?.type}
              onChange={handleChangeAddress}
            /> */}
          </Grid>
          <Grid item xs={12} sm={6}>
            {/* <TextFieldDefault
              label="City"
              variant="filled"
              id="city"
              required
              name="city"
              value={billingInfo.address.city}
              fullWidth
              inputRef={register({
                required: true,
                maxLength: 30,
              })}
              helperText={errors.city ? "Please enter valid city" : ""}
              error={errors.city?.type}
              onChange={handleChangeAddress}
            /> */}
          </Grid>
          <Grid item xs={12} sm={6}>
            {/* <TextFieldDefault
              variant="filled"
              label="County"
              id="state"
              name="state"
              value={billingInfo.address.state}
              fullWidth
              inputRef={register({
                required: false,
                maxLength: 30,
              })}
              helperText={errors.state ? "Please enter valid county" : ""}
              error={errors.state?.type}
              onChange={handleChangeAddress}
            /> */}
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl fullWidth>
              <InputLabel
                shrink
                margin="dense"
                variant="filled"
                htmlFor="cardNumber"
              >
                Card
              </InputLabel>
              <CardSection setPaymentInfo={setPaymentInfo} />
            </FormControl>
          </Grid>
        </Grid>
      </ContentContainer>
      {openCardPaymentSection && (
        <div>
          <Hidden xsDown>
            <BottomNavButtons
              handleBackward={() => setOpenCardPaymentSection(false)}
              handleContinue={handleSubmit}
              continueButtonLabel="Confirm order"
              continueButtonIsLoading={isLoading}
            />
          </Hidden>

          <Hidden smUp>
            <StickyBtnWrapper>
              <BottomNavButtons
                handleBackward={() => setOpenCardPaymentSection(false)}
                handleContinue={handleSubmit}
                continueButtonLabel="Confirm order"
                continueButtonIsLoading={isLoading}
              />
            </StickyBtnWrapper>
          </Hidden>
        </div>
      )}
    </>
  );
}
